import React, { Component } from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import axios from 'axios';
import { getAPIHostURL, getLPGSingleDeviceDataRefreshTime, getSingleDeviceDataRefreshTime } from '../../ClientConfig';
import '../CSS/SingleDeviceData.css';
import '../CSS/VcToiletNodeDashboardData.css';
import { IDS_RefreshData, IDS_SrvrIssueDevcParamsNotRec, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_DevcNoDataOnSrvr, IDS_NoOfPpl, IDS_TodayPeopleCount, 
    IDS_DetailsAndRanges, IDS_BarelySensed, IDS_WeakOdour, IDS_EasilyRecognizedInSingleDevcData, IDS_Pungent, IDS_StrongOdour, 
    IDS_SDDNH3ODLevel1, IDS_SDDNH3ODLevel2, IDS_SDDNH3ODLevel3, IDS_SDDNH3ODLevel4, IDS_SDDNH3ODLevel5, IDS_nitrogenDioxide, IDS_Ozone, IDS_sulphurDioxide, IDS_TVOC, IDS_carbonMonoxide, IDS_Ammonia, IDS_carbonDioxide, 
    IDS_hydrogenSulphide, IDS_Methane, IDS_LPG, IDS_SMOKE, IDS_Dust, IDS_Temperature, IDS_Humidity, IDS_Odour, IDS_PPB, IDS_PPM, 
    IDS_Celsius, IDS_Percent, IDS_NoRange, IDS_Upto, IDS_NormalRange, IDS_Index, IDS_VRI, IDS_MRI, IDS_DetailsAndRangesOfSensor, IDS_ParamsRange,IDS_WhatIsVirusRiskIndex, IDS_WhatIsMouldRiskIndex,
    IDS_RangeLowSevere, IDS_ColdAndChilly, IDS_Good, IDS_Comfortable, IDS_Poor, IDS_Warm, IDS_Hot, IDS_RangeHighSevere, IDS_Dry, IDS_Humid, IDS_Sticky,
    IDS_BarelySensedInSingleDeviceData, IDS_Moderate, IDS_WeakOdourInSingleDeviceData, IDS_EasilyRecognizedInSingleDeviceData, IDS_RangeVeryPoor, IDS_StrongOdourInSingleDeviceData,
    IDS_Severe, IDS_PungentInSingleDeviceData, IDS_Onwards, IDS_AlertLevel1, IDS_AlertLevel2, IDS_AlertLevel3, IDS_AlertLevel4, IDS_AlertLevel5, IDS_TotalSeats, IDS_OccupiedSeats, IDS_SanitationMapping, IDS_Dvc_Blocked, IDS_LowSevere, IDS_VeryPoor, 
    IDS_Weak,IDS_EasilyRecognized, IDS_Strong, IDS_ToiletOdour, IDS_WL, IDS_AvailableWater, IDS_CW, IDS_SL, IDS_Litre_Short, IDS_airQualityIndex, IDS_currentAirQualityIndex, IDS_Satisfactory, IDS_PoorAqi, IDS_VeryPoorAqi, IDS_SevereAqi, IDS_AlertLevel6, IDS_SatisfactoryInSingleDeviceData,
    IDS_Formaldehyde, IDS_Oxygen, IDS_PM100, IDS_NitricOxide, IDS_Chlorine, IDS_MethylMercaptan, IDS_Noise, IDS_LightIntensity, IDS_UV, IDS_Radon, IDS_AirPressure, IDS_WindSpeed, IDS_WindDirection, IDS_DaylightVisibility, IDS_Rainfall, IDS_Hydrogen,
    IDS_North, IDS_Northeast, IDS_East, IDS_Southeast, IDS_Southwest, IDS_South, IDS_West, IDS_Northwest,
    IDS_LightRain, IDS_ModerateRain, IDS_HeavyRain, IDS_VeryHeavyRain, IDS_TorrentialRain, IDS_GoodVisibility, IDS_ModerateVisibility, IDS_FairVisibility, IDS_PoorVisibility, IDS_CalmBreeze, IDS_ModerateBreeze, IDS_StrongGale, IDS_Storm, IDS_LowLightIntensity, IDS_MediumLightIntensity, IDS_HighLightIntensity, IDS_LowNoiseLevel, IDS_MediumNoiseLevel, IDS_HighNoiseLevel,
    IDS_ExtremeLow, IDS_Low, IDS_High, IDS_ExtremeHigh, IDS_Normal,
    IDS_Extreme,
    IDS_VeryHigh,
    IDS_VeryLow,
    IDS_Litre,
    IDS_RangeLowVeryPoor,
    IDS_HygieneMsgGood,
    IDS_HygieneMsgSatisfactory,
    IDS_HygieneMsgModerate,
    IDS_HygieneMsgPoor,
    IDS_HygieneMsgVPoor,
    IDS_HygieneMsgSevere,
    IDS_Device,
    IDS_GentleBreeze,
    IDS_SatisfactoryOdour,
    IDS_SDDNH3ODLevel6, IDS_SafeRange, IDS_WarningRange, IDS_CriticalRange, IDS_EmergencyRange, IDS_HazardousRange,
    IDS_SDD_GasLeakageDetected,
    IDS_SDD_NoGasLeakage,
    IDS_NoParameter} from '../../VcLanguage';
import { NH3OD, PM1, PM25, PM10, HUM, TEMP, LOW_SEVERE_L, LOW_SEVERE_U, GOOD_L, GOOD_U, MODERATE_L, MODERATE_U, POOR_L, POOR_U, V_POOR_L, V_POOR_U,
     HIGH_SEVERE_L, HIGH_SEVERE_U, ALERT_BASED, DISP_GOOD_RANGE, NO2, O3, SO2, VOC, CO, NH3, CO2, H2S, CH4, LPG, SMOKE, MRI, VRI, UPTO, NO_CALIB, ACTIVE_STATUS_MINIMUM_TIME,
     DISP_LOW_SEVERE_RANGE, DISP_MODERATE_RANGE, DISP_POOR_RANGE, DISP_VERY_POOR_RANGE, DISP_HIGH_SEVERE_RANGE, MEN_TOILET_SECTION, WOMEN_TOILET_SECTION,
     LOW_SEVERE, GOOD, MODERATE, POOR, V_POOR, HIGH_SEVERE, ONWARDS, DEVICE_TYPE_PFC, DEVICE_TYPE_WLI, DEVICE_TYPE_STLI, PEOPLE_COUNTER, WATER_LEVEL_INDICATOR, SEPTIC_TANK_LEVEL_INDICATOR, GANDHVEDH, AQI, CAQI, SATISFACTORY, SATISFACTORY_U, SATISFACTORY_L, DISP_SATISFACTORY_RANGE, NO_DEVC_FOUND_FOR_LOGGED_IN_USER,
     HCHO, O2, PM100, NO, CL, CH3SH, NOISE, LUX, UV, RADON, AP, WS, WD, DLV, RNFL, H2, DISP_LOW_VERY_POOR_RANGE, LOW_V_POOR_L,
     LOW_V_POOR_U, PARAM_FOR_SHOWING_IMPACTS, MODEL_LPG
    } from '../../VcConstants';
import { convertLocalDateToStrYYYYMMDDHH24MMSS, convertUTCDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil';
import { FaSadTear, FaSmileBeam, FaFrownOpen, FaDizzy, FaMeh, FaWalking, FaSearch, FaFemale, FaMale, FaBan, FaCircle, FaGrinBeam, FaSmile} from 'react-icons/fa';
import { AiOutlineArrowDown, AiOutlineArrowUp} from 'react-icons/ai';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FiInfo } from "react-icons/fi";
import VcVRIandMRIInfo from './VcVRIandMRIInfo';
import { connect } from 'react-redux';
import { addToOpenToiletNodes, setSearchedOrClickedToiletNode } from './Store/Slices/treeAndSearchSlice';
import VcDetailsAndRanges from './VcDetailsAndRanges';

export class VcToiletNodeDashboardData extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            modelNameForNodeDashboard: [],
            lastIndexOfModelArray: 0,
            devicesListForNodeDashboard: [],
            arrAssignedDevicesToNode: [],
            receivedDeviceModelInfo: [],
            havingPvgToViewBlockedDeviceData: false,
            LoggedInUserID: "",
            SingleDeviceData: [],
            arrReceivedStdAirQualityRange: [],
            selectedParameterAtGlance: "",
            AlertBasedParam: [],
            arrTodaysPplCount: [],
            LogTime: [],
            isDeviceBlocked: false,
            arrDeviceIDsForPFC: [],
            arrDeviceIDsForWLI: [],
            arrDeviceIDsForSTLI: [],
            arrDeviceIDsForHHM: [],
            BlockedUnblockedStatusOfDevices: [],
            allHHMDeviceDataDetails: [],
            allHHMDeviceDetailsArrWhoseDataDoesntExist: [],
            stdAirQualityRanges: [],
            allDeviceDataDetails: [],
            pfcDeviceValues: [],
            allWLIDeviceDetails: [],
            allSTLIDeviceDetails: [],
            Good:[],
            Moderate: [],
            Poor:[],
            VeryPoor:[],
            Severe: [],
            nullValue:[],
            showNormalRange: [],
            ToiletDashboardVisible: false,
            modalFromIcon: false,
            selectedParamToViewRangeDetails: "",
            arrOfRanges: [],
            backdrop: 'static',
            ToiletSection: "",
            TotalToiletSeats: "",
            selectedToiletNode: "",
            paramToShowDetailedInfo: "",
            modalFromInfoButton: false,
            backdropForInfoButton: 'static',
            countOfDevicesHavingNH3ODVal: 0,
            objIncludeAvgBgColorAndRangeOfToilet: [],
            waterLevelIndicationPrcnt: "",
            availableWaterLtr: "",
            TankVolumeLtr: "",
            TotalConsumedWaterLtr: "",
            septicTankLevelIndicationPrcnt: "",
            showImpactsOfParam: false,
            
            errors: {
                others: 'Please wait. Fetching Details for the Device from the server.'
            },
        }
    }

    componentDidMount () { 
    
        let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

        // Clear interval if present.
        if(this.interval != null) {
            clearInterval(this.interval);
        }

        appRelevantDataContextValue.onChangeProcessingReq(true);

        // get all the devices of the selected node.
        this.getAllTheDevicesOfTheSelectedParentNode();
    }   
    
    componentWillUnmount() {
        // clear interval if present.
        clearInterval(this.interval);
    }

    componentDidUpdate() { 

        let currentState = this.state;
        let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

        // if the earlier selected node id is not equals to the current selected node ID then get all the devices of the selected node.
        if(currentState.selectedToiletNode?.length > 0 && currentState?.selectedToiletNode != appRelevantDataContextValue.selectedNodeInfo?.nodeID) {
            // get all the devices of the selected node.
            this.getAllTheDevicesOfTheSelectedParentNode();
        } 

        // if the earlier selected Paramter at glance is not equals to the current selected Paramter at glance 
        // then get all the devices data for the selected paramter at glance.
        if(currentState.selectedParameterAtGlance?.length > 0 && currentState?.selectedParameterAtGlance != appRelevantDataContextValue.quickTrackParamInfo?.quickTrackParam) {
            // get all the devices data for the all the fetched Device Id's/
            this.dbGetLatestDeviceData();
        } 
    }

    getAllTheDevicesOfTheSelectedParentNode = () => {  

        let modifiedState = this.state;
        let inAppRelevantDataContextValue = this.context;

        let selectedTreeNodeID = inAppRelevantDataContextValue.selectedNodeInfo.nodeID;
        let loggedInUser = inAppRelevantDataContextValue.loggedInUserInfo.userID;
        let ownerOfTrackedDevices = inAppRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
        let hasAssociatedTreeNodeIds = inAppRelevantDataContextValue.devicesToTrack.hasAssociatedTreeNodeIds;

        let strLoggedInOrOwnerUserIDToBringDevcData;

        // If the Toilet dashboard is invoked from TrackDevc Func then set trackedDevices login ID as the LoggedInUserID.
        if(ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else if (hasAssociatedTreeNodeIds) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else {
            strLoggedInOrOwnerUserIDToBringDevcData = loggedInUser;
        }

        modifiedState.modelNameForNodeDashboard = [];
        modifiedState.lastIndexOfModelArray = 0;
        modifiedState.devicesListForNodeDashboard = [];
        let arrModelNameForNodeDashboard = [];
        let arrSeqModelNameForNodeDashboard = [];
        let arrDevicesListForNodeDashboard = [];
        let DeviceIDsForPFC = [];
        let DeviceIDsForWLI = [];
        let DeviceIDsForSTLI = [];
        let DeviceIDsForHHM = [];
        modifiedState.arrDeviceIDsForPFC = [];
        modifiedState.arrDeviceIDsForWLI = [];
        modifiedState.arrDeviceIDsForSTLI = [];
        modifiedState.arrDeviceIDsForHHM = [];
        modifiedState.selectedToiletNode = selectedTreeNodeID;

        let jsonBody = {
            NodeID: modifiedState.selectedToiletNode,
            LoggedInUserID: strLoggedInOrOwnerUserIDToBringDevcData
        }
        
        axios.post( `${getAPIHostURL()}/wclient/getDevicesForSelectedTreeNode`, jsonBody)
        .then(response => {  
            if(response.data.code == 'SUCCESS') {
                if((response.data.ArrAssignedDevicesToNode == null || response.data.ArrAssignedDevicesToNode.length <=0) &&
                    !response.data.hasOwnProperty("hasDevcsOnSelectedTreeNode")
                ) {
                    console.log("There are no child tree node Devices and Devices to be shown as Parent Node data are not present for the selected node.");
                    inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
                } else {
                    modifiedState.arrAssignedDevicesToNode = response.data.ArrAssignedDevicesToNode;

                    for(let i=0; i<modifiedState.arrAssignedDevicesToNode.length; i++) {
                        // store all the devices in an array.
                        arrDevicesListForNodeDashboard.push(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"]);

                        // seperate the HHM and PFC devices and store in an array.
                        if(modifiedState.arrAssignedDevicesToNode[i]["ModelName"] == PEOPLE_COUNTER) {
                            DeviceIDsForPFC.push(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"]);                      
                        } else if(modifiedState.arrAssignedDevicesToNode[i]["ModelName"] == WATER_LEVEL_INDICATOR) {
                            DeviceIDsForWLI.push(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"]);   
                        } else if(modifiedState.arrAssignedDevicesToNode[i]["ModelName"] == SEPTIC_TANK_LEVEL_INDICATOR){
                            DeviceIDsForSTLI.push(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"]);   
                        } else {
                            DeviceIDsForHHM.push(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"]);
                        }

                        // check for the  double entry of the same model name.s
                        if(!arrModelNameForNodeDashboard.includes(modifiedState.arrAssignedDevicesToNode[i]["ModelName"])){
                            // store all the Model names of the feched device Id's.
                            arrModelNameForNodeDashboard.push(modifiedState.arrAssignedDevicesToNode[i]["ModelName"]);
                        }
                    }   

                    // In order to show the Water Level Indicator model at the top of the dashboard above toilet ododur block
                    // push the Water Level Indicator Model at the start of the array
                    if(arrModelNameForNodeDashboard.includes(WATER_LEVEL_INDICATOR)){
                        if(!arrSeqModelNameForNodeDashboard.includes(WATER_LEVEL_INDICATOR)){
                            arrSeqModelNameForNodeDashboard.push(WATER_LEVEL_INDICATOR);
                        }
                    } 
                    
                    // In order to show the Septic Tank Level Indicator model at the 2nd position of the dashboard above toilet ododur block
                    // push the Septic Tank Level Indicator Model at the start of the array
                    if(arrModelNameForNodeDashboard.includes(SEPTIC_TANK_LEVEL_INDICATOR)){
                        if(!arrSeqModelNameForNodeDashboard.includes(SEPTIC_TANK_LEVEL_INDICATOR)){
                            arrSeqModelNameForNodeDashboard.push(SEPTIC_TANK_LEVEL_INDICATOR);
                        }
                    } 

                    // In order to show the PFC model at the top of the dashboard 
                    // push the PFC Model at the start of the array
                    if(arrModelNameForNodeDashboard.includes(PEOPLE_COUNTER)){
                        if(!arrSeqModelNameForNodeDashboard.includes(PEOPLE_COUNTER)){
                            arrSeqModelNameForNodeDashboard.push(PEOPLE_COUNTER);
                        }
                    } 

                    // In order to show the Gandhvedh model at the 2nd postion of the dashboard 
                    // push the Gandhvedh Model after PFC model, at the 2nd position of the array.
                    if(arrModelNameForNodeDashboard.includes(GANDHVEDH)){
                        if(!arrSeqModelNameForNodeDashboard.includes(GANDHVEDH)){
                            arrSeqModelNameForNodeDashboard.push(GANDHVEDH);
                        }
                    }

                    // then push the remaining models in random sequence after PFC and Gandhvedh.
                    for(let i=0; i<arrModelNameForNodeDashboard.length; i++) {
                        if(!arrSeqModelNameForNodeDashboard.includes(arrModelNameForNodeDashboard[i])){
                            arrSeqModelNameForNodeDashboard.push(arrModelNameForNodeDashboard[i]);
                        }
                    }

                    modifiedState.modelNameForNodeDashboard = arrSeqModelNameForNodeDashboard;
                    modifiedState.devicesListForNodeDashboard = arrDevicesListForNodeDashboard;
                    modifiedState.arrDeviceIDsForPFC = DeviceIDsForPFC;
                    modifiedState.arrDeviceIDsForWLI = DeviceIDsForWLI;
                    modifiedState.arrDeviceIDsForSTLI = DeviceIDsForSTLI;
                    modifiedState.arrDeviceIDsForHHM = DeviceIDsForHHM;

                    // Clear interval if present.
                    if(this.interval != null) {
                        clearInterval(this.interval);
                    }

                    // get all the devices data for the all the fetched Device Id's.
                    this.dbGetLatestDeviceData(modifiedState);
                    // bring the live and updated data after every 5 mins for the toilet dashboard.
                    this.interval = setInterval( () => { this.dbGetLatestDeviceData() }, getSingleDeviceDataRefreshTime() );
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    console.log('Server experiencing issues.\nTry again later.'); 
                } else if(response.data.code == 'SERVER_EXPERINCING_ISSUES') {
                    console.log('Server experiencing issues.\nTry again later.'); 
                } else {
                    console.log('Should not reach here');
                    console.log('Server experiencing issues.\nTry again later.');  
                }
             //this.setState(modifiedState);
            }
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                console.log('Network issues.\nCheck your Internet and Try again later.');
            }
            //this.setState(modifiedState);
        }); 
    }

    getRangeNameAndStyleBasedOnValue = (inParamValue, inParamNameType) => {

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 

        let rangeToDisplay = "";   // If need to show Current Range in future.

        let singleParamAirQualityRange = {};

        try {
            singleParamAirQualityRange = modifiedState.stdAirQualityRanges[inParamNameType];
        } catch(e) {
            console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
            // Return Default styles if stdAirQualityRange missing.
            return {RangeNameToShow: "", RangeBgColor: "lightGrey"};
            
        }
        
        if(singleParamAirQualityRange == null) {
            console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
            // Return Default styles if stdAirQualityRange missing.
            return {RangeNameToShow: "", RangeBgColor: "lightGrey"};
        }

        let singleParamAirQualityRangeLowSevereL = singleParamAirQualityRange[LOW_SEVERE_L];
        let singleParamAirQualityRangeLowSevereU = singleParamAirQualityRange[LOW_SEVERE_U];
        let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
        let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
        let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
        let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
        let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
        let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
        let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
        let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
        let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];

        if( inParamValue == null || inParamValue.length <= 0) {

            // If in future, we need to show Current Range for Parameteres in Single Device Data.
            rangeToDisplay = '';    
        } else {
            if( ( singleParamAirQualityRangeLowSevereL == null && inParamValue <= singleParamAirQualityRangeLowSevereU ) ||
                ( singleParamAirQualityRangeLowSevereU == null && inParamValue > singleParamAirQualityRangeLowSevereL ) ||
                ( inParamValue > singleParamAirQualityRangeLowSevereL && inParamValue <= singleParamAirQualityRangeLowSevereU ) )
            {            
                rangeToDisplay = LOW_SEVERE; 

            } else if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                        ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                        ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                rangeToDisplay = GOOD;

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                rangeToDisplay = SATISFACTORY; 

            } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                        ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                        ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                rangeToDisplay = MODERATE;


            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                        ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                        ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                rangeToDisplay = POOR;


            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                        ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                        ( inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                rangeToDisplay = V_POOR;


            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                        ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                        ( inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            { 
                rangeToDisplay = HIGH_SEVERE;


            } else {
                // Default black/grey
                rangeToDisplay = '';  
            }
        }

        let selectedRangeNameAndColour = this.showRangeNameAndColorForToilet(rangeToDisplay, NH3OD);

        return {RangeNameToShow: selectedRangeNameAndColour.retRangeName, RangeBgColor: selectedRangeNameAndColour.retRangeBandColor,
            CurrentRange: rangeToDisplay
        };

    }

    getParamValueTextAndBoxStyleBasedOnRange = (inParamValue, inParamNameType, singleDeviceModelInfo, DeviceType) => {

        let modifiedState = this.state;

        let styleValueText = "ParamsValueMissingOrWithoutRangeForToiletDash";
        let styleValueBox = "parameterOuterDivWithoutRangeForToiletDash";

        if(DeviceType != null && DeviceType != DEVICE_TYPE_PFC && DeviceType != DEVICE_TYPE_WLI && DeviceType != DEVICE_TYPE_STLI) {

            let singleParamAirQualityRange = modifiedState.stdAirQualityRanges[inParamNameType];
            modifiedState.highSevereStartValue = "";
            
            if(singleParamAirQualityRange == null) {
                console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
                // Return Default styles if stdAirQualityRange missing.
                return { styleValueText: styleValueText, styleValueBox: styleValueBox }; 
            }

            let paramHighLowValues = singleDeviceModelInfo[inParamNameType];
            let paramAttributes = singleDeviceModelInfo[inParamNameType];
            let bIsParamNonCalibrated = ( paramAttributes != null
                                        && paramAttributes["NoCalib"] != null
                                        ) ? paramAttributes["NoCalib"] : false;

            let bIsAlertBasedParam = ( paramAttributes != null
                                        && paramAttributes[ALERT_BASED] != null
                                    ) ? paramAttributes[ALERT_BASED] : false;

            let singleParamAirQualityRangeLowSevereL = singleParamAirQualityRange[LOW_SEVERE_L];
            let singleParamAirQualityRangeLowSevereU = singleParamAirQualityRange[LOW_SEVERE_U];
            let singleParamAirQualityRangeLowVPoorL = singleParamAirQualityRange[LOW_V_POOR_L];
            let singleParamAirQualityRangeLowVPoorU = singleParamAirQualityRange[LOW_V_POOR_U];
            let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
            let singleParamAirQualityRangeGoodU = (bIsAlertBasedParam && inParamNameType == SMOKE) ? paramHighLowValues["DefMax"] : singleParamAirQualityRange[GOOD_U];
            let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
            let singleParamAirQualityRangeSatisfactoryU = (bIsAlertBasedParam && inParamNameType == SMOKE) ? paramHighLowValues["DefMax"] : singleParamAirQualityRange[SATISFACTORY_U];
            let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
            let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
            let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
            let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
            let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
            let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
            let singleParamAirQualityRangeHighSevereL= (bIsAlertBasedParam && inParamNameType == SMOKE) ? paramHighLowValues["DefMax"] : singleParamAirQualityRange[HIGH_SEVERE_L];
            let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];

            let arrOfRangesToShowRange = [];
            arrOfRangesToShowRange = modifiedState.stdAirQualityRanges[inParamNameType]['DispRngSeq'];
        
            if(arrOfRangesToShowRange != null){
                let objRange = {};
                for(let i=0; i<arrOfRangesToShowRange.length; i++){
                    objRange = arrOfRangesToShowRange[i];

                    // For showing Normal Range for individual parameter in Single Device Data.
                    if(objRange.hasOwnProperty(DISP_GOOD_RANGE)){
                        modifiedState.showNormalRange = objRange.hasOwnProperty(DISP_GOOD_RANGE) ? Object.values(objRange)[0] : "";
                    } else {
                        // console.log("Not Found");
                    }
                }
            }

            if( inParamValue == null || 
                ( bIsParamNonCalibrated == false && inParamNameType != "PFC" &&
                    (inParamValue < paramHighLowValues["RangeMin"] || inParamValue > paramHighLowValues["RangeMax"]) 
                )
            ) {          
                styleValueText = ((inParamNameType == TEMP || inParamNameType == HUM) && 
                                (inParamValue < paramHighLowValues?.["RangeMin"] || inParamValue > paramHighLowValues?.["RangeMax"])) ?
                                "ParamsValueRangeSevereForToiletDash" :
                                inParamValue < paramHighLowValues["RangeMin"] ? "ParamsValueRangeGoodForToiletDash" :
                                inParamValue > paramHighLowValues["RangeMax"] ? "ParamsValueRangeSevereForToiletDash" :
                                inParamValue == null ? "ParamsValueMissingOrWithoutRangeForToiletDash" :
                                "ParamsValueMissingOrWithoutRangeForToiletDash";
                styleValueBox = ((inParamNameType == TEMP || inParamNameType == HUM) && 
                                (inParamValue < paramHighLowValues["RangeMin"] || inParamValue > paramHighLowValues["RangeMax"])) ?
                                "parameterOuterDivRangeSevereForToiletDash" :
                                inParamValue < paramHighLowValues["RangeMin"] ? "parameterOuterDivRangeGoodForToiletDash" :
                                inParamValue > paramHighLowValues["RangeMax"] ? "parameterOuterDivRangeSevereForToiletDash" : 
                                inParamValue == null ? "parameterOuterDivWithoutRangeForToiletDash" :
                                "parameterOuterDivWithoutRangeForToiletDash";

            } else {
                if( ( singleParamAirQualityRangeLowSevereL == null && inParamValue <= singleParamAirQualityRangeLowSevereU ) ||
                    ( singleParamAirQualityRangeLowSevereU == null && inParamValue > singleParamAirQualityRangeLowSevereL ) ||
                    ( inParamValue >= singleParamAirQualityRangeLowSevereL && inParamValue <= singleParamAirQualityRangeLowSevereU ) )
                {            
                    styleValueText = "ParamsValueRangeSevereForToiletDash";
                    styleValueBox = "parameterOuterDivRangeSevereForToiletDash";

                } else if( ( singleParamAirQualityRangeLowVPoorL == null && inParamValue <= singleParamAirQualityRangeLowVPoorU ) ||
                    ( singleParamAirQualityRangeLowVPoorU == null && inParamValue > singleParamAirQualityRangeLowVPoorL ) ||
                    ( inParamValue > singleParamAirQualityRangeLowVPoorL && inParamValue <= singleParamAirQualityRangeLowVPoorU ) )
                {            
                    styleValueText = "ParamsValueRangeLowVPoorForToiletDash";
                    styleValueBox = "parameterOuterDivRangeLowVPoorForToiletDash";

                }
                else if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                            ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                            ( inParamValue >= singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
                {
                    styleValueText = "ParamsValueRangeGoodForToiletDash";
                    styleValueBox = "parameterOuterDivRangeGoodForToiletDash";

                } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
                {
                    styleValueText = "ParamsValueRangeSatisfactoryForToiletDash";
                    styleValueBox = "parameterOuterDivRangeSatisfactoryForToiletDash"; 

                } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                            ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                            ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
                {
                    styleValueText = "ParamsValueRangeModerateForToiletDash";
                    styleValueBox = "parameterOuterDivRangeModerateForToiletDash"; 

                } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                            ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                            ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
                {
                    styleValueText = "ParamsValueRangePoorForToiletDash";
                    styleValueBox = "parameterOuterDivRangePoorForToiletDash"; 

                } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                            ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                            ( inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
                {
                    styleValueText = "ParamsValueRangeVeryPoorForToiletDash";
                    styleValueBox = "parameterOuterDivRangeVeryPoorForToiletDash";

                } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                            ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                            ( inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
                { 
                    styleValueText = "ParamsValueRangeSevereForToiletDash";
                    styleValueBox = "parameterOuterDivRangeSevereForToiletDash";

                } else {
                    // Default black/grey
                    styleValueText = "ParamsValueMissingOrWithoutRangeForToiletDash";
                    styleValueBox = "parameterOuterDivWithoutRangeForToiletDash";
                }
            }
        } else {
            styleValueText = "ParamsValueRangeGoodForToiletDash";
            styleValueBox = "parameterOuterDivRangeGoodForToiletDash";
        }

        return { styleValueText: styleValueText, styleValueBox: styleValueBox };

    }

    renderDevicesBasedOnModelName(singleModelName) {

        let modifiedState = this.state;
        let ArrAssignedDevicesToNode = modifiedState.arrAssignedDevicesToNode;
        let arrOfDevices = [];
        let arrOfDevicesToSend = [];

        for(let i=0; i<ArrAssignedDevicesToNode.length; i++) {
            if(ArrAssignedDevicesToNode[i]["ModelName"] == singleModelName) {
                arrOfDevices.push(ArrAssignedDevicesToNode[i]["DeviceID"])
            }
        }

        arrOfDevicesToSend = arrOfDevices;
        return arrOfDevicesToSend;
    }

    renderDeviceInfo(singleDeviceID) {

        let allDeviceDataDetails = this.state.allDeviceDataDetails;

        let DeviceInfo = {};
        let DeviceInfoToSend = {};

        for(let i=0; i<allDeviceDataDetails.length; i++) {
            if(allDeviceDataDetails[i]["DeviceID"] == singleDeviceID) {
                DeviceInfo = allDeviceDataDetails[i]
            }
        } 

        DeviceInfoToSend = DeviceInfo;

        return DeviceInfoToSend;
    }

    checkDeviceType(singleDeviceID) {

        let modifiedState = this.state;

        let ArrAssignedDevicesToNode = modifiedState.arrAssignedDevicesToNode;

        let DeviceType = "";
        let DeviceTypeToSend = "";

        for(let i=0; i<ArrAssignedDevicesToNode.length; i++) {
            if(ArrAssignedDevicesToNode[i]["DeviceID"] == singleDeviceID) {
                DeviceType = ArrAssignedDevicesToNode[i]["SelectedNodeDeviceType"];
            }
        }

        DeviceTypeToSend = DeviceType;

        return DeviceTypeToSend;
    }

    restrictParamValIfGoesBeyondMinOrMax = (singleValue, singleParam, singleDeviceModelInfo, singleDeviceID) => {

        if(singleValue == null) {
            // Assign default value of '--';
            singleValue = '--';
            console.log(`Should not happen. Value is missing for Param ${singleParam} for Device ${singleDeviceID}`);                                
        } else  {
            let paramAttributes = singleDeviceModelInfo[singleParam];
            let bIsParamNonCalibrated = ( paramAttributes != null
                                          && paramAttributes[NO_CALIB] != null
                                        ) ? paramAttributes[NO_CALIB] : false;

            if(singleValue < paramAttributes["RangeMin"] && bIsParamNonCalibrated == false) {
                singleValue = paramAttributes["RangeMin"];
            } else if(singleValue > paramAttributes["RangeMax"] && bIsParamNonCalibrated == false){
                singleValue = singleDeviceModelInfo[singleParam]["RangeMax"];
            }
        }

        return singleValue;
    }

    renderIconforPfcDevices = (ToiletSection, TotalToiletSeats, DeviceName) => {

        if(ToiletSection != null && ToiletSection === WOMEN_TOILET_SECTION){
            return(
                <span title={DeviceName} className="col pe-0" style={{color: "pink", fontSize: "1.5rem", cursor: "pointer"}}>
                    <FaFemale/> <b className="fs-6" style={{color: "grey"}}>{ToiletSection != null && ToiletSection == WOMEN_TOILET_SECTION ? TotalToiletSeats : 0}</b>
                </span>
            )
        } else if(ToiletSection != null && ToiletSection === MEN_TOILET_SECTION){
            return (
            <span title={DeviceName} className="col ps-0" style={{color: "skyblue", fontSize: "1.5rem", cursor: "pointer"}}>
                <FaMale/> <b className="fs-6" style={{color: "grey"}}>{ToiletSection != null && ToiletSection == MEN_TOILET_SECTION ? TotalToiletSeats : 0}</b>
            </span>   
            )
        } else {
            return (
                <span></span>
            )
        }
    }

    toggleFromInfoButton = (shortNameOfParam) => {
        let modifiedState = this.state;
        modifiedState.modalFromInfoButton = !this.state.modalFromInfoButton;    
        modifiedState.paramToShowDetailedInfo = shortNameOfParam;                          
        
        this.setState(modifiedState);
    }

    renderDeviceModelNameAndDeviceInfo = (arrOfDevicesBasedOnModelName, singleModelName, index) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = this.state;
        let currentLanguage = appRelevantDataContextValue.language.languageToViewIn;

        let Good = this.state.Good;
        let Moderate = this.state.Moderate;
        let Poor = this.state.Poor;
        let VeryPoor = this.state.VeryPoor;
        let Severe = this.state.Severe;
        let nullValue = this.state.nullValue;
        let ParameterAtGlance = "";
        let ParmAtGlanceValueNull = null;

        Good = [];
        Moderate = [];
        Poor = [];
        VeryPoor = [];
        Severe = [];
        nullValue = [];

        // param name to show in details and ranges pop up.
        let nameOfParamToShowInPopup = (modifiedState.selectedParamToViewRangeDetails != null && 
            modifiedState.selectedParamToViewRangeDetails.length > 0) ? 
            this.renderParamNameBasedOnType(modifiedState.selectedParamToViewRangeDetails)['props']['children'] : "";

        let shortNameOfParam = (modifiedState.selectedParamToViewRangeDetails != null && 
            modifiedState.selectedParamToViewRangeDetails.length > 0) ? 
            this.renderParamSymbolBasedOnType(modifiedState.selectedParamToViewRangeDetails)['props']['children'] : "";

        {arrOfDevicesBasedOnModelName.map((singleDeviceID)=>{

            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
            let DeviceType = this.checkDeviceType(singleDeviceID);
            let singleDeviceParamInfo = SingleDeviceInfo["SingleDeviceValue"];   
            let ParamValues = singleDeviceParamInfo["ParamValue"];

            if (DeviceType != DEVICE_TYPE_PFC && DeviceType != DEVICE_TYPE_WLI && DeviceType != DEVICE_TYPE_STLI) {
                
                // if the data of the particular device does not have device data for the device
                // then set the ParameterAtGlance as null for that device ID.
                if(singleDeviceParamInfo["ParameterAtGlance"] == null ){
                    ParmAtGlanceValueNull = null;
                } else {
                    // as devices are fetched on the basis of the model name 
                    // use this paramter to show in the common box.
                    ParameterAtGlance = singleDeviceParamInfo["ParameterAtGlance"];
                }
            }

            let singleDeviceModelInfo = SingleDeviceInfo["ModelInfo"];

            if(DeviceType == DEVICE_TYPE_PFC){
                this.state.ToiletSection = SingleDeviceInfo["ToiletSection"];
                this.state.TotalToiletSeats = SingleDeviceInfo["TotalToiletSeats"];
            }

            if(DeviceType == DEVICE_TYPE_WLI){
                this.state.waterLevelIndicationPrcnt = SingleDeviceInfo["SingleDeviceValue"];
                this.state.availableWaterLtr = SingleDeviceInfo["AvailableWater"];
                this.state.TankVolumeLtr = SingleDeviceInfo["TankVolume"];
                this.state.TotalConsumedWaterLtr = SingleDeviceInfo["TotalConsumedWater"];
            }

            if(DeviceType == DEVICE_TYPE_STLI){
                this.state.septicTankLevelIndicationPrcnt = SingleDeviceInfo["SingleDeviceValue"];
            }
            
            let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(ParamValues, ParameterAtGlance, singleDeviceModelInfo, DeviceType);

            if(styleValueBox === "parameterOuterDivRangeGoodForToiletDash" ) {
                Good.push(singleDeviceID);
            }
            if(styleValueBox == "parameterOuterDivRangeModerateForToiletDash") {
                Moderate.push(singleDeviceID);
            }
            if(styleValueBox === "parameterOuterDivRangePoorForToiletDash") {
                Poor.push(singleDeviceID);
            }
            if(styleValueBox === "parameterOuterDivRangeVeryPoorForToiletDash") {
                VeryPoor.push(singleDeviceID);
            }
            if(styleValueBox == "parameterOuterDivRangeSevereForToiletDash") {
                Severe.push(singleDeviceID);
            }    
            if(styleValueBox === "parameterOuterDivWithoutRangeForToiletDash") {
                nullValue.push(singleDeviceID);
            }
        })} 

        return (
            <div key={index} className="">
                <div className="row mt-3 mb-3 ps-1">
                {
                    singleModelName === NH3OD
                    ?
                    <div id="row1box1" className="col-lg-12 p-0 rounded-3 border" style={{borderColor: "lightgray"}}>
                        <div className="col p-0 m-0 rounded bg-white">
                            <div className="row text-center p-0 m-0 rounded-3" style={{ backgroundColor: "#F7F9F9"}}>
                                <span className="col" style={{color: "green",fontSize: "1.3rem"}}>
                                    <div style={{backgroundColor: "#F7F9F9", fontSize: "0.9rem", paddingTop: "0.1rem",
                                                color: this.state.objIncludeAvgBgColorAndRangeOfToilet["RangeBgColor"],
                                                display: "flex", justifyContent: 'space-between'
                                    }}>
                                        <div>
                                        </div>
                                        {/* <div>
                                            {t(IDS_ToiletOdour)}: {this.state.objIncludeAvgBgColorAndRangeOfToilet["RangeNameToShow"]}
                                        </div> */}
                                        <b>
                                            {this.getHygieneLevelMsgForToilet(this.state.objIncludeAvgBgColorAndRangeOfToilet["CurrentRange"])}
                                        </b>
                                        <div>
                                            {this.showUpDownButtons(index)}
                                        </div>                                        
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    :
                    singleModelName === WATER_LEVEL_INDICATOR
                    ?
                    <div id="row1box1" className="col-lg-12 p-0 rounded-3 border" style={{borderColor: "lightgray"}}>
                        <div className="col p-0 m-0 rounded bg-white">
                            <div className="row text-center p-0 m-0 rounded-3" style={{ backgroundColor: "#F7F9F9"}}>
                                <span className="col" style={{color: "green",fontSize: "1.3rem"}}>
                                    <div style={{display: "flex", justifyContent: 'space-between'}}>
                                        <div>
                                        </div>
                                        <div>
                                            <span className="fs-6" style={{color: "green", fontWeight: "bold"}}>{singleModelName}</span>
                                        </div>
                                        <div>
                                            {this.showUpDownButtons(index)}
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className="row justify-content-left m-0 pe-0">
                                {Good.map((singleDeviceID)=>{

                                    let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                    let DeviceType = this.checkDeviceType(singleDeviceID);

                                    let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;

                                    let ParameterAtGlance = "";
                                    let ParamValues = SingleDeviceInfo["SingleDeviceValue"];
                                    let DeviceName = SingleDeviceInfo["DeviceName"];
                                    let OccupancyPercent = "";
                                    let DeviceStatus = SingleDeviceInfo["DeviceStatus"];
                                    let ToiletSection = "";
                                    let waterLevelIndicationPrcnt = SingleDeviceInfo["SingleDeviceValue"];
                                    let availableWaterLtr = SingleDeviceInfo["AvailableWater"];
                                    let TankVolumeLtr = SingleDeviceInfo["TankVolume"];
                                    let TotalConsumedWaterLtr = SingleDeviceInfo["TotalConsumedWater"];
                                    let septicTankLevelIndicationPrcnt = "";

                                    let styleValueBox = "";

                                    let styleValueText = "";

                                    return(
                                        this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                    )                                
                                })} 
                            </div>
                            <svg className="waves border-bottom rounded-3" xmlns="http://www.w3.org/2000/svg" href="http://www.w3.org/1999/xlink"
                                viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                                <defs>
                                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                                </defs>
                                <g className="parallax">
                                    <use href="#gentle-wave" x="48" y="3" fill="rgba(93, 173, 226,0.3)" />
                                    <use href="#gentle-wave" x="48" y="5" fill="rgba(93, 173, 226,0.4)" />
                                    <use href="#gentle-wave" x="48" y="6" fill="rgba(93, 173, 226,0.7" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    :
                    singleModelName === SEPTIC_TANK_LEVEL_INDICATOR
                    ?
                    <div id="row1box1" className="col-lg-12 p-0 rounded-3 border" style={{borderColor: "lightgray"}}>
                        <div className="col p-0 m-0 rounded bg-white">
                            <div className="row text-center p-0 m-0 rounded-3" style={{ backgroundColor: "#F7F9F9"}}>
                                <span className="col" style={{color: "green",fontSize: "1.3rem"}}>
                                    <div className="row ps-1 border-light ">
                                        <span className="col" style={{color: "green",fontSize: "1.3rem"}}>
                                            <div style={{display: "flex", justifyContent: 'space-between'}}>
                                                <div>
                                                </div>
                                                <div>
                                                    <span className="fs-6" style={{color: "green", fontWeight: "bold"}}>{singleModelName}</span>
                                                </div>
                                                <div>
                                                    {this.showUpDownButtons(index)}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div className="row justify-content-left m-0 pe-0">
                                {Good.map((singleDeviceID)=>{

                                    let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                    let DeviceType = this.checkDeviceType(singleDeviceID);

                                    let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;

                                    let ParameterAtGlance = "";
                                    let ParamValues = SingleDeviceInfo["SingleDeviceValue"];
                                    let DeviceName = SingleDeviceInfo["DeviceName"];
                                    let OccupancyPercent = "";
                                    let DeviceStatus = SingleDeviceInfo["DeviceStatus"];
                                    let ToiletSection = "";
                                    let waterLevelIndicationPrcnt = "";
                                    let availableWaterLtr = "";
                                    let TankVolumeLtr = "";
                                    let TotalConsumedWaterLtr = "";
                                    let septicTankLevelIndicationPrcnt = SingleDeviceInfo["SingleDeviceValue"];

                                    let styleValueBox = "";

                                    let styleValueText = "";

                                    return(
                                        this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                    )                                
                                })} 
                            </div>
                        </div>
                    </div>
                    :
                    singleModelName == PEOPLE_COUNTER
                    ? 
                    <div id="row1box1" className="col-lg-12 p-0 border rounded-3" style={{backgroundColor: "white"}}>
                        <div className="m-0">
                            <div className="row p-0 m-0">
                                <div className="col p-0 m-0 rounded bg-white">
                                    <div className="row text-center p-0 m-0 mb-1 rounded-3" style={{ backgroundColor: "#F7F9F9"}}>
                                        <span className="col" style={{color: "green",fontSize: "1.3rem"}}>
                                            <div style={{display: "flex", justifyContent: 'space-between'}}>
                                                <div>
                                                </div>
                                                <div>
                                                    <FaWalking/>
                                                    <span className="fs-6" style={{color: "green", fontWeight: "bold"}}>{singleModelName}</span>
                                                </div>
                                                <div>
                                                    {this.showUpDownButtons(index)}
                                                </div>
                                            </div>
                                        </span>  
                                    </div>
                                    <div className="row justify-content-left m-0 pe-0">
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-4 m-0 pt-1 border justify-content-center rounded-3" style={{ backgroundColor: "#F7F9F9"}}>
                                            <div className="row m-0 text-center p-1" style={{color: "orange", fontSize: "15px",fontWeight: "bold"}}>
                                                <span>{t(IDS_TotalSeats)}</span>
                                            </div>
                                            <div className="row text-center">
                                                {Good.map((singleDeviceID)=>{

                                                    let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);

                                                    let ToiletSection = SingleDeviceInfo["ToiletSection"];
                                                    let TotalToiletSeats = SingleDeviceInfo["TotalToiletSeats"];
                                                    let DeviceName = SingleDeviceInfo["DeviceName"];

                                                    return(
                                                        this.renderIconforPfcDevices(ToiletSection, TotalToiletSeats, DeviceName)
                                                    )                                
                                                })} 
                                            </div>
                                        </div>

                                        {Good.map((singleDeviceID)=>{

                                            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                            let DeviceType = this.checkDeviceType(singleDeviceID);

                                            let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;

                                            let ParameterAtGlance = "";
                                            let ParamValues = SingleDeviceInfo["SingleDeviceValue"];
                                            let DeviceName = SingleDeviceInfo["DeviceName"];
                                            let OccupancyPercent = SingleDeviceInfo["SeatOccupancyPercent"];
                                            let DeviceStatus = SingleDeviceInfo["DeviceStatus"];
                                            let ToiletSection = SingleDeviceInfo["ToiletSection"];
                                            let waterLevelIndicationPrcnt = "";
                                            let availableWaterLtr = "";
                                            let TankVolumeLtr = "";
                                            let TotalConsumedWaterLtr = "";
                                            let septicTankLevelIndicationPrcnt = "";

                                            let styleValueBox = ToiletSection != null && ToiletSection == WOMEN_TOILET_SECTION ? "parameterOuterDivRangePFCLadiesForToiletDash"
                                                            : ToiletSection != null && ToiletSection == MEN_TOILET_SECTION ? "parameterOuterDivRangePFCGentsForToiletDash"
                                                            : "parameterOuterDivRangeGoodForToiletDash"
                                            
                                            let styleValueText = "";

                                            return(
                                                this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                            )                                
                                        })} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    <div id="row2box1" className="col-12 p-0 border rounded-3" style={{backgroundColor: "white"}}>
                        <div className="m-0">
                            <div className="row p-0 m-0">
                                <div className="col p-0 m-0 rounded bg-white">
                                    <div className="row text-center p-0 mb-1 m-0 rounded-3" style={{ backgroundColor: "#F7F9F9"}}>
                                        <span className="col" style={{color: "green",fontSize: "1.3rem"}}>
                                            <div style={{display: "flex", justifyContent: 'space-between'}}>
                                                <div>
                                                </div>
                                                <div>
                                                    <span className="fs-6" style={{color: "green", fontWeight: "bold"}}>{singleModelName}</span>
                                                </div>
                                                <div>
                                                    {this.showUpDownButtons(index)}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="row justify-content-left m-0 pe-0">
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-4 m-0 p-1 pt-1 border justify-content-center rounded-3" style={{ backgroundColor: "#F7F9F9" }}>
                                                <div className="row m-0 text-center p-1" style={{color: "orange", fontSize: "15px",fontWeight: "bold"}}>
                                                    <div className="col" style={{ backgroundColor: "" }}>
                                                        {this.renderParamSymbolBasedOnType(ParameterAtGlance)} {this.getUnitsBasedOnParamtype(ParameterAtGlance)}
                                                        <span className="pull-right" style={{backgroundColor: ""}}>
                                                            {ParameterAtGlance != LPG &&
                                                                <span id="toggleSpan" className="tooltipForRangeIcon" onClick={ () => this.toggleFromIcon(ParameterAtGlance)}>
                                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="rangeIcon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="20" cy="12" r="1"></circle>
                                                                            <circle cx="20" cy="5" r="1"></circle>
                                                                            <circle cx="20" cy="19" r="1"></circle>
                                                                    </svg>
                                                                    <span className="tooltiptextForRangeIcon">{t(IDS_DetailsAndRanges)}</span>
                                                                </span>
                                                            } 
                                                        </span>
                                                    </div>
                                                </div>

                                            <div className="row text-left">
                                                <span className="col" style={{fontSize: "13px"}}>
                                                    {
                                                        `(${t(IDS_NormalRange)} : ${
                                                            ParameterAtGlance != LPG
                                                                ? modifiedState.showNormalRange != null && modifiedState.showNormalRange.length > 0
                                                                    ? currentLanguage != 'en' && modifiedState.showNormalRange.includes(UPTO)
                                                                        ? `${modifiedState.showNormalRange.split(' ')[1]} ${t(IDS_Upto)}`
                                                                        : modifiedState.showNormalRange
                                                                    : t(IDS_NoRange)
                                                                : t(IDS_NoRange)
                                                        })`
                                                    }
                                                </span>
                                            </div>
                                        </div>

                                        {arrOfDevicesBasedOnModelName.map((singleDeviceID)=>{
                                            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                            let DeviceType = this.checkDeviceType(singleDeviceID);

                                            let singleDeviceParamInfo = SingleDeviceInfo["SingleDeviceValue"];                            
                                            let ParamValues = singleDeviceParamInfo["ParamValue"];
                                            let ParameterAtGlance = singleDeviceParamInfo["ParameterAtGlance"];
                                            let singleDeviceModelInfo = SingleDeviceInfo["ModelInfo"];
                                            let DeviceName = SingleDeviceInfo["DeviceName"];
                                            let OccupancyPercent = "";
                                            let DeviceStatus = SingleDeviceInfo["DeviceOnlineOfflineStatus"];
                                            let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;
                                            let waterLevelIndicationPrcnt = "";
                                            let availableWaterLtr = "";
                                            let TankVolumeLtr = "";
                                            let TotalConsumedWaterLtr = "";
                                            let septicTankLevelIndicationPrcnt = "";

                                            ParamValues = this.restrictParamValIfGoesBeyondMinOrMax(ParamValues, ParameterAtGlance, singleDeviceModelInfo, singleDeviceID);

                                            let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(ParamValues, ParameterAtGlance, singleDeviceModelInfo, DeviceType);

                                            return(
                                                
                                                this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                            )   
                                        })}

                                        {/* {Severe.map((singleDeviceID)=>{
                                            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                            let DeviceType = this.checkDeviceType(singleDeviceID);

                                            let singleDeviceParamInfo = SingleDeviceInfo["SingleDeviceValue"];                            
                                            let ParamValues = singleDeviceParamInfo["ParamValue"];
                                            let ParameterAtGlance = singleDeviceParamInfo["ParameterAtGlance"];
                                            let singleDeviceModelInfo = SingleDeviceInfo["ModelInfo"];
                                            let DeviceName = SingleDeviceInfo["DeviceName"];
                                            let OccupancyPercent = "";
                                            let DeviceStatus = SingleDeviceInfo["DeviceOnlineOfflineStatus"];
                                            let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;
                                            let waterLevelIndicationPrcnt = "";
                                            let availableWaterLtr = "";
                                            let TankVolumeLtr = "";
                                            let TotalConsumedWaterLtr = "";
                                            let septicTankLevelIndicationPrcnt = "";

                                            ParamValues = this.restrictParamValIfGoesBeyondMinOrMax(ParamValues, ParameterAtGlance, singleDeviceModelInfo, singleDeviceID);

                                            let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(ParamValues, ParameterAtGlance, singleDeviceModelInfo, DeviceType);

                                            return(
                                                
                                                this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                            )                                
                                        })} */}

                                        {/* {VeryPoor.map((singleDeviceID)=>{
                                            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                            let DeviceType = this.checkDeviceType(singleDeviceID);

                                            let singleDeviceParamInfo = SingleDeviceInfo["SingleDeviceValue"];                            
                                            let ParamValues = singleDeviceParamInfo["ParamValue"];
                                            let ParameterAtGlance = singleDeviceParamInfo["ParameterAtGlance"];
                                            let singleDeviceModelInfo = SingleDeviceInfo["ModelInfo"];
                                            let DeviceName = SingleDeviceInfo["DeviceName"];
                                            let OccupancyPercent = "";
                                            let DeviceStatus = SingleDeviceInfo["DeviceOnlineOfflineStatus"];
                                            let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;
                                            let waterLevelIndicationPrcnt = "";
                                            let availableWaterLtr = "";
                                            let TankVolumeLtr = "";
                                            let TotalConsumedWaterLtr = "";
                                            let septicTankLevelIndicationPrcnt = "";

                                            ParamValues = this.restrictParamValIfGoesBeyondMinOrMax(ParamValues, ParameterAtGlance, singleDeviceModelInfo, singleDeviceID);

                                            let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(ParamValues, ParameterAtGlance, singleDeviceModelInfo, DeviceType);

                                            return(
                                                this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                            )                                
                                        })}

                                        {Poor.map((singleDeviceID)=>{
                                            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                            let DeviceType = this.checkDeviceType(singleDeviceID);

                                            let singleDeviceParamInfo = SingleDeviceInfo["SingleDeviceValue"];                            
                                            let ParamValues = singleDeviceParamInfo["ParamValue"];
                                            let ParameterAtGlance = singleDeviceParamInfo["ParameterAtGlance"];
                                            let singleDeviceModelInfo = SingleDeviceInfo["ModelInfo"];
                                            let DeviceName = SingleDeviceInfo["DeviceName"];
                                            let OccupancyPercent = "";
                                            let DeviceStatus = SingleDeviceInfo["DeviceOnlineOfflineStatus"];
                                            let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;
                                            let waterLevelIndicationPrcnt = "";
                                            let availableWaterLtr = "";
                                            let TankVolumeLtr = "";
                                            let TotalConsumedWaterLtr = "";
                                            let septicTankLevelIndicationPrcnt = "";

                                            ParamValues = this.restrictParamValIfGoesBeyondMinOrMax(ParamValues, ParameterAtGlance, singleDeviceModelInfo, singleDeviceID);

                                            let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(ParamValues, ParameterAtGlance, singleDeviceModelInfo, DeviceType);

                                            return(
                                                this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                            )                                
                                        })}

                                        {Moderate.map((singleDeviceID)=>{
                                            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                            let DeviceType = this.checkDeviceType(singleDeviceID);

                                            let singleDeviceParamInfo = SingleDeviceInfo["SingleDeviceValue"];  
                                            let ParamValues = singleDeviceParamInfo["ParamValue"];
                                            let ParameterAtGlance = singleDeviceParamInfo["ParameterAtGlance"];
                                            let singleDeviceModelInfo = SingleDeviceInfo["ModelInfo"];
                                            let DeviceName = SingleDeviceInfo["DeviceName"];
                                            let OccupancyPercent = "";
                                            let DeviceStatus = SingleDeviceInfo["DeviceOnlineOfflineStatus"];
                                            let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;
                                            let waterLevelIndicationPrcnt = "";
                                            let availableWaterLtr = "";
                                            let TankVolumeLtr = "";
                                            let TotalConsumedWaterLtr = "";
                                            let septicTankLevelIndicationPrcnt = "";

                                            ParamValues = this.restrictParamValIfGoesBeyondMinOrMax(ParamValues, ParameterAtGlance, singleDeviceModelInfo, singleDeviceID);

                                            let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(ParamValues, ParameterAtGlance, singleDeviceModelInfo, DeviceType);

                                            return(
                                                this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                            )                                
                                        })}

                                        {Good.map((singleDeviceID)=>{

                                            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                            let DeviceType = this.checkDeviceType(singleDeviceID);

                                            let OccupancyPercent = "";
                                            let DeviceStatus = SingleDeviceInfo["DeviceOnlineOfflineStatus"];
                                            let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;
                                            let singleDeviceParamInfo = SingleDeviceInfo["SingleDeviceValue"];                            
                                            let ParamValues = singleDeviceParamInfo["ParamValue"];
                                            let ParameterAtGlance = singleDeviceParamInfo["ParameterAtGlance"];
                                            let singleDeviceModelInfo = SingleDeviceInfo["ModelInfo"];
                                            let DeviceName = SingleDeviceInfo["DeviceName"];
                                            let waterLevelIndicationPrcnt = "";
                                            let availableWaterLtr = "";
                                            let TankVolumeLtr = "";
                                            let TotalConsumedWaterLtr = "";
                                            let septicTankLevelIndicationPrcnt = "";

                                            ParamValues = this.restrictParamValIfGoesBeyondMinOrMax(ParamValues, ParameterAtGlance, singleDeviceModelInfo, singleDeviceID);

                                            let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(ParamValues, ParameterAtGlance, singleDeviceModelInfo, DeviceType);

                                            return(
                                                this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                            )                                    
                                        })} */}

                                        {/* {nullValue.map((singleDeviceID)=>{

                                            let SingleDeviceInfo = this.renderDeviceInfo(singleDeviceID);
                                            let DeviceType = this.checkDeviceType(singleDeviceID);

                                            let singleDeviceParamInfo = SingleDeviceInfo["SingleDeviceValue"];   
                                            let ParamValues = singleDeviceParamInfo["ParamValue"];
                                            let ParameterAtGlance = singleDeviceParamInfo["ParameterAtGlance"];
                                            let singleDeviceModelInfo = SingleDeviceInfo["ModelInfo"];
                                            let DeviceName = SingleDeviceInfo["DeviceName"];
                                            let OccupancyPercent = "";
                                            let DeviceStatus = SingleDeviceInfo["DeviceOnlineOfflineStatus"];
                                            let isDeviceBlocked = SingleDeviceInfo["SingleDeviceBlockedStatus"] == 0 ? true : false;
                                            let waterLevelIndicationPrcnt = "";
                                            let availableWaterLtr = "";
                                            let TankVolumeLtr = "";
                                            let TotalConsumedWaterLtr = "";
                                            let septicTankLevelIndicationPrcnt = "";

                                            ParamValues = this.restrictParamValIfGoesBeyondMinOrMax(ParamValues, ParameterAtGlance, singleDeviceModelInfo, singleDeviceID);
                                            
                                            let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(ParamValues, ParameterAtGlance, singleDeviceModelInfo, DeviceType);
                                            
                                            return(
                                                this.renderDeviceSingleParameterDetails(styleValueBox, styleValueText, ParamValues, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt)
                                            )                                
                                        })}  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {this.state.modalFromIcon == true &&
                    <VcDetailsAndRanges arrOfRanges={this.state.arrOfRanges} arrOfDispRangeValues={this.state.arrOfDispRangeValues} nameOfParamToShowInPopup={nameOfParamToShowInPopup}
                        selectedParamToViewRangeDetails={this.state.selectedParamToViewRangeDetails} shortNameOfParam={shortNameOfParam} modalFromIcon={this.state.modalFromIcon} showImpactsOfParam={this.state.showImpactsOfParam}
                        backdrop={this.state.backdrop} toggleModal={this.toggleFromIcon} unitOfSelectedParamInRangesPopup={"unitOfSelectedParamInRangesPopup"}
                        invokedFrom={"toiletDashboard"}/>      
                }           
                
            </div>
                {/* <Modal size="l" isOpen={this.state.modalFromIcon} toggle={this.toggleFromIcon} backdrop={this.state.backdrop} >
                    <ModalHeader toggle={this.toggleFromIcon} >{t(IDS_DetailsAndRangesOfSensor)}</ModalHeader>
                    <ModalBody>
                        <div className="boxForRangesForSensors">
                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                <div style={{flex:"1", textAlign: "center", marginBottom: "0.8rem"}}>
                                    <div style={{display: "inline-block"}}>
                                        <label className="adduser-form-label" style={{fontWeight: "bold", fontSize: "1.2rem"}}>
                                            {nameOfParamToShowInPopup}{t(IDS_ParamsRange)} ({shortNameOfParam})
                                        </label>
                                    </div>

                                    <div style={{marginLeft: "1rem", display: (shortNameOfParam == "Virus" || shortNameOfParam == "Mould") ? "inline-block" : "none"}}>
                                        <span id="toggleSpan" className="tooltipForRangeIcon" onClick={ () => this.toggleFromInfoButton(shortNameOfParam)}>
                                            <FiInfo className="rangeIcon" style={{fontSize: "1.3rem", fontWeight: "bold"}}/>
                                            <span className="tooltiptextForRangeIcon">
                                                {shortNameOfParam == "Virus" ? t(IDS_WhatIsVirusRiskIndex) : shortNameOfParam == "Mould" ? t(IDS_WhatIsMouldRiskIndex) : ""}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {this.state.arrOfRanges.map( (DispRange, index) => {
                                    return(
                                        this.showRangesForSelectedParam(DispRange, modifiedState.arrOfDispRangeValues[index])
                                    );                            
                                })
                                }  
                            </div>

                        </div>
                    </ModalBody>
                </Modal> */}

                {/* <Modal size="xl" isOpen={this.state.modalFromInfoButton} toggle={this.toggleFromInfoButton} backdrop={this.state.backdropForInfoButton}>
                    <ModalHeader toggle={this.toggleFromInfoButton}>
                        {modifiedState.paramToShowDetailedInfo == "Virus" ? t(IDS_WhatIsVirusRiskIndex) : modifiedState.paramToShowDetailedInfo == "Mould" ? t(IDS_WhatIsMouldRiskIndex) : ""}
                    </ModalHeader>
                    <ModalBody>
                        <VcVRIandMRIInfo 
                            paramToShowDetailedInfo={modifiedState.paramToShowDetailedInfo}
                        />
                    </ModalBody>
                </Modal> */}
            </div> 
        )
    }

    showUpDownButtons = (index) => {
        let modifiedState = this.state; 

        return(
            <div style={{display: "flex"}}>
                <div className="upDownBtn" onClick={() => this.onPutDown(index)} title= "Put down"
                     style={{cursor: "pointer", display: index == modifiedState.lastIndexOfModelArray ? 'none' : 'block'}}>
                    <AiOutlineArrowDown/>
                </div>  
                <div className="upDownBtn" onClick={() => this.onPutUp(index)} title= "Put up"
                     style={{cursor: "pointer", display: index == 0 ? 'none' : 'block'}}>
                    <AiOutlineArrowUp/>
                </div> 
            </div> 
        )
    }

    showRangesForSelectedParam = (DispRange, key) => {

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 
        let RangeNameToShow = null;
        let currentLanguage = appRelevantDataContextValue.language.languageToViewIn;

        if(key.includes(ONWARDS)){
            modifiedState.highSevereStartValueInPopUp = key.split(" ")[0];
        }

        if(key.includes(UPTO)){
            modifiedState.goodEndValueInPopUp = key.split(" ")[1];
        }

        let selectedRangeInfo = this.showRangeNameAndColor(DispRange, modifiedState.selectedParamToViewRangeDetails);

        if(modifiedState.selectedParamToViewRangeDetails == TEMP){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ColdAndChilly);
                    break;
                //case (`${t(IDS_Good)} / ${t(IDS_Satisfactory)}`):
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Warm);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Hot);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 

        } else if(modifiedState.selectedParamToViewRangeDetails == HUM) {

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_Dry);
                    break;
                //case (`${t(IDS_Good)} / ${t(IDS_Satisfactory)}`):
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Humid);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Sticky);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            }
        } else if(modifiedState.selectedParamToViewRangeDetails == NH3OD) {

            switch(selectedRangeInfo.retRangeName) {
                //case (`${t(IDS_Good)} / ${t(IDS_Satisfactory)}`):
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_BarelySensedInSingleDeviceData);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_SatisfactoryInSingleDeviceData);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_WeakOdourInSingleDeviceData);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_EasilyRecognizedInSingleDeviceData);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongOdourInSingleDeviceData);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_PungentInSingleDeviceData);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            }
        } else if(modifiedState.selectedParamToViewRangeDetails == RNFL){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_LightRain);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_ModerateRain);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_HeavyRain);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHeavyRain);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_TorrentialRain);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == NOISE){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_LowNoiseLevel);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_MediumNoiseLevel);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighNoiseLevel);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == LUX){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_LowSevere):
                RangeNameToShow = t(IDS_LowLightIntensity);
                break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_MediumLightIntensity);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighLightIntensity);
                    break;
                default:
                    console.log(selectedRangeInfo.retRangeName);
                    RangeNameToShow =  t(IDS_LowLightIntensity)
                    // RangeNameToShow = '';
                    break;
            } 
        } 
        else if(modifiedState.selectedParamToViewRangeDetails == O2){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_Normal);
                break;
            case t(IDS_RangeLowSevere):
                RangeNameToShow = t(IDS_Low);
                break;
            case t(IDS_Severe):
                RangeNameToShow = t(IDS_High);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } 
        // Will not come since it's decided that DLV will never be selected as paramAtGlance
        // Will be used when DLV is also available to choose for paramAtGlance
        //** Remaining adding code to 
        else if(modifiedState.selectedParamToViewRangeDetails == DLV){ 

            switch(selectedRangeInfo.retRangeName) {
            case t(IDS_Good):
                RangeNameToShow = t(IDS_GoodVisibility);
                break;
            case t(IDS_Moderate):
                RangeNameToShow = t(IDS_ModerateVisibility);
                break;
            case t(IDS_Poor):
                RangeNameToShow = t(IDS_FairVisibility);
                break;
            case t(IDS_VeryPoor):
                RangeNameToShow = t(IDS_PoorVisibility);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } 
        else if(modifiedState.selectedParamToViewRangeDetails == CH4 || modifiedState.selectedParamToViewRangeDetails == H2){ 

            switch(selectedRangeInfo.retRangeName) {
            case t(IDS_Good):
                RangeNameToShow = t(IDS_SafeRange);
                break;
            case t(IDS_Moderate):
                RangeNameToShow = t(IDS_WarningRange);
                break;
            case t(IDS_Poor):
                RangeNameToShow = t(IDS_CriticalRange);
                break;
            case t(IDS_VeryPoor):
                RangeNameToShow = t(IDS_EmergencyRange);
                break;
            case t(IDS_Severe):
                RangeNameToShow = t(IDS_HazardousRange);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } 
        else if(modifiedState.selectedParamToViewRangeDetails == WS){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_CalmBreeze);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_GentleBreeze);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_ModerateBreeze);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongGale);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Storm);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == CL || modifiedState.selectedParamToViewRangeDetails == CH3SH){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_VeryLow);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_Poor);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Severe);
                    break;
                default:
                    console.log(selectedRangeInfo.retRangeName);
                    RangeNameToShow = t(IDS_Low);
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == UV){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHigh);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Extreme);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == AP){ 

            switch(selectedRangeInfo.retRangeName) {
              
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ExtremeLow);
                    break;
                case t(IDS_RangeLowVeryPoor):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Normal);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_ExtremeHigh);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else {
            RangeNameToShow = selectedRangeInfo.retRangeName;
        }
    
        let RangeBgColorToShow = selectedRangeInfo.retRangeBgColor;
        let RangeFontColorToShow = selectedRangeInfo.retRangeFontColor;

        let RangeInfoToShowForNH3OD = null;
        let RangeNameToShowForNH3OD = null;
        let RangeIconToShowForNH3OD = <div></div>;

        if(modifiedState.selectedParamToViewRangeDetails != null && modifiedState.selectedParamToViewRangeDetails == NH3OD){
            RangeInfoToShowForNH3OD = this.showIconAndLevelForOdour(DispRange);
            RangeNameToShowForNH3OD = RangeInfoToShowForNH3OD.retRangeName;
            RangeIconToShowForNH3OD = RangeInfoToShowForNH3OD.retRangeIcon;
        }

        return(
            <div key={key}>
                <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                    <div style={{flex:"6", textAlign: "left"}}>
                        <label className="labelForSingleDeviceDataPopup" style={{fontWeight: "bold"}}>
                            {RangeNameToShow} :
                        </label>
                    </div>

                    <div style={{flex:"1", display: RangeInfoToShowForNH3OD != null ? 'block' : 'none', marginRight:"0.5rem"}}>
                        {RangeIconToShowForNH3OD}
                    </div>
                    
                    <div style={{flex:RangeInfoToShowForNH3OD != null ? "5" : "6", textAlign: "left", height: "0%",
                                 color: RangeFontColorToShow, backgroundColor: RangeBgColorToShow}}>
                        <label className="adduser-form-label" style = {{fontSize: "0.8rem"}} >
                            {(modifiedState.selectedParamToViewRangeDetails == NH3OD && RangeInfoToShowForNH3OD != null) ? 
                                RangeNameToShowForNH3OD
                            :
                                key.includes(ONWARDS) ?  `${modifiedState.highSevereStartValueInPopUp} ${t(IDS_Onwards)}` : 
                                (key.includes(UPTO) && currentLanguage != 'en') ? `${modifiedState.goodEndValueInPopUp} ${t(IDS_Upto)}` :  key
                            }
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    showIconAndLevelForOdour = (DispRange) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;           

        let retRangeIcon = '';
        let retRangeName = '';

        switch(DispRange) {
            case DISP_GOOD_RANGE:
                retRangeName = t(IDS_AlertLevel1);
                retRangeIcon = <FaGrinBeam style={{fontSize: '1.5rem', color:"#50C878"}}/>;
                break;
            case DISP_SATISFACTORY_RANGE:
                retRangeName = t(IDS_AlertLevel2);
                retRangeIcon = <FaSmile style={{fontSize: '1.5rem', color:"#9acd32"}}/>;
                break;
            case DISP_MODERATE_RANGE:
                retRangeName = t(IDS_AlertLevel3);
                retRangeIcon = <FaMeh style={{fontSize: '1.5rem', color:"#FFD700"}}/>;
                break;
            case DISP_POOR_RANGE:
                retRangeName = t(IDS_AlertLevel4);
                retRangeIcon = <FaFrownOpen style={{fontSize: '1.5rem', color:"orange"}}/>;
                break;
            case DISP_VERY_POOR_RANGE:
                retRangeName = t(IDS_AlertLevel5);
                retRangeIcon = <FaSadTear style={{fontSize: '1.5rem', color:"red"}}/>;
                break;
            case DISP_HIGH_SEVERE_RANGE:
                retRangeName = t(IDS_AlertLevel6);
                retRangeIcon = <FaDizzy style={{fontSize: '1.5rem', color:"#C00000"}}/>;
                break;
            default:
                console.error(`Unable to get DispRangeName and Icon. Unknown Range Type: ${DispRange}`);
                retRangeName = '';
                retRangeIcon = <div></div>
                break;
            }
        return {retRangeName: retRangeName, retRangeIcon: retRangeIcon};

    }

    getHygieneLevelMsgForToilet = (inRangeName) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 
        switch (inRangeName) {
            case LOW_SEVERE:
                return 'Hygiene level ' + inRangeName
            case GOOD:
                return t(IDS_HygieneMsgGood, t(IDS_Device))
            case SATISFACTORY:
                return t(IDS_HygieneMsgSatisfactory, t(IDS_Device))
            case MODERATE:
                return t(IDS_HygieneMsgModerate, t(IDS_Device))
            case POOR:
                return t(IDS_HygieneMsgPoor, t(IDS_Device))
            case V_POOR:
                return t(IDS_HygieneMsgVPoor, t(IDS_Device))
            case HIGH_SEVERE:
                return t(IDS_HygieneMsgSevere, t(IDS_Device))
            default:
                return "Hygiene level " + inRangeName
        }
    }

    showRangeNameAndColorForToilet = (inStdRangName, inParamNameType) => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;           

        let retRangeName = '';
        let retRangeBandColor = '';
 
        switch(inStdRangName) {

            case LOW_SEVERE:
                retRangeName = inParamNameType == TEMP ? t(IDS_ColdAndChilly) :
                                inParamNameType == HUM ? t(IDS_Dry) :
                                inParamNameType == NH3OD ? "" :
                                t(IDS_RangeLowSevere);
                retRangeBandColor = '#C00000';
                break;
            case GOOD:
                retRangeName =  inParamNameType == TEMP ? t(IDS_Comfortable) :
                                inParamNameType == HUM ? t(IDS_Comfortable) :
                                // inParamNameType == NH3OD ? t(IDS_BarelySensedInSingleDeviceData) :
                                inParamNameType == NH3OD ? t(IDS_BarelySensed) :
                                //`${t(IDS_Good)} / ${t(IDS_Satisfactory)}`;
                                t(IDS_Good);
                retRangeBandColor = '#50C878';
                break;
            case SATISFACTORY:
                retRangeName =  inParamNameType == TEMP ? "" :
                                inParamNameType == HUM ? "" :
                                // inParamNameType == NH3OD ? t(IDS_WeakOdourInSingleDeviceData) :
                                inParamNameType == NH3OD ? t(IDS_SatisfactoryInSingleDeviceData) :
                                t(IDS_Satisfactory);
                retRangeBandColor = '#9acd32';
                break;
            case MODERATE:
                retRangeName =  inParamNameType == TEMP ? "" :
                                inParamNameType == HUM ? "" :
                                // inParamNameType == NH3OD ? t(IDS_WeakOdourInSingleDeviceData) :
                                inParamNameType == NH3OD ? t(IDS_Weak) :
                                t(IDS_Moderate);
                retRangeBandColor = '#FFD700';
                break;
            case POOR:
                retRangeName =  inParamNameType == TEMP ? t(IDS_Warm):
                                inParamNameType == HUM ? t(IDS_Humid) :
                                // inParamNameType == NH3OD ? t(IDS_EasilyRecognizedInSingleDeviceData) :
                                inParamNameType == NH3OD ? t(IDS_EasilyRecognized) :
                                t(IDS_Poor);
                retRangeBandColor = 'orange';
                break;
            case V_POOR:
                retRangeName =  inParamNameType == TEMP ? "" :
                                inParamNameType == HUM ? "" :
                                // inParamNameType == NH3OD ? t(IDS_StrongOdourInSingleDeviceData) :
                                inParamNameType == NH3OD ? t(IDS_Strong) :
                                t(IDS_RangeVeryPoor);
                retRangeBandColor = 'red';
                break;
            case HIGH_SEVERE:
                retRangeName = inParamNameType == TEMP ? t(IDS_Hot) :
                               inParamNameType == HUM ? t(IDS_Sticky) :
                            //    inParamNameType == NH3OD ? t(IDS_PungentInSingleDeviceData) :
                               inParamNameType == NH3OD ? t(IDS_Pungent) :
                               t(IDS_Severe);
                retRangeBandColor = '#C00000';
                break;
            default:
                console.log(`Unable to get RangeName. Unknown Range Type: ${inStdRangName}`);
                retRangeName = "";
                retRangeBandColor = 'lightGrey';
                break;
        }

        
        return {"retRangeName": retRangeName, "retRangeBandColor": retRangeBandColor}
    }

    showRangeNameAndColor = (DispRange, selectedParamToViewRangeDetails) => {
        //For Now 
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;           

        let retRangeName = '';
        let retRangeBgColor = '';
        let retRangeFontColor = '';

        switch(DispRange) {
            case DISP_LOW_SEVERE_RANGE:
                retRangeName = t(IDS_RangeLowSevere);
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#606444":"#C00000";
                retRangeFontColor = "white"
                break;
            case DISP_LOW_VERY_POOR_RANGE:
                //retRangeName = `${t(IDS_Good)} / ${t(IDS_Satisfactory)}`;
                retRangeName = t(IDS_RangeLowVeryPoor);
                retRangeBgColor = "red";
                retRangeFontColor = "lightGrey";
                break;
            case DISP_GOOD_RANGE:
                //retRangeName = `${t(IDS_Good)} / ${t(IDS_Satisfactory)}`;
                retRangeName = t(IDS_Good);
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#F89C3C":"#50C878";
                // retRangeFontColor = selectedParamToViewRangeDetails == LUX ? "white":"black";
                break;
                
            case DISP_SATISFACTORY_RANGE:
                retRangeName = t(IDS_Satisfactory);
                retRangeBgColor = "#9acd32";
                retRangeFontColor = "black";
                break;
            case DISP_MODERATE_RANGE:
                retRangeName = t(IDS_Moderate);
                retRangeBgColor = "#FFD700";
                retRangeFontColor = "black";
                break;
            case DISP_HIGH_SEVERE_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == TEMP || selectedParamToViewRangeDetails == HUM) ? t(IDS_RangeHighSevere) : (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_SevereAqi) : t(IDS_Severe);
                // retRangeBgColor = "#C00000";
                // retRangeFontColor = "lightGrey";
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#FF4500":"#C00000";
                retRangeFontColor = "white"
                break;
            case DISP_POOR_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_PoorAqi) : t(IDS_Poor);
                retRangeBgColor = "orange";
                retRangeFontColor = "black";
                break;
            case DISP_VERY_POOR_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_VeryPoorAqi  ) : t(IDS_VeryPoor);;
                retRangeBgColor = "red";
                retRangeFontColor = "lightGrey";
                break;
            default:
                // console.error(`Unable to get DispRangeName. Unknown Range Type: ${DispRange}`);
                retRangeName = IDS_NoRange;
                retRangeBgColor = '';
                retRangeFontColor = "";
                break;
            }
        return {retRangeName: retRangeName, retRangeBgColor: retRangeBgColor, retRangeFontColor: retRangeFontColor};

    }

    renderParamSymbolBasedOnType(inSymbolType) {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;  

        switch(inSymbolType) {
            case AQI:
                case CAQI:
                return <small>AQI</small>
            case CAQI:
                return <small>CAQI</small>
            case NO2:
                return (<span>NO<sub>2</sub></span>);
            case O3:
                return (<span>O<sub>3</sub></span>);
            case SO2:
                return (<span>SO<sub>2</sub></span>);
            case VOC:
                return (<span>TVOC</span>);
            case CO:
                return (<span>CO</span>);
            case NH3:
                return (<span>NH<sub>3</sub></span> );
            case CO2:
                return (<span>CO<sub>2</sub></span>);
            case H2S:
                return (<span>H<sub>2</sub>S</span>);
            case CH4:
                return (<span>CH<sub>4</sub></span>  );
            case LPG:
                return (<span>LPG/LNG</span>);
            case SMOKE:
                return (<span>Smoke</span>);
            case PM1:
                return (<span>PM<sub>(1)</sub></span>);
            case PM25:
                return (<span>PM<sub>(2.5)</sub></span>);
            case PM10:
                return (<span>PM<sub>(10)</sub></span> );
            case TEMP:
                return (<span>Temp<sup>o</sup></span>);
            case HUM:
                return (<span>Hum<sup>%</sup></span>);
            case NH3OD:
                return (<span >Odour</span>);  
            case MRI:
                return (<span >Mould</span>);
            case VRI:
                return (<span >Virus</span>);
            case O2:
                return <span>O<sub>2</sub></span>;
            case PM100:
                return <span>PM<sub>100</sub></span>;
            case CH3SH:
                return <span>CH<sub>3</sub>SH</span>;
            case CL:
            case NO:
            case HCHO:
            case LUX:
            case UV:
            case AP:
            case WS:
            case WD:
            case DLV:
            case RNFL:
                return <span>{inSymbolType}</span>;
            case RADON:
                return <span>{t(IDS_Radon)}</span>;
            case NOISE:
                return <span>{t(IDS_Noise)}</span>;
            case H2:
                return <span>{inSymbolType}</span>;
            default:
                console.error(`Unable to get Symbol. Unknown Param Type: ${inSymbolType}`);
                return (<span></span>); // Return empty span
        }
    }

    onViewDeviceData = (clickedNodeID, clickedNodeTitle, SelectedNodeDeviceType, selectedToiletNode=null) => {

        let appRelevantDataContextValue = this.context;
        // Get all the relevant data from AppContext
        
        this.props.setSearchedOrClickedToiletNode('')
        this.props.addToOpenToiletNodes(this.state.selectedToiletNode)

        appRelevantDataContextValue.onSelectedDevice(clickedNodeID, clickedNodeTitle, false, false, true, [], selectedToiletNode, SelectedNodeDeviceType);
        appRelevantDataContextValue.onSelectedNodeContainsChildNode(false)
    }

    renderParamOdourLevel( inParamValue, inParamNameType) {

        // Incase of Sensor value for NH3OD is missing.
        if(inParamValue == null || inParamValue == "--") {
            return <p className="m-0" style={{fontSize: '0.8rem', fontWeight: "normal", paddingTop: "0.2rem"}}>--</p>; 
        }

        let singleParamAirQualityRange = this.state.stdAirQualityRanges[inParamNameType];

        if(singleParamAirQualityRange == null) {
            console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
            // If singleParamAirQualityRange missing.
            return <p className="m-0" style={{fontSize: '0.8rem', fontWeight: "normal", paddingTop: "0.2rem"}}>--</p>; 
        }    

        let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
        let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
        let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
        let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
        let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
        let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
        let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
        let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
        let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];


        if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                    ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                    ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
        {
            return <FaGrinBeam className="m-0" style={{fontSize: '1.4rem'}}/>

        } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                    ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                    ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
        {
            return <FaSmile className="m-0" style={{fontSize: '1.4rem'}}/>

        }  else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                    ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                    ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
        {
            return <FaMeh className="m-0" style={{fontSize: '1.4rem'}}/>;

        } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                    ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                    ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
        {
            return <FaFrownOpen className="m-0" style={{fontSize: '1.4rem'}}/>;

        } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                    ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                    (inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
        {
            return <FaSadTear className="m-0" style={{fontSize: '1.4rem'}}/>;

        } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                    ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                    (inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
        {            
            return <FaDizzy className="m-0" style={{fontSize: '1.4rem'}}/>;

        } else {

            if( inParamValue < 0 ) {
                return <FaSmileBeam className="m-0" style={{fontSize: '1.4rem'}}/>;
            } else {
                return<FaDizzy className="m-0" style={{fontSize: '1.4rem'}}/>;
            }
        } 
    }

    renderParamOdourSymbol( inParamValue, inParamNameType ) {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;  

        let singleParamAirQualityRange = this.state.stdAirQualityRanges[inParamNameType];

        if(inParamValue == null || inParamValue == "--") {
            return; 
        } 

        if(singleParamAirQualityRange == null) {
            console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
            // If singleParamAirQualityRange missing.
            // return(<h6 className="NameOfParameter">{t(IDS_BarelySensed)}</h6>); 
            return(<span><b>{t(IDS_BarelySensed)} </b></span>); 

        }

        let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
        let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
        let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
        let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
        let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
        let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
        let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
        let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
        let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];

        if(inParamNameType == NH3OD) {

            if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                    ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                    ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                // return(<h6 className="NameOfParameter">{t(IDS_BarelySensed)}</h6>);
                return(<span><b>{t(IDS_BarelySensed)} </b></span>);

            } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                    ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                    ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                // return(<h6 className="NameOfParameter">{t(IDS_WeakOdour)}</h6>);
                return(<span><b>{t(IDS_WeakOdour)} </b></span>);

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                    ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                    ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                // return(<h6 className="NameOfParameter">{t(IDS_WeakOdour)}</h6>);
                return(<span><b>{t(IDS_SatisfactoryOdour)} </b></span>);

            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                    ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                    ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                // return(<h6 className="NameOfParameter">{t(IDS_EasilyRecognizedInSingleDevcData)}</h6>);
                return(<span><b>{t(IDS_EasilyRecognizedInSingleDevcData)} </b></span>);

            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                ( inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                // return(<h6 className="NameOfParameter">{t(IDS_StrongOdour)}</h6>);
                return(<span><b>{t(IDS_StrongOdour)} </b></span>);

            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                    ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                    ( inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            { 
                // return(<h6 className="NameOfParameter">{t(IDS_Pungent)}</h6>);
                return(<span><b>{t(IDS_Pungent)} </b></span>);

            } else {
                if(inParamValue < 0 ) {
                    // return(<h6 className="NameOfParameter">{t(IDS_BarelySensed)}</h6>);
                    return(<span><b>{t(IDS_BarelySensed)} </b></span>);

                } else {
                    // return(<h6 className="NameOfParameter">{t(IDS_Pungent)}</h6>);
                    return(<span><b>{t(IDS_Pungent)} </b></span>);

                }
            }
        }    
    }

    renderParamNameBasedOnType(inParamNameType){
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;          

        switch(inParamNameType) {
            case AQI:
                return (<h6 className="NameOfParameter">{t(IDS_airQualityIndex)}</h6>);
              case CAQI:
                return (<h6 className="NameOfParameter">{t(IDS_currentAirQualityIndex)}</h6>);
            case NO2:
                return (<h6 className="NameOfParameter">{t(IDS_nitrogenDioxide)}</h6>);
            case O3:
                return (<h6 className="NameOfParameter">{t(IDS_Ozone)}</h6>);
            case SO2:
                return (<h6 className="NameOfParameter">{t(IDS_sulphurDioxide)}</h6>);
            case VOC:
                return (<h6 className="NameOfParameter">{t(IDS_TVOC)}</h6>);
            case CO:
                return (<h6 className="NameOfParameter">{t(IDS_carbonMonoxide)}</h6>);
            case NH3:
                return (<h6 className="NameOfParameter">{t(IDS_Ammonia)}</h6>);
            case CO2:
                return (<h6 className="NameOfParameter">{t(IDS_carbonDioxide)}</h6>);
            case H2S:
                return (<h6 className="NameOfParameter">{t(IDS_hydrogenSulphide)}</h6>);
            case CH4:
                return (<h6 className="NameOfParameter">{t(IDS_Methane)}</h6>);                
            case PM1:
                return (<h6 className="NameOfParameter">{t(IDS_Dust)}</h6>);
            case PM25:
                return (<h6 className="NameOfParameter">{t(IDS_Dust)}</h6>);
            case PM10:
            case PM100:
                return (<h6 className="NameOfParameter">{t(IDS_Dust)}</h6>);
            case TEMP:
                return (<h6 className="NameOfParameter">{t(IDS_Temperature)}</h6>);
            case HUM:
                return (<h6 className="NameOfParameter">{t(IDS_Humidity)}</h6>);
            case NH3OD:
                return (<h6 className="NameOfParameter">{t(IDS_Odour)}</h6>);
            case MRI:
                return (<h6 className="NameOfParameter">{t(IDS_MRI)}</h6>);
            case VRI:
                return (<h6 className="NameOfParameter">{t(IDS_VRI)}</h6>);  
            
            case HCHO:
                return (<span className="NameOfParameter">{t(IDS_Formaldehyde)}</span>);
            case O2:
                return (<span className="NameOfParameter">{t(IDS_Oxygen)}</span>);
            case PM100:
                return (<span className="NameOfParameter">{t(IDS_PM100)}</span>);
            case NO:
                return (<span className="NameOfParameter">{t(IDS_NitricOxide)}</span>);
            case CL:
                return (<span className="NameOfParameter">{t(IDS_Chlorine)}</span>);
            case CH3SH:
                return (<span className="NameOfParameter">{t(IDS_MethylMercaptan)}</span>);
            case NOISE:
                return (<span className="NameOfParameter">{t(IDS_Noise)}</span>);
            case LUX:
                return (<span className="NameOfParameter">{t(IDS_LightIntensity)}</span>);
            case UV:
                return (<span className="NameOfParameter">{t(IDS_UV)}</span>);
            case RADON:
                return (<span className="NameOfParameter">{t(IDS_Radon)}</span>);
            case AP:
                return (<span className="NameOfParameter">{t(IDS_AirPressure)}</span>);
            case WS:
                return (<span className="NameOfParameter">{t(IDS_WindSpeed)}</span>);
            case WD:
                return (<span className="NameOfParameter">{t(IDS_WindDirection)}</span>);
            case DLV:
                return (<span className="NameOfParameter">{t(IDS_DaylightVisibility)}</span>);
            case RNFL:
                return (<span className="NameOfParameter">{t(IDS_Rainfall)}</span>);       
            case H2:
                return (<span className="NameOfParameter">{t(IDS_Hydrogen)}</span>);
            default:
                console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (<h6></h6>); // Return empty tag
        }
    }

    getUnitsBasedOnParamtype = inParamType => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;           

        let retUnit = '';
        switch(inParamType) {
            case NO2:
            case O3:
            case SO2:
            case VOC:
            case H2S:
            case HCHO:
            case CL:
            case CH3SH:
                retUnit = `(${t(IDS_PPB)})`;
                break;
            case CO:
            case NH3:
            case CO2:
            case NH3OD:  
            case NO:
            case NH3:  
                retUnit = `(${t(IDS_PPM)})`;
                break;
            case PM1:
            case PM25:
            case PM10:
            case PM100:
                retUnit = (<span >㎍/m<sup>3</sup></span>)
                break;
            case TEMP:
                retUnit = `(${t(IDS_Celsius)})`;
                break;
            case HUM:
                retUnit = `(${t(IDS_Percent)})`;
                break;
            case MRI:
            case VRI:
            case UV:
                retUnit = `(${t(IDS_Index)})`;
                break;
            case AQI:
                retUnit = `(${t(IDS_airQualityIndex)})`;
                break;
            case CAQI:
                retUnit = `(${t(IDS_currentAirQualityIndex)})`;
                break;
            case O2:
            case CH4:
            case H2: 
                retUnit = '(%)';
                break;
            case NOISE:
                retUnit = '(dB)';
                break;
            case LUX:
                retUnit = '(lux)';
                break;
            case RADON:
                retUnit = '(Bq/m³)';
                break;
            case AP:
                retUnit = '(hPa)';
                break;
            case WS:
                retUnit = '(m/s)';
                break;
            case WD:
                // if(singleValue) {
                //     retUnit = getDirectionName(singleValue)
                // } else {
                    retUnit = '°';
                // }
                break;
            case DLV:
                retUnit = '(km)';
                break;
            case RNFL:
                retUnit = '(mm)';
                break;
            // case LPG: 
            //     retUnit = t(IDS_LPGUnit);
            //     break;
            default:
                // console.error(`Unable to get Units. Unknown Param Type: ${inParamType}`);
                retUnit = '';
                break;
            }
        return retUnit;
    }
    
    renderDeviceSingleParameterDetails = (styleValueBox, styleValueText, singleValue, ParameterAtGlance, DeviceType, singleDeviceID, DeviceName, OccupancyPercent, DeviceStatus, isDeviceBlocked, waterLevelIndicationPrcnt, availableWaterLtr, TankVolumeLtr, TotalConsumedWaterLtr, septicTankLevelIndicationPrcnt) => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        const lpgRangeValues = Object.keys(this.state.stdAirQualityRanges) != null && Object.keys(this.state.stdAirQualityRanges).length > 0 && this.state.stdAirQualityRanges.LPG;

        let styleValueBoxRecieved = isDeviceBlocked == true ? "parameterOuterDivWithoutRangeForToiletDash" : styleValueBox;
        
        if(ParameterAtGlance === NH3OD && DeviceType != null && DeviceType == "HHM") {
            return (
                <div className="deviceBox col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1 m-0 border rounded-3" onClick={() => this.onViewDeviceData(singleDeviceID, DeviceName, DeviceType)} style={{cursor: "pointer"}}>
                    <div className={styleValueBoxRecieved}>
                        <div className="row m-0 pt-1 pb-1"  style={{fontSize: "14px"}}>
                            {isDeviceBlocked == false ?
                                <div className="col-1 p-2 pb-1">
                                    <div className="m-0" style={{borderRadius: "50%", border: "1px solid grey", backgroundColor: DeviceStatus == "true" ? "#00ff00": "white", height: "0.6rem", width:"0.6rem", marginRight: "0.3rem"}}></div>
                                </div> 
                                : null
                            }
                            <span className="col-11 text-left">{DeviceName}</span>
                        </div> 
                        {isDeviceBlocked == true ?
                            <div className="row m-0 mt-0 p-0">
                                <div className="row text-left p-0 m-0 pb-0 border rounded-3" style={{fontSize: "12px"}}>
                                    <div className="col-9 p-2" style={{fontSize: "12px"}}>
                                        <div className="justify-content-left" style={{fontSize: "13px", color: "green"}}>
                                            <span className="ParamsValueMissingOrWithoutRangeForToiletDash"><b>{t(IDS_Dvc_Blocked)}</b></span>
                                        </div>
                                    </div>
                                    <div className="col-3 p-2 border-start rounded-3 " style={{fontSize: "12px"}}>
                                        <span  className="ParamsValueMissingOrWithoutRangeForToiletDash" >                   
                                        <FaBan className="m-0" style={{fontSize: '1.4rem'}}/>                               
                                        </span>                                                
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="row m-0 mt-0 p-0">
                                <div className="row text-left p-0 m-0 pb-0 border rounded-3" style={{fontSize: "12px"}}>
                                    <div className="col-9 p-2" style={{fontSize: "12px"}}>
                                        <div className="justify-content-left" style={{fontSize: "13px", color: "green"}}>
                                            <span className={styleValueText}><b>{this.renderParamOdourSymbol(singleValue, ParameterAtGlance)}</b></span>
                                            <span style={{fontSize: "11px", color: "grey"}}>{this.renderOdourLevel(singleValue, ParameterAtGlance)}</span>
                                        </div>
                                    </div>
                                    <div className="col-3 p-2 border-start rounded-3 " style={{fontSize: "12px"}}>
                                        <span  className={styleValueText} >                   
                                            {this.renderParamOdourLevel(singleValue, ParameterAtGlance)}                             
                                        </span>                                                
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            );
        } else if(ParameterAtGlance != NH3OD && DeviceType != null && DeviceType == "HHM") {
            return(
                <div className="deviceBox col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1 m-0 border rounded-3" onClick={() => this.onViewDeviceData(singleDeviceID, DeviceName, DeviceType)} style={{cursor: "pointer"}}>
                    <div className={styleValueBoxRecieved}>
                        <div className="row m-0 pt-1 pb-1" style={{fontSize: "14px"}}>
                            {isDeviceBlocked == false ?
                                <div className="col-1 p-2 pb-1">
                                    <div className="m-0" style={{borderRadius: "50%", border: "1px solid grey", backgroundColor: DeviceStatus == "true" ? "#00ff00": "white", height: "0.6rem", width:"0.6rem", marginRight: "0.3rem"}}></div>
                                </div> 
                                : null
                            }
                            <span className="col-11 text-left">{DeviceName}</span>
                        </div>   

                        {isDeviceBlocked == true ? 
                            <div className="row m-0 mt-0 p-0">
                                <div className="row text-center p-0 m-0 pb-0 ">
                                    <div className="col-12 p-2 border-top rounded-3" style={{fontSize: "12px"}}>
                                        <div className="justify-content-center" style={{fontSize: "13px"}}>
                                            <span className="ParamsValueMissingOrWithoutRangeForToiletDash"><b>{t(IDS_Dvc_Blocked)}</b></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="row m-0 mt-0 p-0">
                                <div className="row text-center p-0 m-0 pb-0 ">
                                    <div className="col-12 p-2 border-top rounded-3" style={{fontSize: "12px"}}>
                                        <div className="justify-content-center" style={{fontSize: "13px"}}>
                                            <span className={styleValueText}><b>
                                                {(ParameterAtGlance == LPG && singleValue > lpgRangeValues.GoodU) ? 
                                                    t(IDS_SDD_GasLeakageDetected) :
                                                    ParameterAtGlance == LPG && singleValue <= lpgRangeValues.GoodU ?
                                                        t(IDS_SDD_NoGasLeakage) : 
                                                        singleValue != null ?
                                                            singleValue : 
                                                                "--"
                                                }
                                            </b></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            );
        } else {
            if(DeviceType != null && DeviceType == DEVICE_TYPE_PFC){
                return(
                    <div className="deviceBox col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-4 m-0 p-1 border rounded-3" onClick={() => this.onViewDeviceData(singleDeviceID, DeviceName, DeviceType)} style={{ cursor: "pointer"}}>
                        <div className={styleValueBoxRecieved}>
                            <div className="row m-0 pt-1 pb-1" style={{fontSize: "14px"}}>
                                {isDeviceBlocked == false ?
                                    <div className="col-1 p-2 pb-1">
                                        <div className="m-0" style={{borderRadius: "50%", border: "1px solid grey", backgroundColor: DeviceStatus == "true" ? "#00ff00": "white", height: "0.6rem", width:"0.6rem", marginRight: "0.3rem"}}></div>
                                    </div> 
                                    : null
                                }
                                <span className="col-11 text-left">{DeviceName}</span>
                            </div>   
                            {isDeviceBlocked == true ? 
                                <div className="row m-0 mt-0 p-0 border-top rounded-3">
                                    <div className="row text-left p-3 m-0 " style={{fontSize: "12px"}}>
                                        <div className="col-12" style={{fontSize: "12px", paddingTop: "0.2rem"}}>
                                            <span className="ParamsValueMissingOrWithoutRangeForToiletDash"><b>{t(IDS_Dvc_Blocked)}</b></span>
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="row m-0 mt-0 p-0">
                                    <div className="row text-left p-0 m-0 border rounded-3" style={{fontSize: "12px"}}>
                                        <div className="col-9 p-1" style={{fontSize: "12px"}}>
                                            <span>{t(IDS_TodayPeopleCount)}</span>
                                        </div>
                                        <div className="col-3 p-1 border-start rounded-3" style={{fontSize: "12px"}}>
                                            <span><b style={{color: "black", fontSize: "12px", fontWeight:"normal"}}>{singleValue}</b></span>
                                        </div>
                                    </div>
                                    <div className="row text-left p-0 m-0 border rounded-3" style={{fontSize: "12px"}}>
                                        <div className="col-9 p-1" style={{fontSize: "12px"}}>
                                            <span>{t(IDS_OccupiedSeats)}</span>
                                        </div>
                                        <div className="col-3 p-1 border-start rounded-3" style={{fontSize: "12px"}}>
                                            <span><b style={{color: "black", fontSize: "12px", fontWeight:"normal"}}>{OccupancyPercent == null ? "--" : OccupancyPercent + "%"}</b></span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                );
            } else if(DeviceType != null && DeviceType == DEVICE_TYPE_WLI){
                return(
                    isDeviceBlocked == false ?
                    <div className="row m-0 mb-0" style={{position: "relative"}}>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1 wlidiv" onClick={() => this.onViewDeviceData(singleDeviceID, DeviceName, DeviceType, this.state.selectedToiletNode)} style={{ cursor: "pointer"}}>
                            <div className="row text-left p-0 m-0 rounded-3" style={{ cursor: "pointer", backgroundColor: "skyblue", opacity: "0.9"}}>
                                {isDeviceBlocked == false ?
                                    <div className="col-1 p-1" style={{fontSize: "14px"}}>
                                        <div className="m-0" style={{borderRadius: "50%", border: "1px solid grey", backgroundColor: DeviceStatus == "true" ? "#00ff00": "white", height: "0.6rem", width:"0.6rem", marginRight: "0.3rem"}}></div>
                                    </div>
                                    : null
                                }
                                <div className="col-11 p-1" style={{fontSize: "14px"}}>
                                    <span><b style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>{DeviceName}</b></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1">
                            <div className="row text-left p-0 m-0 rounded-3" style={{opacity: "0.7", backgroundColor: "lightskyblue"}}>
                                <div className="col-7 p-1" style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>
                                    <span>{t(IDS_WL)}</span>
                                </div>
                                <div className="col-5 p-1 border-start border-secondary rounded-3" style={{fontSize: "14px", fontWeight:"bold", borderColor: "black"}}>
                                    <span><b style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>{waterLevelIndicationPrcnt == null ? "--" : waterLevelIndicationPrcnt + "%"}</b></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1">
                            <div className="row text-left p-0 m-0 rounded-3" style={{opacity: "0.7" , backgroundColor: "lightskyblue"}}>
                                <div className="col-7 p-1" style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>
                                    <span>{t(IDS_AvailableWater)}</span>
                                </div>
                                <div className="col-5 p-1 border-start border-secondary rounded-3" style={{fontSize: "14px"}}>
                                    {/* <span><b style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>{availableWaterLtr == null ||  TankVolumeLtr == null ? "--" : availableWaterLtr + " / " + TankVolumeLtr + t(IDS_Litre_Short)}</b></span> */}
                                    <span><b style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>{availableWaterLtr == null ||  TankVolumeLtr == null ? "--" : availableWaterLtr + " " + t(IDS_Litre_Short)}</b></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1">
                            <div className="row text-left p-0 m-0 rounded-3" style={{opacity: "0.7", backgroundColor: "lightskyblue"}}>
                                <div className="col-7 p-1" style={{color: "black",fontSize: "14px", fontWeight:"bold"}}>
                                    <span>{t(IDS_CW)}</span>
                                </div>
                                <div className="col-5 p-1 border-start border-secondary rounded-3" style={{fontSize: "12px"}}>
                                    <span><b style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>{TotalConsumedWaterLtr == null ? "--" : TotalConsumedWaterLtr + " " + t(IDS_Litre_Short)}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row m-0 mb-0" style={{position: "relative", alignItems: "center"}}>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1 wlidiv" onClick={() => this.onViewDeviceData(singleDeviceID, DeviceName, DeviceType)} style={{ cursor: "pointer"}}>
                            <div className="row text-left p-0 m-0 rounded-3" style={{ cursor: "pointer", opacity: "0.9", backgroundColor: 'skyblue'}}>
                                {isDeviceBlocked == false ?
                                    <div className="col-1 p-1" style={{fontSize: "14px"}}>
                                        <div className="m-0" style={{borderRadius: "50%", border: "1px solid grey", backgroundColor: DeviceStatus == "true" ? "#00ff00": "white", height: "0.6rem", width:"0.6rem", marginRight: "0.3rem"}}></div>
                                    </div>
                                    : null
                                }
                                <div className="col-11 p-1" style={{fontSize: "14px"}}>
                                    <span><b style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>{DeviceName}</b></span>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-xl-9 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1" >
                            <div className="row text-left p-0 m-0 border rounded-3" style={{opacity: "0.9"}}>
                                <div className="col-12 p-1" style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>
                                    <span className="ParamsValueMissingOrWithoutRangeForToiletDash">{t(IDS_Dvc_Blocked)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else if(DeviceType != null && DeviceType == DEVICE_TYPE_STLI){
                return(
                    isDeviceBlocked == false ?
                    <div className="row m-0" style={{position: "relative"}}>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1 wlidiv" onClick={() => this.onViewDeviceData(singleDeviceID, DeviceName, DeviceType)} style={{ cursor: "pointer"}}>
                            <div className="row text-left p-0 m-0 rounded-3" style={{ cursor: "pointer", backgroundColor: "#c8961e", opacity: "0.9"
                            }}>
                                {isDeviceBlocked == false ?
                                    <div className="col-1 p-1" style={{fontSize: "14px"}}>
                                        <div className="m-0" style={{borderRadius: "50%", border: "1px solid grey", backgroundColor: DeviceStatus == "true" ? "#00ff00": "white", height: "0.6rem", width:"0.6rem", marginRight: "0.3rem"}}></div>
                                    </div>
                                    : null
                                }
                                <div className="col-11 p-1" style={{fontSize: "14px"}}>
                                    <span><b style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>{DeviceName}</b></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-6 col-sm-12 col-xs-4 p-1">
                            <div className="row text-left p-0 m-0 border rounded-3 p-0">
                                <div className="col-xl-3 col-lg-4 col-md-6 rounded-3" style={{padding: "0.2rem", opacity: "0.7", color: "black", fontSize: "14px", fontWeight:"bold"}}>
                                    <span>{t(IDS_SL)}</span>
                                </div>
                                <div className="col-xl-9 col-lg-8 col-md-6 p-0 border-start rounded-3 progress" style={{fontSize: "14px", fontWeight:"bold", borderColor: "black", height: "1.8rem"}}>
                                    <div className="progress-bar" role="progressbar" 
                                        aria-valuenow={septicTankLevelIndicationPrcnt}
                                        aria-valuemin="0" 
                                        aria-valuemax="100" 
                                        style={{width: septicTankLevelIndicationPrcnt == "0" || septicTankLevelIndicationPrcnt == null ? "100%" : septicTankLevelIndicationPrcnt + "%",
                                                background: 
                                                    septicTankLevelIndicationPrcnt == "0" ?
                                                    "linear-gradient(to right, #f2f2f2 0% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 10 && Number(septicTankLevelIndicationPrcnt) <= 20 ?
                                                    "linear-gradient(to right, #fcf7eb 50%, #f5e7c3 50% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 20 && Number(septicTankLevelIndicationPrcnt) <= 30 ?
                                                    "linear-gradient(to right, #fcf7eb 33.3%, #f5e7c3 33.3% 66.3%, #eed79c 66.3% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 30 && Number(septicTankLevelIndicationPrcnt) <= 40 ? 
                                                    "linear-gradient(to right, #fcf7eb 25%, #f5e7c3 25% 50% , #eed79c 50% 75%, #e8c774 75% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 40 && Number(septicTankLevelIndicationPrcnt) <= 50 ?
                                                    "linear-gradient(to right, #fcf7eb 20%, #f5e7c3 20% 40% , #eed79c 40% 60%, #e8c774 60% 80%, #e1b74c 80% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 50 && Number(septicTankLevelIndicationPrcnt) <= 60 ?
                                                    "linear-gradient(to right, #fcf7eb 16.6%, #f5e7c3 16.6% 33.3% , #eed79c 33.3% 50%, #e8c774 50% 66.6%, #e1b74c 66.6% 83.3%, #daa725 83.3% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 60 && Number(septicTankLevelIndicationPrcnt) <= 70 ?
                                                    "linear-gradient(to right, #fcf7eb 14.2%, #f5e7c3 14.2% 28.5% , #eed79c 28.5% 42.8%, #e8c774 42.8% 57.1%, #e1b74c 57.1% 71.4%, #daa725 71.4% 85.7%, #b3881e 85.7% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 70 && Number(septicTankLevelIndicationPrcnt) <= 80 ?
                                                    "linear-gradient(to right, #fcf7eb 12.5%, #f5e7c3 12.5% 25% , #eed79c 25% 37.5%, #e8c774 37.5% 50%, #e1b74c 50% 62.5%, #daa725 62.5% 75%, #b3881e 75% 87.5%, #8b6a17 87.5% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 80 && Number(septicTankLevelIndicationPrcnt) <= 90 ?
                                                    "linear-gradient(to right, #fcf7eb 11.1%, #f5e7c3 11.1% 22.2% , #eed79c 22.2% 33.3%, #e8c774 33.3% 44.4%, #e1b74c 44.4% 55.5%, #daa725 55.5% 66.6%, #b3881e 66.6% 77.7%, #8b6a17 77.7% 88.8%, #634c11 88.8% 100%)"
                                                    : Number(septicTankLevelIndicationPrcnt) > 90 && Number(septicTankLevelIndicationPrcnt) <= 100 ?
                                                    "linear-gradient(to right, #fcf7eb 10%, #f5e7c3 10% 20% , #eed79c 20% 30%, #e8c774 30% 40%, #e1b74c 40% 50%, #daa725 50% 60%, #b3881e 60% 70%, #8b6a17 70% 80%, #634c11 80% 90%, #3b2d0a 90% 100%)"
                                                    : "linear-gradient(to right, #f2f2f2 0% 100%)",
                                                color: "black"
                                            }}>
                                        {septicTankLevelIndicationPrcnt == null ? "--" : septicTankLevelIndicationPrcnt + "%"} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row m-0" style={{position: "relative", alignItems: "center"}}>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1 wlidiv" onClick={() => this.onViewDeviceData(singleDeviceID, DeviceName, DeviceType)} style={{ cursor: "pointer"}}>
                            <div className="row text-left p-0 m-0 rounded-3" style={{ cursor: "pointer", backgroundColor: '#c8961e'}}>
                                {isDeviceBlocked == false ?
                                    <div className="col-1 p-1" style={{fontSize: "14px"}}>
                                        <div className="m-0" style={{borderRadius: "50%", border: "1px solid grey", backgroundColor: DeviceStatus == "true" ? "#00ff00": "white", height: "0.6rem", width:"0.6rem", marginRight: "0.3rem"}}></div>
                                    </div>
                                    : null
                                }
                                <div className="col-11 p-1" style={{fontSize: "14px"}}>
                                    <span><b style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>{DeviceName}</b></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-4 col-md-6 col-sm-12 col-xs-4 p-1">
                            <div className="row text-left p-0 m-0 border rounded-3">
                                <div className="col-12 p-1" style={{color: "black", fontSize: "14px", fontWeight:"bold"}}>
                                    <span className="ParamsValueMissingOrWithoutRangeForToiletDash">{t(IDS_Dvc_Blocked)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    renderOdourLevel( inParamValue, inParamNameType ) {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;  

        if(inParamValue === null || inParamValue === "--") {
            return "(--)";
        }

        if(inParamNameType == NH3OD) {
            let singleParamAirQualityRange = this.state.stdAirQualityRanges[inParamNameType];

            if(singleParamAirQualityRange == null) {
                console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
                // If singleParamAirQualityRange missing.
                return "(--)"; 
            }

            let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
            let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
            let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
            let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
            let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
            let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
            let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
            let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
            let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
            let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
            let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
            let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];


            if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                        ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                        ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                // return "(Level 1)";
                return t(IDS_SDDNH3ODLevel1, inParamValue);

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                // return "(Level 2)";
                return t(IDS_SDDNH3ODLevel2, inParamValue);

            } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                        ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                        ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                // return "(Level 2)";
                return t(IDS_SDDNH3ODLevel3, inParamValue);

            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                        ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                        ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                // return "(Level 3)";
                return t(IDS_SDDNH3ODLevel4, inParamValue);


            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                        ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                        (inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                // return "(Level 4)";
                return t(IDS_SDDNH3ODLevel5, inParamValue);


            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                        ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                        (inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            {            
                // return "(Level 5)";
                return t(IDS_SDDNH3ODLevel6, inParamValue);

            } else {
                if(inParamValue < 0 ) {
                    // return "(Level 1)";
                    return t(IDS_SDDNH3ODLevel1, inParamValue);

                } else {
                    // return "(Level 5)";
                    return t(IDS_SDDNH3ODLevel6, inParamValue);

                }
            }
        }    
    }

    dbGetLatestDeviceData = (inModifiedState = null) => {

        let inAppRelevantDataContextValue = this.context;
        let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
        let t = inAppRelevantDataContextValue.t;  

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let currentBrowserDtTm = new Date();
        let strCurrentBrowserDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(currentBrowserDtTm);

        let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "00:00:00";
        let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "23:59:59";
  
        let crossingCountUtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserStartDtTm.valueOf()));
        let crossingCountUtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserEndDtTm.valueOf()));        

        modifiedState.stdAirQualityRanges = [];
        modifiedState.LoggedInUserID = loggedInUserID;
        modifiedState.havingPvgToViewBlockedDeviceData = false;
        modifiedState.allHHMDeviceDataDetails = [];
        modifiedState.allHHMDeviceDetailsArrWhoseDataDoesntExist = [];
        modifiedState.allDeviceDataDetails = [];
        modifiedState.pfcDeviceValues = [];
        modifiedState.allWLIDeviceDetails = [];
        modifiedState.allSTLIDeviceDetails = [];
        let AllHHMDeviceDataDetails = [];
        let HHMDeviceIDs = [];
        let allHHMDeviceDetailsArrWhoseDataDoesntExist = [];
        let sumOdourValOfToilet = null;
        let avgOdourValOfToilet = null;
        modifiedState.countOfDevicesHavingNH3ODVal = 0;
        modifiedState.objIncludeAvgBgColorAndRangeOfToilet = [];

        let jsonParams = {
            arrDeviceIDsForHHM: modifiedState.arrDeviceIDsForHHM != null || modifiedState.arrDeviceIDsForHHM.length > 0 ? modifiedState.arrDeviceIDsForHHM : [],
            arrDeviceIDsForPFC: modifiedState.arrDeviceIDsForPFC != null || modifiedState.arrDeviceIDsForPFC.length > 0 ? modifiedState.arrDeviceIDsForPFC : [],
            arrDeviceIDsForWLI: modifiedState.arrDeviceIDsForWLI != null || modifiedState.arrDeviceIDsForWLI.length > 0 ? modifiedState.arrDeviceIDsForWLI : [],
            arrDeviceIDsForSTLI: modifiedState.arrDeviceIDsForSTLI != null || modifiedState.arrDeviceIDsForSTLI.length > 0 ? modifiedState.arrDeviceIDsForSTLI : [],
            AllArrDeviceIDs: modifiedState.devicesListForNodeDashboard,
            LoggedInUserID: loggedInUserID,
            StartDtTmUTC: crossingCountUtcStartDtTm,
            EndDtTmUTC: crossingCountUtcEndDtTm,
            ActiveStatusMinimumTime: ACTIVE_STATUS_MINIMUM_TIME
        }

        if(modifiedState.devicesListForNodeDashboard.length !== 0) {
            axios.post(`${getAPIHostURL()}/wclient/getLatestDeviceDataForTheToiletNode`, jsonParams)
                .then(response =>{

                    if(response.data.code == 'SUCCESS') {
                        inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
                        modifiedState.receivedDeviceModelInfo = response.data.retreivedrelevantModelInfo;
                        let receivedParamsHighLowValues = response.data.retreivedstdAirQualityRanges;
                        let HHMDeviceValues = response.data.selectSingleDeviceData;
                        modifiedState.pfcDeviceValues = response.data.arrCrossCountBasedOnDevc;
                        modifiedState.allWLIDeviceDetails = response.data.allWLIDeviceDetails;
                        modifiedState.allSTLIDeviceDetails = response.data.allSTLIDeviceDetails;
                        modifiedState.allDeviceDataDetails = [];

                        modifiedState.BlockedUnblockedStatusOfDevices = response.data.BlockedUnblockedStatusOfDevices; 

                        // Show blocked Devices Data to Production/Support Users.                
                        modifiedState.havingPvgToViewBlockedDeviceData = response.data.havingPvgToViewBlockedDeviceData != null && 
                            response.data.havingPvgToViewBlockedDeviceData > 0 ? true : false;

                        // add the SeatOccupancyPercent in the PFC data.
                        if(modifiedState.pfcDeviceValues != null && modifiedState.pfcDeviceValues.length > 0) {

                            let pfcDeviceValues = [];

                            for(let i=0; i<modifiedState.pfcDeviceValues.length; i++) {

                                let SeatOccupancyCount = modifiedState.pfcDeviceValues[i]["SeatOccupancyCount"];

                                let TotalToiletSeats =  modifiedState.pfcDeviceValues[i]["TotalToiletSeats"];

                                let SeatOccupancyPercent;

                                // calculate the SeatOccupancyPercent.
                                if((SeatOccupancyCount != null && TotalToiletSeats != null && (SeatOccupancyCount != "0" && SeatOccupancyCount != 0 ) && (TotalToiletSeats != "0" && TotalToiletSeats != 0))||(
                                    (SeatOccupancyCount != null && TotalToiletSeats != null && (TotalToiletSeats != "0" && TotalToiletSeats != 0)))) {
                                    SeatOccupancyPercent = Math.round((Number((SeatOccupancyCount/TotalToiletSeats) * 100)));
                                } else {
                                    SeatOccupancyPercent = null;
                                }

                                let SeatOccupancyPercentObj = {
                                    SeatOccupancyPercent : SeatOccupancyPercent
                                }

                                pfcDeviceValues.push({...modifiedState.pfcDeviceValues[i], ...SeatOccupancyPercentObj});
                            }
                            modifiedState.pfcDeviceValues = pfcDeviceValues;
                        }

                        for(let i=0; i<HHMDeviceValues.length; i++) {

                            HHMDeviceIDs.push(HHMDeviceValues[i]["DeviceID"])
                        }

                        let ArryOfDevicesWhoseDataDoesntExist = [];

                        // due to data inconsistency if there is no device data of the particluar device
                        // then collect such devices and make the appropriate data for those devices.
                        ArryOfDevicesWhoseDataDoesntExist = modifiedState.arrDeviceIDsForHHM.filter(f => !HHMDeviceIDs.includes(f));

                        if(ArryOfDevicesWhoseDataDoesntExist != null && ArryOfDevicesWhoseDataDoesntExist.length > 0) {
                        
                            for(let i=0; i<ArryOfDevicesWhoseDataDoesntExist.length; i++) {

                                let DeviceIdWhoseDataDoesntExist = "";
                                let DeviceNameWhoseDataDoesntExist = "";
                                let BlockedStatusOfDvcWhoseDataDoesntExist = "";
                                let DeviceModelIDWhoseDataDoesntExist = "";
                                let ModelInfoOfDvcWhoseDataDoesntExist = {};
                                let relevantModelInfoWhoseDataDoesntExist = [];
                                let allHHMDeviceDetailsWhoseDataDoesntExist = {};
                                let SingleDeviceParamValue = {};

                                DeviceIdWhoseDataDoesntExist = ArryOfDevicesWhoseDataDoesntExist[i];

                                for(let i=0; i<modifiedState.arrAssignedDevicesToNode.length; i++) {

                                    if(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"] == DeviceIdWhoseDataDoesntExist) {
                                        DeviceNameWhoseDataDoesntExist = modifiedState.arrAssignedDevicesToNode[i]["DeviceName"]
                                        DeviceModelIDWhoseDataDoesntExist = modifiedState.arrAssignedDevicesToNode[i]["ModelID"];

                                    }
                                }

                                for(let i=0; i<modifiedState.BlockedUnblockedStatusOfDevices.length; i++) {
                                    if(modifiedState.BlockedUnblockedStatusOfDevices[i]["DeviceID"] == DeviceIdWhoseDataDoesntExist){
                                        BlockedStatusOfDvcWhoseDataDoesntExist = modifiedState.havingPvgToViewBlockedDeviceData != null && modifiedState.havingPvgToViewBlockedDeviceData == true ? 1 : 
                                        modifiedState.BlockedUnblockedStatusOfDevices[i]["BlockUnblockStatus"];
                                    }
                                }

                                for(let i=0; i<modifiedState.receivedDeviceModelInfo.length; i++) {
                                    if(modifiedState.receivedDeviceModelInfo[i]["ModelID"] == DeviceModelIDWhoseDataDoesntExist){
                                        ModelInfoOfDvcWhoseDataDoesntExist = JSON.parse(modifiedState.receivedDeviceModelInfo[i]["MeasuredParams"]);
                                    }
                                }

                                SingleDeviceParamValue =  {
                                    ParameterAtGlance: null,
                                    ParamValue: null
                                };

                                relevantModelInfoWhoseDataDoesntExist = ModelInfoOfDvcWhoseDataDoesntExist
                                
                                allHHMDeviceDetailsWhoseDataDoesntExist = {
                                    DeviceID: DeviceIdWhoseDataDoesntExist,
                                    SingleDeviceValue: SingleDeviceParamValue,
                                    ModelInfo: relevantModelInfoWhoseDataDoesntExist,
                                    SingleDeviceBlockedStatus: BlockedStatusOfDvcWhoseDataDoesntExist,
                                    DeviceName: DeviceNameWhoseDataDoesntExist,
                                    DeviceOnlineOfflineStatus: null,
                                }
                                allHHMDeviceDetailsArrWhoseDataDoesntExist.push(allHHMDeviceDetailsWhoseDataDoesntExist);
                            }
                            modifiedState.allHHMDeviceDetailsArrWhoseDataDoesntExist = allHHMDeviceDetailsArrWhoseDataDoesntExist;
                        } 

                        // make the data for the HHM devices.
                        if(HHMDeviceValues != null && HHMDeviceValues.length > 0) {

                            modifiedState.countOfDevicesHavingNH3ODVal = 0;

                            for(let i=0; i<HHMDeviceValues.length; i++) {

                                let Paramsvalue = {};
                                let DeviceID = "";
                                let DeviceName = "";
                                let DeviceBlockedStatus = "";
                                let DeviceModelID = "";
                                let ModelInfo = {};
                                let MeasuredParam;
                                let HighLow;
                                let Seq = [];
                                let arrAddtitonalTrackParam = [];
                                let SingleDeviceParamValue = {};
                                let relevantModelInfo = [];
                                let selectedParameterAtGlance = "";
                                let OnlineOfflineStatus = "";
                                let restrictedParamValues;

                                selectedParameterAtGlance = inAppRelevantDataContextValue.quickTrackParamInfo.quickTrackParam;
                                // store the paramter at glance value in the state variable.
                                modifiedState.selectedParameterAtGlance = selectedParameterAtGlance;

                                try {

                                    DeviceID = HHMDeviceValues[i]["DeviceID"];
                            
                                    if(HHMDeviceValues[i]["DeviceID"] == DeviceID) {
                                        Paramsvalue = JSON.parse(HHMDeviceValues[i]["MeasuredParams"]);
                                        DeviceName = HHMDeviceValues[i]["DeviceName"];
                                        OnlineOfflineStatus = HHMDeviceValues[i]["DeviceStatus"];
                                    }

                                    for(let i=0; i<modifiedState.arrAssignedDevicesToNode.length; i++) {
                                        if(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"] == DeviceID){
                                            DeviceModelID = modifiedState.arrAssignedDevicesToNode[i]["ModelID"];
                                        }
                                    }

                                    for(let i=0; i<modifiedState.receivedDeviceModelInfo.length; i++) {
                                        if(modifiedState.receivedDeviceModelInfo[i]["ModelID"] == DeviceModelID){
                                            ModelInfo = JSON.parse(modifiedState.receivedDeviceModelInfo[i]["MeasuredParams"]);
                                            Seq = ModelInfo["Seq"];
                                        }
                                    }

                                    relevantModelInfo = ModelInfo;

                                    // if the Paramsvalue is not null and doesnt have the selected paramter at glance value
                                    if(Paramsvalue != null && !Paramsvalue.hasOwnProperty(selectedParameterAtGlance) ) {

                                        // if the Paramsvalue is not null and doesnt have the selected paramter at glance value
                                        // but does includes in the sequence of model info
                                        // then set the selectedParameterAtGlance value to null
                                        if(Seq.includes(selectedParameterAtGlance)){

                                            //If key is not present in json. Add same to json.
                                            let objParamValue = {
                                                [selectedParameterAtGlance]: null
                                            };
                                            
                                            Paramsvalue = {...Paramsvalue, ...objParamValue};

                                        } else {

                                            // if the parameter is not included in the model 
                                            // then set to the default paramter.
                                            if(selectedParameterAtGlance == NH3OD) {
                                                arrAddtitonalTrackParam.push(PM1, PM10, VRI, TEMP);
                                            } else if(selectedParameterAtGlance == VRI) {
                                                arrAddtitonalTrackParam.push(NH3OD, PM10, PM1, TEMP);
                                            } else if(selectedParameterAtGlance == PM1) {
                                                arrAddtitonalTrackParam.push(NH3OD, PM10, VRI, TEMP);
                                            } else if(selectedParameterAtGlance == PM10) {
                                                arrAddtitonalTrackParam.push(NH3OD, PM1, VRI, TEMP);
                                            } else if(selectedParameterAtGlance == TEMP) {
                                                arrAddtitonalTrackParam.push(NH3OD, PM1, PM10, VRI);
                                            } else {
                                                arrAddtitonalTrackParam.push(NH3OD, PM10, VRI, PM1, TEMP, LPG);
                                            }

                                            if(arrAddtitonalTrackParam.includes(NH3OD) && Seq.includes(NH3OD)) {

                                            selectedParameterAtGlance = NH3OD; 

                                                if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[NH3OD] == null || Paramsvalue[NH3OD].length <= 0) {

                                                    // If key is not present in json. Add same to json.
                                                    let objParamValue = {
                                                        [selectedParameterAtGlance]: null
                                                    };
                                                    
                                                    Paramsvalue = {...Paramsvalue, ...objParamValue};
                                                }

                                            } else if(arrAddtitonalTrackParam.includes(VRI) && Seq.includes(VRI)) {

                                                selectedParameterAtGlance = VRI;
                                                
                                                if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[VRI] == null || Paramsvalue[VRI].length <= 0) {
                                                    
                                                    // If key is not present in json. Add same to json.
                                                    let objParamValue = {
                                                        [selectedParameterAtGlance]: null
                                                    };
                                                    
                                                    Paramsvalue = {...Paramsvalue, ...objParamValue};
                                                }

                                            } else if(arrAddtitonalTrackParam.includes(PM1) && Seq.includes(PM1)) {

                                                selectedParameterAtGlance = PM1;

                                                if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[PM1] == null || Paramsvalue[PM1].length <= 0) {
                                                    
                                                    // If key is not present in json. Add same to json.
                                                    let objParamValue = {
                                                        [selectedParameterAtGlance]: null
                                                    };
                                                    
                                                    Paramsvalue = {...Paramsvalue, ...objParamValue};
                                                }

                                            } else if(arrAddtitonalTrackParam.includes(PM10) && Seq.includes(PM10)) {

                                                selectedParameterAtGlance = PM10;

                                                if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[PM10] == null || Paramsvalue[PM10].length <= 0 ) {
                                                    
                                                    // If key is not present in json. Add same to json.
                                                    let objParamValue = {
                                                        [selectedParameterAtGlance]: null
                                                    };
                                                    
                                                    Paramsvalue = {...Paramsvalue, ...objParamValue};
                                                }
                                            } else if(arrAddtitonalTrackParam.includes(TEMP) && Seq.includes(TEMP)) {

                                                selectedParameterAtGlance = TEMP; 
        
                                                    if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[TEMP] == null || Paramsvalue[TEMP].length <= 0) {
        
                                                        // If key is not present in json. Add same to json.
                                                        let objParamValue = {
                                                            [selectedParameterAtGlance]: null
                                                        };
                                                        
                                                        Paramsvalue = {...Paramsvalue, ...objParamValue};
                                                    }
                                            } else if(Seq != null && Seq.includes(LPG)) {

                                                selectedParameterAtGlance = LPG; 
        
                                                    if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[LPG] == null || Paramsvalue[LPG].length <= 0) {
        
                                                        // If key is not present in json. Add same to json.
                                                        let objParamValue = {
                                                            [selectedParameterAtGlance]: null
                                                        };
                                                        
                                                        Paramsvalue = {...Paramsvalue, ...objParamValue};
                                                    }
                                            } else {
                                                
                                                // return;
                                                selectedParameterAtGlance = t(IDS_NoParameter); 
        
                                                // If key is not present in json. Add same to json.
                                                let objParamValue = {
                                                    [selectedParameterAtGlance]: null
                                                };

                                                Paramsvalue = {...Paramsvalue, ...objParamValue};
                                            }
                                        }

                                    } else if( Paramsvalue != null && (Paramsvalue[selectedParameterAtGlance] == null || Paramsvalue[selectedParameterAtGlance].length <=0 )) {

                                        // If key is not present in json. Add same to json.
                                        let objParamValue = {
                                            [selectedParameterAtGlance]: null
                                        };
                                        
                                        Paramsvalue = {...Paramsvalue, ...objParamValue};

                                    } else if(Paramsvalue != null && isNaN(Paramsvalue[selectedParameterAtGlance]) ) {

                                        let objParamValue = {
                                            [selectedParameterAtGlance]: null
                                        };
                                        
                                        Paramsvalue = {...Paramsvalue, ...objParamValue};

                                    } else if(Paramsvalue != null && Paramsvalue.hasOwnProperty(selectedParameterAtGlance) && !Seq.includes(selectedParameterAtGlance)){
                                        
                                        // when data is inconsistent and has paramters in the mesaured params object
                                        // which are not there in the sequence info of the model
                                        // then set the default paramter as parameter at glance
                                        if(selectedParameterAtGlance == NH3OD) {
                                            arrAddtitonalTrackParam.push(PM1, PM10, VRI, TEMP);
                                        } else if(selectedParameterAtGlance == VRI) {
                                            arrAddtitonalTrackParam.push(NH3OD, PM10, PM1, TEMP);
                                        } else if(selectedParameterAtGlance == PM1) {
                                            arrAddtitonalTrackParam.push(NH3OD, PM10, VRI, TEMP);
                                        } else if(selectedParameterAtGlance == PM10) {
                                            arrAddtitonalTrackParam.push(NH3OD, PM1, VRI, TEMP);
                                        } else if(selectedParameterAtGlance == TEMP) {
                                            arrAddtitonalTrackParam.push(NH3OD, PM1, PM10, VRI);
                                        } else {
                                            arrAddtitonalTrackParam.push(NH3OD, PM10, VRI, PM1, TEMP);
                                        }

                                        if(arrAddtitonalTrackParam.includes(NH3OD) && Seq.includes(NH3OD)) {

                                        selectedParameterAtGlance = NH3OD; 

                                            if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[NH3OD] == null || Paramsvalue[NH3OD].length <= 0) {

                                                // If key is not present in json. Add same to json.
                                                let objParamValue = {
                                                    [selectedParameterAtGlance]: null
                                                };
                                                
                                                Paramsvalue = {...Paramsvalue, ...objParamValue};
                                            }

                                        } else if(arrAddtitonalTrackParam.includes(VRI) && Seq.includes(VRI)) {
                                            
                                            selectedParameterAtGlance = VRI;
                                            
                                            if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[VRI] == null || Paramsvalue[VRI].length <= 0) {
                                                
                                                // If key is not present in json. Add same to json.
                                                let objParamValue = {
                                                    [selectedParameterAtGlance]: null
                                                };
                                                
                                                Paramsvalue = {...Paramsvalue, ...objParamValue};
                                            }

                                        } else if(arrAddtitonalTrackParam.includes(PM1) && Seq.includes(PM1)) {

                                            selectedParameterAtGlance = PM1;

                                            if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[PM1] == null || Paramsvalue[PM1].length <= 0) {
                                                
                                                // If key is not present in json. Add same to json.
                                                let objParamValue = {
                                                    [selectedParameterAtGlance]: null
                                                };
                                                
                                                Paramsvalue = {...Paramsvalue, ...objParamValue};
                                            }

                                        } else if(arrAddtitonalTrackParam.includes(PM10) && Seq.includes(PM10)) {

                                            selectedParameterAtGlance = PM10;

                                            if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[PM10] == null || Paramsvalue[PM10].length <= 0 ) {
                                                
                                                // If key is not present in json. Add same to json.
                                                let objParamValue = {
                                                    [selectedParameterAtGlance]: null
                                                };
                                                
                                                Paramsvalue = {...Paramsvalue, ...objParamValue};
                                            }
                                        } else if(arrAddtitonalTrackParam.includes(TEMP) && Seq.includes(TEMP)) {

                                            selectedParameterAtGlance = TEMP;

                                            if(!Paramsvalue.hasOwnProperty(selectedParameterAtGlance) || Paramsvalue[TEMP] == null || Paramsvalue[TEMP].length <= 0 ) {
                                                
                                                // If key is not present in json. Add same to json.
                                                let objParamValue = {
                                                    [selectedParameterAtGlance]: null
                                                };
                                                
                                                Paramsvalue = {...Paramsvalue, ...objParamValue};
                                            }
                                        } 

                                    } else {
                                        // No need to modify
                                    }

                                    if(Paramsvalue == null){
                                        SingleDeviceParamValue = {
                                            ParameterAtGlance: selectedParameterAtGlance,
                                            ParamValue: null
                                        }  
                                    } else {
                                        SingleDeviceParamValue = {
                                            ParameterAtGlance: selectedParameterAtGlance,
                                            ParamValue: Paramsvalue[selectedParameterAtGlance]
                                        }
                                    }

                                    for(let i=0; i<modifiedState.BlockedUnblockedStatusOfDevices.length; i++) {
                                        if(modifiedState.BlockedUnblockedStatusOfDevices[i]["DeviceID"] == DeviceID){
                                            DeviceBlockedStatus = modifiedState.havingPvgToViewBlockedDeviceData != null && modifiedState.havingPvgToViewBlockedDeviceData == true ? 1 : 
                                            modifiedState.BlockedUnblockedStatusOfDevices[i]["BlockUnblockStatus"];
                                        }
                                    }

                                    // if the Paramsvalue and has NH3OD parameter in it
                                    // and also included in its device model sequence and 
                                    // if the device is not blocked then 
                                    // take the count of all the devices which has NH3OD paramter and
                                    // get the some of all the NH3OD values
                                    if(Paramsvalue != null && Paramsvalue["NH3OD"] != null) {
                                        if(Seq.includes("NH3OD") && (DeviceBlockedStatus == 1 || DeviceBlockedStatus == "1")) {
                                            modifiedState.countOfDevicesHavingNH3ODVal += 1;
                                            // restrict value to its min or max if value goes beyound its maximun or minimum value.
                                            restrictedParamValues = this.restrictParamValIfGoesBeyondMinOrMax(Paramsvalue["NH3OD"], NH3OD, relevantModelInfo, DeviceID);
                                            sumOdourValOfToilet += restrictedParamValues;
                                        }
                                    }

                                    for(let i=0; i < receivedParamsHighLowValues.length; i++){
                                        let singleAirQualityRangeValues = receivedParamsHighLowValues[i];
                                        MeasuredParam = singleAirQualityRangeValues.MeasuredParam;

                                        HighLow = JSON.parse(receivedParamsHighLowValues[i].RangeValues);
                                        let singleRangeValues = {
                                            MeasuredParam: MeasuredParam,
                                            ...HighLow
                                        }

                                        modifiedState.stdAirQualityRanges[MeasuredParam] = singleRangeValues;
                                    }
                                
                                    let allHHMDeviceDetails = {
                                        DeviceID: DeviceID,
                                        SingleDeviceValue: SingleDeviceParamValue,
                                        ModelInfo: relevantModelInfo,
                                        SingleDeviceBlockedStatus: DeviceBlockedStatus,
                                        DeviceName: DeviceName,
                                        DeviceOnlineOfflineStatus: OnlineOfflineStatus,
                                    }

                                    AllHHMDeviceDataDetails.push(allHHMDeviceDetails);

                                } catch (error) {
                                    console.log(`Should not happen. The device data obtained from server for DeviceID ${DeviceID} is in invalid JSON format.`);
                                    // Skip this device information and move to next
                                }
                            }

                            // if the NH3OD devices count is more than 0 then take the average of sumOdourValOfToilet
                            // and based on average assign the colour and odour level of NH3OD parameter.
                            if(modifiedState.countOfDevicesHavingNH3ODVal > 0){
                                avgOdourValOfToilet = (sumOdourValOfToilet/modifiedState.countOfDevicesHavingNH3ODVal).toFixed(2);
                                modifiedState.objIncludeAvgBgColorAndRangeOfToilet = this.getRangeNameAndStyleBasedOnValue(avgOdourValOfToilet, NH3OD);
                            }

                            modifiedState.allHHMDeviceDataDetails = AllHHMDeviceDataDetails;
                        }

                        if(this.state.countOfDevicesHavingNH3ODVal > 0 && modifiedState.modelNameForNodeDashboard.includes(NH3OD) == false){
                            modifiedState.modelNameForNodeDashboard = [NH3OD].concat(modifiedState.modelNameForNodeDashboard)
                        }

                        modifiedState.lastIndexOfModelArray = modifiedState.modelNameForNodeDashboard.length - 1;

                        modifiedState.allDeviceDataDetails.push(...modifiedState.allHHMDeviceDataDetails, ...modifiedState.pfcDeviceValues, ...modifiedState.allHHMDeviceDetailsArrWhoseDataDoesntExist, ...modifiedState.allWLIDeviceDetails, ...modifiedState.allSTLIDeviceDetails);
                        //this.setState(modifiedState);
            
                    } else {
                        if(response.data.code == 'REQ_PARAMS_MISSING'){
                            // modifiedState.errors.others = 'Server experiencing issues (Device Params not received).\n Try again later.';
                            modifiedState.errors.others = t(IDS_SrvrIssueDevcParamsNotRec);
                        } else if(response.data.code == 'SQL_ERROR') {
                            // modifiedState.errors = 'Server experiencing issues.\n Try again later.';
                            modifiedState.errors.others = t(IDS_LoginServerIssue);
                        } else {
                            console.log("Should not reach here");
                            // modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                            modifiedState.errors.others = t(IDS_LoginServerIssue);
                        }
                    }
                    
                    // Do not update state in case of axios error. Continue to show the last updated value.
                    this.setState(modifiedState);

                })
                .catch(err => {
                    console.log("Network error");
                    console.log(err);
                    if (axios.isCancel(err)) {
                        console.log('Axios request cancelled because of too many requests being sent to the Server.');
                    } else {
                        // modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                        modifiedState.errors.others = t(IDS_RegistNetworkError);
                        console.log(modifiedState.errors.others);
                    }

                    // Do not update state in case of Network error. Continue to show the last updated value.
                    this.setState(modifiedState);
                })
        }
    }

    onRefreshBtnClicked = () => {
        let refreshIconEle = document.getElementById('tnddbut');
        if (refreshIconEle) {
            refreshIconEle?.classList.add('spinn')
            setTimeout(() => {
                refreshIconEle?.classList.remove('spinn')
            }, 1000);
        }
        this.dbGetLatestDeviceData();
    }

    // Called when Icon for More (Triple Dot Icon) is clicked.
    toggleFromIcon = (singleParam) => {

        let modifiedState = this.state;
        modifiedState.arrOfRanges = [];
        modifiedState.arrOfDispRangeValues = [];
        modifiedState.modalFromIcon = !this.state.modalFromIcon;
        modifiedState.selectedParamToViewRangeDetails = singleParam;

        let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(modifiedState.selectedParamToViewRangeDetails);

        let arrOfRangesOfSelectedParam = modifiedState.stdAirQualityRanges[modifiedState.selectedParamToViewRangeDetails] != null ? 
                                         modifiedState.stdAirQualityRanges[modifiedState.selectedParamToViewRangeDetails]['DispRngSeq'] : [] ;

        if(arrOfRangesOfSelectedParam != null && arrOfRangesOfSelectedParam.length > 0){
            arrOfRangesOfSelectedParam.forEach((childSnapshot) => {
                modifiedState.arrOfRanges.push(Object.keys(childSnapshot)[0]);
                modifiedState.arrOfDispRangeValues.push(Object.values(childSnapshot)[0]);
            });
        }     
        
        for(let i=0; i<PARAM_FOR_SHOWING_IMPACTS.length; i++) {
              
            if(PARAM_FOR_SHOWING_IMPACTS[i] == modifiedState.selectedParamToViewRangeDetails) {
                modifiedState.showImpactsOfParam = true;
                break;           
            } else {
                modifiedState.showImpactsOfParam = false;
            }
        }
        
        this.setState(modifiedState);
    }
    
    onFillGoogleFormClicked = (e) =>{
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScv4Y2sG_RKlhCBYDWy3J_6KzxzuQ2or-ZIx1tKNab5erLp9g/viewform?usp=sf_link");
    }

    // function for reordering the array
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        
        return result;
    };

    // function to put Device Model up
    onPutUp = (index) => {
        let modifiedState = this.state;
        
        let reorderedItems = this.reorder(
            this.state.modelNameForNodeDashboard,
            index,
            index - 1
        );

        modifiedState.modelNameForNodeDashboard = reorderedItems;

        this.setState(modifiedState);
    };

    // function to put Device Model down
    onPutDown = (index) => {
        let modifiedState = this.state;
        
        let reorderedItems = this.reorder(
            this.state.modelNameForNodeDashboard,
            index,
            index + 1
        );

        modifiedState.modelNameForNodeDashboard = reorderedItems;

        this.setState(modifiedState);
    };

    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        let arrOfDevicesBasedOnModelName = [];

        let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;
        let allDeviceDataDetails = this.state.allDeviceDataDetails;
           
        return (
            <>
                 <div className='py-2 w-100 flex justify-between'>
                    <span className='text-left flex align-items-center gap-1'>
                        <small>{this.props.fullPath}</small>
                        <small className='selectedNodeTitle' style={{fontWeight:"600"}}>
                            {selectedTreeNodeTitle}
                        </small>
                    </span>
                    <button className='refreshButtonStyle'
                                onClick={this.onRefreshBtnClicked} 
                                title={t(IDS_RefreshData)}
                            >
                                <i className="fa fa-refresh flex-center" id='tnddbut'
                                style={{ paddingLeft: "0", fontSize: "20px"}} 
                                aria-hidden="true"></i>
                            </button>
                </div>
                <div>
                    <div className="container " style={{paddingBottom: "0.5rem"}}>
                        {/* <div className="flex w-100 justify-between items-center ">

                            <button
                                type = "button" 
                                className="dvcSanitationBtn" 
                                title={t(IDS_SanitationMapping)}
                                onClick={this.onFillGoogleFormClicked}
                                style={{marginLeft: "0rem"}}
                            > 
                                {t(IDS_SanitationMapping)}
                            </button>

                            <button className='refreshButtonStyle'
                                onClick={this.onRefreshBtnClicked} 
                                title={t(IDS_RefreshData)}
                            >
                                <i className="fa fa-refresh flex-center" 
                                style={{ paddingLeft: "0", fontSize: "20px"}} 
                                aria-hidden="true"></i>
                            </button>
                            
                        </div> */}
                        {this.state.modelNameForNodeDashboard.map((singleModelName, index) => {
                            arrOfDevicesBasedOnModelName = this.renderDevicesBasedOnModelName(singleModelName);

                            return(
                                this.state.allDeviceDataDetails == null || this.state.allDeviceDataDetails.length <= 0 ?
                                <div key={index} /> :
                                this.renderDeviceModelNameAndDeviceInfo(arrOfDevicesBasedOnModelName, singleModelName, index) 
                            )      
                        })}
                    </div>
                </div>  
            </>
            
        )
    }
}

const mapStateToProps = (dispatch) => ({
    setSearchedOrClickedToiletNode: (x) => dispatch(setSearchedOrClickedToiletNode(x)),
    addToOpenToiletNodes: (x) => dispatch(addToOpenToiletNodes(x))
});

VcToiletNodeDashboardData.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

// export default VcToiletNodeDashboardData;
// export default connect(mapStateToProps ,null)(VcToiletNodeDashboardData);
export default connect(null ,mapStateToProps)(VcToiletNodeDashboardData);

 