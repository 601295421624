import './App.css';
import './Component/CSS/TailwindClass.css';
import { AppRelevantDataContext } from './AppContext';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import VcSupport from './Component/JS/VcSupport';
//import AboutHHM from './Component/JS/AboutHHM';
import VcLoginPg from './Component/JS/VcLoginPg';
import VcRegistration from './Component/JS/VcRegistration';
import React, {Component} from 'react';
import VcNavBar from './Component/JS/VcNavbar';
import axios from 'axios';
import AddUserForm from './Component/JS/AddUserForm';
import RemoveUser from './Component/JS/RemoveUserForm';
// import VcAboutHHM from './Component/JS/VcAboutHHM';
import { getAPIHostURL } from './ClientConfig';
import { convertLocalDateToStrYYYYMMDDHH24MMSS } from './vtUtil';
import VcDeviceRawData from './Component/JS/VcDeviceRawData';
import VcMaps from './Component/JS/VcMaps';
import VcForgotPassword from './Component/JS/VcForgotPassword';
import { tFormat } from '../src/vtUtil';
import VcAdminHome from './Component/JS/VcAdminHome';
import VcCrmHome from './Component/JS/VcCrmHome';
import VcAdminEnrollFieldEngineer from './Component/JS/VcAdminEnrollFieldEngineer';
import VcDeviceProfile from './Component/JS/VcDeviceProfile';
import VcAddRemoveUserToTheNode from './Component/JS/VcAddRemoveUserToTheNode';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { APP_TRANSLATIONS, APPLANG_DEFAULT, IDS_AUMaxUsersAlert,IDS_AUNotOwner, IDS_AUServerError, IDS_AUNotSpecDevcIDOrLogUserID, 
        IDS_RegistNetworkError, IDS_RmvUsrNoNeedToRmvUsers, IDS_RmvUsrNotOwner, IDS_AlertMsgForPFCDevicesForDeviceRawData, IDS_LoginServerIssue } from '../../vilisoclient/src/VcLanguage';
import VcResetPassword from './Component/JS/VcResetPassword';
import VcPopUpToBeShownOnExceedingAMaxAxiosReq from './Component/JS/VcPopUpToBeShownOnExceedingAMaxAxiosReq';
import { TOO_MANY_SERVER_REQUESTS, STD_DIFF_BW_TWO_AXIOS_CALLS, MAX_AXIOS_REQ, MAX_API_RETRIES_FOR_SINGLE_API_TILL_CHALLAN_REFRESH, IV_KEY_GEN_VAL, INVOKED_FROM_TRACK_DEVC, DEVICE_TYPE_PFC} from './VcConstants';
import VcSpinner from './Component/JS/VcSpinner';
import VcResetDeviceDetails from './Component/JS/VcResetDeviceDetails';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import CryptoJS from 'crypto-js';
import VcFAQPg from './Component/JS/VcFAQPg';
import VcRateToilet from './Component/JS/VcRateToilet';
import VcMapPg from './Component/JS/VcMapPg';
import VcVRIandMRIInfo from './Component/JS/VcVRIandMRIInfo';
import VcOTAStatusReport from './Component/JS/VcOTAStatusReport';
import VcProvideSolForCustComplaintPg from './Component/JS/VcProvideSolForCustComplaintPg';
// import VcAirQualityMap from './Component/JS/VcAirQualityMap';
import VcRateToiletWithQr from './Component/JS/VcRateToiletWithQR';
import {messaging} from './VcFirebase';
import { onMessage } from "firebase/messaging";
import { notification } from 'antd';
import VcDeviceHome from './Component/JS/VcDeviceHome';
import VcSanitationMapping from './Component/JS/VcSanitationMapping';
import VcSanitationMappingForm from './Component/JS/VcSanitationMappingForm';
import VcParameterDocumentationPg from './Component/JS/VcParameterDocumentationPg';
import VcParameterDocumentation from './Component/JS/VcParameterDocumentation';
import VcVilisoAPIs from './Component/JS/VcVilisoAPIs';

let LS_ITEM_APP_RELEVANT_DATA_CONTEXT = 'AppRelevantDataContext';

class App extends Component {

  constructor(props){
    super(props);

    this.lastRequestURL = '';
    this.countNoOfAxiosCallsInCurrentTrackedInterval = 0;
    this.FisrstReqDtTm = null;
    this.showAlert = false;

    this.state = {
        LoggedInUserID: '',
        Privilege:'',
        DevicesInfo: [],
        SelectedDeviceInfo: {},
        ownerOfTrackedDevices: "",
        UserFirstName: '',
        UserLastName: '',
        selectedTreeNodeID: '',
        SelectedNodeDeviceType: "",
        selectedTreeNodeTitle: '',
        isRootSelected: true,
        // chartParamType:'TEMP',
        chartParamType:'NH3OD',
        chartPeriodToView:'Weekly',
        chartGraphToView:'lineAndBarGraph',
        chartStartDateTime: null,
        chartEndDateTime: null,
        languageToViewIn: APPLANG_DEFAULT, // Initially the selected language will be Default (English)
        // Only for faster translation, this structure (map) will be loaded whenever the language changes.
        // This structure will not be stored in LocalStorage.
        selectedLangTranslations: APP_TRANSLATIONS[APPLANG_DEFAULT], // Initially load the translation object(map) for Default (English)
        // selectedModelName:'HHM-10Sensor-InHouse',
        // selectedModelID: 1,
        selectedModelName: '',
        selectedModelID: '',
        isCustomizedDateTimeChecked: false,

        isReqProcessing: false,

        addUserPopupInfo: {
          isVisible: false,
          deviceIDToBeAdded: '',
          deviceNameToBeAdded: '',
          parentIDOfSelectedNode: null,
        },
        removeUserPopupInfo: {
          isVisible: false,
          deviceIDToBeRemoved: '',
          deviceNameToBeRemoved: '',
          parentIDOfSelectedNode: null,
        },
        RawDataPopupInfo: {
          isVisible: false,
          deviceIDToBeDisplay: '',
          deviceNameToBeDisplay: ''
        },
        DeviceProfilePopupInfo: {
          isVisible: false,
          deviceIDToBeDisplay: '',
          deviceNameToBeDisplay: ''
        },
        addRemoveUserPopupInfo: {
          isVisible: false,
          treeNodeIDToBeDisplay: '',
          treeNodeTitleToBeDisplay: '',
          selectedTreeNodeHasDevc: false,
        },
        isCountOfAxiosReqExceeds: false,
        canRefreshPage: false,
        sessChallan: '',
        sessChaabi: '',
        sessID: '',
        selectedQuickTrackParam: '',
        hasDevcsForSelectedTreeNode: false,
        isSelectedTreeNodeDevc: false,
        arrDeviceType: [],
        selectedTreeNodeParentID: null,
        hasAssociatedTreeNodeIds: false,
        userIsLoggedInAndRenderedTree: false,
        selectedNodeContainsChildNode: false,
        nodePath: '',
        loginPagePath: "",
        showNextPagePathAfterLogin: "",
        menuSwitchBtn: "",
        fcmRegID: '',
        treeStructureSettingsEnabled: false,
        toiletDashboardVisible: false,
        reRenderTreeOnAddEditRemove: false,
        notification: {title: '', body: ''},
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.getLatestValueForAppRelevantDataContext = this.getLatestValueForAppRelevantDataContext.bind(this);

  }

  componentDidMount () {
    this.checkCountOfAxiosCall()
  }

  // This functions is used to play the notification sound.
  // We just need to call & pass a URL of the file to this function to play the sound
  playSound = (url) => {
    // this.playSound("https://www.computerhope.com/jargon/m/example.mp3");
    console.log("url")
    const audio = new Audio(url);
    console.log("audio = ", audio);
    audio.play();
  }

  componentDidUpdate(){

    let modifiedState = this.state;

    let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t; 

    // Enable if Wanted to use Notification Feature on HHM Web.
    // if(messaging != null){

    //   // This is an inbuild function of FCM (Firebase Cloud Messaging)
    //   // This function catches foreground notifications (when our web app is running in foreground)
    //   // Whenever a notification is sent and if web app is in foreground, the notification will get catched by onMessage function
    //   // which will then show the catched notification inside the app.
    //   onMessage(messaging, (payload) => {

    //     modifiedState.notification = {title: payload.notification.title, body: payload.notification.body};

    //     this.openNotification();

    //     this.setState(modifiedState);
    //   });
    // }

      // messaging.onTokenRefresh(() => {
      //   messaging.getToken()
      //     .then((refreshedToken) => {

      //       let fcmRegID = appRelevantDataContextValue.fcmRegDetails.fcmRegID;
      //       let sessID = appRelevantDataContextValue.sessJankari.sessID;
      //       let UserID = appRelevantDataContextValue.loggedInUserInfo.userID;

      //       if(fcmRegID != null && fcmRegID.length > 0 && sessID != null && sessID.length > 0){
      //         this.saveDeviceRegIdIntoDB(UserID, fcmRegID, refreshedToken, 'TokenRefresh', sessID);
      //       }
      //     }).catch((error) => {
      //       console.error('Unable to retrieve refreshed token ', error);
      //     });
      // });

  }

  checkCountOfAxiosCall = () => {

    // If a user is logged in and the chaabi is not available in a context we have to renew both a Challan and chaabi too.
    if((this.state.UserFirstName != null && this.state.UserFirstName.length > 0) && 
        (this.state.sessChaabi == null || this.state.sessChaabi.length <=0) &&
        (this.state.DevicesInfo == null || this.state.DevicesInfo.length <=0) &&
        (this.state.ownerOfTrackedDevices == null || this.state.ownerOfTrackedDevices.length <=0)
    ) {

      let jsonParams = {
        LoggedInUserID: this.state.LoggedInUserID,
        EncChallan: this.state.sessChallan,
        sessID: this.state.sessID,
      }

      axios.post( `${getAPIHostURL()}/wclient/getSessJankari`, jsonParams)
      .then(response => {
        if(response.data.code == 'SUCCESS') {
          const resData = response.data;

          this.onChangeSessJankari(resData.EncChallan, resData.ChaabiToEnc, this.state.sessID);

        } else {

          let strMsg = "";

          if (response.data.code == 'REQ_PARAMS_MISSING') {
            // Let the user know that the Required parameters were not sent to the Server
            strMsg = 'Server experiencing issues.\nTry again later.';
            console.log(strMsg);
          } else if (response.data.code == 'SQL_ERROR') {
              // Tell the user that Server is experiencing errors
              strMsg = 'Server experiencing issues.\nTry again later.';
              console.log(strMsg);
          } else {
              console.log('Should not reach here');
          }

          // If an authorization failed, make sure you clear all the logged-in user information from context and local storage. 
          // Also, redirect the user to the Login page.
          App.clearAppRelevantDataContextFromLocalStorage();

          alert(`Session Expired. Redirecting you to Login page. Please Re-Login.`)

          if(this.updateAppRelevantDataContextFromLocalStorage() == false) {
            window.location.replace('/');
          }
          return; // no need further processing.
        }
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        // Tell the user that there are network issues
        console.log("Server experiencing issues.\nTry again later.");

        // If an authorization failed, make sure you clear all the logged-in user information from context and local storage. 
        // Also, redirect the user to the Login page.
        App.clearAppRelevantDataContextFromLocalStorage();

        alert(`Session Expired. Redirecting you to Login page. Please Re-Login.`)

        if(this.updateAppRelevantDataContextFromLocalStorage() == false) {
          window.location.replace('/');
        }
        return; // no need further processing.
      }); 
    }

    //The problem was, requests are keep on geting stored in axios.interceptors.request  (like stack)
    //now I have removed those request.. before next request excecution.. uising below peice of code.
    this.myConfig = axios.interceptors.request.use();
    if((this.myConfig-1) > 0)
    {
        axios.interceptors.request.eject(this.myConfig-1);
    }    

    axios.interceptors.request.use( config  => {
      // console.log("INSIDE INTERCEPTER 1");

      // to avoid overwriting if another interceptor
      // already defined the same object (meta)
      // config.meta = config.meta || {}
      // config.meta.requestStartedAt = new Date().getTime();

      // console.log("calling  = ",config.url);
      // If a Maximum request are exceeds then cancel all other request timm One minute.
      if(this.state.isCountOfAxiosReqExceeds == true) {
        throw new axios.Cancel(TOO_MANY_SERVER_REQUESTS);
      }

      let DtTmOfAxiosReq = new Date();

      let strChallan = "";

      if(this.state.sessChallan != null && this.state.sessChallan.length> 0 && 
        this.state.sessChaabi != null && this.state.sessChaabi.length >0
      ) {
          let keyUsedToDecrypt = CryptoJS.PBKDF2(this.state.sessChaabi, this.state.sessChaabi, {
            keySize: 256 / 32
          });

          let iv = CryptoJS.PBKDF2(IV_KEY_GEN_VAL, IV_KEY_GEN_VAL, {
              keySize: 128 / 32
          });

          let bytes = aes.decrypt(this.state.sessChallan.toString(), keyUsedToDecrypt, { 
            iv: iv, 
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
          });

          strChallan = bytes.toString(enc);  
      }

      if(strChallan.length<=0) {
        // This is mostly happening on PageRefresh where Decrypted challan is empty just because of the absence of the chaabi. 
        // So, here we are explicitly sending the empty challan which will cause request failure.
        strChallan = ""
      } else {
        strChallan = strChallan +  "_" + this.state.sessID;
      }

      if((this.state.DevicesInfo != null && this.state.DevicesInfo.length > 0) || (this.state.ownerOfTrackedDevices != null && this.state.ownerOfTrackedDevices.length > 0)) {
        strChallan = INVOKED_FROM_TRACK_DEVC;
      }

      // Sends a decrypted Challan in a headers.
      config.headers.Authorization = strChallan;
      config.headers["X-Client-Type"] = "web";   // Adding the X-Client-Type header

      // This is required in first case just to get first reqest time unique.
      if (this.lastRequestURL == '' || this.lastRequestURL.length <= 0) {
          this.lastRequestURL = config.url;
          this.FisrstReqDtTm = new Date();
      }

      // get difference between two calls in seconds.
      let diffBetweenTwoAxiosCalls = (DtTmOfAxiosReq - this.FisrstReqDtTm)/1000;

      // console.log("diffBetweenTwoAxiosCalls =", diffBetweenTwoAxiosCalls);
      // Count no of axios.
      this.countNoOfAxiosCallsInCurrentTrackedInterval++;

      if(diffBetweenTwoAxiosCalls > STD_DIFF_BW_TWO_AXIOS_CALLS) {

          if(this.countNoOfAxiosCallsInCurrentTrackedInterval < MAX_AXIOS_REQ) {
              this.countNoOfAxiosCallsInCurrentTrackedInterval = 0;
              this.FisrstReqDtTm = new Date();    

          } else {

              // reset tym and count and dont hit any axios req next one minute.
              this.countNoOfAxiosCallsInCurrentTrackedInterval = 0;
              this.FisrstReqDtTm = new Date();  

              this.setState({
                isCountOfAxiosReqExceeds: true,
                canRefreshPage: false,
              })
              
              // cancel axios req.
              throw new axios.Cancel(TOO_MANY_SERVER_REQUESTS);
          }

      } else {
      }            
      return config; 
    }, error => {
        // handle the error
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {

      // console.log("INSIDE INTERCEPTER 2", response);

      if(response.data.code == 'CHALLAN_CHECK_SQL_ERR' || response.data.code == "CHALLAN_MISSING_INVALID_EXPIRED" ||
          response.data.code == 'SAME_SESS_IS_USED_FROM_MULTIPLE_LOC'
      ) {

          // If an authorization failed, make sure you clear all the logged-in user information from context and local storage. 
          // Also, redirect the user to the Login page.
          App.clearAppRelevantDataContextFromLocalStorage();
          
          // This is just to prevent showing the multiple alerts on an authorization failure.
          if(this.showAlert == false) {
            alert(`Session Expired. Redirecting you to Login page. Please Re-Login.`);         
          }

          if(this.updateAppRelevantDataContextFromLocalStorage() == false) {
            window.location.replace('/');  
          }

          this.showAlert = true;
      }

      // console.log(`Execution time for: ${response.config.url} - ${ (new Date().getTime() - response.config.meta.requestStartedAt)/1000} s`)

      return response;
    }, (error) => {

      // console.log("error = ",error);
      const originalRequest = error.config;
      // console.log("originalRequest = ",originalRequest);

      // Challan refresh is the first API called in case the Challan DecryptKey is missing.
      // Till that time, additional APIs get called and will fail. This will leave the UI in 
      // an unpredictable state in case of Refresh. So, we retry the same API till the token 
      // is refreshed. But, to prevent infinite loop, we will retry the single API only thrice.
      if (error.response.status == 401 && 
          (("_retryCount" in originalRequest) == false || originalRequest._retryCount <= MAX_API_RETRIES_FOR_SINGLE_API_TILL_CHALLAN_REFRESH) 
      ) {

        // console.log("originalRequest._retryCount = ",originalRequest._retryCount);
        originalRequest._retryCount = ("_retryCount" in originalRequest) == false ? 1 : originalRequest._retryCount + 1;

        return axios(originalRequest); // Retry the original request.
      }

      // console.log(`Execution time for ERROR: ${error.response.config.url} - ${ (new Date().getTime() - error.response.config.meta.requestStartedAt)/1000} s`)

      return Promise.reject(error.message);
    });

  }

  onCloseAddUserPopup = () => {
    this.setState({
        addUserPopupInfo: {
            isVisible: false,
            deviceIDToBeAdded: '',
            deviceNameToBeAdded: '',
            parentIDOfSelectedNode: null
        }     
    });
  }

  onCloseRemoveUserPopup = () => {
    this.setState({
        removeUserPopupInfo: {
            isVisible: false,
            deviceIDToBeRemoved: '',
            deviceNameToBeRemoved: '',
            parentIDOfSelectedNode: null,
        }     
    });
  }

  onCloseRawDataPopup = () => {
    this.setState({
      RawDataPopupInfo: {
          isVisible: false,
          deviceIDToBeDisplay: '',
          deviceNameToBeDisplay: ''
      }     
    });
  }

  OnCloseDeviceProfilePopup = () => {
    this.setState({
      DeviceProfilePopupInfo: {
        isVisible: false,
        deviceIDToBeDisplay: '',
        deviceNameToBeDisplay: ''
      }     
    });
  }

  OnCloseAddRemoveUserPopup = () => {
    this.setState({
      addRemoveUserPopupInfo: {
        isVisible: false,
        treeNodeIDToBeDisplay: '',
        treeNodeTitleToBeDisplay: '',
        selectedTreeNodeHasDevc: false,
      }     
    });
  }

  onChangeSessJankari = (inSessChallan, inSessChaabi, inSessID) => {

    let modifiedState = this.state;
    modifiedState.sessChallan = inSessChallan;
    modifiedState.sessChaabi = inSessChaabi;
    modifiedState.sessID = inSessID;

    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    
    this.setState (modifiedState);

  }

  onChangeQuickTrackParam = (inParam) => {
    let modifiedState = this.state;
    modifiedState.selectedQuickTrackParam = inParam;

    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    
    this.setState (modifiedState);
  }

  onTreeRenderedFirstTime = (inuserIsLoggedInAndRenderedTree) => {
    let modifiedState = this.state;
    modifiedState.userIsLoggedInAndRenderedTree = inuserIsLoggedInAndRenderedTree;

    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    
    this.setState (modifiedState);

  }

  OnClosePopupToBeShownOnExceedingAMaxAxiosReq = () => {
    // canRefreshPage is just explicitly refresh page in order to avoid unneccessay UI behaviour.
    this.setState({
      isCountOfAxiosReqExceeds: false,
      canRefreshPage: true
    })
  }


  onAddUser = (nodeID, nodeTitle, parentID=null) => {
    let strMsg = '';
    let selectedDeviceID = nodeID;
    let SelectedDeviceName = nodeTitle;
    let strLoggedInUserID = this.state.LoggedInUserID;

    const jsonUserAndDeviceInfo = {
        DeviceIDToCheck: selectedDeviceID,
        LoggedInUserID: strLoggedInUserID
    };

    axios.post(`${getAPIHostURL()}/wclient/checkCanAddUsersOnDevice`, jsonUserAndDeviceInfo)    
    .then(response => {

        if(response.data.code == 'SUCCESS') {

            let resultValue = response.data.result;

            if (resultValue == 'SUCCESS') {
                // Invoke AddUser Popup for adding User to the device.
                this.setState({
                    addUserPopupInfo: {
                        isVisible: true,
                        deviceIDToBeAdded: selectedDeviceID,
                        deviceNameToBeAdded: SelectedDeviceName,
                        parentIDOfSelectedNode: parentID
                    }     
                });
            } else {
                // Device already has MaxUsers.
                strMsg = ``;

                if (resultValue == 'DEVC_HAS_MAX_USERS') {
                    // strMsg = `Device ${SelectedDeviceName} already has Max Users. Cannot Add more Users. `;
                    strMsg = this.t(IDS_AUMaxUsersAlert, SelectedDeviceName);
                } else {
                    strMsg = this.t(IDS_AUNotOwner, strLoggedInUserID, SelectedDeviceName);
                    // strMsg = `User '${strLoggedInUserID}' cannot add more users to Device '${SelectedDeviceName}'.` + 
                    // `\nEither the user is not the Owner of the Device or does not have required Privileges.`;
                }

                alert(strMsg); 
                return; // No further processing.
            }
            
            return; // No further processing required
        } else {
            if (response.data.code == 'REQ_PARAMS_MISSING') {
                // Let the user know that the Required parameters were not sent to the Server
                strMsg = this.t(IDS_AUNotSpecDevcIDOrLogUserID);
            } else if (response.data.code == 'SQL_ERROR') {
                // Tell the user that Server is experiencing errors
                strMsg = this.t(IDS_AUServerError);
            } else {
                console.log('Should not reach here');
                strMsg = this.t(IDS_AUServerError);
            }
            alert(strMsg);
        }  
    })
    .catch( error => {
        // Tell the user that there are network issues
        strMsg = this.t(IDS_RegistNetworkError);
        alert(strMsg);
    }); 
  }

  onRemoveUser = (nodeID, nodeTitle, parentID=null) => {

    let strMsg = '';
    let selectedDeviceID = nodeID;
    let SelectedDeviceName = nodeTitle;
    let strLoggedInUserID = this.state.LoggedInUserID;
    // First check if the device for which RemoveUser is invoked has any Users added to it for viewing.

    const jsonUserAndDeviceInfo = {
        DeviceIDToCheck: selectedDeviceID,
        LoggedInUserID: strLoggedInUserID
    };

    axios.post(`${getAPIHostURL()}/wclient/checkCanRemoveUsersFromDevice`, jsonUserAndDeviceInfo)    
    .then(response => {

        if(response.data.code == 'SUCCESS') {

            let resultValue = response.data.result;

            if (resultValue == 'SUCCESS') {
                // Invoke RemoveUser Popup for removing Users from the device.
                this.setState({
                    removeUserPopupInfo: {
                        isVisible: true,
                        deviceIDToBeRemoved: selectedDeviceID,
                        deviceNameToBeRemoved: SelectedDeviceName,
                        parentIDOfSelectedNode: parentID,
                    }     
                });
            } else {
                // Device already has MaxUsers.
                strMsg = ``;

                if (resultValue == 'DEVC_HAS_NO_ADDITIONAL_USERS') {
                    strMsg = this.t(IDS_RmvUsrNoNeedToRmvUsers, SelectedDeviceName);
                } else {
                    // `\nEither the user is not the Owner of the Device or does not have required Privileges.`;
                    strMsg = this.t(IDS_RmvUsrNotOwner, strLoggedInUserID, SelectedDeviceName);
                }

                alert(strMsg); 
                return; // No further processing.
            }

            return; // No further processing required
        } else {
            if (response.data.code == 'REQ_PARAMS_MISSING') {
                // Let the user know that the Required parameters were not sent to the Server
                strMsg = this.t(IDS_AUNotSpecDevcIDOrLogUserID);
            } else if (response.data.code == 'SQL_ERROR') {
                // Tell the user that Server is experiencing errors
                strMsg = this.t(IDS_AUServerError);
            } else {
                console.log('Should not reach here');
                strMsg = this.t(IDS_AUServerError);
            }
            alert(strMsg);
        }  
    })
    .catch( error => {
        // Tell the user that there are network issues
        strMsg = this.t(IDS_RegistNetworkError);
        alert(strMsg);
    }); 

  }

  onGetRawData = (nodeID, nodeTitle, SelectedNodeDeviceType) => {

    let selectedDeviceID = nodeID;
    let selectedDeviceName = nodeTitle;

    let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t; 

    if(SelectedNodeDeviceType != null && SelectedNodeDeviceType.length > 0 && SelectedNodeDeviceType == DEVICE_TYPE_PFC) {
      let strMsg = ``;
      strMsg = this.t(IDS_AlertMsgForPFCDevicesForDeviceRawData, nodeTitle);
      alert(strMsg);
      return;
    } else {
      this.setState({
        RawDataPopupInfo: {
            isVisible: true,
            deviceIDToBeDisplay: selectedDeviceID,
            deviceNameToBeDisplay: selectedDeviceName
        }     
      });
    }
  }

  onDeviceProfile = (nodeID, nodeTitle) => {
    let selectedDeviceID = nodeID;
    let selectedDeviceName = nodeTitle;

    this.setState({
      DeviceProfilePopupInfo: {
          isVisible: true,
          deviceIDToBeDisplay: selectedDeviceID,
          deviceNameToBeDisplay: selectedDeviceName
      }     
    });

  }

  onAddUserEmailID = (nodeID, nodeTitle, nodeHasDevices) => {
    let selectedTreeNodeID = nodeID;
    let selectedTreeNodeName = nodeTitle;
    let selectedTreeNodeHasDevc = nodeHasDevices;

    this.setState({
      addRemoveUserPopupInfo: {
          isVisible: true,
          treeNodeIDToBeDisplay: selectedTreeNodeID,
          treeNodeTitleToBeDisplay: selectedTreeNodeName,
          selectedTreeNodeHasDevc: selectedTreeNodeHasDevc,
      }     
    });
  }

  onChangeLanguageToViewIn = (languageToViewIn) => {
    let modifiedState = this.state;

    if( (languageToViewIn in APP_TRANSLATIONS) == false ) {
      console.log(`Should not happen. Selected language [${languageToViewIn}] does not exist. Defaulting to language [${APPLANG_DEFAULT}]`);
    }

    let strSelectedLang = (languageToViewIn in APP_TRANSLATIONS) == true ? languageToViewIn : APPLANG_DEFAULT;

    modifiedState.languageToViewIn = strSelectedLang;
    modifiedState.selectedLangTranslations = APP_TRANSLATIONS[strSelectedLang];

    this.saveAppRelevantDataContextToLocalStorage(modifiedState);

    this.setState(modifiedState);
  }
  
  t = (inStringID, ...args) => {
    
    let strRetTranslatedString = ''; // If no translation happens, this will be blank
    
    let selectedLangTranslations = this.state.selectedLangTranslations;
    let defaultLangTranslations = APP_TRANSLATIONS[APPLANG_DEFAULT];

    // Check if the specified StringID is present in selected language translation and get it.
    // If not present in selected lang, then get the same string from default language translation.
    // If not found in both, then use blank string.
    strRetTranslatedString = selectedLangTranslations[inStringID] != null ? selectedLangTranslations[inStringID] :
      (defaultLangTranslations != null && defaultLangTranslations[inStringID] != null) ? defaultLangTranslations[inStringID] :
      '';

    if(strRetTranslatedString.length <= 0) {
      console.log(`Could not translate StringID [${inStringID}] for Language [${this.state.languageToViewIn}]. The ID does not exist for the selected language as well as default language.`);
    }

    strRetTranslatedString = tFormat(strRetTranslatedString, ...args);

    return strRetTranslatedString;
  }

  onChangeChartParamType = (chartParamType) => {
    let modifiedState = this.state;

    modifiedState.chartParamType = chartParamType;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);  
  }

  onChangeChartPeriodToView = (chartPeriodToView) =>{
    let modifiedState = this.state;

    modifiedState.chartPeriodToView = chartPeriodToView;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }

  onChangeChartGraphView = (chartGraphToView) =>{
    let modifiedState = this.state;

    modifiedState.chartGraphToView = chartGraphToView;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }

  onChangeChartPeriodToViewWRTDateTime = ( chartStartDateTime, chartEndDateTime ) => {
    let modifiedState = this.state;

    modifiedState.chartStartDateTime = chartStartDateTime;
    modifiedState.chartEndDateTime = chartEndDateTime;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);

  }

  onChangeCustomizedDateTimeChecked = ( isCustomizedDateTimeChecked ) => {
    let modifiedState = this.state;

    modifiedState.isCustomizedDateTimeChecked = isCustomizedDateTimeChecked;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);

  }

  onChangeloggedInUserInfo = (UserFirstName, UserLastName) => {
    let modifiedState = this.state;

    modifiedState.UserFirstName = UserFirstName;
    modifiedState.UserLastName = UserLastName;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }

  onChangeChartRelatedContext = ( chartParamType, chartPeriodToView, isCustomizedDateTimeChecked, chartStartDateTime, chartEndDateTime ) => {
    let modifiedState = this.state;

    modifiedState.chartParamType = chartParamType;
    modifiedState.chartPeriodToView = chartPeriodToView;
    modifiedState.isCustomizedDateTimeChecked = isCustomizedDateTimeChecked;
    modifiedState.chartStartDateTime = chartStartDateTime;
    modifiedState.chartEndDateTime = chartEndDateTime;
    
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);

  }

  onChangeModelName = (selectedModelID, selectedModelName) => {
    let modifiedState = this.state;

    modifiedState.selectedModelID = selectedModelID;
    modifiedState.selectedModelName = selectedModelName;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }

  onChangeLoggedInUserPvg = (privilege) => {
    let modifiedState = this.state;

    modifiedState.Privilege = privilege;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }

  onChangeTrackDeviceInfo = (DevicesInfo, SelectedDeviceInfo, ownerOfTrackedDevices, hasAssociatedTreeNodeIds=false) => {
    let modifiedState = this.state;

    modifiedState.DevicesInfo = DevicesInfo;
    modifiedState.SelectedDeviceInfo = SelectedDeviceInfo;
    modifiedState.ownerOfTrackedDevices = ownerOfTrackedDevices;
    modifiedState.hasAssociatedTreeNodeIds = hasAssociatedTreeNodeIds;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }

  onChangeSwitchBtn= (menuSwitchBtn) => {
    let modifiedState = this.state;
    modifiedState.menuSwitchBtn = menuSwitchBtn;

    this.saveAppRelevantDataContextToLocalStorage(modifiedState);

    this.setState(modifiedState);
  }

  onChangeFCMRegId= (fcmRegID) => {
    let modifiedState = this.state;
    modifiedState.fcmRegID = fcmRegID;

    this.saveAppRelevantDataContextToLocalStorage(modifiedState);

    this.setState(modifiedState);
  }

  onEnabledTreeStructureSettings = (treeStructureSettingsEnabled, reRenderTreeOnAddEditRemove) => {
    let modifiedState = this.state;

    modifiedState.treeStructureSettingsEnabled = treeStructureSettingsEnabled;
    modifiedState.reRenderTreeOnAddEditRemove = reRenderTreeOnAddEditRemove;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }

  onToiletDashboardVisibility = (toiletDashboardVisible) => {
    let modifiedState = this.state;

    modifiedState.toiletDashboardVisible = toiletDashboardVisible;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }
  
  onInvokedloginFromDiffrentPath = (loginPagePath, showNextPagePathAfterLogin) => {
    let modifiedState = this.state;

    modifiedState.loginPagePath = loginPagePath;
    modifiedState.showNextPagePathAfterLogin = showNextPagePathAfterLogin;
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);
    this.setState(modifiedState);
  }

  onChangeProcessingReq = (isReqProcessing) => {

    let modifiedState = this.state;

    if(isReqProcessing != modifiedState.isReqProcessing) {
      modifiedState.isReqProcessing = isReqProcessing;
      this.setState(modifiedState);
    }

    // this.saveAppRelevantDataContextToLocalStorage(modifiedState);
  }

  OnCloseProcessingReq = () => {
    this.setState({
      isReqProcessing: false,
    })
  }

  appjsOnSelectedDevice = (nodeID, nodeTitle, isRoot, hasDevc=false, isDevc=false, deviceType=[], parentID=null, SelectedNodeDeviceType="", nodePath='') => {

    let modifiedState = this.state;

    modifiedState.isRootSelected = isRoot;
    modifiedState.selectedTreeNodeID = nodeID;
    modifiedState.selectedTreeNodeTitle = nodeTitle;
    modifiedState.hasDevcsForSelectedTreeNode = hasDevc;
    modifiedState.isSelectedTreeNodeDevc = isDevc;
    modifiedState.arrDeviceType = deviceType;
    modifiedState.selectedTreeNodeParentID = parentID;
    modifiedState.SelectedNodeDeviceType = SelectedNodeDeviceType;
    modifiedState.nodePath = nodePath;

    // Before updating the AppState, store the same value in LocalStorage for later use
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);

    this.setState (modifiedState);

  }

  appjsOnSelectedRootNode = (nodeID, nodeTitle, isRoot, hasDevc=false, isDevc=false, deviceType=[], parentID=null, nodePath='') => {

    let modifiedState = this.state;

    modifiedState.isRootSelected = isRoot;
    modifiedState.selectedTreeNodeID = nodeID;
    modifiedState.selectedTreeNodeTitle = nodeTitle;
    modifiedState.hasDevcsForSelectedTreeNode = hasDevc;
    modifiedState.isSelectedTreeNodeDevc = isDevc;
    modifiedState.arrDeviceType = deviceType;
    modifiedState.selectedTreeNodeParentID = parentID;
    modifiedState.nodePath = nodePath;


    // Before updating the AppState, store the same value in LocalStorage for later use
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);

    this.setState (modifiedState);
  }

  onSelectedNodeContainsChildNode = (containsChildNode=false) => {
    let modifiedState = this.state;

    modifiedState.selectedNodeContainsChildNode = containsChildNode;

    // Before updating the AppState, store the same value in LocalStorage for later use
    this.saveAppRelevantDataContextToLocalStorage(modifiedState);

    this.setState (modifiedState);
    
  }
  
  // Saves the App Relevant Data Context to LocalStorage using the State Object provided as input.
  // Converts the entire context object to single JSON string before saving it to local storage.
  saveAppRelevantDataContextToLocalStorage = (inStateObject) => {

    // Create the app relevant data context object from the input state object
    let appRelevantDataContextObject = {
      loggedInUserInfo: {
        userID: inStateObject.LoggedInUserID,
        userFirstName: inStateObject.UserFirstName,
        userLastName: inStateObject.UserLastName,
        userIsLoggedInAndRenderedTree: inStateObject.userIsLoggedInAndRenderedTree,
      },
      selectedNodeInfo: {
        nodeID: inStateObject.selectedTreeNodeID,
        nodeTitle: inStateObject.selectedTreeNodeTitle,
        isRoot: inStateObject.isRootSelected,
        hasDevc: inStateObject.hasDevcsForSelectedTreeNode,
        isDevc: inStateObject.isSelectedTreeNodeDevc,
        deviceType: inStateObject.arrDeviceType,
        parentID: inStateObject.selectedTreeNodeParentID,
        containsChildNode: inStateObject.selectedNodeContainsChildNode,
        SelectedNodeDeviceType: inStateObject.SelectedNodeDeviceType,
        nodePath: inStateObject.nodePath,
      },
      chartData: {
        chartParamType: inStateObject.chartParamType,
        chartPeriodToView: inStateObject.chartPeriodToView,
        chartGraphToView: inStateObject.chartGraphToView,
        chartStartDateTime: inStateObject.chartStartDateTime == null ? null : convertLocalDateToStrYYYYMMDDHH24MMSS(inStateObject.chartStartDateTime),
        chartEndDateTime: inStateObject.chartEndDateTime == null ? null : convertLocalDateToStrYYYYMMDDHH24MMSS(inStateObject.chartEndDateTime),
        isCustomizedDateTimeChecked: inStateObject.isCustomizedDateTimeChecked,
        
      },
      selectedModelInfo: {
        modelName: inStateObject.selectedModelName,
        modelID: inStateObject.selectedModelID,
      },
      language: {
        languageToViewIn: inStateObject.languageToViewIn,
        // isReqProcessing: inStateObject.isReqProcessing,
      },    
      loggedInUserPrivilege: {
        Privilege: inStateObject.Privilege,
      },
      devicesToTrack: {
        DevicesInfo: inStateObject.DevicesInfo,
        SelectedDeviceInfo: inStateObject.SelectedDeviceInfo,
        ownerOfTrackedDevices: inStateObject.ownerOfTrackedDevices,
        hasAssociatedTreeNodeIds: inStateObject.hasAssociatedTreeNodeIds,
      },
      navMenu: {
        menuSwitchBtn: inStateObject.menuSwitchBtn
      },
      fcmRegDetails: {
        fcmRegID: inStateObject.fcmRegID
      },
      DevicesTreeStructureSettings: {
        treeStructureSettingsEnabled: inStateObject.treeStructureSettingsEnabled,
        reRenderTreeOnAddEditRemove: inStateObject.reRenderTreeOnAddEditRemove
      },
      ToiletDashboardVisibility: {
        toiletDashboardVisible: inStateObject.toiletDashboardVisible
      },
      sessJankari: {
        sessChallan: inStateObject.sessChallan,
        sessID: inStateObject.sessID,
      },
      quickTrackParamInfo: {
        quickTrackParam: inStateObject.selectedQuickTrackParam
      },
      invokedLoginPgInfo: {
        loginPagePath: inStateObject.loginPagePath,
        showNextPagePathAfterLogin: inStateObject.showNextPagePathAfterLogin,
      },
  
    };

    // Convert the data context object to JsonString before saving it in LocalStorage
    localStorage.setItem( LS_ITEM_APP_RELEVANT_DATA_CONTEXT, JSON.stringify(appRelevantDataContextObject) );

  }

  // Updates the App Relevant Data context from the value that had been stored earlier in LocalStorage.
  // Updates the App State from the same value.
  // Returns true if the relevant data was found in LocalStorage.
  // Returns false if the relevant data was not found in LocalStorage.
  // Note: If bUpdateStateIfLangInfoFound is true, then AppState will be updated even if
  // only the language info is found (used for showing Language and UserID in Login page from 
  // last logged out context).
  updateAppRelevantDataContextFromLocalStorage = (bUpdateStateIfLangInfoFound = false) => {

    let modifiedState = this.state;

    let bLanguageFoundInLSContext = false;

    // If AppRelevantDataContext is available in 'LocalStorage' then use the values from the same
    let strAppDataContextJsonStringFromLS = localStorage.getItem(LS_ITEM_APP_RELEVANT_DATA_CONTEXT);

    if(strAppDataContextJsonStringFromLS != null) {
      try {
        let appDataStringToObject = JSON.parse(strAppDataContextJsonStringFromLS);

        // Parsing successful. Take all the values from the local storage and update AppState.
        modifiedState.LoggedInUserID = appDataStringToObject.loggedInUserInfo.userID;
        modifiedState.UserFirstName = appDataStringToObject.loggedInUserInfo.userFirstName;
        modifiedState.UserLastName = appDataStringToObject.loggedInUserInfo.userLastName;
        modifiedState.userIsLoggedInAndRenderedTree = appDataStringToObject.loggedInUserInfo.userIsLoggedInAndRenderedTree;

        modifiedState.isRootSelected = appDataStringToObject.selectedNodeInfo.isRoot;
        modifiedState.selectedTreeNodeID = appDataStringToObject.selectedNodeInfo.nodeID;
        modifiedState.selectedTreeNodeTitle = appDataStringToObject.selectedNodeInfo.nodeTitle;
        modifiedState.hasDevcsForSelectedTreeNode = appDataStringToObject.selectedNodeInfo.hasDevc;
        modifiedState.isSelectedTreeNodeDevc = appDataStringToObject.selectedNodeInfo.isDevc;
        modifiedState.arrDeviceType = appDataStringToObject.selectedNodeInfo.deviceType;
        modifiedState.selectedTreeNodeParentID = appDataStringToObject.selectedNodeInfo.parentID;
        modifiedState.selectedNodeContainsChildNode = appDataStringToObject.selectedNodeInfo.containsChildNode;
        modifiedState.SelectedNodeDeviceType = appDataStringToObject.selectedNodeInfo.SelectedNodeDeviceType;
        modifiedState.nodePath = appDataStringToObject.selectedNodeInfo.nodePath;

        modifiedState.selectedModelID = appDataStringToObject.selectedModelInfo.modelID;
        modifiedState.selectedModelName = appDataStringToObject.selectedModelInfo.modelName;
        
        modifiedState.chartParamType = appDataStringToObject.chartData.chartParamType;
        modifiedState.chartPeriodToView = appDataStringToObject.chartData.chartPeriodToView;
        modifiedState.chartGraphToView = appDataStringToObject.chartData.chartGraphToView;
        
        modifiedState.Privilege = appDataStringToObject.loggedInUserPrivilege.Privilege;

        modifiedState.DevicesInfo = appDataStringToObject.devicesToTrack.DevicesInfo;
        modifiedState.SelectedDeviceInfo = appDataStringToObject.devicesToTrack.SelectedDeviceInfo;
        modifiedState.ownerOfTrackedDevices = appDataStringToObject.devicesToTrack.ownerOfTrackedDevices;
        modifiedState.hasAssociatedTreeNodeIds = appDataStringToObject.devicesToTrack.hasAssociatedTreeNodeIds;

        modifiedState.menuSwitchBtn = appDataStringToObject.navMenu.menuSwitchBtn;
        modifiedState.fcmRegID = appDataStringToObject.fcmRegDetails.fcmRegID;

        modifiedState.treeStructureSettingsEnabled = appDataStringToObject.DevicesTreeStructureSettings.treeStructureSettingsEnabled;
        modifiedState.reRenderTreeOnAddEditRemove = appDataStringToObject.DevicesTreeStructureSettings.reRenderTreeOnAddEditRemove;

        modifiedState.toiletDashboardVisible = appDataStringToObject.ToiletDashboardVisibility.toiletDashboardVisible;

        modifiedState.loginPagePath = appDataStringToObject.invokedLoginPgInfo.loginPagePath;
        modifiedState.showNextPagePathAfterLogin = appDataStringToObject.invokedLoginPgInfo.showNextPagePathAfterLogin;

        bLanguageFoundInLSContext = 
          (appDataStringToObject.language.languageToViewIn in APP_TRANSLATIONS) == true ? true : false;

        let strSelectedLang = (appDataStringToObject.language.languageToViewIn in APP_TRANSLATIONS) == true ? 
                              appDataStringToObject.language.languageToViewIn : APPLANG_DEFAULT;
        modifiedState.languageToViewIn = strSelectedLang;
        modifiedState.selectedLangTranslations = APP_TRANSLATIONS[strSelectedLang];

        // modifiedState.isReqProcessing = appDataStringToObject.language.isReqProcessing;

        // In case of safari the browser is not automatically able to indentify Dates with hyphen. 
        // So, we have replaced hyphen with slash.
        modifiedState.chartStartDateTime = appDataStringToObject.chartData.chartStartDateTime == null ? 
                                           null : new Date(appDataStringToObject.chartData.chartStartDateTime.replace(/-/g, "/"));
        modifiedState.chartEndDateTime = appDataStringToObject.chartData.chartEndDateTime == null ? 
                                           null : new Date(appDataStringToObject.chartData.chartEndDateTime.replace(/-/g, "/"));
        
        modifiedState.isCustomizedDateTimeChecked = appDataStringToObject.chartData.isCustomizedDateTimeChecked;

        modifiedState.sessChallan = appDataStringToObject.sessJankari.sessChallan;
        modifiedState.sessID = appDataStringToObject.sessJankari.sessID;

        modifiedState.selectedQuickTrackParam = appDataStringToObject.quickTrackParamInfo.quickTrackParam;


      } catch (e) {
        console.log("Should not happen. The App Relevant Data Context in Local Storage is invalid. Delete the same from Local Storage.");
        localStorage.removeItem(LS_ITEM_APP_RELEVANT_DATA_CONTEXT);
      }
    } else {
      console.log("Local Storage does not contain a copy of the App Relevant Data Context. User will need to Re-login.");
    }

    // If UserFirstName is not present in LocalStorage then return false to the caller to indicate that
    // app relevant data context is not present in Local Storage.
    // Also in case user had requested to update the state even if only language was found in LS Context,
    // then do not return false when the language is found in the LS.
    // (Note: Earlier we used to check UserID, however we now use UserFirstName. Thats because the UserID and Language are 
    // not flushed even after logout so that last used login id and language can be shown when the user opens the login page).
    if( ( bUpdateStateIfLangInfoFound != true || bLanguageFoundInLSContext != true ) && 
        ( modifiedState.UserFirstName == null || modifiedState.UserFirstName.length <= 0 )
    ) {

      console.log("App Relevant Data Context not found in Local Storage");
      return false;
    }

    // App Relevant Data context found in Local Storage. Update the App State with the same.
    // Or Navigate to Login.
    this.setState (modifiedState);

    // Return true to indicate that App Relevant Data Context was obtained from LocalStorage
    return true;
  }

  getLatestValueForAppRelevantDataContext = () => {

    // Get the value for AppContext either from the input state or from this state
    let latestState = this.state;

    return ({
      loggedInUserInfo: {
        userID: latestState.LoggedInUserID,
        userFirstName: latestState.UserFirstName,
        userLastName: latestState.UserLastName,
        userIsLoggedInAndRenderedTree: latestState.userIsLoggedInAndRenderedTree,
      },
      selectedNodeInfo: {
        nodeID: latestState.selectedTreeNodeID,
        nodeTitle: latestState.selectedTreeNodeTitle,
        isRoot: latestState.isRootSelected,
        hasDevc: latestState.hasDevcsForSelectedTreeNode,
        isDevc: latestState.isSelectedTreeNodeDevc,
        deviceType: latestState.arrDeviceType,
        parentID: latestState.selectedTreeNodeParentID,
        containsChildNode: latestState.selectedNodeContainsChildNode,
        SelectedNodeDeviceType: latestState.SelectedNodeDeviceType,
        nodePath: latestState.nodePath,
      },
      chartData: {
        chartParamType: latestState.chartParamType,
        chartPeriodToView: latestState.chartPeriodToView,
        chartGraphToView: latestState.chartGraphToView,
        chartStartDateTime: latestState.chartStartDateTime,
        chartEndDateTime: latestState.chartEndDateTime,
        isCustomizedDateTimeChecked: latestState.isCustomizedDateTimeChecked
      },
      selectedModelInfo: {
        modelName: latestState.selectedModelName,
        modelID: latestState.selectedModelID,
      },
      language: {
        languageToViewIn: latestState.languageToViewIn,
        // isReqProcessing: latestState.isReqProcessing,
      },   
      loggedInUserPrivilege: {
        Privilege: latestState.Privilege,
      },    
      devicesToTrack: {
        DevicesInfo: latestState.DevicesInfo,
        SelectedDeviceInfo: latestState.SelectedDeviceInfo,
        ownerOfTrackedDevices: latestState.ownerOfTrackedDevices,
        hasAssociatedTreeNodeIds: latestState.hasAssociatedTreeNodeIds,
      },
      navMenu: {
        menuSwitchBtn: latestState.menuSwitchBtn
      },

      fcmRegDetails: {
        fcmRegID: latestState.fcmRegID
      },

      DevicesTreeStructureSettings: {
        treeStructureSettingsEnabled: latestState.treeStructureSettingsEnabled,
        reRenderTreeOnAddEditRemove: latestState.reRenderTreeOnAddEditRemove
      },

      ToiletDashboardVisibility: {
        toiletDashboardVisible: latestState.toiletDashboardVisible
      },

      sessJankari: {
        sessChallan: latestState.sessChallan,
        sessChaabi: latestState.sessChaabi,
        sessID: latestState.sessID,
      },
      quickTrackParamInfo: {
        quickTrackParam: latestState.selectedQuickTrackParam
      },
      invokedLoginPgInfo: {
        loginPagePath: latestState.loginPagePath,
        showNextPagePathAfterLogin: latestState.showNextPagePathAfterLogin,
      },

      onSelectedDevice: this.appjsOnSelectedDevice,
      onSelectedRoot: this.appjsOnSelectedRootNode,
      
      onAddUser: this.onAddUser,
      onRemoveUser: this.onRemoveUser,
      onGetRawData: this.onGetRawData,
      onDeviceProfile: this.onDeviceProfile,
      onAddUserEmailID: this.onAddUserEmailID,
      
      onChangeChartParamType: this.onChangeChartParamType,
      onChangeChartPeriodToView: this.onChangeChartPeriodToView,
      onChangeChartGraphView: this.onChangeChartGraphView,
      onChangeChartPeriodToViewWRTDateTime: this.onChangeChartPeriodToViewWRTDateTime,
      onChangeCustomizedDateTimeChecked: this.onChangeCustomizedDateTimeChecked,
      onChangeloggedInUserInfo: this.onChangeloggedInUserInfo,
      onChangeChartRelatedContext: this.onChangeChartRelatedContext, 
      
      onChangeLanguageToViewIn: this.onChangeLanguageToViewIn,      
      t: this.t, // Translates the specified String ID
      // t: this.tdngr, // TODO
      
      onChangeModelName: this.onChangeModelName,

      onChangeLoggedInUserPvg: this.onChangeLoggedInUserPvg,
      onChangeProcessingReq: this.onChangeProcessingReq,
      onChangeTrackDeviceInfo: this.onChangeTrackDeviceInfo,
      onChangeSwitchBtn: this.onChangeSwitchBtn,
      onChangeFCMRegId: this.onChangeFCMRegId,
      onEnabledTreeStructureSettings: this.onEnabledTreeStructureSettings,
      onToiletDashboardVisibility: this.onToiletDashboardVisibility,

      updateAppRelevantDataContextFromLocalStorage: this.updateAppRelevantDataContextFromLocalStorage,

      onChangeSessJankari: this.onChangeSessJankari,
      onChangeQuickTrackParam: this.onChangeQuickTrackParam,
      onTreeRenderedFirstTime: this.onTreeRenderedFirstTime,

      onInvokedloginFromDiffrentPath: this.onInvokedloginFromDiffrentPath,

      onSelectedNodeContainsChildNode: this.onSelectedNodeContainsChildNode,
    });

  }

  handleLogin = (loginData) => {
    const UserID = loginData[0];
    const RetrievedFirstName = loginData[1];
    const RetrievedLastName = loginData[2];

    let modifiedState = this.state;

    modifiedState.LoggedInUserID = UserID;
    modifiedState.selectedTreeNodeID = UserID; // May change in future (Currently RootNodeID is same as LoggedInUserID)
    modifiedState.selectedTreeNodeTitle = `${RetrievedFirstName} ${RetrievedLastName}`;// May change in future (Currently RootNodeTitle is same as LoggedInUserName)
    modifiedState.isRootSelected = true;
    modifiedState.UserFirstName = RetrievedFirstName;
    modifiedState.UserLastName = RetrievedLastName;
    modifiedState.UserLastName = RetrievedLastName;

    this.saveAppRelevantDataContextToLocalStorage(modifiedState);

    this.setState(modifiedState);
  }

  // This function Renders the 'Loading' Page
  // This is a static function. Does not depend on the state of the app.
  static renderLoadingPage = () => {
    return (
      <div> 
        <VcNavBar/>
        <div className="container-fluid hhmLoadingPage">
          <div className="row">
            <div className = "container col-lg-8">
              <div className="col-xs-12 col-sm-12 col-md-12  hhmLoadingPageContent">
                <div className="outerRoundedDivWithShadow col-xs-12 col-sm-6 col-md-8 offset-md-2 offset-xs-3 offset-sm-3">
                  <div>
                    <span className="pageMsgFont" 
                    >
                      Loading Page. Please Wait... 
                    </span>
                  </div>                  
                </div>
              </div>
            </div>     
          </div>
        </div>
      </div>
    );
  }

  // This function clears the AppRelevantDataContext that has been stored in the LocalStorage.
  // Instead of removing entire context just clean (set empty string) for all other keys in the 
  // context (except UserID and LanguageToViewIn and a few other default values). This uncleaned info will be used to show 
  // pre-filled UserID and last selected language to the User.
  // Note: This method is static so that it can be directly accessed using App class.
  static clearAppRelevantDataContextFromLocalStorage = () => {
    // localStorage.removeItem(LS_ITEM_APP_RELEVANT_DATA_CONTEXT); // Don't remove entirely anymore.
    let strAppDataContextJsonStringFromLS = localStorage.getItem(LS_ITEM_APP_RELEVANT_DATA_CONTEXT);

    if(strAppDataContextJsonStringFromLS != null) {
      try {
        let appDataStringToObject = JSON.parse(strAppDataContextJsonStringFromLS);

        let strSelectedLang = (appDataStringToObject.language.languageToViewIn in APP_TRANSLATIONS) == true ? 
                                appDataStringToObject.language.languageToViewIn : APPLANG_DEFAULT;

        // Create the app relevant data context object from the Local storage.
        let appRelevantDataContextObject = {
          loggedInUserInfo: {
            userID: appDataStringToObject.loggedInUserInfo.userID,
            userFirstName: '',
            userLastName: '',
            userIsLoggedInAndRenderedTree: false
          },
          selectedNodeInfo: {
            nodeID: '',
            nodeTitle: '',
            isRoot: true,
            hasDevc: false,
            isDevc: false,
            deviceType: [],
            parentID: null,
            containsChildNode: false,
            SelectedNodeDeviceType: "",
            nodePath: '',
          },
          chartData: {
            chartParamType: appDataStringToObject.chartData.chartParamType,
            chartPeriodToView: appDataStringToObject.chartData.chartPeriodToView,
            chartGraphToView: appDataStringToObject.chartData.chartGraphToView,
            chartStartDateTime: null,
            chartEndDateTime: null,
            isCustomizedDateTimeChecked: false,
          },
          selectedModelInfo: {
            modelName: '',
            modelID: '',
          },
          language: {
            languageToViewIn: strSelectedLang,
            // isReqProcessing: false,
          },   
          loggedInUserPrivilege: {
            Privilege: '',
          },   
          devicesToTrack: {
            DevicesInfo: [],
            SelectedDeviceInfo: {},
            ownerOfTrackedDevices: "",
            hasAssociatedTreeNodeIds: false
          },  
          navMenu: {
            menuSwitchBtn: "",
          },
          fcmRegDetails: {
            fcmRegID: ""
          },
          DevicesTreeStructureSettings: {
            treeStructureSettingsEnabled: false,
            reRenderTreeOnAddEditRemove: false
          },
          ToiletDashboardVisibility: {
            toiletDashboardVisible: false
          },
          sessJankari: {
            sessChallan: '',
            sessID: '',
          },
          quickTrackParamInfo: {
            quickTrackParam: ''
          },
          invokedLoginPgInfo: {
            loginPagePath: "",
            showNextPagePathAfterLogin: "",
          },  
        };

        // Convert the data context object to JsonString before saving it in LocalStorage
        localStorage.setItem( LS_ITEM_APP_RELEVANT_DATA_CONTEXT, JSON.stringify(appRelevantDataContextObject) );

      } catch(e) {
        console.log("Should not happen. The App Relevant Data Context in Local Storage is invalid. Delete the same from Local Storage.");
        localStorage.removeItem(LS_ITEM_APP_RELEVANT_DATA_CONTEXT);      
      }
    } else {
      console.log("Local Storage does not contain a copy of the App Relevant Data Context. User will need to Re-login.");
    }
  }

  // This function shows the notification popup in the web app
  // It makes use of notification of antd
  openNotification = () => {

    let notifcationBody = this.state.languageToViewIn == 'hi' ? this.state.notification.body.split("।")[0] : this.state.notification.body.split(".")[0];

    notification.open({
      message: "Alert: " + this.state.notification.title,
      description: (
        <div style={{  wordWrap: "break-word", whiteSpace: 'normal'}}>{notifcationBody}</div>
      ),
      className: 'custom-class',
      placement: "bottomRight",
      style: {
        width: 385,
        whiteSpace: 'wrap', 
        color: "gray",
        borderRadius: "10px",
        border: "2px solid orange",
        cursor: "pointer",
      },
    });
  };
  

  render() {
    
    // This will refresh whole page explicitly.
    if(this.state.canRefreshPage == true) {
      window['location'].reload();
    }
    
    return (

      <AppRelevantDataContext.Provider value={this.getLatestValueForAppRelevantDataContext()}>

        <div className="App" style={{overflowX:"hidden", height:"100vh"}}>
        <div>
              { this.state.addUserPopupInfo.isVisible ? 
                  <AddUserForm
                      LoggedInUserID={this.state.LoggedInUserID} 
                      DeviceName={this.state.addUserPopupInfo.deviceNameToBeAdded} 
                      DeviceID={this.state.addUserPopupInfo.deviceIDToBeAdded}
                      ParentIDOfSelectedNode = {this.state.addUserPopupInfo.parentIDOfSelectedNode}
                      onCloseAddUserPopup={this.onCloseAddUserPopup}
                  />
                  : null
              }
          </div>
          <div>
              { this.state.removeUserPopupInfo.isVisible ? 
                  <RemoveUser
                      LoggedInUserID={this.state.LoggedInUserID} 
                      DeviceName={this.state.removeUserPopupInfo.deviceNameToBeRemoved} 
                      DeviceID={this.state.removeUserPopupInfo.deviceIDToBeRemoved}
                      ParentIDOfSelectedNode = {this.state.removeUserPopupInfo.parentIDOfSelectedNode}
                      onCloseRemoveUserPopup={this.onCloseRemoveUserPopup}
                  />
                  : null
              }
          </div>
          <div>
              { this.state.RawDataPopupInfo.isVisible ? 
                  <VcDeviceRawData
                      LoggedInUserID={this.state.LoggedInUserID} 
                      DeviceName={this.state.RawDataPopupInfo.deviceNameToBeDisplay} 
                      DeviceID={this.state.RawDataPopupInfo.deviceIDToBeDisplay}
                      onCloseRawDataPopup={this.onCloseRawDataPopup}
                  />
                  : null
              }
          </div>

          <div>
              { this.state.DeviceProfilePopupInfo.isVisible ? 
                  <VcDeviceProfile
                      LoggedInUserID={this.state.LoggedInUserID} 
                      DeviceName={this.state.DeviceProfilePopupInfo.deviceNameToBeDisplay} 
                      DeviceID={this.state.DeviceProfilePopupInfo.deviceIDToBeDisplay}
                      OnCloseDeviceProfilePopup={this.OnCloseDeviceProfilePopup}
                  />
                  : null
              }
          </div>

          <div>
              { this.state.addRemoveUserPopupInfo.isVisible ? 
                  <VcAddRemoveUserToTheNode
                      LoggedInUserID={this.state.LoggedInUserID} 
                      TreeNodeTitle={this.state.addRemoveUserPopupInfo.treeNodeTitleToBeDisplay} 
                      TreeNodeID={this.state.addRemoveUserPopupInfo.treeNodeIDToBeDisplay}
                      NodeHasDevices = {this.state.addRemoveUserPopupInfo.selectedTreeNodeHasDevc}
                      OnCloseAddRemoveUserPopup={this.OnCloseAddRemoveUserPopup}
                  />
                  : null
              }
          </div>

          <div>
            { this.state.isCountOfAxiosReqExceeds ?
                <VcPopUpToBeShownOnExceedingAMaxAxiosReq
                  OnClosePopupToBeShownOnExceedingAMaxAxiosReq={this.OnClosePopupToBeShownOnExceedingAMaxAxiosReq}
                />
                  : null
              }
          </div>

          <div>
            { this.state.isReqProcessing ?
              <VcSpinner
                OnCloseProcessingReq = {this.OnCloseProcessingReq}
              />                  
              : null
            }
          </div>
          <DndProvider backend={HTML5Backend}>
            <Router>
                {/* <Route path="/admin" element={<VcAdminHome/>} /> */}
              <Routes>
                <Route path="/VcAdminEnrollFieldEngineer" element={<VcAdminEnrollFieldEngineer />} />

                <Route path="/"element={<VcLoginPg handleLogin={this.handleLogin} />}/>

                <Route path="/login/provideSolForCustComplaint/:id" 
                  element={<VcLoginPg handleLogin={this.handleLogin} />}
                />

                <Route path="/registration" element={<VcRegistration />} /> 

                <Route path="/forgotpassword" element={<VcForgotPassword />} /> 

                <Route path="/chaabibadlo/:id" element={<VcResetPassword />} /> 

                <Route path="/maps" element={<VcMaps />} />

                {/* <Route path="/airQualityMap" element={<VcAirQualityMap />} /> */}

                <Route path="/resetDeviceDetails/:id" element={<VcResetDeviceDetails />}/>

                <Route path="/provideSolForCustComplaint/:id" element={<VcProvideSolForCustComplaintPg />}/>

                <Route path="/userregistration/:id" 
                  element={<VcRegistration key = "/userregistration/:id" invokedFrom="validateEmailId"/> }
                />

                  <Route path="/device/*" 
                  // element={<VcDeviceHome />}
                  element={<VcDeviceHome />}
                /> 

                <Route path="/faqs" 
                  element={
                      <VcFAQPg/>
                  }
                  />
                  
                  <Route path="/paramDocumentation" 
                    element={
                        <VcParameterDocumentationPg/>
                    }
                  />

                  <Route path="/paramInfo" 
                    element={
                        <VcParameterDocumentation/>
                    }
                  />

                <Route path="/support" 
                  element={<VcSupport LoggedInUserID = {this.state.LoggedInUserID}/>}
                  />

                    {/* <Route path="/abouthhm" 
                      exact 
                      render = {props => (
                        <AboutHHM {...props} />
                      )}
                    /> */}

                    {/* <Route path="/maps" 
                      exact 
                      render = {props => (
                        <VcMaps {...props} />
                      )}
                    /> */}

                  <Route path="/mapsonlogin" 
                      element={<VcMapPg />}
                  />

                  <Route path="/sanitationMapping" 
                        element={<VcSanitationMapping />}
                    />

                    <Route path="/sanitationMappingForm" 
                        element={<VcSanitationMappingForm />}
                    />
               
                <Route path="/admin" 
                  element={<VcAdminHome />}
                />   

                <Route path="/otaStatusReport" 
                  element={<VcOTAStatusReport />}
                />  
                  
                <Route path="/crm/*" 
                  element={<VcCrmHome />}
                />      

                {/* Mobile Route */}
                {/* <Route path={["/mabouthhm/:id", "/mabouthhm"]} 
                  render = {props => (
                    <VcAboutHHM />
                  )}
                /> */}

                {/* Mobile Route */}
                <Route path="/mVRIandMRIInfo/:langAndParamToShowInfo"
                  element={<VcVRIandMRIInfo />}
                />

                <Route path="/mVRIandMRIInfo"
                  element={<VcVRIandMRIInfo />}
                />

                <Route path="/OdourAndCleanlinessRating/:id"
                  element={<VcRateToilet invokedFrom = "QrScanner"/>}
                />

                <Route path="/RateOdourAndCleanliness/:id"
                element={<VcRateToiletWithQr invokedFrom = "QrScanner" />} 
                />

                

          <Route path="/*"
                element={
                  <div>
                    The URL is invalid or you do not have privilege to access it.
                  </div>
                } 
                />
            </Routes>
              </Router> 
          </DndProvider>      
        </div>
      </AppRelevantDataContext.Provider>
    )
  }
} 
export default App;
