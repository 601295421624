import React, { Component } from 'react'
import { AppRelevantDataContext } from '../../AppContext';
import axios from 'axios';
import DateTimePicker from 'react-datetime-picker';
import { getAPIHostURL } from '../../ClientConfig';
import {Modal, ModalHeader, ModalBody } from 'reactstrap';
import "../CSS/Chart.css"
import { getLocalTimezoneOffset, convertUTCDateToStrYYYYMMDDHH24MMSS, 
         getListOfDatesBetweenRange, getListOfHoursBetweenRange, getListOfMinutesBetweenRange,
         convertDateToStrMMMDD, convertDateToStrDowMMMDD, getListOfSecondsBetweenRange,
         convertDateToStrMMMDDHH00, convertDateToStrMMMDDHHMI, convertDateToStrMMMDDHHMISS, convertLocalDateWithFormatDDMMMYY } from '../../vtUtil';
import { CO, O3, LPG, SMOKE, CH4, CO2, HUM, NO2, VOC, PM10, PM25, TEMP, PM1, NH3, H2S, SO2, NH3OD, LOW_SEVERE_L, GOOD_L, MODERATE_L, POOR_L, V_POOR_L, HIGH_SEVERE_L, 
        NO_DEVC_FOUND_FOR_LOGGED_IN_USER, LOW, MAX, LOW_SEVERE_U, GOOD_U, MODERATE_U, POOR_U, V_POOR_U, HIGH_SEVERE_U, ALERT_BASED, MAX_NO_OF_DAY_FOR_VIEWING_DATA_AFTER_SUBS_EXPIRY, ONWARDS,
        DISP_GOOD_RANGE, DISP_MODERATE_RANGE, DISP_POOR_RANGE, DISP_VERY_POOR_RANGE, DISP_HIGH_SEVERE_RANGE, DISP_LOW_SEVERE_RANGE, VRI, MRI, DEVICE_TYPE_PFC, DEVICE_TYPE_WLI, DEVICE_TYPE_STLI, 
        UPTO, PROD_DEVC_OWNER_USER_ID, PFC, WLI, WLIP, SLIP, STLI, AQI, CAQI, SATISFACTORY_L, SATISFACTORY_U, DISP_SATISFACTORY_RANGE,
        HCHO, O2, PM100, NO, CL, CH3SH, NOISE, LUX, UV, RADON, AP, WS, WD, DLV, RNFL, H2,
        NO_CHART_PARAMS,
        LOW_V_POOR_L,
        LOW_V_POOR_U,
        DISP_LOW_VERY_POOR_RANGE, PARAM_FOR_SHOWING_IMPACTS
    } from '../../VcConstants';
import { IDS_NoDataFoundForChart, IDS_HourlyDropDown, IDS_DailyDropDown, IDS_WeeklyDropDown, IDS_MonthlyDropDown,
        IDS_SelectDiffPeriodToView, IDS_StartDateTime, IDS_EndDateTime, IDS_PartsPerBillion, IDS_PPB, IDS_PPM,
        IDS_PartsPerMillion, IDS_OdourLevel, IDS_Celsius, IDS_Percent, IDS_HourMinuteChart, IDS_DailyChart,
        IDS_WeekDayChart, IDS_MonthlyChart, IDS_nitrogenDioxide, IDS_Ozone, IDS_sulphurDioxide, IDS_TVOC,
        IDS_carbonMonoxide, IDS_Ammonia, IDS_carbonDioxide, IDS_hydrogenSulphide, IDS_Methane, IDS_LPG, IDS_SMOKE, IDS_Dust,
        IDS_Temperature, IDS_Humidity, IDS_Odour, IDS_ErrMsgEndTimeIsGreaterThanCurrentTime,
        IDS_ErrMsgStartTimeIsGreaterThanCurrentTime, IDS_LowSevere, IDS_Good, IDS_Moderate, IDS_Poor, IDS_VeryPoor,
        IDS_Severe, IDS_AUSrvrIssueReqParamsNotSent, IDS_LoginServerIssue, IDS_ErrMsgEndDtNotInRange, IDS_SubsServerIssue,
        IDS_ErrMsgStrtDtNotInRange, IDS_ErrMsgStrtDtMinPossibleForDay, IDS_ErrMsgEndDtAdjustedBcozNotInRange, IDS_GeneralUser,
        IDS_LineandBarGraph, IDS_OnlyLineGraph, IDS_OnlyBarGraph, IDS_MinuteDropDown,
        IDS_Comfortable, IDS_Warm, IDS_Hot, IDS_ColdAndChilly, IDS_Humid, IDS_Sticky, IDS_Dry, IDS_HourMinuteSecChart, IDS_RegistNetworkError, IDS_WarningPeriodForSubs, 
        IDS_GracePeriodForSubs, IDS_ExpiredSubs, IDS_AUSrvrIssueReqParamsNotSentForSubsInfo, IDS_NoteToProdUsrInChart, IDS_SubNotApplicable,
        IDS_Legends_And_Range_Of_Sensore, IDS_ParamsRange, IDS_Onwards, IDS_RangeLowSevere, IDS_Satisfactory, IDS_RangeHighSevere,
        IDS_BarelySensedInSingleDeviceData, IDS_WeakOdourInSingleDeviceData, IDS_EasilyRecognizedInSingleDeviceData, IDS_StrongOdourInSingleDeviceData, IDS_PungentInSingleDeviceData,
        IDS_RangeVeryPoor, IDS_NoRange, IDS_AlertLevel1, IDS_AlertLevel2, IDS_AlertLevel3, IDS_AlertLevel4, IDS_AlertLevel5,
        IDS_DefInfoMsgForTreeNodeInChartPg, 
        IDS_InfoMsgForTreeNodeWhileChildDevicesMissingInChartPg, IDS_DrillDown,
        IDS_InfoMsgForTreeNodeWhileChildNodesMissingInChartPg, IDS_Upto, 
        IDS_InfoMsgForTreeNodeMissingInChartPg, IDS_VRI, IDS_MRI, IDS_Index, IDS_InfoMsgForPFCDevicesInChartPg, 
        IDS_DevcBlockedDueToNonPaymentOfSomeCost, IDS_PFC, IDS_PeopleCount, IDS_CW, IDS_SL, IDS_Litre, IDS_airQualityIndex, IDS_currentAirQualityIndex, IDS_PoorAqi, IDS_VeryPoorAqi, IDS_SevereAqi, IDS_AlertLevel6, IDS_SatisfactoryInSingleDeviceData,
        IDS_Formaldehyde, IDS_Oxygen, IDS_PM100, IDS_NitricOxide, IDS_Chlorine, IDS_MethylMercaptan, IDS_Noise, IDS_LightIntensity, IDS_UV, IDS_Radon, IDS_AirPressure, IDS_WindSpeed, IDS_WindDirection, IDS_DaylightVisibility, IDS_Rainfall,
        IDS_North, IDS_Northeast, IDS_East, IDS_Southeast, IDS_Southwest, IDS_South, IDS_West, IDS_Northwest,
        IDS_LightRain, IDS_ModerateRain, IDS_HeavyRain, IDS_VeryHeavyRain, IDS_TorrentialRain, IDS_GoodVisibility, IDS_ModerateVisibility, IDS_FairVisibility, IDS_PoorVisibility, IDS_CalmBreeze, IDS_ModerateBreeze, IDS_StrongGale, IDS_Storm, IDS_LowLightIntensity, IDS_MediumLightIntensity, IDS_HighLightIntensity, IDS_LowNoiseLevel, IDS_MediumNoiseLevel, IDS_HighNoiseLevel,
        IDS_RefreshData,
        IDS_RangeLowVeryPoor,
        IDS_ExtremeLow, IDS_Low, IDS_High, IDS_ExtremeHigh,
        IDS_Normal,
        IDS_Extreme,
        IDS_VeryHigh,
        IDS_VeryLow,
        IDS_NoDataFound,
        IDS_GentleBreeze, IDS_SafeRange, IDS_WarningRange, IDS_CriticalRange, IDS_EmergencyRange, IDS_HazardousRange, IDS_NoChartForLPGModel,
        IDS_ParamNoChartAvailable, IDS_Hydrogen
    } from '../../VcLanguage';
import VcSetupDevice from './VcSetupDevice';
import { FaSadTear, FaFrownOpen, FaDizzy, FaMeh, FaGrinBeam, FaSmile} from 'react-icons/fa';
import {Chart, ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController,
        PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale,
        RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Title, Tooltip, SubTitle} from 'chart.js';
import { Select, Switch, Button } from 'antd';
import { IoCheckmark } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { FaTemperatureLow } from "react-icons/fa";
import { DatePicker, Space } from 'antd';
import VcDetailsAndRanges from './VcDetailsAndRanges';
// import moment from 'moment';


const { RangePicker } = DatePicker;
  
Chart.register( ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController,
        PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale,
        RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Title, Tooltip, SubTitle );

class VcChartData extends Component {
    chartRef = React.createRef();
    currentChart = null;

    constructor(props) {
        super(props)
    
        this.state = {
            selectedTreeNodeID: '', 
            selectedDeviceType: '', 
            isRootSelected: true, // Initially AllDeviceData will be rendered
            selectedTreeNodeTitle: '',  
            LoggedInUserID: '',
            relevantModelInfo:[],
            selectedParamType:'',
            selectedParamTypeToShow: '',
            periodToView:'',
            EndDateTime: null,
            StartDateTime: null,
            isCustomizedDateTimeChecked: false,
            datetimeRefreshButtonClicked: false,
            errors:'',
            noDataFound:'',
            languageToViewIn: '',
            chartGraphView: '',
            objRecievedStdAirQualityRanges: [],
            subscriptionStartDtTm: null,
            subscriptionEndDtTm: null,
            gracePeriod: 0,
            warningPeriod: 0,
            subscriptionErr: '',
            subscriptionInfo: '',
            noteToShowIfApplicable: '',
            subNotApplicableErr: '',
            backdrop: false,
            modalFromIcon: false,
            highSevereStartValueInPopUp: '',
            goodEndValueInPopUp: '',
            arrOfDispRangeValues: [],
            arrOfRanges: [],
            isDeviceBlocked: false,
            bCheckDeviceBlockedStatus: false,
            havingPvgToViewBlockedDeviceData: false,
            periodOptions : [],
            renderLPGModelChart: true,
            showImpactsOfParam: false,
            parsedModelInfo: {}
        }
    }

    onCustomizedDateTimeChecked = (e) => {
        
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

        // let modifiedState = this.state;
        let isChecked = e.target.checked;
        
        this.setState( 
            prevState => {
                let modifiedState = prevState;
                modifiedState.isCustomizedDateTimeChecked = isChecked;
                let periodToView = modifiedState.periodToView;
                modifiedState.errors = "";

                if(isChecked == false) {
                    modifiedState.StartDateTime = null;
                    modifiedState.EndDateTime = null;
                } else {
                    modifiedState.EndDateTime = new Date();

                    if(periodToView == 'Hourly') {
                        // Set the ViewEndDateTime 1 hour before the ViewStartDateTime
                        let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
                        viewEndDateTime.setHours(viewEndDateTime.getHours() - 1);
                        modifiedState.StartDateTime = viewEndDateTime;

                    } else if(periodToView == 'Daily') {
                        // Set the ViewEndDateTime 24 hour before the ViewStartDateTime
                        let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
                        viewEndDateTime.setHours(viewEndDateTime.getHours() - 24);
                        modifiedState.StartDateTime = viewEndDateTime;        

                    } else if(periodToView == 'Weekly') {
                        // Set the ViewEndDateTime 6 day before the ViewStartDateTime
                        let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
                        viewEndDateTime.setDate(viewEndDateTime.getDate() - 6);
                        modifiedState.StartDateTime = viewEndDateTime;        
                    } else if(periodToView == 'Monthly') {
                        // Set the ViewEndDateTime 30 day before the ViewStartDateTime
                        let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
                        viewEndDateTime.setDate(viewEndDateTime.getDate() - 30);
                        modifiedState.StartDateTime = viewEndDateTime;
                    }    
                }
                appRelevantDataContextValue.onChangeCustomizedDateTimeChecked(modifiedState.isCustomizedDateTimeChecked);
                appRelevantDataContextValue.onChangeChartPeriodToViewWRTDateTime(modifiedState.StartDateTime, modifiedState.EndDateTime);
                return modifiedState;
            }
        );
    }

    getMeasuredPramName(inParamName, t){

        switch(inParamName) {
            case t(IDS_airQualityIndex):
                return AQI;
            case t(IDS_currentAirQualityIndex):
                return CAQI;
            case t(IDS_nitrogenDioxide):
                return NO2;
            case t(IDS_Ozone):
                return O3;
            case t(IDS_sulphurDioxide):
                return SO2;
            case t(IDS_TVOC):
                return VOC;
            case t(IDS_carbonMonoxide):
                return CO;
            case t(IDS_Ammonia):
                return NH3;
            case t(IDS_carbonDioxide):
                return CO2;
            case t(IDS_hydrogenSulphide):
                return H2S;
            case t(IDS_Methane):
                return CH4;
            case t(IDS_LPG):
                return LPG;
            case t(IDS_SMOKE):
                return SMOKE;
            case t(IDS_Dust) + " (PM1)":
                return PM1;
            case t(IDS_Dust) + " (PM2.5)":
                return PM25;
            case t(IDS_Dust) + " (PM10)":
                return PM10;
            case t(IDS_Dust) + " (PM100)":
                return PM100;
            case t(IDS_Temperature):
                return TEMP;
            case t(IDS_Humidity):
                return HUM;
            case t(IDS_Odour):
                return NH3OD;
            case t(IDS_VRI):
                return VRI;
            case t(IDS_MRI):
                return MRI;
            case t(IDS_PFC):
                return DEVICE_TYPE_PFC;
            case t(IDS_CW):
                return WLIP;
            case t(IDS_SL):
                return SLIP;  
            case t(IDS_Formaldehyde):
                return HCHO;
            case t(IDS_Oxygen):
                return O2;
            case t(IDS_NitricOxide):
                return NO;
            case t(IDS_Chlorine):
                return CL;
            case t(IDS_MethylMercaptan):
                return CH3SH;
            case t(IDS_Noise):
                return NOISE;
            case t(IDS_LightIntensity):
                return LUX;
            case t(IDS_UV):
                return UV;
            case t(IDS_Radon):
                return RADON;
            case t(IDS_AirPressure):
                return AP;
            case t(IDS_WindSpeed):
                return WS;
            case t(IDS_WindDirection):
                return WD;
            case t(IDS_DaylightVisibility):
                return DLV;
            case t(IDS_Rainfall):
                return RNFL; 
            case t(IDS_Hydrogen):
                return H2;                                 
            default:
                console.error(`Unable to get ParaM. Unknown Param: ${inParamName}`);
                return (""); // Return empty tag
        }
    } 

    getParamNameBasedOnType(inParamNameType, t){
        switch(inParamNameType) {
            case AQI:
                return t(IDS_airQualityIndex);
              case CAQI:
                return t(IDS_currentAirQualityIndex);
            case NO2:
                return t(IDS_nitrogenDioxide);
            case O3:
                return t(IDS_Ozone);
            case SO2:
                return t(IDS_sulphurDioxide);
            case VOC:
                return t(IDS_TVOC);
            case CO:
                return t(IDS_carbonMonoxide);
            case NH3:
                return t(IDS_Ammonia);
            case CO2:
                return t(IDS_carbonDioxide);
            case H2S:
                return t(IDS_hydrogenSulphide);
            case CH4:
                return t(IDS_Methane);
            case LPG:
                return t(IDS_LPG);
            case SMOKE:
                return t(IDS_SMOKE);
            case PM1:
                return t(IDS_Dust) + " (PM1)";
            case PM25:
                return t(IDS_Dust) + " (PM2.5)";
            case PM10:
                return t(IDS_Dust) + " (PM10)";
            case PM100:
                return t(IDS_Dust) + " (PM100)";
            case TEMP:
                return t(IDS_Temperature);
            case HUM:
                return t(IDS_Humidity);
            case NH3OD:
                return t(IDS_Odour);
            case VRI:
                return t(IDS_VRI);
            case MRI:
                return t(IDS_MRI);
            case DEVICE_TYPE_PFC:
                return t(IDS_PFC);
            case WLIP:
                return t(IDS_CW);
            case SLIP:
                return t(IDS_SL); 
            case HCHO:
                return t(IDS_Formaldehyde);
            case O2:
                return t(IDS_Oxygen);
            case NO:
                return t(IDS_NitricOxide);
            case CL:
                return t(IDS_Chlorine);
            case CH3SH:
                return t(IDS_MethylMercaptan);
            case NOISE:
                return t(IDS_Noise);
            case LUX:
                return t(IDS_LightIntensity);
            case UV:
                return t(IDS_UV);
            case RADON:
                return t(IDS_Radon);
            case AP:
                return t(IDS_AirPressure);
            case WS:
                return t(IDS_WindSpeed);
            case WD:
                return t(IDS_WindDirection);
            case DLV:
                return t(IDS_DaylightVisibility);
            case RNFL:
                return t(IDS_Rainfall);     
            case H2:
                return t(IDS_Hydrogen);                                
            default:
                console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (""); // Return empty tag
        }
    } 

    getUnitsBasedOnParamtype = (inParamType, t) => {

        let retUnit = '';
        switch(inParamType) {
            case NO2:
            case O3:
            case SO2:
            case VOC:
            case H2S:
            case HCHO:
            case CL:
            case CH3SH:
                retUnit = '( ' + t(IDS_PartsPerBillion) + ' - ' + t(IDS_PPB) + ' )';
                break;
            case CO:
            case NH3:
            case CO2:
            case NO:
            case NH3:
                retUnit = '( ' + t(IDS_PartsPerMillion) + ' - ' + t(IDS_PPM) + ' )';
                break;
            case LPG: 
            case SMOKE:
                retUnit = ''; // No units in case of AlertBased parameters.
                break;  
            case NH3OD:
                retUnit = '( ' + t(IDS_OdourLevel) + ' )';
                break;                
            case PM1:
            case PM25:
            case PM10:
            case PM100:
                retUnit = '(㎍/㎥)';
                // retUnit = (<span >㎍/m<sup>3</sup></span>)
                break;
            case TEMP:
                retUnit = '( ' + t(IDS_Celsius) + ' )';
                break;
            case HUM:
            case SLIP:
                retUnit = '( ' + t(IDS_Percent) + ' )';
                break;
            case VRI:
            case MRI:
            case UV:
                retUnit = '( ' + t(IDS_Index) + ' )';
                break;
            case DEVICE_TYPE_PFC:
                retUnit = '( ' + t(IDS_PeopleCount) + ' )';
                break;
            case WLIP:
                retUnit = '( ' + t(IDS_Litre) + ' )';
                break;
            case AQI:
            case CAQI:
                retUnit = '';
                break;
            case O2:
            case CH4:
            case H2:
                retUnit = '(%)'
                break;
            case NOISE:
                retUnit = '(dB)';
                break;
            case LUX:
                retUnit = '(lux)';
                break;
            case RADON:
                retUnit = '(Bq/m³)';
                break;
            case AP:
                retUnit = '(hPa)';
                break;
            case WS:
                retUnit = '(m/s)';
                break;
            case WD:
                retUnit = '(°)';
                break;
            case DLV:
                retUnit = '(km)';
                break;
            case RNFL:
                retUnit = '(mm)';
                break;
            default:
                console.error(`Unable to get Units. Unknown Param Type: ${inParamType}`);
                retUnit = '';
                break;
            }
        return retUnit;
    }

    getXAxesUnitsBasedOnPeriodToView = (inParamType, t) => {

        let retUnit = '';
        switch(inParamType) {
            case "Minute":
                retUnit = '( ' + t(IDS_HourMinuteSecChart) + ' )';
                break;
            case "Hourly":
                retUnit = '( ' + t(IDS_HourMinuteChart) + ' )';
                break;
            case "Daily":
                retUnit = '( ' + t(IDS_DailyChart) + ' )';
                break;
            case "Weekly":
                retUnit = '( ' + t(IDS_WeekDayChart) + ' )';
                break;
            case "Monthly":
                retUnit = '( ' + t(IDS_MonthlyChart) + ' )';
                break;
            default:
                console.error(`Unable to get Units. Unknown Param Type: ${inParamType}`);
                retUnit = '';
                break;
            }
        return retUnit;
    }

    getPeriodToView = (inParamType, t) => {

        let retTPeriodToView = '';
        switch(inParamType) {
            case "Minute":
                retTPeriodToView =  t(IDS_MinuteDropDown);
                break;
            case "Hourly":
                retTPeriodToView =  t(IDS_HourlyDropDown);
                break;
            case "Daily":
                retTPeriodToView =  t(IDS_DailyDropDown);
                break;
            case "Weekly":
                retTPeriodToView =  t(IDS_WeeklyDropDown);
                break;
            case "Monthly":
                retTPeriodToView =  t(IDS_MonthlyDropDown);
                break;
            default:
                console.error(`Unable to get Translated Period To View. Unknown Param Type: ${inParamType}`);
                retTPeriodToView = '';
                break;
            }
        return retTPeriodToView;
    }

    
    componentDidMount() {

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        
        // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
        appRelevantDataContextValue.onChangeProcessingReq(true); 

        // this.getLatestChartData(appRelevantDataContextValue);
        this.getCurrentSubscriptionInfoAndChartData();
    }

    // Get SubscriptionInfo for the selected Device and show appropriate subscription info
    // for the particular Device.
    // During Warning Period: At the top of the Chart show message: "Your subscription will expire on <Date>. 
    //                            Please renew the same before the end date to be able to view older data."
    // During Grace Period: At the top of the Chart show message: "Your subscription has expired on <Date>. 
    //                            Please renew the same at the earliest to be able to view older data."
    // After Expiration: At the top of the Chart show message: "Your subscription has expired on <Date>. 
    //                            You will be able to view only last <7> days data. Please renew your subscription at the earliest."
    getCurrentSubscriptionInfoAndChartData = () => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;    
        let modifiedState = this.state;

        let localTimeZone = (new Date()).getTimezoneOffset();
        let selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        modifiedState.periodToView = appRelevantDataContextValue.chartData.chartPeriodToView;
        modifiedState.chartGraphView = appRelevantDataContextValue.chartData.chartGraphToView;
        modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;
        modifiedState.selectedParamType = appRelevantDataContextValue.chartData.chartParamType;
        modifiedState.selectedParamTypeToShow = this.getParamNameBasedOnType(appRelevantDataContextValue.chartData.chartParamType, t);
        modifiedState.selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;

        modifiedState.subscriptionErr = '';
        modifiedState.subscriptionInfo = '';
        modifiedState.subNotApplicableErr = '';
        modifiedState.subscriptionStartDtTm = null;
        modifiedState.subscriptionEndDtTm = null;
        modifiedState.gracePeriod = 0;
        modifiedState.warningPeriod = 0;

        let jsonParams = {
            DeviceID: selectedTreeNodeID,
            localTimeZone: getLocalTimezoneOffset( localTimeZone ),
            LoggedInUserID: LoggedInUserID
        }

        axios.post(`${getAPIHostURL()}/wclient/getCurrentSubscriptionInfo`,jsonParams)
        .then(response => {
            if(response.data.code == "SUCCESS" ) {

                if((response.data["countOfHavingPvgToViewOlderDataWithoutSubs"] != null && 
                    response.data["countOfHavingPvgToViewOlderDataWithoutSubs"].length > 0 &&
                    response.data["countOfHavingPvgToViewOlderDataWithoutSubs"] > 0 
                    ) ||
                    response.data["isSpecialProductionUserID"]
                ) {
                    modifiedState.noteToShowIfApplicable = t(IDS_NoteToProdUsrInChart);
                } else {
                    modifiedState.noteToShowIfApplicable = '';
                }

                if(response.data["SubscriptionInfo"] == null || response.data["SubscriptionInfo"].length <=0) {
                    modifiedState.subNotApplicableErr = t(IDS_SubNotApplicable);
                } else {

                    let receivedSubscriptionInfo = response.data["SubscriptionInfo"];

                    if(receivedSubscriptionInfo[0]["SubscriptionStartDtTm"] != null &&  receivedSubscriptionInfo[0]["SubscriptionEndDtTm"] != null ) {
                        let subscriptionEndDtTmToCompare = receivedSubscriptionInfo[0]["SubscriptionEndDtTm"].replace(/-/g, "/");
                        
                        modifiedState.subscriptionStartDtTm = convertLocalDateWithFormatDDMMMYY(receivedSubscriptionInfo[0]["SubscriptionStartDtTm"].replace(/-/g, "/"));
                        modifiedState.subscriptionEndDtTm = convertLocalDateWithFormatDDMMMYY(receivedSubscriptionInfo[0]["SubscriptionEndDtTm"].replace(/-/g, "/"));
                        modifiedState.gracePeriod = receivedSubscriptionInfo[0]["GracePeriod"];
                        modifiedState.warningPeriod = receivedSubscriptionInfo[0]["WarningPeriod"];

                        let receivedSubsEndDtTmForWarningPeriod = new Date(receivedSubscriptionInfo[0]["SubscriptionEndDtTm"].replace(/-/g, "/"));
                        let receivedSubsEndDtTmForGracePeriod = new Date(receivedSubscriptionInfo[0]["SubscriptionEndDtTm"].replace(/-/g, "/"));

                        let DtTmAfterRemovalOfWarningPeriod = new Date(receivedSubsEndDtTmForWarningPeriod.setDate(receivedSubsEndDtTmForWarningPeriod.getDate() - receivedSubscriptionInfo[0]["WarningPeriod"]));
                        let DtTmAfterAddingOfGracePeriod = new Date(receivedSubsEndDtTmForGracePeriod.setDate(receivedSubsEndDtTmForGracePeriod.getDate() + receivedSubscriptionInfo[0]["GracePeriod"]));

                        let currentTimeStamp = new Date();

                        if(new Date(subscriptionEndDtTmToCompare) > currentTimeStamp && DtTmAfterRemovalOfWarningPeriod < currentTimeStamp) {
                            modifiedState.subscriptionInfo = t(IDS_WarningPeriodForSubs, modifiedState.subscriptionEndDtTm);
                        } else if(new Date(subscriptionEndDtTmToCompare) <= currentTimeStamp && DtTmAfterAddingOfGracePeriod > currentTimeStamp) {
                            modifiedState.subscriptionInfo = t(IDS_GracePeriodForSubs, modifiedState.subscriptionEndDtTm);
                        } else if(new Date(subscriptionEndDtTmToCompare) < currentTimeStamp) {
                            modifiedState.subscriptionInfo = t(IDS_ExpiredSubs, modifiedState.subscriptionEndDtTm, MAX_NO_OF_DAY_FOR_VIEWING_DATA_AFTER_SUBS_EXPIRY);
                        } else {
                            modifiedState.subscriptionInfo = '';
                        }
                    } else {
                        modifiedState.subNotApplicableErr = t(IDS_SubNotApplicable);
                    }
                }

            } else if(response.data.code == 'MODEL_WITHOUT_SUBSCRIPTION') {
                console.log("Subscription plan is not applicable for this type of Model.")
                modifiedState.subscriptionErr = '';
                modifiedState.subscriptionInfo = '';
                modifiedState.subNotApplicableErr = '';
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.subscriptionErr = t(IDS_AUSrvrIssueReqParamsNotSentForSubsInfo);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.subscriptionErr = t(IDS_SubsServerIssue);
                } else {
                    console.log('Should not reach here');
                    modifiedState.subscriptionErr = t(IDS_SubsServerIssue);
                }
            }
            this.getLatestChartData(modifiedState);

        })
        .catch(error => {
            console.log("Network error:");
            console.log(error);
            appRelevantDataContextValue.onChangeProcessingReq(false);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.subscriptionErr = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        })
    }

    // getLatestChartData(appRelevantDataContextValue) {
    getLatestChartData(inModifiedState=null) {
                
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;    

        let selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        let selectedDeviceType = appRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType;  
        let isRootSelected = appRelevantDataContextValue.selectedNodeInfo.isRoot; 
        let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        let selectedParamType = appRelevantDataContextValue.chartData.chartParamType;

        let currentChartPeriod = appRelevantDataContextValue.chartData.chartPeriodToView;

        if(selectedDeviceType == STLI){
            // appRelevantDataContextValue.onChangeChartPeriodToView("Monthly")
            currentChartPeriod = "Monthly"
        }
        if (selectedParamType == DLV || selectedParamType == WS) {
            if (currentChartPeriod != "Daily" && currentChartPeriod != "Hourly") {
                currentChartPeriod = "Hourly"
            }
        } 
        appRelevantDataContextValue.onChangeChartPeriodToView(currentChartPeriod)
        
        let periodToView = appRelevantDataContextValue.chartData.chartPeriodToView;
        let chartGraphView = appRelevantDataContextValue.chartData.chartGraphToView;
        let StartDateTime = appRelevantDataContextValue.chartData.chartStartDateTime;
        let EndDateTime = appRelevantDataContextValue.chartData.chartEndDateTime;
        let isCustomizedDateTimeChecked = appRelevantDataContextValue.chartData.isCustomizedDateTimeChecked;        
        let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;
        let IsSelectedNodeDevice = appRelevantDataContextValue.selectedNodeInfo.isDevc;

        // // During refresh, somehow the Start/End DateTime stored in context is in UTC format. To solve this issue, if
        // // the date in the page is not same as context (context has UTC date), then recreate the date again from string.
        // if( (StartDateTime!= null && EndDateTime!= null) && (StartDateTime != new Date(StartDateTime.valueOf()) || EndDateTime != new Date(EndDateTime.valueOf()))) {
        //     StartDateTime = new Date(StartDateTime.valueOf());
        //     EndDateTime = new Date(EndDateTime.valueOf());
        // }
        // console.log("Formatted date: ", new Date(StartDateTime.valueOf()))
        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        // let StartDateTime = modifiedState.StartDateTime;
        // let EndDateTime = modifiedState.EndDateTime;

        let strStartDateTime;
        let strEndDateTime;
        // Convert the input dates to UTC before sending to the Server
        if(isCustomizedDateTimeChecked && StartDateTime != null && EndDateTime != null ) {
            strStartDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(StartDateTime.valueOf()) );
            strEndDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(EndDateTime.valueOf()) );
        } else {
            strStartDateTime = null;
            strEndDateTime = null;
        }
        
        // modifiedState.datetimeRefreshButtonClicked = !modifiedState.datetimeRefreshButtonClicked;
        modifiedState.selectedTreeNodeID = selectedTreeNodeID;
        modifiedState.selectedTreeNodeTitle = selectedTreeNodeTitle;
        modifiedState.selectedDeviceType = selectedDeviceType;
        modifiedState.isRootSelected = isRootSelected;
        modifiedState.LoggedInUserID = loggedInUserID;
        modifiedState.selectedParamType = selectedParamType;
        modifiedState.selectedParamTypeToShow = this.getParamNameBasedOnType(selectedParamType, t);
        modifiedState.periodToView = periodToView;
        modifiedState.chartGraphView = chartGraphView;
        modifiedState.isCustomizedDateTimeChecked = isCustomizedDateTimeChecked;
        modifiedState.StartDateTime = StartDateTime;
        modifiedState.EndDateTime = EndDateTime;
        modifiedState.relevantModelInfo = [];
        modifiedState.errors = "";
        modifiedState.languageToViewIn = languageToViewIn;
        modifiedState.isDeviceBlocked = false;
        modifiedState.bCheckDeviceBlockedStatus = false;
        modifiedState.havingPvgToViewBlockedDeviceData = false;
        // modifiedState.renderLPGModelChart = true;

        // Get the Browser Timezone Offset
        let localTimeZone = (new Date()).getTimezoneOffset();

        // Incase the LHS Tree does not have any devices OR if it is a General user (a user without Devices) Logged in
        // and the User has clicked the node with ID 'NO_DEVC_FOUND_FOR_LOGGED_IN_USER' or If the selected node itself is not a valid device.
        // In this case no need to bring data from server as DeviceID is not valid and we have to show him a HTML page with message.
        // Also you have to update state with loggedinUser, selectedTreeNodeID as it will avoid triggering componentDidUpdate infinitely.
        if((selectedTreeNodeID.length > 0 && selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER) || !IsSelectedNodeDevice) {
            this.setState(modifiedState);
            appRelevantDataContextValue.onChangeProcessingReq(false);
            return;
        }

        let SelectedNodeDeviceType = appRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType;

        // if(SelectedNodeDeviceType.length > 0 && SelectedNodeDeviceType == "PFC" && SelectedNodeDeviceType != null) {
        //     this.setState(modifiedState);
        //     appRelevantDataContextValue.onChangeProcessingReq(false);
        //     return;
        // }

        // if user has privileges to see a chart data then only create a mychartRef.
        let myChartRef = "";

        if (this.chartRef.current) {
            myChartRef = this.chartRef.current.getContext("2d");
            // Proceed with your Chart.js initialization
        } else {
            console.error("chartRef is not yet initialized");
        }

        let jsonParams = {
            selectedTreeNodeID: selectedTreeNodeID,
            selectedParamType: selectedParamType,
            periodToView:  periodToView,
            StartDateTime:  strStartDateTime,
            EndDateTime:  strEndDateTime,
            loggedInUserID: loggedInUserID,
            localTimeZone: getLocalTimezoneOffset( localTimeZone )
        }

        axios.post(`${getAPIHostURL()}/wclient/getLatestChartData`,jsonParams)
        .then(response => {
        appRelevantDataContextValue.onChangeProcessingReq(false);
        if(response.data.code == "SUCCESS") {

            let receivedChartData = response.data.retrievedChartData;
            let receivedRelevantModelInfo = response.data.retrievedRelevantModelInfo;
            let recievedStdAirQualityRanges = response.data.retrievedStdAirQualityRanges;

            // Show blocked Devices Data to Production/Support Users.
            modifiedState.bCheckDeviceBlockedStatus = response.data.bCheckDeviceBlockedStatus != null && 
                response.data.bCheckDeviceBlockedStatus == 0 ? true : false;
            modifiedState.havingPvgToViewBlockedDeviceData = response.data.havingPvgToViewBlockedDeviceData != null && 
                response.data.havingPvgToViewBlockedDeviceData > 0 ? true : false;
            modifiedState.relevantModelInfo = [];
            
            let objReceivedRelevantModelInfo;
            // let objRecievedStdAirQualityRanges;

            let deviceType = response.data.retrievedRelevantModelInfo[0].DeviceType;

            try {
                objReceivedRelevantModelInfo = JSON.parse(receivedRelevantModelInfo[0].MeasuredParams);
                modifiedState.parsedModelInfo = objReceivedRelevantModelInfo;
                modifiedState.objRecievedStdAirQualityRanges = JSON.parse(recievedStdAirQualityRanges[0].RangeValues);
            }
            catch {
                console.log(`Should not happen. The device data obtained from server for DeviceID ${selectedTreeNodeID} is in invalid JSON format.`);
                // Skip this device information and move to next
            }

            let chartData = [];
            let chartDataFmtLogTime = [];
            let chartDataOrgLogTime = [];
            // let stdAirQualityRangesGood = [];
            // let stdAirQualityRangesModerate = [];
            // let stdAirQualityRangesPoor = [];
            // let stdAirQualityRangesVPoor = [];
            // let stdAirQualityRangesHighSevere = [];
            // let stdAirQualityRangesLowSevere = [];

            let stdAirQualityRangesLow = modifiedState.objRecievedStdAirQualityRanges[LOW];
            let stdAirQualityRangesMax = modifiedState.objRecievedStdAirQualityRanges[MAX];
            let stdAirQualityRangesLowSevereL = modifiedState.objRecievedStdAirQualityRanges[LOW_SEVERE_L];
            let stdAirQualityRangesLowSevereU = modifiedState.objRecievedStdAirQualityRanges[LOW_SEVERE_U];
            let stdAirQualityRangesLowVPoorL = modifiedState.objRecievedStdAirQualityRanges[LOW_V_POOR_L];
            let stdAirQualityRangesLowVPoorU = modifiedState.objRecievedStdAirQualityRanges[LOW_V_POOR_U];
            let stdAirQualityRangesGoodL = modifiedState.objRecievedStdAirQualityRanges[GOOD_L];
            let stdAirQualityRangesGoodU = modifiedState.objRecievedStdAirQualityRanges[GOOD_U];
            let stdAirQualityRangesSatisfactoryL = modifiedState.objRecievedStdAirQualityRanges[SATISFACTORY_L];
            let stdAirQualityRangesSatisfactoryU = modifiedState.objRecievedStdAirQualityRanges[SATISFACTORY_U];
            let stdAirQualityRangesModerateL = modifiedState.objRecievedStdAirQualityRanges[MODERATE_L];
            let stdAirQualityRangesModerateU = modifiedState.objRecievedStdAirQualityRanges[MODERATE_U];
            let stdAirQualityRangesPoorL = modifiedState.objRecievedStdAirQualityRanges[POOR_L];
            let stdAirQualityRangesPoorU = modifiedState.objRecievedStdAirQualityRanges[POOR_U];
            let stdAirQualityRangesVPoorL = modifiedState.objRecievedStdAirQualityRanges[V_POOR_L];
            let stdAirQualityRangesVPoorU = modifiedState.objRecievedStdAirQualityRanges[V_POOR_U];
            let stdAirQualityRangesHighSevereL= modifiedState.objRecievedStdAirQualityRanges[HIGH_SEVERE_L];
            let stdAirQualityRangesHighSevereU = modifiedState.objRecievedStdAirQualityRanges[HIGH_SEVERE_U];

            let arrChartDatasets = [];
            let objChartOptions = {};
            let data = {};

            // JCS-Start

            let chartRangeStartDate = null;
            let chartRangeEndDate = null;
            if( StartDateTime != null && EndDateTime != null ) {
                chartRangeStartDate = new Date(StartDateTime.valueOf());
                chartRangeEndDate = new Date(EndDateTime.valueOf());
            } else {
                chartRangeEndDate = new Date();
                chartRangeStartDate = new Date(chartRangeEndDate.valueOf());

                // Change start date as per period to view
                switch(periodToView) {
                    case "Minute":
                        chartRangeStartDate.setMinutes(chartRangeStartDate.getMinutes() - 1);
                        break;
                    case "Hourly":
                        chartRangeStartDate.setHours(chartRangeStartDate.getHours() - 1);
                        break;
                    case "Daily":
                        chartRangeStartDate.setHours(chartRangeStartDate.getHours() - 24);
                        break;
                    case "Weekly":
                        chartRangeStartDate.setDate(chartRangeStartDate.getDate() - 6);
                        break;
                    case "Monthly":
                        chartRangeStartDate.setDate(chartRangeStartDate.getDate() - 30);
                        break;
                    default:
                        console.error(`Invalid period to view - ${periodToView}. Assuming Hourly Period.`);
                        chartRangeStartDate.setHours(chartRangeStartDate.getHours() - 1);
                        break;
                }
            }

            // Fill out missing date-times with null value wherever applicable

            // First get the list of date times as per the selected PeriodToView
            let listPeriodDtTm = [];
            switch(periodToView) {
                case "Monthly":
                case "Weekly":
                    listPeriodDtTm = getListOfDatesBetweenRange(chartRangeStartDate, chartRangeEndDate);
                    break;
                case "Daily":
                    listPeriodDtTm = getListOfHoursBetweenRange(chartRangeStartDate, chartRangeEndDate);
                    break;
                case "Hourly":
                    listPeriodDtTm = getListOfMinutesBetweenRange(chartRangeStartDate, chartRangeEndDate);
                    break;
                case "Minute":
                        listPeriodDtTm = getListOfSecondsBetweenRange(chartRangeStartDate, chartRangeEndDate);                    
                    break;
                default:
                    console.error(`Invalid period to view - ${periodToView}. Assuming Minutely Period.`);
                    listPeriodDtTm = getListOfSecondsBetweenRange(chartRangeStartDate, chartRangeEndDate);
                    break;
            }

            // Next convert each date time-time from the above list as per the string format applicable
            // for the particular period type. This will be used to check whether a particular X-Axis
            // element is missing from the data that came from the API.
            let noOfReceivedChartDataPoints = receivedChartData.length;
            let noOfEstimatedXAxisPoints = listPeriodDtTm.length;
            let nextListPeriodDtTm = null;
            let strNextFormatLogTime = null;
            let nextUnusedChartDataPosition = 0; // We start to check from first index of chart data if it is the required date-time
            for(let j=0; ( noOfReceivedChartDataPoints > 0 && j < noOfEstimatedXAxisPoints ); j++) {

                nextListPeriodDtTm = listPeriodDtTm[j]; // Take next date-time for the selected Period Type
                // Convert the date-time as per the X-Axis format applicable for the Period Type
                switch(periodToView) {
                    case "Monthly":
                        strNextFormatLogTime = convertDateToStrMMMDD( nextListPeriodDtTm );
                        break;
                    case "Weekly":
                        strNextFormatLogTime = convertDateToStrDowMMMDD( nextListPeriodDtTm );
                        break;
                    case "Daily":
                        strNextFormatLogTime = convertDateToStrMMMDDHH00( nextListPeriodDtTm );
                        break;
                    case "Hourly":
                        strNextFormatLogTime = convertDateToStrMMMDDHHMI( nextListPeriodDtTm );
                        break;
                    case "Minute":
                        strNextFormatLogTime = convertDateToStrMMMDDHHMISS( nextListPeriodDtTm );
                        break;    
                    default:
                        console.error(`Invalid period to view - ${periodToView}. Assuming Minutely Period.`);
                        strNextFormatLogTime = convertDateToStrMMMDDHHMISS( nextListPeriodDtTm );
                        break;
                }    

                // Y-Axis value
                if( nextUnusedChartDataPosition < noOfReceivedChartDataPoints &&
                    receivedChartData[nextUnusedChartDataPosition].FmtLogTime == strNextFormatLogTime 
                ) {
                    chartData.push(receivedChartData[nextUnusedChartDataPosition].Avg); // Data found
                    ++nextUnusedChartDataPosition; // Point to the next available chart data
                } else {
                    chartData.push(null); // Push null as no data found
                }

                // // Corresponding X-Axis value
                // chartDataFmtLogTime.push(strNextFormatLogTime);

                // Corresponding X-Axis value
                if(!chartDataFmtLogTime.includes(strNextFormatLogTime)) {
                    // Sometimes the last DateTime on the x-axis gets added twice when the time part in the StartDateTime is not
                    // same as the time part in the EndDateTime. For this reason we will check if the formatted LogTime
                    // is already present in the x-axis data points before adding it to the array.
                    chartDataFmtLogTime.push(strNextFormatLogTime);
                } else {
                    // No need to do anything.
                }

                // here we are storing original DateTime not a formatted one as this will required for drilldown.
                if(!chartDataOrgLogTime.includes(nextListPeriodDtTm)) {
                    // Sometimes the last DateTime on the x-axis gets added twice when the time part in the StartDateTime is not
                    // same as the time part in the EndDateTime. For this reason we will check if the formatted LogTime
                    // is already present in the x-axis data points before adding it to the array.
                    chartDataOrgLogTime.push(nextListPeriodDtTm);
                } else {
                    // No need to do anything.
                }

            }

            // JCS-End

            // for(let i=0; i < receivedChartData.length; i++) {
            //     chartData.push(receivedChartData[i].Avg);
            //     chartDataFmtLogTime.push(receivedChartData[i].FmtLogTime);

            //     // if(objRecievedStdAirQualityRanges[LOW_SEVERE_L] != null) {
            //     //     // This condition will not happen, but added for future use.
            //     //     stdAirQualityRangesLowSevere.push(objRecievedStdAirQualityRanges[LOW_SEVERE_L]); 
            //     // }
            //     // if(objRecievedStdAirQualityRanges[GOOD_L] != null) {
            //     //     stdAirQualityRangesGood.push(objRecievedStdAirQualityRanges[GOOD_L]);
            //     // }
            //     // if(objRecievedStdAirQualityRanges[MODERATE_L] != null) {
            //     //     stdAirQualityRangesModerate.push(objRecievedStdAirQualityRanges[MODERATE_L]);
            //     // }
            //     // if(objRecievedStdAirQualityRanges[POOR_L] != null) {
            //     //     stdAirQualityRangesPoor.push(objRecievedStdAirQualityRanges[POOR_L]);
            //     // }
            //     // if(objRecievedStdAirQualityRanges[V_POOR_L] != null) {
            //     //     stdAirQualityRangesVPoor.push(objRecievedStdAirQualityRanges[V_POOR_L]);
            //     // }
            //     // if(objRecievedStdAirQualityRanges[HIGH_SEVERE_L] != null) {
            //     //     stdAirQualityRangesHighSevere.push(objRecievedStdAirQualityRanges[HIGH_SEVERE_L]);
            //     // }
            // }

            if(chartData.length <= 0) {
                modifiedState.noDataFound = t(IDS_NoDataFoundForChart);
            } else {
                modifiedState.noDataFound = '';
            }
            
            if(selectedParamType === NH3OD) {
                objChartOptions = {
                    responsive: true,
                    maintainAspectRatio: false,
                    barThickness: chartData.length < 10 ? 25 : null,
                    plugins: { 
                        legend: { 
                                display: false 
                            },
                         },
                    scales: {
                        y: {
                            ticks: {
                                display: false  
                            },

                            title: {
                                display: true,
                                text: this.getUnitsBasedOnParamtype(selectedParamType, t),
                            }, 
                        },
                        x: {
                            barThickness: chartData.length < 20 ? 25 : "flex",
                            ticks: {
                                display: true  
                            },

                            title: {
                                display: true,
                                text: this.getXAxesUnitsBasedOnPeriodToView(periodToView, t),
                            },                             
                        }                        
                    }
                }
            } else {

                objChartOptions = {
                    responsive: true,
                    maintainAspectRatio: false,
                    barThickness: chartData.length < 10 ? 25 : null,
                    
                    plugins: { 
                        legend: { 
                                display: false 
                            },
                         },
                    scales: {
                        y: {
                            ticks: {
                                // autoSkip: false,
                                display: true,
                            },
                            
                            min: selectedParamType === SLIP ? 0 : null,
                            max: selectedParamType === SLIP ? 100 : null,
                            
                            title: {
                                display: true,
                                text: this.getUnitsBasedOnParamtype(selectedParamType, t),
                            }                            
                        },
                        x: {
                            barThickness: chartData.length < 20 ? 25 : "flex",
                            ticks: {
                                // autoSkip: false,
                                display: true,  
                            },
                            title: {
                                display: true,
                                text: this.getXAxesUnitsBasedOnPeriodToView(periodToView, t),
                            },                          
                        }                      
                    }
                }
            }

            let barAndPointColorFromStdAirQualityRange = [];

            for(let i = 0; i < chartData.length; i++)
            {
                // if(chartData[i] == null || chartData[i] < stdAirQualityRangesLow || chartData[i] > stdAirQualityRangesMax ) {
                if(chartData[i] == null) {
                        barAndPointColorFromStdAirQualityRange[i] = "black";    
                } else {

                    if(modifiedState.selectedDeviceType == WLI){
                        barAndPointColorFromStdAirQualityRange[i] = "#678AEE"

                    } else if(modifiedState.selectedDeviceType == STLI){
                        // if Sewage Level is less than 80% then show "light brown" color else show "dark brown" color.
                        barAndPointColorFromStdAirQualityRange[i] = chartData[i] <= stdAirQualityRangesLowSevereL ? "#bd9a38" : "#99700f"

                    } else if(modifiedState.selectedParamType == RNFL){
                        barAndPointColorFromStdAirQualityRange[i] = "#0958d9" 

                    } else if( ( stdAirQualityRangesLowSevereL == null && chartData[i] <= stdAirQualityRangesLowSevereU) ||
                        ( stdAirQualityRangesLowSevereU == null && chartData[i] > stdAirQualityRangesLowSevereL ) ||
                        ( chartData[i] > stdAirQualityRangesLowSevereL && chartData[i] <= stdAirQualityRangesLowSevereU) )
                    {            
                        barAndPointColorFromStdAirQualityRange[i] = "#C00000"

                    } else if( ( stdAirQualityRangesGoodL == null && chartData[i] <= stdAirQualityRangesGoodU ) ||
                               ( stdAirQualityRangesGoodU == null && chartData[i] > stdAirQualityRangesGoodL ) ||
                               ( chartData[i] > stdAirQualityRangesGoodL && chartData[i] <= stdAirQualityRangesGoodU ) )
                    {
                        barAndPointColorFromStdAirQualityRange[i] = "#00b050"
        
                    } else if( ( stdAirQualityRangesLowVPoorL == null && chartData[i] <= stdAirQualityRangesLowVPoorU ) ||
                               ( stdAirQualityRangesLowVPoorU == null && chartData[i] > stdAirQualityRangesLowVPoorL ) ||
                               ( chartData[i] > stdAirQualityRangesLowVPoorL && chartData[i] <= stdAirQualityRangesLowVPoorU ) )
                    {
                        barAndPointColorFromStdAirQualityRange[i] = "#ff0000"
        
                    } 
                    else if( ( stdAirQualityRangesSatisfactoryL == null && chartData[i] <= stdAirQualityRangesSatisfactoryU ) ||
                               ( stdAirQualityRangesSatisfactoryU == null && chartData[i] > stdAirQualityRangesSatisfactoryL ) ||
                               ( chartData[i] > stdAirQualityRangesSatisfactoryL && chartData[i] <= stdAirQualityRangesSatisfactoryU ) )
                    {
                        barAndPointColorFromStdAirQualityRange[i] = "#9acd32"
        
                    } else if( ( stdAirQualityRangesModerateL == null && chartData[i] <= stdAirQualityRangesModerateU ) ||
                               ( stdAirQualityRangesModerateU == null && chartData[i] > stdAirQualityRangesModerateL ) ||
                               ( chartData[i] > stdAirQualityRangesModerateL && chartData[i] <= stdAirQualityRangesModerateU ) )
                    {
                        barAndPointColorFromStdAirQualityRange[i] = "#FFD700" 
        
                    } else if( ( stdAirQualityRangesPoorL == null && chartData[i] <= stdAirQualityRangesPoorU ) ||
                               ( stdAirQualityRangesPoorU == null && chartData[i] > stdAirQualityRangesPoorL ) ||
                               ( chartData[i] > stdAirQualityRangesPoorL && chartData[i] <= stdAirQualityRangesPoorU ) )
                    {
                        barAndPointColorFromStdAirQualityRange[i] = "orange"
        
                    } else if( ( stdAirQualityRangesVPoorL == null && chartData[i] <= stdAirQualityRangesVPoorU ) ||
                               ( stdAirQualityRangesVPoorU == null && chartData[i] > stdAirQualityRangesVPoorL ) ||
                               ( chartData[i] > stdAirQualityRangesVPoorL && chartData[i] <= stdAirQualityRangesVPoorU ) )
                    {
                        barAndPointColorFromStdAirQualityRange[i] = "red"
        
                    } else if( ( stdAirQualityRangesHighSevereL == null && chartData[i] <= stdAirQualityRangesHighSevereU ) ||
                               ( stdAirQualityRangesHighSevereU == null && chartData[i] > stdAirQualityRangesHighSevereL ) ||
                               ( chartData[i] > stdAirQualityRangesHighSevereL && chartData[i] <= stdAirQualityRangesHighSevereU ) )
                    { 
                        barAndPointColorFromStdAirQualityRange[i] = "#C00000" 
        
                    } else {
                        // Default black
                        barAndPointColorFromStdAirQualityRange[i] = "black"
                    }
                }
            }

            if(modifiedState.chartGraphView == "onlyBarGraph" || modifiedState.chartGraphView == "lineAndBarGraph")
            {
                arrChartDatasets.push(
                    {
                        label: selectedTreeNodeTitle +"-"+ this.getParamNameBasedOnType(selectedParamType, t) +", "+ this.getPeriodToView(periodToView, t),
                        data: chartData,
                        type: "bar", 
                        borderColor: "#BDBDBD",
                        backgroundColor: barAndPointColorFromStdAirQualityRange, 
                        hoverBackgroundColor: barAndPointColorFromStdAirQualityRange ,
                        hoverBorderColor: "#BDBDBD",
                    }
                );  
            }

            if(modifiedState.chartGraphView == "onlyLineGraph" || modifiedState.chartGraphView == "lineAndBarGraph")
            {
                arrChartDatasets.push(
                    {
                        label: selectedTreeNodeTitle + "-" + this.getParamNameBasedOnType(selectedParamType, t) + ", "+ this.getPeriodToView(periodToView, t),
                        data: chartData,
                        borderColor: "#BDBDBD",
                        // borderColor: 'none',
                        backgroundColor: 'rgba(176,176,176,0.3)', 
                        type: "line", 
                        fill: true,
                        // pointBorderColor: "#BDBDBD",
                        // pointBackgroundColor: barAndPointColorFromStdAirQualityRange,
                        // pointHoverBorderColor: "#BDBDBD",
                        // pointHoverBackgroundColor: barAndPointColorFromStdAirQualityRange,

                        pointBorderColor : modifiedState.chartGraphView == "lineAndBarGraph" ?
                                                chartData.map( (data) => {
                                                    return (data != null && data == 0.0) ?
                                                    "#BDBDBD" : "transparent"
                                                })
                                                : 
                                                "#BDBDBD",
                        pointBackgroundColor : modifiedState.chartGraphView == "lineAndBarGraph" ?
                                                chartData.map( (data, index) => {
                                                    return (data != null && data == 0.0) ?
                                                    barAndPointColorFromStdAirQualityRange[index] : "transparent"
                                                })
                                                : 
                                                barAndPointColorFromStdAirQualityRange,
                        pointHoverBorderColor : modifiedState.chartGraphView == "lineAndBarGraph" ?
                                                chartData.map( (data) => {
                                                    return (data != null && data == 0.0) ?
                                                    "#BDBDBD" : "transparent"
                                                })
                                                : 
                                                "#BDBDBD",
                        pointHoverBackgroundColor : modifiedState.chartGraphView == "lineAndBarGraph" ?
                                                chartData.map( (data, index) => {
                                                    return (data != null && data == 0.0) ?
                                                    barAndPointColorFromStdAirQualityRange[index] : "transparent"
                                                })
                                                : 
                                                barAndPointColorFromStdAirQualityRange,


                        // pointRadius : modifiedState.chartGraphView =="onlyLineGraph" ? "6" : "",
                        // pointHoverRadius : modifiedState.chartGraphView =="onlyLineGraph" ? "8" : "",

                        // pointRadius : modifiedState.chartGraphView ==  "lineAndBarGraph" ?
                        //                                                     chartData.map( (data) => {
                        //                                                         return (data != null && data == 0.0) ?
                        //                                                         "6" : "none"
                        //                                                     })
                        //                                                 : "6",
                        // pointHoverRadius : modifiedState.chartGraphView =="lineAndBarGraph" ? 
                        //                                                     chartData.map( (data) => {
                        //                                                         return (data != null && data == 0.0) ?
                        //                                                         "8" : "none"
                        //                                                     })
                        //                                                 : "8",                                          

                        pointRadius : 6,
                        hoverRadius: 9,

                    }
                );  
            }

            // In case of Bar Graph whenever the data is zero, nothing is shown on the X axis.
            // So it is very difficult to guess whether it is absence of data or zero value of data.
            // So in case of Bar Graph we have also added an invisible line chart such that points with 
            // non-zero radius are shown only if the data point value is zero. In all other cases, 
            // the points are not shown. 
            if(modifiedState.chartGraphView == "onlyBarGraph")
            {
                // arrChartDatasets.push(
                //     {
                //         label: selectedTreeNodeTitle +"-"+ this.getParamNameBasedOnType(selectedParamType, t) +", "+ this.getPeriodToView(periodToView, t),
                //         data: chartData,
                //         borderColor: "transparent",
                //         backgroundColor: "transparent", 
                //         type: "line", 
                //         pointBorderColor: "#BDBDBD",
                //         pointBackgroundColor: barAndPointColorFromStdAirQualityRange,
                //         pointHoverBorderColor: "#BDBDBD",
                //         pointHoverBackgroundColor: barAndPointColorFromStdAirQualityRange,
                //         pointRadius : modifiedState.chartGraphView == "onlyBarGraph" ?
                //                                                         chartData.map( (data) => {
                //                                                             return (data != null && data == 0.0) ?
                //                                                             "6" : "none"
                //                                                         })
                //                                                         : 
                //                                                         "none",
                //         pointHoverRadius : modifiedState.chartGraphView == "onlyBarGraph" ?
                //                                                         chartData.map( (data) => {
                //                                                             return (data != null && data == 0.0) ?
                //                                                             "8" : "none"
                //                                                         })
                //                                                         : 
                //                                                         "none",
                //     }
                // );

                arrChartDatasets.push(
                    {
                        label: selectedTreeNodeTitle +"-"+ this.getParamNameBasedOnType(selectedParamType, t) +", "+ this.getPeriodToView(periodToView, t),
                        data: chartData,
                        borderColor: "transparent",
                        backgroundColor: "transparent", 
                        type: "line", 
                        // pointBorderColor: "#BDBDBD",
                        // pointBackgroundColor: barAndPointColorFromStdAirQualityRange,
                        // pointHoverBorderColor: "#BDBDBD",
                        // pointHoverBackgroundColor: barAndPointColorFromStdAirQualityRange,

                        pointBorderColor : modifiedState.chartGraphView == "onlyBarGraph" ?
                                                                        chartData.map( (data) => {
                                                                            return (data != null && data == 0.0) ?
                                                                            "#BDBDBD" : "transparent"
                                                                        })
                                                                        : 
                                                                        "none",
                        pointBackgroundColor : modifiedState.chartGraphView == "onlyBarGraph" ?
                                                                        chartData.map( (data, index) => {
                                                                            return (data != null && data == 0.0) ?
                                                                            barAndPointColorFromStdAirQualityRange[index] : "transparent"
                                                                        })
                                                                        : 
                                                                        "none",
                        pointHoverBorderColor : modifiedState.chartGraphView == "onlyBarGraph" ?
                                                                        chartData.map( (data) => {
                                                                            return (data != null && data == 0.0) ?
                                                                            "#BDBDBD" : "transparent"
                                                                        })
                                                                        : 
                                                                        "none",
                        pointHoverBackgroundColor : modifiedState.chartGraphView == "onlyBarGraph" ?
                                                                        chartData.map( (data, index) => {
                                                                            return (data != null && data == 0.0) ?
                                                                            barAndPointColorFromStdAirQualityRange[index] : "transparent"
                                                                        })
                                                                        : 
                                                                        "none",
                        pointRadius : modifiedState.chartGraphView == "onlyBarGraph" ?
                                                                        "6"
                                                                        : 
                                                                        "none",
                        pointHoverRadius : modifiedState.chartGraphView == "onlyBarGraph" ?
                                                                        "8"
                                                                        : 
                                                                        "none",
                    }
                );

            }

            // arrChartDatasets.push(
            //     {
            //         label: selectedTreeNodeTitle +"-"+ this.getParamNameBasedOnType(selectedParamType, t) +", "+ this.getPeriodToView(periodToView, t),
            //         data: chartData,
            //         borderColor: "#BDBDBD",
            //         backgroundColor: '#eceff170',                         
            //     }
            // );            
            // if(objRecievedStdAirQualityRanges[LOW_SEVERE_L] != null) {
            //     // This condition will not happen, but added for future use.

            //     arrChartDatasets.push(
            //         {
            //             label: t(IDS_LowSevere),
            //             data: stdAirQualityRangesLowSevere,
            //             borderColor: "#C00000",
            //             backgroundColor: 'transparent',
            //             borderWidth: "1",
            //             pointStyle: "dash",
            //         }                    
            //     );
            // }
            // if(objRecievedStdAirQualityRanges[GOOD_L] != null) {

            //     arrChartDatasets.push(
            //         {
            //             label: t(IDS_Good),
            //             data: stdAirQualityRangesGood,
            //             borderColor: "#00b050",
            //             backgroundColor: 'transparent',
            //             borderWidth: "1",
            //             pointStyle: "dash",
            //         }
            //     );
            // }
            // if(objRecievedStdAirQualityRanges[MODERATE_L] != null) {

            //     arrChartDatasets.push(
            //         {
            //             label: selectedParamType === NH3OD ? "L2" : t(IDS_Moderate),
            //             data: stdAirQualityRangesModerate,
            //             borderColor: "yellow",
            //             backgroundColor: 'transparent',
            //             borderWidth: "1",
            //             pointStyle: "dash",
            //         }
            //     );                
            // }
            // if(objRecievedStdAirQualityRanges[POOR_L] != null) {

            //     arrChartDatasets.push(
            //         {
            //             label: selectedParamType === NH3OD ? "L3" : t(IDS_Poor),
            //             data: stdAirQualityRangesPoor,
            //             borderColor: "orange",
            //             backgroundColor: 'transparent',
            //             borderWidth: "1",
            //             pointStyle: "dash"
            //         }
            //     );
            // }
            // if(objRecievedStdAirQualityRanges[V_POOR_L] != null) {

            //     arrChartDatasets.push(
            //         {
            //             label: selectedParamType === NH3OD ? "L4" : t(IDS_VeryPoor),
            //             data: stdAirQualityRangesVPoor,
            //             borderColor: "red",
            //             backgroundColor: 'transparent',
            //             borderWidth: "1",
            //             pointStyle: "dash"
            //         }
            //     );
            // }
            // if(objRecievedStdAirQualityRanges[HIGH_SEVERE_L] != null) {

            //     arrChartDatasets.push(
            //         {
            //             label: selectedParamType === NH3OD ? "L5" : t(IDS_Severe),
            //             data: stdAirQualityRangesHighSevere,
            //             borderColor: "#C00000",
            //             backgroundColor: 'transparent',
            //             borderWidth: "1",
            //             pointStyle: "dash"
            //         }
            //     );
            // }      

            let bIsAlertBased = false;
            let singleParamInfoFromModel = null;
            for(let i = 0; i < objReceivedRelevantModelInfo.Seq.length; i++) {
                
                // Check if the parameter is AlertBased
                singleParamInfoFromModel = objReceivedRelevantModelInfo[objReceivedRelevantModelInfo.Seq[i]];
                bIsAlertBased = ( singleParamInfoFromModel != null 
                                 && singleParamInfoFromModel[ALERT_BASED] != null ) ?
                                singleParamInfoFromModel[ALERT_BASED] : false; 
                
                // If the parameter is not alert based, only then show it in the Chart Drop Down
                if(!bIsAlertBased) {
                    if(deviceType == PFC){
                        modifiedState.relevantModelInfo.push(objReceivedRelevantModelInfo.Seq[0]);
                        break;
                    } else if(deviceType == WLI){
                        modifiedState.relevantModelInfo.push(objReceivedRelevantModelInfo.Seq[0]);
                        break;
                    } else {
                        modifiedState.relevantModelInfo.push(objReceivedRelevantModelInfo.Seq[i]);
                    }
                } else {
                    console.log(`Parameter ${objReceivedRelevantModelInfo.Seq[i]} is Alert Based so will not be shown in selection dropdown.`);
                }
            }

            if(modifiedState.relevantModelInfo.includes(modifiedState.selectedParamType)) {
                modifiedState.selectedParamType = selectedParamType;
                modifiedState.selectedParamTypeToShow = this.getParamNameBasedOnType(selectedParamType, t);
            } else if(modifiedState.relevantModelInfo.includes(NH3OD)){
                appRelevantDataContextValue.onChangeChartParamType(NH3OD)
            } else if(modifiedState.relevantModelInfo.includes(PM25)){
                appRelevantDataContextValue.onChangeChartParamType(PM25)
            } else if(modifiedState.relevantModelInfo.includes(PM1)){
                appRelevantDataContextValue.onChangeChartParamType(PM1)
            } else if(modifiedState.relevantModelInfo.includes(PM10)){
                appRelevantDataContextValue.onChangeChartParamType(PM10)
            } else if(modifiedState.relevantModelInfo.includes(PFC)){
                appRelevantDataContextValue.onChangeChartParamType(PFC)
            } else if(modifiedState.relevantModelInfo.includes(WLIP)){
                appRelevantDataContextValue.onChangeChartParamType(WLIP)
            } else if(modifiedState.relevantModelInfo.includes(SLIP)){
                appRelevantDataContextValue.onChangeChartParamType(SLIP);
            } else {
                appRelevantDataContextValue.onChangeChartParamType(modifiedState.relevantModelInfo[0])
            }

            if (this.currentChart) this.currentChart.destroy();
            let latestChartObj = new Chart(myChartRef, {               
                type: "bar",
                options: objChartOptions,
                
                data: {
                    //Bring in data
                    labels: chartDataFmtLogTime,
                    datasets: arrChartDatasets,
                    arrChartDataOrgLogTime: chartDataOrgLogTime
                },
            });

            this.currentChart = latestChartObj;

            // JCS Chart Click: START

            (myChartRef.canvas).onclick = function(evt) {

                const points = latestChartObj.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

                if(points != null && points.length > 0) {
                 
                    var activePoint = points[0];

                    if(activePoint != null && Object.keys(activePoint).length > 0 &&
                        activePoint.index != null &&
                        latestChartObj != null && Object.keys(latestChartObj).length > 0 &&
                        latestChartObj.config != null && Object.keys(latestChartObj.config).length > 0 &&
                        latestChartObj.config._config != null && Object.keys(latestChartObj.config._config).length > 0 &&
                        latestChartObj.config._config.data != null && Object.keys(latestChartObj.config._config.data).length > 0 &&
                        latestChartObj.config._config.data.arrChartDataOrgLogTime != null && latestChartObj.config._config.data.arrChartDataOrgLogTime.length > 0 &&
                        (
                            modifiedState.periodToView == "Hourly" || modifiedState.periodToView == "Daily" || 
                            modifiedState.periodToView == "Weekly" || modifiedState.periodToView == "Monthly"
                        ) && deviceType != STLI &&
                        !(
                            (modifiedState.selectedParamType == DLV || modifiedState.selectedParamType == WS ) &&
                            (modifiedState.periodToView == "Hourly" )
                        )
                    ) {
                        let confirmationMsgToBeShown = t(IDS_DrillDown);

                        if(window.confirm(confirmationMsgToBeShown)) {
                            // alert(`The data arised on (For Line and Bar): ${activePoint._chart.tooltip._data.labels[activePoint._index]}, ${chartRangeStartDate}, ${chartRangeEndDate}}`);

                            // This is will give the actual x-axis label which is showing on the UI. (FormattedDate);
                            // let ClickedDate = activePoint._chart.tooltip._data.labels[activePoint._index];

                            // here we are extracting a original date from array.
                            let drillDownStartDtTm = new Date(latestChartObj.config._config.data.arrChartDataOrgLogTime[activePoint.index]);
                            let drillDownEndDtTm = new Date(latestChartObj.config._config.data.arrChartDataOrgLogTime[activePoint.index]);

                            // here we are changing period on drilldown.
                            let periodToShowOnDrillDown = "";

                            if(modifiedState.periodToView == "Hourly") {

                                // Incase of hourly we are showing him a 60 Datapoints (avg by per minute) which are included in a specific one hour.
                                // If a user wants to drill down a particular datapoint from the 60 datapoints, 
                                // so he will be able to see all the datapoint which are included within one minute.
                                // So, here we are explicitly setting PTV to the Minute. 
                                // The Start date-time will be initiated at the very first seconds of that minute i.e. "Aug-12 02:05:00" 
                                // and the End date-time will be restricted to the very last second of that minute i.e. "Aug-12 02:05:59".

                                periodToShowOnDrillDown = "Minute";

                                drillDownStartDtTm.setSeconds(0);  

                                drillDownEndDtTm.setSeconds(59);

                            } else if(modifiedState.periodToView == "Daily") {

                                // Incase of Daily we are showing him a 24 Datapoints (avg by per Hour) which are included in a specific one Day.
                                // If a user want to drill down a particular datapoint from the 24 datapoints, 
                                // so he will be able to see all the datapoint which are included within one Hour.
                                // So, here we are explicitly setting PTV to the Hourly. 
                                // The Start date-time will initiate at the very first seconds of the first minute of that selected Hour. i.e. "Aug-12 02:00:00" 
                                // and The End date-time will be restricted to the very last second of the last minute of that selected Hour. i.e. "Aug-12 02:59:59".
                                
                                periodToShowOnDrillDown = "Hourly";

                                drillDownStartDtTm.setMinutes(0);
                                drillDownStartDtTm.setSeconds(0);  

                                drillDownEndDtTm.setMinutes(59);
                                drillDownEndDtTm.setSeconds(59);

                            } else if(modifiedState.periodToView == "Weekly" || modifiedState.periodToView == "Monthly") {

                                // Incase of Weekly and Monthly we are showing him a 7 and 30 Datapoints (avg by per Day) respectively.
                                // If a user want to drill down a particular datapoint from the 7 or 30 datapoints, 
                                // so he will be able to see all the datapoint which are included within one Day.
                                // So, here we are explicitly setting PTV to the Daily. 
                                // The Start date-time will initiate at the very first seconds of the first minute of the first hour of that selected Day. i.e. "Aug-12 00:00:00" 
                                // and The End date-time will be restricted to the very last second of the last minute of the last hour that selected Day. i.e. "Aug-12 23:59:59".

                                periodToShowOnDrillDown = "Daily";

                                drillDownStartDtTm.setHours(0);
                                drillDownStartDtTm.setMinutes(0);
                                drillDownStartDtTm.setSeconds(0);  
                            
                                drillDownEndDtTm.setHours(23);
                                drillDownEndDtTm.setMinutes(59);
                                drillDownEndDtTm.setSeconds(59);

                            } else {
                                // No need to do anything.
                            }

                            let currentDate = new Date();

                            // Restrict the endDtTm to the currentDtTm if it is greater than the currentDtTm.
                            if(drillDownEndDtTm > currentDate) {
                                drillDownEndDtTm = currentDate;
                            } else {
                                // No need to do anything.
                            }

                            // Store the changed values in a context that will automatically trigger setState 
                            // And, It will reflect expected changes in the UI by storing the context values in the State.
                            appRelevantDataContextValue.onChangeChartRelatedContext(
                                modifiedState.selectedParamType, 
                                periodToShowOnDrillDown, true, 
                                drillDownStartDtTm, drillDownEndDtTm
                            );

                        } else {
                            // No need to do anything.
                        }

                    } else {
                        // no further processing required.
                    }
                }
            };

            // JCS Chart Click: END

            this.setState(modifiedState);

        } else {
            if (response.data.code == 'REQ_PARAMS_MISSING') {
                // Let the user know that the Required parameters were not sent to the Server
                modifiedState.errors = t(IDS_AUSrvrIssueReqParamsNotSent);
            } else if (response.data.code == 'SQL_ERROR') {
                // Tell the user that Server is experiencing errors
                modifiedState.errors = t(IDS_LoginServerIssue);
            } else if (response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                // Tell the user that Server is experiencing errors
                modifiedState.errors = t(IDS_LoginServerIssue);
            } else if (response.data.code == 'DEVICE_IS_BLOCKED') {
                modifiedState.isDeviceBlocked = true;
            } else {
                console.log('Should not reach here');
                modifiedState.errors = t(IDS_LoginServerIssue);
            }
            this.setState(modifiedState);
        }})
        .catch(error => {
            appRelevantDataContextValue.onChangeProcessingReq(false);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                console.log(error);
            }
        })
    }

    componentDidUpdate(prevProps, prevState) { 

        let currentState = this.state;
        let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

        if( appRelevantDataContextValue == null ||
            ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.loggedInUserInfo == null ||
            ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
            appRelevantDataContextValue.loggedInUserInfo.userID == null ||
            appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
            ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.selectedNodeInfo == null ||
            ("nodeID" in appRelevantDataContextValue.selectedNodeInfo) == false ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID == null ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID.length <= 0
        ) {
            console.error("ChartData: componentDidUpdate - Should not happen. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
            return; // Do not process further
        }

        if( currentState.LoggedInUserID != appRelevantDataContextValue.loggedInUserInfo.userID ||
            // currentState.selectedTreeNodeID != appRelevantDataContextValue.selectedNodeInfo.nodeID ||
            currentState.selectedParamType != appRelevantDataContextValue.chartData.chartParamType ||
            currentState.periodToView != appRelevantDataContextValue.chartData.chartPeriodToView ||
            currentState.chartGraphView != appRelevantDataContextValue.chartData.chartGraphToView ||
            currentState.isCustomizedDateTimeChecked != appRelevantDataContextValue.chartData.isCustomizedDateTimeChecked 
            // currentState.languageToViewIn != appRelevantDataContextValue.language.languageToViewIn
        ) {

            // if(currentState.periodToView != appRelevantDataContextValue.chartData.chartPeriodToView && currentState.isCustomizedDateTimeChecked) {
            //     this.periodToViewAfterIsCheck();
            // }

            // The selected tree node has changed. Update the state based on the new selected node.
            // this.getLatestChartData(appRelevantDataContextValue);
            this.getLatestChartData();
        } else if(currentState.selectedTreeNodeID != appRelevantDataContextValue.selectedNodeInfo.nodeID ||
                    currentState.languageToViewIn != appRelevantDataContextValue.language.languageToViewIn
        ) {
            // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
            appRelevantDataContextValue.onChangeProcessingReq(true); 

            // On node change and language change, bring subscription info for the selected NodeID and then bring the Latest chart data.
            this.getCurrentSubscriptionInfoAndChartData();
        } else {
            // No need get chart data.
        }
    }

    onChangeChartParamType = (value) => {

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;    

        let chartParamType = this.getMeasuredPramName(value, t);        
        // let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext
        // let onChangeChartParamType = appRelevantDataContextValue.onChangeChartParamType;
        // onChangeChartParamType(chartParamType);

        let currState = this.state;
        // if (currState.selectedDeviceType == STLI) {}

        const isParamBlocked = this.renderChartParamType();
        
        if(!isParamBlocked) {
            appRelevantDataContextValue.onChangeChartRelatedContext(
                chartParamType, appRelevantDataContextValue.chartData.chartPeriodToView, false, null, null);   
        } else {
            // Save into the App Context any current Date changes that might have happened on the UI
            // before the Chart Param type was changed
            appRelevantDataContextValue.onChangeChartRelatedContext(
                chartParamType, 
                appRelevantDataContextValue.chartData.chartPeriodToView, appRelevantDataContextValue.chartData.isCustomizedDateTimeChecked, 
                currState.StartDateTime, currState.EndDateTime);
        }
    }

    onChangeChartPeriodToView = (e) => {
        let chartPeriodToView = e

        // let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

        // let onChangeChartPeriodToView = appRelevantDataContextValue.onChangeChartPeriodToView;
        // onChangeChartPeriodToView(chartPeriodToView);

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

        let currState = this.state;

        let lobjPeriodStartDateTime = null; // If checkbox is unchecked
        let lobjPeriodEndDateTime = null;   // If checkbox is unchecked
        let isCustomizeChartPeriodChecked = appRelevantDataContextValue.chartData.isCustomizedDateTimeChecked;

        if(isCustomizeChartPeriodChecked) {
            lobjPeriodEndDateTime = currState.EndDateTime;
            // Calculate start date time based on Period
            lobjPeriodStartDateTime = this.getStartDateBasedOnEndDateTimeAndPeriodToView(chartPeriodToView, lobjPeriodEndDateTime);
        }

        // Save into the App Context any current Date changes that might have happened on the UI
        // before the Chart Period to view was changed
        appRelevantDataContextValue.onChangeChartRelatedContext(
            appRelevantDataContextValue.chartData.chartParamType, 
            chartPeriodToView, isCustomizeChartPeriodChecked, lobjPeriodStartDateTime, lobjPeriodEndDateTime);
    }

    onChangeChartGraphView = (e) => {

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let chartGraphView = e
        appRelevantDataContextValue.onChangeChartGraphView(chartGraphView);

    }

    onChangeEndDateTime = (updatedTime) => {
        
        let modifiedState = this.state; 

        let updatedEndDateTime = updatedTime;

        modifiedState.EndDateTime = 
        updatedEndDateTime == null ? modifiedState.EndDateTime : updatedEndDateTime;

        this.setState(modifiedState);
    }

    onChangeStartDateTime = (updatedTime) => {
        let modifiedState = this.state;
        let updatedActiveStartDateTime = updatedTime;

        modifiedState.StartDateTime = updatedActiveStartDateTime;
        this.setState(modifiedState);
    }


    onEndDateTimeCalendarOrClockClose = () => {
        let strMsg = "";
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;

        let modifiedState = this.state;
        let periodToView = modifiedState.periodToView

        this.setState( 
            prevState => {

                let updatedEndDateTime = prevState.EndDateTime;
                let minRequiredStartDateTime = new Date( updatedEndDateTime.valueOf() );

                // Based on the currently set EndDateTime, calculate the minimum required EndDateTime as respect to the Period To View.
                if(periodToView == 'Minute') {

                    minRequiredStartDateTime.setMinutes(minRequiredStartDateTime.getMinutes() - 1);

                } else if(periodToView == 'Hourly') {

                    minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 1);

                } else if(periodToView == 'Daily') {

                    minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

                } else if(periodToView == 'Weekly') {

                    minRequiredStartDateTime.setDate(minRequiredStartDateTime.getDate() - 6);

                } else if(periodToView == 'Monthly') {

                    minRequiredStartDateTime.setDate(minRequiredStartDateTime.getDate() - 30);
                }

                let currentlySetStartDateTime = prevState.StartDateTime;

                let diffBetweenCurrentlyAndMinRequiredTime = currentlySetStartDateTime - minRequiredStartDateTime;                
                
                // Give a tolerance of 1 minutes to the current date time to avoid unnecessary messages
                let currentDateTime = new Date();
                // currentDateTime.setMinutes(currentDateTime.getMinutes() + 1);

                // console.log("updatedEndDateTime: ", updatedEndDateTime);
                // console.log("minRequiredStartDateTime: ", minRequiredStartDateTime);
                // console.log("currentlySetStartDateTime: ", currentlySetStartDateTime);
                let modifiedState = prevState;

                if(updatedEndDateTime != null && (updatedEndDateTime > currentDateTime)) {

                    // strMsg = 'End Date Time cannot be greater than the current date time.' + 
                    //         '\nIt will be set to previous date time. Click Refresh.'

                    strMsg = t(IDS_ErrMsgEndTimeIsGreaterThanCurrentTime);

                    modifiedState.errors = strMsg;

                    modifiedState.EndDateTime = appRelevantDataContextValue.chartData.chartEndDateTime;
                    modifiedState.StartDateTime = appRelevantDataContextValue.chartData.chartStartDateTime;

                    appRelevantDataContextValue.onChangeChartPeriodToViewWRTDateTime(modifiedState.StartDateTime, modifiedState.EndDateTime);

                } else if (updatedEndDateTime <= currentlySetStartDateTime || currentlySetStartDateTime < minRequiredStartDateTime) {

                    // The below will be used for equal-to comparison (as the dates might have millisecond components which might differ)
                    let strUpdatedEndDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(updatedEndDateTime.valueOf()) );
                    let strCurrentlySetStartDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime.valueOf()) );

                    if( periodToView == 'Daily' && strUpdatedEndDateTime == strCurrentlySetStartDateTime ) {

                        // Special condition in the case of Daily selection where the user wants to type out a period
                        // within the current day

                        // strMsg = `End Date Time will be adjusted because it is not within a Range according to that of [${periodToView}] Period to View.`+
                        // '\nThe time will be adjusted to max possible for the day. Click Refresh.';

                        // strMsg = t(IDS_ErrMsgEndDtNotInRange, periodToView);
                        strMsg = t(IDS_ErrMsgEndDtNotInRange, this.getPeriodToView(periodToView, t));

                        modifiedState.StartDateTime = prevState.StartDateTime;
                        modifiedState.EndDateTime = new Date( prevState.EndDateTime.valueOf() );
                        modifiedState.EndDateTime.setHours(23);
                        modifiedState.EndDateTime.setMinutes(59);
                        modifiedState.EndDateTime.setSeconds(59);

                        if(modifiedState.EndDateTime > currentDateTime) {
                            modifiedState.EndDateTime = currentDateTime;
                        }

                    } else {
                        // strMsg = `Start Date Time will be adjusted because it is not within a Range according to that of [${periodToView}] Period to View.`+
                        // '\nIt will be set according to End Date Time. Click Refresh.'

                        strMsg = t(IDS_ErrMsgStrtDtNotInRange, this.getPeriodToView(periodToView, t));

                        modifiedState.StartDateTime =  minRequiredStartDateTime;
                    }

                    // alert(strMsg);
                    modifiedState.errors = strMsg;

                    appRelevantDataContextValue.onChangeChartPeriodToViewWRTDateTime(modifiedState.StartDateTime, modifiedState.EndDateTime);

                } else {
                    strMsg = "";
                    modifiedState.errors = strMsg;
                    appRelevantDataContextValue.onChangeChartPeriodToViewWRTDateTime(modifiedState.StartDateTime, modifiedState.EndDateTime);
                }

                return modifiedState;
            },
        );
    }
    
    periodToViewAfterIsCheck = () => {

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

        let periodToView = appRelevantDataContextValue.chartData.chartPeriodToView;
        modifiedState.isCustomizedDateTimeChecked = appRelevantDataContextValue.chartData.isCustomizedDateTimeChecked;
        
        // modifiedState.EndDateTime = new Date();
        modifiedState.EndDateTime = appRelevantDataContextValue.chartData.chartEndDateTime;
        
        // Set the ViewEndDateTime wrt ViewStartDateTime
        if(periodToView == 'Minute') {
            let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
            viewEndDateTime.setMinutes(viewEndDateTime.getMinutes() - 1);
            modifiedState.StartDateTime = viewEndDateTime;

        } else if(periodToView == 'Hourly') {
            let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
            viewEndDateTime.setHours(viewEndDateTime.getHours() - 1);
            modifiedState.StartDateTime = viewEndDateTime;

        } else if(periodToView == 'Daily') {
            let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
            viewEndDateTime.setHours(viewEndDateTime.getHours() - 24);
            modifiedState.StartDateTime = viewEndDateTime;        

        } else if(periodToView == 'Weekly') {
            let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
            viewEndDateTime.setDate(viewEndDateTime.getDate() - 6);
            modifiedState.StartDateTime = viewEndDateTime;        
        } else if(periodToView == 'Monthly') {
            let viewEndDateTime = new Date( modifiedState.EndDateTime.valueOf() );
            viewEndDateTime.setDate(viewEndDateTime.getDate() - 30);
            modifiedState.StartDateTime = viewEndDateTime;
        }

        this.setState(modifiedState);
        appRelevantDataContextValue.onChangeChartPeriodToViewWRTDateTime(modifiedState.StartDateTime, modifiedState.EndDateTime);
    }

    onStartDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;
        let strMsg = '';

        let modifiedState = this.state;
        let periodToView = modifiedState.periodToView;

        this.setState( 
            prevState => {

                let currentlySetEndDateTime = prevState.EndDateTime;
                let minRequiredStartDateTime = new Date( currentlySetEndDateTime.valueOf() );

                // Based on the currently set EndDateTime, calculate the minimum required EndDateTime with respect to Period to View.
                if(periodToView == "Minute") {
                    minRequiredStartDateTime.setMinutes(minRequiredStartDateTime.getMinutes() - 1);

                } else if(periodToView == "Hourly") {
                    minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 1);

                } else if(periodToView == "Daily") {
                    minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

                } else if(periodToView == "Weekly") {
                    minRequiredStartDateTime.setDate(minRequiredStartDateTime.getDate() - 6);

                } else if(periodToView == "Monthly") {
                    minRequiredStartDateTime.setDate(minRequiredStartDateTime.getDate() - 30);
                    
                }

                let updatedStartDateTime = prevState.StartDateTime;
                // let diffBetweenUpdatedAndMinRequiredTime = updatedStartDateTime - minRequiredStartDateTime;
                let modifiedState = prevState;

                let currentDateTime = new Date();
                // Give a tolerance of -1 minutes to the current date time to avoid unnecessary messages
                // currentDateTime.setMinutes(currentDateTime.getMinutes() - 1)                

                // The below will be used for equal-to comparison (as the dates might have millisecond components which might differ)
                let strUpdatedStartDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(updatedStartDateTime.valueOf()) );
                let strCurrentlySetEndDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime.valueOf()) );

                // console.log("currentDateTime: ", currentDateTime);
                if ( updatedStartDateTime != null && (updatedStartDateTime >= currentDateTime) ) {

                    strMsg = t(IDS_ErrMsgStartTimeIsGreaterThanCurrentTime);
                    // alert(strMsg);
                    modifiedState.errors = strMsg;
                    
                    modifiedState.StartDateTime = appRelevantDataContextValue.chartData.chartStartDateTime;
                    appRelevantDataContextValue.onChangeChartPeriodToViewWRTDateTime(modifiedState.StartDateTime, modifiedState.EndDateTime);

                } else if( updatedStartDateTime < minRequiredStartDateTime || updatedStartDateTime >= currentlySetEndDateTime || strUpdatedStartDateTime == strCurrentlySetEndDateTime ) {

                    if( periodToView == 'Daily' && strUpdatedStartDateTime == strCurrentlySetEndDateTime ) {

                        // Special condition in the case of Daily selection where the user wants to type out a period
                        // within the current day

                        // strMsg = `Start Date Time will be adjusted because it is not within a Range according to that of [${periodToView}] Period to View.`+
                        // '\nThe time will be adjusted to minimum possible for the day. Click Refresh.';

                        strMsg = t(IDS_ErrMsgStrtDtMinPossibleForDay, this.getPeriodToView(periodToView, t));
                        
                        modifiedState.errors = strMsg;

                        modifiedState.EndDateTime = prevState.EndDateTime;
                        modifiedState.StartDateTime = new Date( prevState.StartDateTime.valueOf() );
                        modifiedState.StartDateTime.setHours(0);
                        modifiedState.StartDateTime.setMinutes(0);
                        modifiedState.StartDateTime.setSeconds(0);

                    } else {

                        // strMsg = `End Date Time has been adjusted because it is not within a Range according to that of [${periodToView}] Period to View. Click Refresh.`;
                        strMsg = t(IDS_ErrMsgEndDtAdjustedBcozNotInRange, this.getPeriodToView(periodToView, t));

                        // alert(strMsg);
                        modifiedState.errors = strMsg;
                        
                        let updatedEndtDateTime = new Date( updatedStartDateTime.valueOf() );

                        if(periodToView == "Minute") {
                            updatedEndtDateTime.setMinutes(updatedEndtDateTime.getMinutes() + 1);
        
                        } else if(periodToView == "Hourly") {
                            updatedEndtDateTime.setHours(updatedEndtDateTime.getHours() + 1);
        
                        } else if(periodToView == "Daily") {
                            updatedEndtDateTime.setHours(updatedEndtDateTime.getHours() + 24);
        
                        } else if(periodToView == "Weekly") {
                            updatedEndtDateTime.setDate(updatedEndtDateTime.getDate() + 6);
        
                        } else if(periodToView == "Monthly") {
                            updatedEndtDateTime.setDate(updatedEndtDateTime.getDate() + 30);
                            
                        }
                        
                        modifiedState.EndDateTime = updatedEndtDateTime;
                        
                        if(modifiedState.EndDateTime > currentDateTime) {
                            modifiedState.EndDateTime = appRelevantDataContextValue.chartData.chartEndDateTime;
                            modifiedState.StartDateTime = appRelevantDataContextValue.chartData.chartStartDateTime;
                        }

                    }

                    appRelevantDataContextValue.onChangeChartPeriodToViewWRTDateTime(modifiedState.StartDateTime, modifiedState.EndDateTime);
                } else {
                    strMsg = "";
                    modifiedState.errors = strMsg;
                    appRelevantDataContextValue.onChangeChartPeriodToViewWRTDateTime(modifiedState.StartDateTime, modifiedState.EndDateTime);

                }

                return modifiedState;
            },

        );
    }

    RefreshClicked = () => {

        this.setState({
            datetimeRefreshButtonClicked : !this.state.datetimeRefreshButtonClicked,
            errors: "",
        })
    }

    // Calculates and returns the start date time based on the provided value of End Date Time
    // and the period to view
    getStartDateBasedOnEndDateTimeAndPeriodToView = (inPeriodToView, inEndDateTime) => {
        
        let lobjStartDateTimeToReturn = new Date( inEndDateTime.valueOf() );

        let nPeriodDiff = inPeriodToView == 'Monthly' ? 30 :
                          inPeriodToView == 'Weekly' ? 6 :
                          inPeriodToView == 'Daily' ? 24 :
                          inPeriodToView == 'Hourly' ? 1 :
                          inPeriodToView == 'Minute' ? 1 :
                          1; // By default assume period of 1 Minute

        if(inPeriodToView == 'Monthly' || inPeriodToView == 'Weekly') {
            lobjStartDateTimeToReturn.setDate(inEndDateTime.getDate() - nPeriodDiff);
        } else if(inPeriodToView == 'Daily' || inPeriodToView == 'Hourly') {
            lobjStartDateTimeToReturn.setHours(inEndDateTime.getHours() - nPeriodDiff);
        } else {
            lobjStartDateTimeToReturn.setMinutes(inEndDateTime.getMinutes() - nPeriodDiff);
        }

        return lobjStartDateTimeToReturn;
    }

    onEnableDisablePeriodToView = (e) => {
        let isCustomizeChartPeriodChecked = e

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

        let lobjPeriodStartDateTime = null; // If checkbox is unchecked
        let lobjPeriodEndDateTime = null;   // If checkbox is unchecked

        if(isCustomizeChartPeriodChecked) {
            lobjPeriodEndDateTime = new Date(); // Current time becomes the end time
            // Calculate start date time based on Period
            lobjPeriodStartDateTime = this.getStartDateBasedOnEndDateTimeAndPeriodToView(
                                        appRelevantDataContextValue.chartData.chartPeriodToView, lobjPeriodEndDateTime);
        }

        appRelevantDataContextValue.onChangeChartRelatedContext(
            appRelevantDataContextValue.chartData.chartParamType, appRelevantDataContextValue.chartData.chartPeriodToView,
            isCustomizeChartPeriodChecked, lobjPeriodStartDateTime, lobjPeriodEndDateTime);
    }

    onRefreshClicked = () => {
        let refreshIconEle = document.getElementById('chartRefreshButton');
        if (refreshIconEle) {
            refreshIconEle?.classList.add('spinn')
            setTimeout(() => {
                refreshIconEle?.classList.remove('spinn')
            }, 1000);
        }

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

        let currState = this.state;

        // Save into the App Context any current Date changes that might have happened on the UI
        // before the Refresh button was clicked
        appRelevantDataContextValue.onChangeChartRelatedContext(
            appRelevantDataContextValue.chartData.chartParamType, appRelevantDataContextValue.chartData.chartPeriodToView,
            appRelevantDataContextValue.chartData.isCustomizedDateTimeChecked, 
            currState.StartDateTime, currState.EndDateTime);

        // The above will not trigger any fresh getChartData from the server because only dates
        // have changed (we have prevented getChartData everytime the date changes unless refresh
        // button is clicked, as every small change in date's components should not trigger the same).

        // Explicitly trigger getChartData since refresh was clicked
        this.getLatestChartData();
    }

    // Called when Icon for More (Triple Dot Icon) is clicked.
    toggleFromIcon = (singleParam, singleValue) => {
        let modifiedState = this.state;
        modifiedState.arrOfRanges = [];
        modifiedState.arrOfDispRangeValues = [];
        modifiedState.modalFromIcon = !this.state.modalFromIcon;
        modifiedState.selectedParamToViewRangeDetails = singleParam;
        modifiedState.selectedParamsCurrentValue = singleValue;

        // let { styleValueText, styleValueBox } = this.getParamValueTextAndBoxStyleBasedOnRange(modifiedState.selectedParamsCurrentValue, modifiedState.selectedParamToViewRangeDetails);
        // modifiedState.styleToBeAppliedToPopup = styleValueBox;

        let arrOfRangesOfSelectedParam = modifiedState.objRecievedStdAirQualityRanges != null ? 
                                            modifiedState.objRecievedStdAirQualityRanges['DispRngSeq'] : [] ;

        if(arrOfRangesOfSelectedParam != null && arrOfRangesOfSelectedParam.length > 0){
            arrOfRangesOfSelectedParam.forEach((childSnapshot) => {
                modifiedState.arrOfRanges.push(Object.keys(childSnapshot)[0]);
                modifiedState.arrOfDispRangeValues.push(Object.values(childSnapshot)[0]);
            });
        }      
        
        for(let i=0; i<PARAM_FOR_SHOWING_IMPACTS.length; i++) {
              
            if(PARAM_FOR_SHOWING_IMPACTS[i] == modifiedState.selectedParamToViewRangeDetails) {
                modifiedState.showImpactsOfParam = true;
                break;           
            } else {
                modifiedState.showImpactsOfParam = false;
            }
        }
        
        this.setState(modifiedState);
    }

    showRangeNameAndColor = (DispRange, selectedParamToViewRangeDetails) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;           

        let retRangeName = '';
        let retRangeBgColor = '';
        let retRangeFontColor = '';
       
        switch(DispRange) {
            case DISP_LOW_SEVERE_RANGE:
                retRangeName = t(IDS_RangeLowSevere);
                retRangeBgColor = "#C00000";
                retRangeFontColor = "lightGrey";
                break;
            case DISP_LOW_VERY_POOR_RANGE:
                retRangeName = t(IDS_RangeLowVeryPoor);
                retRangeBgColor = "red";
                retRangeFontColor = "lightGrey";
                break;
            case DISP_GOOD_RANGE:
                //retRangeName = `${t(IDS_Good)} / ${t(IDS_Satisfactory)}`;
                retRangeName = t(IDS_Good);
                retRangeBgColor = "#50C878";
                retRangeFontColor = "black";
                break;
            case DISP_SATISFACTORY_RANGE:
                //retRangeName = `${t(IDS_Good)} / ${t(IDS_Satisfactory)}`;
                retRangeName = t(IDS_Satisfactory);
                retRangeBgColor = "#9acd32";
                retRangeFontColor = "black";
                break;
            case DISP_MODERATE_RANGE:
                retRangeName = t(IDS_Moderate);
                retRangeBgColor = "#FFD700";
                retRangeFontColor = "black";
                break;
            case DISP_POOR_RANGE:
                retRangeName =t(IDS_Poor);
                retRangeBgColor = "orange";
                retRangeFontColor = "black";
                break;
            case DISP_VERY_POOR_RANGE:
                retRangeName = t(IDS_RangeVeryPoor);
                retRangeBgColor = "red";
                retRangeFontColor = "lightGrey";
                break;
            case DISP_HIGH_SEVERE_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == TEMP || selectedParamToViewRangeDetails == HUM) ? t(IDS_RangeHighSevere) : (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_SevereAqi) : t(IDS_Severe);
                retRangeBgColor = "#C00000";
                retRangeFontColor = "lightGrey";
                break;
            default:
                console.error(`Unable to get DispRangeName. Unknown Range Type: ${DispRange}`);
                retRangeName = IDS_NoRange;
                retRangeBgColor = '';
                retRangeFontColor = "";
                break;
            }
        return {retRangeName: retRangeName, retRangeBgColor: retRangeBgColor, retRangeFontColor: retRangeFontColor};

    }

    getRangeDisplayName = (paramName, retRangeName) => {
        let RangeNameToShow = '';
        // let paramName = modifiedState.selectedParamToViewRangeDetails;
        let t = this.context.t; 
        if(paramName == TEMP){ 

            switch(retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ColdAndChilly);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Warm);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Hot);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 

        } else if(paramName == HUM) {

            switch(retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_Dry);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Humid);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Sticky);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            }
        } else if(paramName == NH3OD) {

            switch(retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_BarelySensedInSingleDeviceData);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_WeakOdourInSingleDeviceData);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_EasilyRecognizedInSingleDeviceData);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongOdourInSingleDeviceData);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_PungentInSingleDeviceData);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(paramName == RNFL){ 

            switch(retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_LightRain);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_ModerateRain);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_HeavyRain);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHeavyRain);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_TorrentialRain);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(paramName == NOISE){ 

            switch(retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_LowNoiseLevel);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_MediumNoiseLevel);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighNoiseLevel);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(paramName == LUX){ 

            switch(retRangeName) {
                case t(IDS_LowSevere):
                RangeNameToShow = t(IDS_LowLightIntensity);
                break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_MediumLightIntensity);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighLightIntensity);
                    break;
                default:
                    // console.log(retRangeName);
                    RangeNameToShow =  t(IDS_LowLightIntensity)
                    // RangeNameToShow = '';
                    break;
            } 
        } 
        else if(paramName == O2){ 

            switch(retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_Normal);
                break;
            case t(IDS_RangeLowSevere):
                RangeNameToShow = t(IDS_Low);
                break;
            case t(IDS_Severe):
                RangeNameToShow = t(IDS_High);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } else if(paramName == DLV){ 

            switch(retRangeName) {
            case t(IDS_Good):
                RangeNameToShow = t(IDS_GoodVisibility);
                break;
            case t(IDS_Moderate):
                RangeNameToShow = t(IDS_ModerateVisibility);
                break;
            case t(IDS_Poor):
                RangeNameToShow = t(IDS_FairVisibility);
                break;
            case t(IDS_VeryPoor):
                RangeNameToShow = t(IDS_PoorVisibility);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } 
        else if(paramName == WS){ 

            switch(retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_CalmBreeze);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_GentleBreeze);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_ModerateBreeze);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongGale);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Storm);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(paramName == CL || paramName == CH3SH){ 

            switch(retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_VeryLow);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_Poor);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Severe);
                    break;
                default:
                    console.log(retRangeName);
                    RangeNameToShow = t(IDS_Low);
                    break;
                } 
        } else if(paramName == UV){ 

            switch(retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHigh);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Extreme);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(paramName == AP){ 

            switch(retRangeName) {
              
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ExtremeLow);
                    break;
                case t(IDS_RangeLowVeryPoor):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Normal);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_ExtremeHigh);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(paramName == CH4 || paramName == H2){ 

            switch(retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_SafeRange);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_WarningRange);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_CriticalRange);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_EmergencyRange);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HazardousRange);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else {
            RangeNameToShow = retRangeName;
        }
        if(RangeNameToShow == '') {
            RangeNameToShow = retRangeName;
        }
        return RangeNameToShow;
        
    }

    showRangesForSelectedParam = (DispRange, key) => {

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 
        let RangeNameToShow = null;
        let currentLanguage = appRelevantDataContextValue.language.languageToViewIn;

        if(key.includes(ONWARDS)){
            modifiedState.highSevereStartValueInPopUp = key.split(" ")[0];
        }
        if(key.includes(UPTO)){
            modifiedState.goodEndValueInPopUp = key.split(" ")[1];
        }

        let selectedRangeInfo = this.showRangeNameAndColor(DispRange, modifiedState.selectedParamToViewRangeDetails);

        if(modifiedState.selectedParamToViewRangeDetails == TEMP){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ColdAndChilly);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Warm);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Hot);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 

        } else if(modifiedState.selectedParamToViewRangeDetails == HUM) {

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_Dry);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Humid);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Sticky);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            }
        } else if(modifiedState.selectedParamToViewRangeDetails == NH3OD) {

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_BarelySensedInSingleDeviceData);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_SatisfactoryInSingleDeviceData);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_WeakOdourInSingleDeviceData);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_EasilyRecognizedInSingleDeviceData);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongOdourInSingleDeviceData);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_PungentInSingleDeviceData);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == RNFL){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_LightRain);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_ModerateRain);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_HeavyRain);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHeavyRain);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_TorrentialRain);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == NOISE){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_LowNoiseLevel);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_MediumNoiseLevel);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighNoiseLevel);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == LUX){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_LowSevere):
                RangeNameToShow = t(IDS_LowLightIntensity);
                break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_MediumLightIntensity);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighLightIntensity);
                    break;
                default:
                    // console.log(selectedRangeInfo.retRangeName);
                    RangeNameToShow =  t(IDS_LowLightIntensity)
                    // RangeNameToShow = '';
                    break;
            } 
        } 
        else if(modifiedState.selectedParamToViewRangeDetails == O2){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_Normal);
                break;
            case t(IDS_RangeLowSevere):
                RangeNameToShow = t(IDS_Low);
                break;
            case t(IDS_Severe):
                RangeNameToShow = t(IDS_High);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == DLV){ 

            switch(selectedRangeInfo.retRangeName) {
            case t(IDS_Good):
                RangeNameToShow = t(IDS_GoodVisibility);
                break;
            case t(IDS_Moderate):
                RangeNameToShow = t(IDS_ModerateVisibility);
                break;
            case t(IDS_Poor):
                RangeNameToShow = t(IDS_FairVisibility);
                break;
            case t(IDS_VeryPoor):
                RangeNameToShow = t(IDS_PoorVisibility);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } 
        else if(modifiedState.selectedParamToViewRangeDetails == WS){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_CalmBreeze);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_GentleBreeze);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_ModerateBreeze);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongGale);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Storm);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        }
        else if(modifiedState.selectedParamToViewRangeDetails == CH4 || modifiedState.selectedParamToViewRangeDetails == H2){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_SafeRange);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_WarningRange);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_CriticalRange);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_EmergencyRange);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HazardousRange);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == CL || modifiedState.selectedParamToViewRangeDetails == CH3SH){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_VeryLow);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_Poor);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Severe);
                    break;
                default:
                    console.log(selectedRangeInfo.retRangeName);
                    RangeNameToShow = t(IDS_Low);
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == UV){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHigh);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Extreme);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == AP){ 

            switch(selectedRangeInfo.retRangeName) {
              
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ExtremeLow);
                    break;
                case t(IDS_RangeLowVeryPoor):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Normal);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_ExtremeHigh);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        }  else {
            RangeNameToShow = selectedRangeInfo.retRangeName;
        }
    
        let RangeBgColorToShow = selectedRangeInfo.retRangeBgColor;
        let RangeFontColorToShow = selectedRangeInfo.retRangeFontColor;

        let RangeInfoToShowForNH3OD = null;
        let RangeNameToShowForNH3OD = null;
        let RangeIconToShowForNH3OD = <div></div>;

        if(modifiedState.selectedParamToViewRangeDetails != null && modifiedState.selectedParamToViewRangeDetails == NH3OD){
            RangeInfoToShowForNH3OD = this.showIconAndLevelForOdour(DispRange);
            RangeNameToShowForNH3OD = RangeInfoToShowForNH3OD.retRangeName;
            RangeIconToShowForNH3OD = RangeInfoToShowForNH3OD.retRangeIcon;
        }

        return(
            <div key={key}>
                <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                    <div style={{flex:"6", textAlign: "left"}}>
                        <label className="labelForSingleDeviceDataPopup" style={{fontWeight: "bold"}}>
                            {RangeNameToShow} :
                        </label>
                    </div>

                    <div style={{flex:"1", display: RangeInfoToShowForNH3OD != null ? 'block' : 'none', marginRight:"0.5rem"}}>
                        {RangeIconToShowForNH3OD}
                    </div>
                    
                    <div style={{flex:RangeInfoToShowForNH3OD != null ? "5" : "6", textAlign: "left", height: "0%",
                                 color: RangeFontColorToShow, backgroundColor: RangeBgColorToShow}}>
                        <label className="adduser-form-label" style = {{fontSize: "0.8rem", fontWeight: "500"}}>
                            {(modifiedState.selectedParamToViewRangeDetails == NH3OD && RangeInfoToShowForNH3OD != null) ? 
                                RangeNameToShowForNH3OD
                            :
                                key.includes(ONWARDS) ?  `${modifiedState.highSevereStartValueInPopUp} ${t(IDS_Onwards)}` : 
                                (key.includes(UPTO) && currentLanguage != 'en') ? `${modifiedState.goodEndValueInPopUp} ${t(IDS_Upto)}` :  key
                                
                            }
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    showIconAndLevelForOdour = (DispRange) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;           

        let retRangeIcon = '';
        let retRangeName = '';

        switch(DispRange) {
            case DISP_GOOD_RANGE:
                retRangeName = t(IDS_AlertLevel1);
                retRangeIcon = <FaGrinBeam style={{fontSize: '1.5rem', color:"#50C878"}}/>;
                break;
            case DISP_SATISFACTORY_RANGE:
                retRangeName = t(IDS_AlertLevel2);
                retRangeIcon = <FaSmile style={{fontSize: '1.5rem', color:"#9acd32"}}/>;
                break;
            case DISP_MODERATE_RANGE:
                retRangeName = t(IDS_AlertLevel3);
                retRangeIcon = <FaMeh style={{fontSize: '1.5rem', color:"#FFD700"}}/>;
                break;
            case DISP_POOR_RANGE:
                retRangeName = t(IDS_AlertLevel4);
                retRangeIcon = <FaFrownOpen style={{fontSize: '1.5rem', color:"orange"}}/>;
                break;
            case DISP_VERY_POOR_RANGE:
                retRangeName = t(IDS_AlertLevel5);
                retRangeIcon = <FaSadTear style={{fontSize: '1.5rem', color:"red"}}/>;
                break;
            case DISP_HIGH_SEVERE_RANGE:
                retRangeName = t(IDS_AlertLevel6);
                retRangeIcon = <FaDizzy style={{fontSize: '1.5rem', color:"#C00000"}}/>;
                break;
            default:
                console.error(`Unable to get DispRangeName and Icon. Unknown Range Type: ${DispRange}`);
                retRangeName = '';
                retRangeIcon = <div></div>
                break;
            }
        return {retRangeName: retRangeName, retRangeIcon: retRangeIcon};
    }

    paramOptionsList = () => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t; 

        let filteredOptions = this.state?.parsedModelInfo?.Seq && this.state.parsedModelInfo.Seq.filter(param => !NO_CHART_PARAMS.includes(param))

        const options = filteredOptions?.map(param => ({
            label: <span>{this.getParamNameBasedOnType(param, t)}</span>,
            value: this.getParamNameBasedOnType(param, t)
        }));
        return options;
    };

    renderChartParamType = () => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let chartParamTypeInContext = appRelevantDataContextValue.chartData.chartParamType;
        let isParamBlock = false;

        let filteredArr = this.state?.parsedModelInfo && Object.entries(this.state.parsedModelInfo).filter((param) => {
            if(param[1]?.NoChart) {
                return param;
            }
        })

        filteredArr.forEach((noChartParam) => {
            if(noChartParam[0] == chartParamTypeInContext) {
                isParamBlock = true;
            }
        })

        return isParamBlock;
    }

    render() {
        const getParamNameBasedOnType = this.getParamNameBasedOnType;

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t; 

        let periodToViewInContext = appRelevantDataContextValue.chartData.chartPeriodToView;
        let isCustomizedDateTimeChecked = appRelevantDataContextValue.chartData.isCustomizedDateTimeChecked;
        let chartParamTypeInContext = appRelevantDataContextValue.chartData.chartParamType;

        let unitOfSelectedParamInRangesPopup = "";

        if(this.state.selectedParamToViewRangeDetails != null && this.state.selectedParamToViewRangeDetails.length > 0) {
            unitOfSelectedParamInRangesPopup = this.getUnitsBasedOnParamtype(this.state.selectedParamToViewRangeDetails, t);
            unitOfSelectedParamInRangesPopup = unitOfSelectedParamInRangesPopup.includes(t(IDS_PPB)) ? 
                                            "(" +t(IDS_PPB) +")" 
                                            : unitOfSelectedParamInRangesPopup.includes(t(IDS_PPM)) 
                                            ? "(" + t(IDS_PPM) + ")" 
                                            : unitOfSelectedParamInRangesPopup;
        }

        let HasSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
        let IsSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.isDevc;
        let SelectedNodeContainsChildNodes = appRelevantDataContextValue.selectedNodeInfo.containsChildNode;
        let SelectedNodeDeviceType = appRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType;
        let isParamBlock = this.renderChartParamType();

        return (
            <div>
                    <>
                        {
                            appRelevantDataContextValue.selectedNodeInfo.nodeID != NO_DEVC_FOUND_FOR_LOGGED_IN_USER && this.state.isDeviceBlocked != true &&
                                <div className='py-2 w-100'>
                                    <span className='text-left flex align-items-center gap-1' style={{minHeight: "30px"}}>
                                        <small>{this.props.fullPath}</small>
                                        <small className='selectedNodeTitle' style={{fontWeight:"600"}}>
                                            {this.state.selectedTreeNodeTitle}
                                        </small>
                                    </span>
                                </div>
                        }

                        {
                            appRelevantDataContextValue.selectedNodeInfo.nodeID != NO_DEVC_FOUND_FOR_LOGGED_IN_USER ?
                        <div className='rounded-xl py-2'
                        style={{
                            background: this.state.isDeviceBlocked == true ? 'transparent' : '#f7f9fa'
                        }}
                        >

                        {
                        (this.state.selectedTreeNodeID == null || this.state.selectedTreeNodeID.length <=0)
                        ?
                            <div className="sddSingleParam">
                                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 ">
                                        {t(IDS_InfoMsgForTreeNodeMissingInChartPg)}
                                </div>
                            </div>
                        :
                                        // (!IsSelectedNodeDevices && !SelectedNodeContainsChildNodes)
                        // ?
                        //     <div className="col-xs-12 col-sm-12 col-md-12  sddSingleParam"
                        //         style={{height:"88vh", display:"flex", justifyContent:"center", alignItems:"center",width:'66vw'}}
                        //     >
                        //         <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 offset-md-2 offset-xs-3 offset-sm-3">
                        //             <div style={{fontSize:"1.2rem", color: 'var(--secondaryColor)'}}>
                        //             {
                        //                 HasSelectedNodeDevices 
                        //                 ?
                        //                 t(IDS_InfoMsgForTreeNodeWhileChildDevicesMissingInChartPg, this.state.selectedTreeNodeTitle)
                        //                 :
                        //                 t(IDS_InfoMsgForTreeNodeWhileChildNodesMissingInChartPg, this.state.selectedTreeNodeTitle)
                        //             }
                        //             </div>  
                        //         </div>
                        //     </div>
                        // :
                        //  if the selected node itself is not the device
                        // then show default msg to the user.
                        ((!HasSelectedNodeDevices && !IsSelectedNodeDevices) || HasSelectedNodeDevices)
                        ?
                            <div className="sddSingleParam">
                                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 ">
                                    {t(IDS_DefInfoMsgForTreeNodeInChartPg, this.state.selectedTreeNodeTitle)}
                                </div>
                            </div>
                        :

                        // // if the selected node is PFC Device
                        // // then show default msg to the user.
                        // (SelectedNodeDeviceType != null && SelectedNodeDeviceType.length > 0 && SelectedNodeDeviceType == DEVICE_TYPE_PFC)
                        // ?
                        //     <div className="col-xs-12 col-sm-12 col-md-12  sddSingleParam"
                        //         style={{height:"88vh", display:"flex", justifyContent:"center", alignItems:"center",width:'66vw'}}
                        //     >
                        //         <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 offset-md-2 offset-xs-3 offset-sm-3">
                        //             <div style={{fontSize:"1.2rem", color: 'var(--secondaryColor)'}}>
                        //                 {t(IDS_InfoMsgForPFCDevicesInChartPg, this.state.selectedTreeNodeTitle)}
                        //             </div>  
                        //         </div>
                        //     </div>
                        // :


                        // If the dveice has been blocked due to non-payment if some cost.
                        (this.state.isDeviceBlocked == true)
                        ?
                        <div className="sddSingleParam">
                            <div className="w-100 flex-center" style={{height:"78vh"}}>
                                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8">
                                    {t(IDS_DevcBlockedDueToNonPaymentOfSomeCost)}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="container-fluid padding"> 
                            {(this.state.subscriptionInfo.length > 0 || this.state.subscriptionErr.length > 0 || (this.state.subNotApplicableErr != null && this.state.subNotApplicableErr.length > 0)) ? 
                                <div style={{border:"1px solid var(--primaryColor)", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.3rem", marginTop:"0.5rem", padding:"0.1rem"}}>
                                    <div style={{color:"red", fontSize:"0.8rem"}}>{this.state.subscriptionInfo}</div>
                                    {this.state.subNotApplicableErr != null && this.state.subNotApplicableErr.length > 0 && <div style={{color:"green", fontSize:"0.8rem"}}>{this.state.subNotApplicableErr}</div>}
                                    {this.state.subscriptionErr != null && this.state.subscriptionErr.length > 0 && <div style={{color:"red", fontSize:"0.8rem"}}>{this.state.subscriptionErr}</div>}
                                </div>
                                :
                                null
                            }
                            {/* Show blocked Devices Data to Production/Support Users. */}
                            { this.state.bCheckDeviceBlockedStatus == true && this.state.havingPvgToViewBlockedDeviceData == true ?
                                <div style={{color:"green", marginTop:"0.1rem", fontSize:"0.8rem"}}>Note: The Device is blocked or Rental Period is expired. Production/Support Team can view the data.</div>
                            : 
                                <div style={{color:"green", fontSize:"0.8rem"}}>{this.state.noteToShowIfApplicable.length > 0 ? this.state.noteToShowIfApplicable : ""}</div>
                            }

                            {/* <div className = "DVDeviceNameOfSD">{this.state.selectedTreeNodeTitle}</div> */}
                            <div className="py-2 d-flex flex-col gap-2 gap-md-3 ">      
                                <div className="d-flex align-items-center justify-content-start justify-content-md-center gap-4 flex-wrap">
                                    <div style={{fontSize: '1.2rem', color:'var(--primaryColor)'}}> 
                                        <Select
                                            className="parameterDropdown mySelector"
                                            value={this.state.selectedParamTypeToShow} // Assuming quickTrackParam is the state variable holding the selected value
                                            onChange={this.onChangeChartParamType} // Pass the function directly as onChange handler
                                            options={this.paramOptionsList()} // Assuming options is the array of options for the Select component
                                        />
                                    </div>
                                    
                                    <div style={{ fontSize: '1.2rem',color:'var(--primaryColor)'}}>
                                        {this.state.selectedDeviceType == STLI 
                                        ? 
                                        <Select
                                            className="chartPeriodDropdwon mySelector"
                                            value={this.state.periodToView} // Assuming quickTrackParam is the state variable holding the selected value
                                            onChange={this.onChangeChartPeriodToView} // Pass the function directly as onChange handler
                                            options={[
                                                {
                                                    label: t(IDS_MonthlyDropDown),
                                                    value: "Monthly"
                                                }
                                            ]} // Assuming options is the array of options for the Select component
                                            disabled={this.renderChartParamType()}
                                        />
                                        :
                                        this.state.selectedParamType == DLV ?  //Not working since DeviceTypeMayNotPeThere
                                        <Select
                                            className="chartPeriodDropdwon mySelector"
                                            value={this.state.periodToView} // Assuming quickTrackParam is the state variable holding the selected value
                                            onChange={this.onChangeChartPeriodToView} // Pass the function directly as onChange handler
                                            options={[
                                                {
                                                    label: t(IDS_HourlyDropDown),
                                                    value: "Hourly"
                                                },
                                                {
                                                    label: t(IDS_DailyDropDown),
                                                    value: "Daily"
                                                },
                                            ]} // Assuming options is the array of options for the Select component
                                            disabled={this.renderChartParamType()}
                                        />
                                    : this.state.selectedParamType == WS ?  //Not working since DeviceTypeMayNotPeThere
                                        <Select
                                            className="chartPeriodDropdwon mySelector"
                                            value={this.state.periodToView} // Assuming quickTrackParam is the state variable holding the selected value
                                            onChange={this.onChangeChartPeriodToView} // Pass the function directly as onChange handler
                                            options={[
                                                {
                                                    label: t(IDS_HourlyDropDown),
                                                    value: "Hourly"
                                                },
                                                {
                                                    label: t(IDS_DailyDropDown),
                                                    value: "Daily"
                                                },
                                            ]} // Assuming options is the array of options for the Select component
                                            disabled={this.renderChartParamType()}
                                        />
                                    :   <Select
                                            className="chartPeriodDropdwon mySelector"
                                            value={this.state.periodToView} // Assuming quickTrackParam is the state variable holding the selected value
                                            onChange={this.onChangeChartPeriodToView} // Pass the function directly as onChange handler
                                            options={[
                                                {
                                                    label: t(IDS_MinuteDropDown),
                                                    value: "Minute"
                                                },
                                                {
                                                    label: t(IDS_HourlyDropDown),
                                                    value: "Hourly"
                                                },
                                                {
                                                    label: t(IDS_DailyDropDown),
                                                    value: "Daily"
                                                },
                                                {
                                                    label: t(IDS_WeeklyDropDown),
                                                    value: "Weekly"
                                                },
                                                {
                                                    label: t(IDS_MonthlyDropDown),
                                                    value: "Monthly"
                                                }
                                            ]} // Assuming options is the array of options for the Select component
                                            disabled={this.renderChartParamType()}
                                        />
                                        } 
                                    </div>

                                    <div style={{ fontSize: '1.2rem',color:'var(--primaryColor)'}}>
                                        <Select
                                            className="graphTypeDropdwon mySelector"
                                            value={this.state.chartGraphView} // Assuming quickTrackParam is the state variable holding the selected value
                                            onChange={this.onChangeChartGraphView} // Pass the function directly as onChange handler
                                            options={[
                                                {
                                                    label: t(IDS_LineandBarGraph),
                                                    value: "lineAndBarGraph"
                                                },
                                                {
                                                    label: t(IDS_OnlyLineGraph),
                                                    value: "onlyLineGraph"
                                                },
                                                {
                                                    label: t(IDS_OnlyBarGraph),
                                                    value: "onlyBarGraph"
                                                },
                                            ]}
                                            disabled={this.renderChartParamType()}
                                        />
                                    </div>

                                    <div className="d-flex align-items-center justify-content-center">
                                        <span>{t(IDS_SelectDiffPeriodToView)}: &nbsp;</span>
                                        <Switch 
                                            className='filterPeriodToggle'
                                            checked={this.state.isCustomizedDateTimeChecked}
                                            onChange={this.onEnableDisablePeriodToView}
                                            checkedChildren={<IoCheckmark />} 
                                            unCheckedChildren={<RxCross2 className='antdSwitchCrossBtn'/>} 
                                            disabled={this.renderChartParamType()}
                                        />                                        
                                    </div>
                                </div>

                                {/* <div className="w-100 "> */}
                                    { this.state.isCustomizedDateTimeChecked == true 
                                        ?  
                                            <div className="d-flex align-items-center justify-content-start justify-content-md-center gap-3  flex-wrap ">
                                                <div className="flex align-items-center gap-2">
                                                    <div style={{fontSize:"0.9rem", color: "#666666"}}>{t(IDS_StartDateTime)}: </div>
                                                    <div style={{fontSize: "0.9rem", marginRight: "1rem", marginLeft: "-1.6rem"}}>
                                                        <DateTimePicker
                                                            clearIcon={null}
                                                            onChange={this.onChangeStartDateTime}
                                                            onCalendarClose={this.onStartDateTimeCalendarOrClockClose}
                                                            onClockClose={this.onStartDateTimeCalendarOrClockClose}
                                                            className="input-form-datetime"
                                                            format={"yyyy/MM/dd HH:mm:ss"} 
                                                            value={this.state.StartDateTime}    
                                                            name="StartDateTime"
                                                            />
                                                    </div>
                                                </div>
                                                <div className="flex align-items-center gap-2">
                                                    <div style={{fontSize:"0.9rem", color: "#666666"}}>{t(IDS_EndDateTime)}:&nbsp; </div>
                                                    <div style={{fontSize: "0.9rem", marginRight: "1rem", marginLeft: "-1.6rem"}}>
                                                        <DateTimePicker
                                                            clearIcon={null}
                                                            selected={this.state.EndDateTime}
                                                            onChange={this.onChangeEndDateTime}
                                                            onCalendarClose={this.onEndDateTimeCalendarOrClockClose}
                                                            onClockClose={this.onEndDateTimeCalendarOrClockClose}
                                                            className="input-form-datetime"
                                                            format={"yyyy/MM/dd HH:mm:ss"} 
                                                            value={this.state.EndDateTime} 
                                                            name="EndDateTime"
                                                        />
                                                        
                                                    </div>
                                                </div>
                                                
                                                <button className='refreshButtonStyle'
                                                    onClick={this.onRefreshClicked} 
                                                    title={t(IDS_RefreshData)}
                                                >
                                                    <i className="fa fa-refresh flex-center" id='chartRefreshButton'
                                                    style={{ paddingLeft: "0", fontSize: "20px"}} 
                                                    aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        :
                                            null
                                    }
                                    
                                {/* </div>   */}
                                {this.state.errors.length > 0 && <div style={{display: "flex", fontSize: "0.8rem", color: "green", justifyContent: "center", alignItems: "center"}}>
                                    <span>{this.state.errors}</span> 
                                </div> }
                                {
                                isParamBlock == false &&
                                    <div className="flex-center" style={{fontSize: "1.2rem"}}>
                                        <div>
                                            {this.state.selectedTreeNodeTitle +" - "+ this.getParamNameBasedOnType(this.state.selectedParamType, t) +", "+ this.getPeriodToView(this.state.periodToView, t)}                 
                                        </div>
                                    </div>
                                }

                                {(isParamBlock == false && this.state.selectedDeviceType != STLI && this.state.selectedDeviceType != WLI && chartParamTypeInContext != RNFL) &&
                                    <div className="flex-center flex-wrap gap-2" style={{fontSize: "0.8rem"}}>
                                        {this.state.objRecievedStdAirQualityRanges[LOW_SEVERE_L] != null || this.state.objRecievedStdAirQualityRanges[LOW_SEVERE_U] != null 
                                            ?    
                                                <div className='mx-2'>                                        <button className="rounded-4 mx-2" style={{marginRight:"0.2rem", background: "#C00000", width:"2rem", height:"1rem", verticalAlign:"text-top"}} onClick={ () => this.toggleFromIcon(this.state.selectedParamType, this.state.singleValue)}></button>
                                                    <span className='parameterRangeTitle'>
                                                        { chartParamTypeInContext == "TEMP" ? t(IDS_ColdAndChilly) 
                                                        : chartParamTypeInContext == "HUM" ? t(IDS_Dry) 
                                                        : this.getRangeDisplayName(chartParamTypeInContext, t(IDS_LowSevere))
                                                        // : t(IDS_LowSevere)
                                                        }     
                                                    </span>           
                                                </div>
                                            : 
                                            <div/>
                                        }
                                        {this.state.objRecievedStdAirQualityRanges[LOW_V_POOR_L] != null || this.state.objRecievedStdAirQualityRanges[LOW_V_POOR_U] != null 
                                            ?    
                                                <div className='mx-2'>
                                                    <button className="rounded-4 mx-2" style={{marginRight:"0.2rem", background: "#FF0000", width:"2rem", height:"1rem", verticalAlign:"text-top"}} onClick={ () => this.toggleFromIcon(this.state.selectedParamType, this.state.singleValue)}></button>
                                                    <span className='parameterRangeTitle'>
                                                        {this.getRangeDisplayName(chartParamTypeInContext, t(IDS_RangeLowVeryPoor))}
                                                    {/* {t(IDS_RangeLowVeryPoor)} */}
                                                    </span>           
                                                </div>
                                            : 
                                            <div/>
                                        }                           
                                        {this.state.objRecievedStdAirQualityRanges[GOOD_L] != null || this.state.objRecievedStdAirQualityRanges[GOOD_U] != null 
                                            ? 
                                                <div className='mx-2'>
                                                    <button className="rounded-4 mx-2" style={{marginRight:"0.2rem", background: "#00b050", width:"2rem", height:"1rem", verticalAlign: "text-top"}} onClick={ () => this.toggleFromIcon(this.state.selectedParamType, this.state.singleValue)}></button>
                                                    <span className='parameterRangeTitle'>
                                                    { this.state.selectedParamType == NH3OD ? "L1" 
                                                    : chartParamTypeInContext == "TEMP" ? t(IDS_Comfortable) 
                                                    : chartParamTypeInContext == "HUM" ? t(IDS_Comfortable) 
                                                    : this.getRangeDisplayName(chartParamTypeInContext, t(IDS_Good))
                                                    //: t(IDS_Good)
                                                    }                      
                                                    </span>                     
                                                </div>
                                            : 
                                                <div/>      
                                        }
                                        {this.state.objRecievedStdAirQualityRanges[SATISFACTORY_L] != null || this.state.objRecievedStdAirQualityRanges[SATISFACTORY_U] != null 
                                            ? 
                                                <div className='mx-2'>
                                                    <button className="rounded-4 mx-2" style={{marginRight:"0.2rem", background: "#9acd32", width:"2rem", height:"1rem", verticalAlign: "text-top"}} onClick={ () => this.toggleFromIcon(this.state.selectedParamType, this.state.singleValue)}></button>
                                                    <span className='parameterRangeTitle'>
                                                    { this.state.selectedParamType == NH3OD ? "L2" 
                                                    : chartParamTypeInContext == "TEMP" ? t(IDS_Comfortable) 
                                                    : chartParamTypeInContext == "HUM" ? t(IDS_Comfortable) 
                                                    : this.getRangeDisplayName(chartParamTypeInContext, t(IDS_Satisfactory))
                                                    // : t(IDS_Satisfactory)
                                                    }                      
                                                    </span>                 
                                                </div>
                                            : 
                                                <div/>      
                                        }

                                        {this.state.objRecievedStdAirQualityRanges[MODERATE_L] != null || this.state.objRecievedStdAirQualityRanges[MODERATE_L] != null 
                                            ?
                                                <div className='mx-2'>
                                                    <button className="rounded-4 mx-2" style={{marginRight:"0.2rem", background: "#FFD700", width:"2rem", height:"1rem", verticalAlign: "text-top"}} onClick={ () => this.toggleFromIcon(this.state.selectedParamType, this.state.singleValue)}></button>
                                                    <span className='parameterRangeTitle'>
                                                        {this.state.selectedParamType == NH3OD ? "L3" : 
                                                        this.getRangeDisplayName(chartParamTypeInContext, t(IDS_Moderate))}
                                                        {/* t(IDS_Moderate)} */}
                                                    </span>
                                                </div>
                                            :   
                                                <div/>
                                        }

                                        {this.state.objRecievedStdAirQualityRanges[POOR_L] != null || this.state.objRecievedStdAirQualityRanges[POOR_U] != null 
                                            ?     
                                                <div className='mx-2'>                               
                                                    <button className="rounded-4 mx-2" style={{marginRight:"0.2rem", background: "orange", width:"2rem", height:"1rem", verticalAlign: "text-top"}} onClick={ () => this.toggleFromIcon(this.state.selectedParamType, this.state.singleValue)}></button>
                                                    <span className='parameterRangeTitle'>
                                                    { this.state.selectedParamType == NH3OD ? "L4" 
                                                    : chartParamTypeInContext == "TEMP" ? t(IDS_Warm) 
                                                    : chartParamTypeInContext == "HUM" ? t(IDS_Humid) 
                                                    : chartParamTypeInContext == "AQI" || chartParamTypeInContext == "CAQI" ? t(IDS_PoorAqi) 
                                                    : this.getRangeDisplayName(chartParamTypeInContext, t(IDS_Poor))
                                                    // : t(IDS_Poor)
                                                    }                 
                                                    </span>              
                                                </div>
                                            :
                                                <div/>
                                        }

                                        {this.state.objRecievedStdAirQualityRanges[V_POOR_L] != null || this.state.objRecievedStdAirQualityRanges[V_POOR_U] != null 
                                            ?
                                                <div className='mx-2'>
                                                    <button className="rounded-4 mx-2" style={{marginRight:"0.2rem", background: "red", width:"2rem", height:"1rem", verticalAlign: "text-top"}} onClick={ () => this.toggleFromIcon(this.state.selectedParamType, this.state.singleValue)}></button>
                                                    <span className='parameterRangeTitle'>
                                                    { this.state.selectedParamType == NH3OD ? "L5" 
                                                    : this.state.selectedParamType == "AQI" || this.state.selectedParamType == "CAQI" ? t(IDS_VeryPoorAqi) 
                                                    : this.getRangeDisplayName(chartParamTypeInContext, t(IDS_VeryPoor))}                  
                                                    {/* : t(IDS_VeryPoor)}                     */}
                                                    </span>                  
                                                </div>
                                            : 
                                                <div/>
                                        }

                                        {this.state.objRecievedStdAirQualityRanges[HIGH_SEVERE_L] != null || this.state.objRecievedStdAirQualityRanges[HIGH_SEVERE_U] != null 
                                            ? 
                                                <div className='mx-2'>
                                                    <button className="rounded-4 mx-2" style={{marginRight:"0.2rem", background: "#C00000", width:"2rem", height:"1rem", verticalAlign: "text-top"}} onClick={ () => this.toggleFromIcon(this.state.selectedParamType, this.state.singleValue)}></button>
                                                    <span className='parameterRangeTitle'>
                                                    { this.state.selectedParamType == NH3OD ? "L6" 
                                                    : chartParamTypeInContext == "TEMP" ? t(IDS_Hot)
                                                    : chartParamTypeInContext == "HUM" ? t(IDS_Sticky) 
                                                    : chartParamTypeInContext == "AQI" || chartParamTypeInContext == "CAQI" ? t(IDS_SevereAqi) 
                                                    : this.getRangeDisplayName(chartParamTypeInContext, t(IDS_Severe))
                                                    // : t(IDS_Severe)
                                                    }                     
                                                    </span>            
                                                </div>
                                            : 
                                                <div/>
                                        }  
                                    </div>
                                }
                            </div>
                            {this.state.modalFromIcon == true &&
                                <VcDetailsAndRanges arrOfRanges={this.state.arrOfRanges} arrOfDispRangeValues={this.state.arrOfDispRangeValues} selectedParamToViewRangeDetails={this.state.selectedParamToViewRangeDetails}
                                    styleToBeAppliedToPopup={this.state.styleToBeAppliedToPopup}  
                                    selectedParamsCurrentValue={this.state.selectedParamsCurrentValue} modalFromIcon={this.state.modalFromIcon} showImpactsOfParam={this.state.showImpactsOfParam}
                                    backdrop={this.state.backdrop} toggleModal={this.toggleFromIcon} unitOfSelectedParamInRangesPopup={unitOfSelectedParamInRangesPopup} selectedParamType={this.state.selectedParamType}
                                    invokedFrom={"charts"}/>      
                            }
                    {/* <div className="modal-overlay" style={ this.state.modalFromIcon ? { position: "fixed"} : {} }>
                                <Modal className="rounded-4" size="l" isOpen={this.state.modalFromIcon} toggle={this.toggleFromIcon} backdrop={this.state.backdrop}>
                                <ModalHeader toggle={this.toggleFromIcon}>{t(IDS_Legends_And_Range_Of_Sensore)}</ModalHeader>
                                <ModalBody >
                                    <div >
                                        <div style={{display: "flex", marginTop:"-0.5rem", marginBottom:"-1.2rem"}} >
                                            <div style={{flex:"1", textAlign: "center"}}>
                                                <label style={{fontWeight: "bold", color: "black", fontSize:"1.2rem"}}>
                                                    {this.getParamNameBasedOnType(this.state.selectedParamType, t)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                        <div className="boxForRangesForSensors rounded-3 mx-3 mt-4 px-4 pb-4">
                                        <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                            <div style={{flex:"1", textAlign: "center", marginBottom: "0.8rem"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold", fontSize: "1.05rem"}}>
                                                            {this.getParamNameBasedOnType(this.state.selectedParamType, t)}{t(IDS_ParamsRange)} {unitOfSelectedParamInRangesPopup}
                                                </label>
                                            </div>
                                        </div>

                                        <div>
                                            {this.state.arrOfRanges.map( (DispRange, index) => {
                                                return(
                                                    this.showRangesForSelectedParam(DispRange, this.state.arrOfDispRangeValues[index])
                                                );                            
                                            })
                                            }  
                                        </div>

                                    </div>
                                </ModalBody>
                            </Modal>
                            </div> */}
                        </div>
                        }

                        {
                        isParamBlock == false ?
                            <div className='w-100 flex-center h-50 w-100'>
                                <div   
                                    className="container col-lg-10 relative " 
                                    style={{
                                        marginTop: "1.2rem", display: (
                                            (this.state.selectedTreeNodeID == null || this.state.selectedTreeNodeID.length <=0) ||
                                            (this.state.selectedTreeNodeID.length > 0 && this.state.selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER) ||
                                            (!IsSelectedNodeDevices && !SelectedNodeContainsChildNodes) ||
                                            ((!HasSelectedNodeDevices && !IsSelectedNodeDevices) || HasSelectedNodeDevices)
                                        )
                                        ? "none": "block",
                                        maxHeight:'425px'
                                    }}
                                    hidden = {this.state.isDeviceBlocked == true ? true : false}
                                >
                                    <canvas
                                    className='p-2 rounded w-100 w-md-75 bg-white'
                                    // style={{backgroundColor:"#f7f9fa"}}
                                        ref={this.chartRef}
                                    />
                                    {this.state.noDataFound != null && this.state.noDataFound.length > 0 && this.state.isDeviceBlocked == false && <span className='absolute-center py-2 px-4 bg-white ' style={{color:"green"}}>
                                            {t(IDS_NoDataFound)}
                                    </span>}
                                </div>
                            </div> :
                            <div style={{ height: "53vh" }} className="sddSingleParam">
                                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 ">
                                    {t(IDS_ParamNoChartAvailable, this.state.selectedParamTypeToShow)}
                                </div>
                            </div>
                        }
                        
                        {(isParamBlock == false && this.state.noDataFound != null && this.state.noDataFound.length > 0 && this.state.isDeviceBlocked == false) ?
                        // SelectedNodeDeviceType != null && SelectedNodeDeviceType.length > 0 && SelectedNodeDeviceType != DEVICE_TYPE_PFC &&
                            <div className="container col-lg-10">
                                <div style={{border:"1px solid var(--primaryColor)", color: "green", backgroundColor:"rgb(168,204,168,0.3)", 
                                            borderRadius:"0.3rem", marginTop:"1rem", padding:"0.1rem"}}>
                                    {this.state.noDataFound}
                                </div>
                            </div> : null
                        } 

                            </div> :
                            // (this.state.selectedTreeNodeID.length > 0 && this.state.selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER) 
                            // ?
                            <div className="sddSingleParam">
                                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 fs-5 d-flex justify-content-center align-items-center">
                                    {t(IDS_GeneralUser)}
                                    <div style={{margin:"0.5rem"}}>
                                        <VcSetupDevice/>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                
            </div>
        )
    }
}

VcChartData.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcChartData;