import React, { useState, useEffect, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import axios from 'axios';
import {FaSearch} from 'react-icons/fa';
import { AppRelevantDataContext } from '../../AppContext';
import { getLocalTimezoneOffset, convertUTCDateStringToLocaleString, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { CO, O3, LPG, CH4, CO2, HUM, NO2, VOC, PM10, PM25, TEMP, FIRE, LAT, LON, PM1, NH3, NH4, H2S, ALCO, SO2, NH3OD, NO_DEVC_FOUND_FOR_LOGGED_IN_USER, 
         SMOKE, NO_CALIB, ALERT_BASED, INCL_LOW_SEVERE_L, EXCL_LOW_SEVERE_U, INCL_GOOD_L, EXCL_GOOD_U, INCL_MODERATE_L, EXCL_MODERATE_U, INCL_POOR_L, 
         EXCL_POOR_U, INCL_V_POOR_L, EXCL_V_POOR_U, INCL_HIGH_SEVERE_L, EXCL_HIGH_SEVERE_U, LOW_SEVERE, GOOD, MODERATE, POOR, V_POOR, HIGH_SEVERE,
         MODEL_CODE_VAYUVEDH_TOI, MODEL_CODE_GANDHVEDH, PROD_DEVC_OWNER_USER_ID, WLIP, SLIP, AQI, CAQI, SATISFACTORY_L, SATISFACTORY_U, INCL_SATISFACTORY_L, EXCL_SATISFACTORY_U, SATISFACTORY, SATISFACTORY_WHEN,
         LOW_V_POOR, LOW_V_POOR_L, LOW_V_POOR_U, INCL_LOW_V_POOR_L, EXCL_LOW_V_POOR_U, LOW_V_POOR_WHEN} from '../../VcConstants';
import { LOW, LOW_SEVERE_L, LOW_SEVERE_U, GOOD_L, GOOD_U, MODERATE_L, MODERATE_U, POOR_L, POOR_U, V_POOR_L, V_POOR_U, HIGH_SEVERE_L, HIGH_SEVERE_U, MAX,
         PARAM_VALUE_MISSING, PARAM_STD_QUALITY_MISSING, UNABLE_TO_CALCULATE_RANGE_FOR_PARAMVALUE, PARAM_VALUE_LESS_THAN_LOWER_RANGE, 
         PARAM_VALUE_GREATER_THAN_UPPER_RANGE, LOW_SEVERE_WHEN, GOOD_WHEN, MODERATE_WHEN, POOR_WHEN, V_POOR_WHEN, HIGH_SEVERE_WHEN, PARAM_MODEL_INFO_MISSING, 
         MRI, VRI, DEVICE_TYPE_PFC,
         HCHO, O2, PM100, NO, CL, CH3SH, NOISE, LUX, UV, RADON, AP, WS, WD, DLV, RNFL, H2} from '../../VcConstants';
import { getAPIHostURL, getAlertLogDataRefreshTime, getLPGSingleDeviceDataRefreshTime } from '../../ClientConfig';
import { IDS_AlertMeasuredParam, IDS_AlertValue, IDS_AlertMsg, IDS_AlertMinLimitVal, IDS_AlertMaxLimitVal,
        IDS_AlertTime, IDS_Search, IDS_nitrogenDioxide, IDS_Ozone, IDS_sulphurDioxide, IDS_TVOC, IDS_carbonMonoxide,
        IDS_Ammonia, IDS_carbonDioxide, IDS_hydrogenSulphide, IDS_Methane, IDS_LPG, IDS_Dust, IDS_Temperature, 
        IDS_Humidity, IDS_Odour, IDS_AlertOdourL2, IDS_AlertOdourL3, IDS_AlertOdourL4, IDS_AlertOdourL5, 
        IDS_SnsrOdourNeedsCalli, IDS_AlertLevel1, IDS_AlertLevel2, IDS_AlertLevel3, IDS_AlertLevel4, IDS_AlertStrSever,
        IDS_AlertLevel5, IDS_AlertLevel1Value, IDS_AlertLevel2Value, IDS_AlertLevel3Value, IDS_AlertLevel4Value,
        IDS_AlertLevel5Value, IDS_PPB, IDS_PPM, IDS_Celsius, IDS_Percent, IDS_AlertMessage, IDS_AlertStrAbove, IDS_AlertStrVeryPoor,
        IDS_AlertStrBelow, IDS_SnsrNeedsCalli, IDS_RegistNetworkError, IDS_LoginServerIssue, IDS_AlertSrvcIssue,IDS_SnsrFaultyLPG,
        IDS_AlertNoAlertOnSrvr, IDS_PrevBtn, IDS_NxtBtn, IDS_AlertNoAlertToShow, IDS_TodaySearch, IDS_GeneralUser, IDS_SMOKE,
        IDS_AlertMessageLPG, IDS_AlertMessageSmoke, IDS_SnsrValueLPG, IDS_SnsrValueSmoke, IDS_RemedyAlertMsgForHighTemp, IDS_RemedyAlertMsgForHighHum,
        IDS_RemedyAlertMsgForLowTemp, IDS_RemedyAlertMsgForLowHum, IDS_RemedyAlertMsgForCo, IDS_RemedyAlertMsgForNO2, IDS_RemedyAlertMsgForO3,
        IDS_RemedyAlertMsgForCO2, IDS_RemedyAlertMsgForCO2Moderate, IDS_RemedyAlertMsgForCO2Poor, IDS_RemedyAlertMsgForVOC,IDS_RemedyAlertMsgForPM,
        IDS_RemedyAlertMsgForH2S, IDS_RemedyAlertMsgForSO2, IDS_RemedyAlertMsgForNH3OD, IDS_RemedyAlertMsgForLPG, IDS_DefInfoMsgForTreeNodeInAlertPg, 
        IDS_InfoMsgForTreeNodeWhileChildDevicesMissingInAlertPg, IDS_InfoMsgForTreeNodeWhileChildNodesMissingInAlertPg, 
        IDS_RefreshData, IDS_InfoMsgForTreeNodeMissingInAlertPg, IDS_VRI, IDS_MRI, IDS_Index, IDS_InfoMsgForPFCDevicesInAlertPg,
        IDS_RemedyAlertMsgForLowHumTOIBasedModel, IDS_RemedyAlertMsgForHighHumTOIBasedModel, 
        IDS_DevcBlockedDueToNonPaymentOfSomeCost, IDS_RemedyAlertMsgForVRI, IDS_RemedyAlertMsgForMRI, IDS_RemedyAlertMsgForLowWLIP, 
        IDS_RemedyAlertMsgForHighWLIP, IDS_WLIP, IDS_SLIP, IDS_RemedyAlertMsgForHighSLIP, IDS_airQualityIndex, IDS_currentAirQualityIndex, IDS_RemedyAlertMsgForAQI, IDS_Moderate, IDS_PoorAqi, IDS_VeryPoor, IDS_SevereAqi, IDS_VeryPoorAqi, IDS_Satisfactory, IDS_AlertLevel6Value, IDS_AlertLevel6, IDS_AlertOdourL6,
        IDS_Formaldehyde, IDS_Oxygen, IDS_PM100, IDS_NitricOxide, IDS_Chlorine, IDS_MethylMercaptan, IDS_Noise, IDS_LightIntensity, IDS_UV, IDS_Radon, IDS_AirPressure, IDS_WindSpeed, IDS_WindDirection, IDS_DaylightVisibility, IDS_Rainfall,
        IDS_RemedyAlertMsgForNewParams, param,
        IDS_RemedyAlertMsgForHCHOSatisfactory,
        IDS_RemedyAlertMsgForHCHOPoor,
        IDS_RemedyAlertMsgForHCHOVeryPoor,
        IDS_RemedyAlertMsgForNH3Satisfactory,
        IDS_RemedyAlertMsgForNH3Poor,
        IDS_RemedyAlertMsgForNH3VeryPoor,
        IDS_RemedyAlertMsgForCIModerate,
        IDS_RemedyAlertMsgForCIVeryPoor,
        IDS_RemedyAlertMsgForHCHOHighSevere,
        IDS_RemedyAlertMsgForNH3HighSevere,
        IDS_RemedyAlertMsgForCIHighSevere,
        IDS_RemedyAlertMsgForCISatisfactory,
        IDS_RemedyAlertMsgForLPGLevelNormalized,
        IDS_RemedyAlertMsgForHumTempLevelNormalized,
        IDS_IsOKAlert,
        IDS_NoGasLeakage,
        IDS_Hydrogen,} from '../../VcLanguage';
import VcSetupDevice from './VcSetupDevice';
import { Button, Select, InputNumber, Pagination } from 'antd';
import { IoMdArrowDropupCircle } from "react-icons/io";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { FaArrowDownLong } from "react-icons/fa6";
import { FaArrowUpLong } from "react-icons/fa6";
import VcLoader from './DeviceHome/Charts/VcLoader.js';
import useInterval from './useInterval.js';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, setFilter },
  }) {
    const context = useContext(AppRelevantDataContext);
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;  
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                className='searchInputBox'
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder={t(IDS_Search)}
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem 0.8rem", border:"1px solid rgba(0,0,0,.1)", borderRadius: "1rem"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, passedStateVariable }) => {

    const context = useContext(AppRelevantDataContext);
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;  
    
    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        pageCount,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // Set page size based on tables
            initialState: {pageSize: 10},
            filterTypes,
            // Add siblingCount here
            pagination: { siblingCount: 1 }
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "unset",
                    height: "2.7rem",
                    display: 'block',
                }}
            >
                <td className = "td" style={{border: "0"}}
                >
                    {NoData == true?
                    <div><span>&nbsp; {!passedStateVariable[0] ? passedStateVariable[1] : ""}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    const handlePageChange = (page) => {
        gotoPage(page - 1);
    };

    return (
        <div className='bg-white' style={{borderRadius:"15px", overflow:"hidden"}}>
            <div className='tableWrap ' style={{ backgroundColor: "#efefef" }}> 
                <table  {...getTableProps()} style={{overflow:'auto'}}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="bg-white trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader d-flex justify-content-center align-items-center">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // in order to prevent the other clicks happening
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody className="tBodyContent" {...getTableBodyProps()} style={{overflowY:'scroll'}}>
                        {/* page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    // className = "trBodyRow"
                                    // style={{
                                    //     cursor: '',
                                    //     background: '',
                                    //     color: 'black',
                                    //     alignItems: "center",
                                    // }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style,},
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // ||
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent: "left"}}>{!passedStateVariable[0] ? passedStateVariable[1] : ""}</span>
                        //         </td>
                        //     </tr>  
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>
            <div className='w-100 d-flex bg-white justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                        <Pagination showQuickJumper 
                            defaultCurrent={pageIndex + 1} 
                            total={pageOptions.length * 10}
                            // total={10 * 10} 
                            onChange={handlePageChange} 
                            showSizeChanger={false}
                            current={pageIndex + 1} 
                        />

                        <Select
                            style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                            aria-label="rows per page"
                            className="paginationDropdown mySelector"
                            value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                            onChange={e => { 
                                setPageSize(Number(e)) 
                            }} 
                            options={[
                                {
                                    label: "5 rows",
                                    value: 5
                                },
                                {
                                    label: "10 rows",
                                    value: 10
                                },
                                {
                                    label: "20 rows",
                                    value: 20
                                },
                                {
                                    label: "25 rows",
                                    value: 25
                                },
                                {
                                    label: "100 rows",
                                    value: 100
                                }
                            ]} // Assuming options is the array of options for the Select component
                        />
                </div>
            </div>
        </div>
    );
};

function VcAlertLog (props) {

    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if(id != "CreatedByDeviceTime") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
                })
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]  
                                  
                if(String(t(IDS_TodaySearch).toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                    else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            })
        }
        
    };
    const [state, setState] = useState({
        selectedTreeNodeID: '', 
        isRootSelected: true, // Initially AllDeviceData will be rendered
        selectedTreeNodeTitle: '',  
        languageToViewIn: '',
        bCheckDeviceBlockedStatus: false,
        havingPvgToViewBlockedDeviceData: false,
        errors: {
            others: 'Please wait. Fetching Settings for the Device from Server...'
        },
        data: [
            // { MeasuredParam : 'TEMP, Value: 25, Message: 'Temprature has crossed minimum limit', Notification Sent: 'Yes', Minimum Limit Value: 20, Maximum Limit Value: 60, CreatedByDeviceTime: 2019-03-13 12:40:32, ReceivedFromDeviceTime: 2019-03-13 12:40:32, NotificationSentTime: 2019-03-13 12:40:32 },
        ],
        stdAirQualityRanges: [],
        modelInfo:[],
        SeqParam: [],
        noDataFound: "",
        MinimumAlertLogRetrievalUtcDttm: '',
        isDeviceBlocked: false,
        columns: [
            {       
                Header:() => <div className="AlertLogTableHeader" dangerouslySetInnerHTML={{__html: context.t(IDS_AlertMeasuredParam)}}></div>, 
                accessor: 'MeasuredParam',
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // height: "2.6rem",

                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader" dangerouslySetInnerHTML={{__html: context.t(IDS_AlertValue)}}></div>,  
                accessor: 'Value',
                width: 165,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // height: "2.6rem",
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader" dangerouslySetInnerHTML={{__html: context.t(IDS_AlertMsg)}}></div>,  
                accessor: 'Message',
                width: 480,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    // textOverflow: "ellipsis", 
                    // overflow: "hidden",
                    'whiteSpace': 'unset',                  
                     // height: "2.6rem",
                }),
            },
            // {       
            //     Header:() => <div className="AlertLogTableHeader">Notification <br/>Sent</div>,   
            //     accessor: 'NotificationSent',
            //     filter: filterCaseInsensitive,
            // },            
            {       
                Header:() => <div className="AlertLogTableHeader"dangerouslySetInnerHTML={{__html: context.t(IDS_AlertMinLimitVal)}}></div>,  
                accessor: 'MinLimitVal',
                filter: filterCaseInsensitive,
                width: 125,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader" dangerouslySetInnerHTML={{__html: context.t(IDS_AlertMaxLimitVal)}}></div>,  
                accessor: 'MaxLimitVal',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // height: "2.6rem",
                }),
            },               
            {       
                Header:() => <div className="AlertLogTableHeader" dangerouslySetInnerHTML={{__html: context.t(IDS_AlertTime)}}></div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'CreatedByDeviceTime',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width:250,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // height: "2.6rem",
                }),
            },
            // {        
            //     Header:() => <div className="AlertLogTableHeader">Received From<br/>Device Time</div>,
            //     accessor: 'ReceivedFromDeviceTime',
            //     width:170,
            //     filter: filterCaseInsensitive,
            // }, 
            // {       
            //     Header:() => <div className="AlertLogTableHeader">Notification<br/>Sent Time</div>, 
            //     accessor: 'NotificationSentTime',
            //     width: 170,
            //     filter: filterCaseInsensitive,
            // },                         

        ],
        interval: null
    });

    const [Loading, setLoading] = useState(true)

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);

        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const renderParamNameBasedOnType = (inParamNameType) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        switch(inParamNameType) {
            case AQI:
                return t(IDS_airQualityIndex) ;
            case CAQI:
                return t(IDS_currentAirQualityIndex);
            case NO2:
                return t(IDS_nitrogenDioxide);
            case O3:
                return t(IDS_Ozone);
            case SO2:
                return t(IDS_sulphurDioxide);
            case VOC:
                return t(IDS_TVOC);
            case CO:
                return t(IDS_carbonMonoxide);
            case NH3:
                return t(IDS_Ammonia);
            case CO2:
                return t(IDS_carbonDioxide);
            case H2S:
                return t(IDS_hydrogenSulphide);
            case CH4:
                return t(IDS_Methane);
            case LPG:
                return t(IDS_LPG);
            case PM1:
                return t(IDS_Dust) + "(PM1)";
            case PM25:
                return t(IDS_Dust) + "(PM2.5)";
            case PM10:
                return t(IDS_Dust) + "(PM10)";
            case TEMP:
                return t(IDS_Temperature);
            case HUM:
                return t(IDS_Humidity);
            case NH3OD:
                return t(IDS_Odour);
            case SMOKE:
                return t(IDS_SMOKE);
            case VRI:
                return t(IDS_VRI);
            case MRI:
                return t(IDS_MRI);
            case WLIP:
                return t(IDS_WLIP);
            case SLIP:
                return t(IDS_SLIP);
            case HCHO:
                return t(IDS_Formaldehyde);
            case O2:
                return t(IDS_Oxygen);
            case PM100:
                return t(IDS_PM100);
            case NO:
                return t(IDS_NitricOxide);
            case CL:
                return t(IDS_Chlorine);
            case CH3SH:
                return t(IDS_MethylMercaptan);
            case NOISE:
                return t(IDS_Noise);
            case LUX:
                return t(IDS_LightIntensity);
            case UV:
                return t(IDS_UV);
            case RADON:
                return t(IDS_Radon);
            case AP:
                return t(IDS_AirPressure);
            case WS:
                return t(IDS_WindSpeed);
            case WD:
                return t(IDS_WindDirection);
            case DLV:
                return t(IDS_DaylightVisibility);
            case RNFL:
                return t(IDS_Rainfall);  
            case H2:
                return t(IDS_Hydrogen);  
            default:
                console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (" "); // Return empty tag
        }
    }
   
    // componentDidMount () {
    //     let appRelevantDataContextValue = context; // Get all the relevant data from AppContext 
        
    //     getAlertLogData(appRelevantDataContextValue);
    //     interval = setInterval( () => { getAlertLogData(appRelevantDataContextValue) }, getAlertLogDataRefreshTime() );
    // }

    // =============================================

    // In range selection the lower range is excluded and upper range is included.
    // However we can specify flags to include both or exclude both or include/exclude any one.
    // Based on these parameters, the function decides whether the specified value falls within the specified range.

    const isValueWithinSpecifiedRange = (inValue, inRangeLowerLimit, inRangeUpperLimit, inbIncludeLower, inbExcludeUpper, inRangeWhen) => {

        let bIsWithinSpecifiedRange = false;

        if( (inRangeWhen != null && inValue == inRangeWhen) ||
            ( inRangeLowerLimit == null && ( inbExcludeUpper ? inValue <  inRangeUpperLimit : inValue <= inRangeUpperLimit ) ) ||
            ( inRangeUpperLimit == null && ( inbIncludeLower ? inValue >= inRangeLowerLimit : inValue >  inRangeLowerLimit ) ) ||
            ( ( inbIncludeLower ? inValue >= inRangeLowerLimit : inValue >  inRangeLowerLimit ) && 
              ( inbExcludeUpper ? inValue <  inRangeUpperLimit : inValue <= inRangeUpperLimit ) 
            ) 
        ) {
            bIsWithinSpecifiedRange = true;
        }

        return bIsWithinSpecifiedRange;
    }

    const getRemedyMsgBasedOnLevel= (inModelID, param, paramValue) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = state;

        let arrModelInfo = modifiedState.modelInfo.filter((singleModelInfo) => singleModelInfo["ModelID"] == inModelID);
        let bToiletVersionModel = false;

        if(arrModelInfo != null && arrModelInfo.length > 0) {
            if(arrModelInfo[0]["ModelCode"] == MODEL_CODE_VAYUVEDH_TOI || arrModelInfo[0]["ModelCode"] == MODEL_CODE_GANDHVEDH) {
                bToiletVersionModel = true;
            }
        }
        let RangeBasedOnParamValue = getRangeBasedOnParamValue(inModelID, paramValue, param);

        let strVeryPoorOrSever = (RangeBasedOnParamValue != null && RangeBasedOnParamValue.length > 0 && RangeBasedOnParamValue == V_POOR) ? t(IDS_AlertStrVeryPoor) :  
                                 (RangeBasedOnParamValue != null && RangeBasedOnParamValue.length > 0 && RangeBasedOnParamValue == HIGH_SEVERE) ? t(IDS_AlertStrSever) : "";

        let strAqiVeryPoorOrSevere = (RangeBasedOnParamValue != null && RangeBasedOnParamValue.length > 0 && RangeBasedOnParamValue == V_POOR) ? t(IDS_VeryPoorAqi) :  
                                 (RangeBasedOnParamValue != null && RangeBasedOnParamValue.length > 0 && RangeBasedOnParamValue == HIGH_SEVERE) ? t(IDS_SevereAqi) : "";

        switch(RangeBasedOnParamValue) {
            case SATISFACTORY:
                switch(param) {
                    case AQI:
                    case CAQI:
                        return t(IDS_RemedyAlertMsgForAQI, t(IDS_Satisfactory));
                    case HCHO:
                        return t(IDS_RemedyAlertMsgForHCHOSatisfactory);
                    case NH3:
                        return t(IDS_RemedyAlertMsgForNH3Satisfactory);
                    case CL:
                        return t(IDS_RemedyAlertMsgForCISatisfactory);
                        
                    default:
                        console.error(`Unable to get Remedy Message for specified ParamName. Unknown Param Type: ${param, SATISFACTORY}`);
                        return "";
                }
            case MODERATE:
                switch(param) {
                    case CO2:
                        return t(IDS_RemedyAlertMsgForCO2Moderate);
                    case AQI:
                    case CAQI:
                        return t(IDS_RemedyAlertMsgForAQI, t(IDS_Moderate));
                    case CL:
                        return t(IDS_RemedyAlertMsgForCIModerate);

                    default:
                        console.error(`Unable to get Remedy Message for specified ParamName. Unknown Param Type: ${param, MODERATE}`);
                        return ""; // return blank string
                    }
            case POOR:
                switch(param) {
                    case CO2:
                        return t(IDS_RemedyAlertMsgForCO2Poor);
                    case VRI:
                        return t(IDS_RemedyAlertMsgForVRI);
                    case MRI:
                        return t(IDS_RemedyAlertMsgForMRI);
                    case WLIP:
                        return t(IDS_RemedyAlertMsgForLowWLIP);
                    case AQI:
                    case CAQI:
                        return t(IDS_RemedyAlertMsgForAQI, t(IDS_PoorAqi));
                    case HCHO:
                        return t(IDS_RemedyAlertMsgForHCHOPoor);
                    case NH3:
                        return t(IDS_RemedyAlertMsgForNH3Poor);
                    
                    
                    default:
                        console.error(`Unable to get Remedy Message for specified ParamName. Unknown Param Type: ${param, POOR}`);
                        return ""; // return blank string
                    }
            case V_POOR:
                switch(param) {
                    case AQI:
                    case CAQI:
                        return t(IDS_RemedyAlertMsgForAQI, strAqiVeryPoorOrSevere);
                    case CO:
                        return t(IDS_RemedyAlertMsgForCo, strVeryPoorOrSever);
                    case NO2:
                        return t(IDS_RemedyAlertMsgForNO2, strVeryPoorOrSever);
                    case O3:
                        return t( IDS_RemedyAlertMsgForO3, strVeryPoorOrSever);
                    case CO2:
                        return t(IDS_RemedyAlertMsgForCO2, strVeryPoorOrSever);
                    case VOC:
                        return t(IDS_RemedyAlertMsgForVOC, strVeryPoorOrSever);
                    case PM1:
                    case PM10:
                    case PM25:
                        return t(IDS_RemedyAlertMsgForPM, strVeryPoorOrSever);
                    case H2S:
                        return t(IDS_RemedyAlertMsgForH2S, strVeryPoorOrSever);    
                    case SO2:
                        return t(IDS_RemedyAlertMsgForSO2, strVeryPoorOrSever);
                    case NH3OD:
                        return t(IDS_RemedyAlertMsgForNH3OD);
                    case TEMP:
                        return t(IDS_RemedyAlertMsgForHighTemp);    
                    case HUM:
                        return (bToiletVersionModel ? t(IDS_RemedyAlertMsgForHighHumTOIBasedModel): t(IDS_RemedyAlertMsgForHighHum));  
                    case VRI:
                        return t(IDS_RemedyAlertMsgForVRI);
                    case MRI:
                        return t(IDS_RemedyAlertMsgForMRI);
                    case HCHO:
                        return t(IDS_RemedyAlertMsgForHCHOVeryPoor);
                    case NH3:
                        return t(IDS_RemedyAlertMsgForNH3VeryPoor);
                    case CL:
                        return t(IDS_RemedyAlertMsgForCIVeryPoor);
                    default:
                        console.error(`Unable to get Remedy Message for specified ParamName. Unknown Param Type: ${param,V_POOR, HIGH_SEVERE}`);
                        return ""; // return blank string
                    }
            case HIGH_SEVERE: 
            switch(param) {
                case AQI:
                case CAQI:
                    return t(IDS_RemedyAlertMsgForAQI, strAqiVeryPoorOrSevere);
                case CO:
                    return t(IDS_RemedyAlertMsgForCo, strVeryPoorOrSever);
                case NO2:
                    return t(IDS_RemedyAlertMsgForNO2, strVeryPoorOrSever);
                case O3:
                    return t( IDS_RemedyAlertMsgForO3, strVeryPoorOrSever);
                case CO2:
                    return t(IDS_RemedyAlertMsgForCO2, strVeryPoorOrSever);
                case VOC:
                    return t(IDS_RemedyAlertMsgForVOC, strVeryPoorOrSever);
                case PM1:
                case PM10:
                case PM25:
                    return t(IDS_RemedyAlertMsgForPM, strVeryPoorOrSever);
                case H2S:
                    return t(IDS_RemedyAlertMsgForH2S, strVeryPoorOrSever);    
                case SO2:
                    return t(IDS_RemedyAlertMsgForSO2, strVeryPoorOrSever);
                case NH3OD:
                    return t(IDS_RemedyAlertMsgForNH3OD);
                case TEMP:
                    return t(IDS_RemedyAlertMsgForHighTemp);    
                case HUM:
                    return (bToiletVersionModel ? t(IDS_RemedyAlertMsgForHighHumTOIBasedModel): t(IDS_RemedyAlertMsgForHighHum));  
                case VRI:
                    return t(IDS_RemedyAlertMsgForVRI);
                case MRI:
                    return t(IDS_RemedyAlertMsgForMRI);
                case HCHO:
                    return t(IDS_RemedyAlertMsgForHCHOHighSevere);
                case NH3:
                    return t(IDS_RemedyAlertMsgForNH3HighSevere);
                case CL:
                    return t(IDS_RemedyAlertMsgForCIHighSevere);
                case LPG:
                    return t(IDS_RemedyAlertMsgForLPG); 
                default:
                    console.error(`Unable to get Remedy Message for specified ParamName. Unknown Param Type: ${param,V_POOR, HIGH_SEVERE}`);
                    return ""; // return blank string
                }   
                
            case LOW_SEVERE:
                switch(param) {
                    case AQI:
                    case CAQI:
                        return t(IDS_RemedyAlertMsgForAQI, t(IDS_VeryPoorAqi));
                    case TEMP:
                        return t(IDS_RemedyAlertMsgForLowTemp);
                    case HUM:
                        return (bToiletVersionModel ? t(IDS_RemedyAlertMsgForLowHumTOIBasedModel): t(IDS_RemedyAlertMsgForLowHum));
                    case WLIP:
                        return t(IDS_RemedyAlertMsgForHighWLIP);  
                    case SLIP:
                        return t(IDS_RemedyAlertMsgForHighSLIP); 
                    default:
                        console.error(`Unable to get Remedy Message for specified ParamName. Unknown Param Type: ${param, LOW_SEVERE}`);
                        return ""; // return blank string
                    }  
            case LOW_V_POOR:
                switch(param) {
                    case AP:
                        return t(IDS_RemedyAlertMsgForNewParams);
                    default:
                        console.error(`Unable to get Remedy Message for specified ParamName. Unknown Param Type: ${param, LOW_V_POOR}`);
                        return "";
                }             
            default:
                console.log(`Possibly Remedy Message is not applicable for specified ParamName. Param Type: ${param, RangeBasedOnParamValue}`);
                return ""; // return blank string
        }
    }

    // function for getting range dependes on param  and its value.
    const getRangeBasedOnParamValue = (inModelID, inParamValue, inParamNameType) => {

        let strRetRange = PARAM_VALUE_MISSING;

        let modifiedState = state;

        let singleParamAirQualityInfo = 
        modifiedState.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] == inParamNameType && arrElement["RangeValues"] != null );

        if(singleParamAirQualityInfo == null) {
            console.log(`Should not happen. Param ${inParamNameType} was not found in stdAirQualityRange.`);
            strRetRange = PARAM_STD_QUALITY_MISSING;
            return strRetRange;         
        }

        let singleParamQualityRange = {};
        try {
            singleParamQualityRange = JSON.parse(singleParamAirQualityInfo.RangeValues);
        } catch {
            console.log(`Should not happen. StdAirQualityRange for Param [${inParamNameType}] is in invalid JSON format.`);
            strRetRange = PARAM_STD_QUALITY_MISSING;
            return strRetRange;         
        }

        if(singleParamQualityRange == null) {
            console.log(`Should not happen. Param [${inParamNameType}] was not found in stdQualityRange. Might happen during first Render.`);
            strRetRange = PARAM_STD_QUALITY_MISSING;
            return strRetRange; 
        }

        let filteredModelInfo = modifiedState.modelInfo.find((singleModelInfo) => singleModelInfo["ModelID"] == inModelID);

        let objMeasuredParams = {};
        let paramHighLowValues = {};

        try {
            objMeasuredParams = JSON.parse(filteredModelInfo["MeasuredParams"]);
            paramHighLowValues = objMeasuredParams[inParamNameType];
        } catch {
            console.log(`Should not happen. MeasureParams from the filtered ModelInfo for ModelID [${inModelID}] is in invalid format.`);
            objMeasuredParams = {};
            paramHighLowValues = {};
            strRetRange = PARAM_MODEL_INFO_MISSING;
            return strRetRange; 
        }

        let bIsParamNonCalibrated = ( paramHighLowValues != null
                                        && paramHighLowValues["NoCalib"] != null
                                    ) ? paramHighLowValues["NoCalib"] : false;

        // let bIsParamBoolean = ( paramAttributes != null
        //                         && paramAttributes[IS_BOOLEAN] != null
        //                     ) ? paramAttributes[IS_BOOLEAN] : false; 

        let singleParamQualityRangeLowVPoorL = singleParamQualityRange[LOW_V_POOR_L];
        let singleParamQualityRangeLowVPoorU = singleParamQualityRange[LOW_V_POOR_U];
        let singleParamQualityRangeLowSevereL = singleParamQualityRange[LOW_SEVERE_L];
        let singleParamQualityRangeLowSevereU = singleParamQualityRange[LOW_SEVERE_U];
        let singleParamQualityRangeGoodL = singleParamQualityRange[GOOD_L];
        let singleParamQualityRangeGoodU = singleParamQualityRange[GOOD_U];
        let singleParamQualityRangeSatisfactoryL = singleParamQualityRange[SATISFACTORY_L];
        let singleParamQualityRangeSatisfactoryU = singleParamQualityRange[SATISFACTORY_U];
        let singleParamQualityRangeModerateL = singleParamQualityRange[MODERATE_L];
        let singleParamQualityRangeModerateU = singleParamQualityRange[MODERATE_U];
        let singleParamQualityRangePoorL = singleParamQualityRange[POOR_L];
        let singleParamQualityRangePoorU = singleParamQualityRange[POOR_U];
        let singleParamQualityRangeVPoorL = singleParamQualityRange[V_POOR_L];
        let singleParamQualityRangeVPoorU = singleParamQualityRange[V_POOR_U];
        let singleParamQualityRangeHighSevereL= singleParamQualityRange[HIGH_SEVERE_L];
        let singleParamQualityRangeHighSevereU = singleParamQualityRange[HIGH_SEVERE_U];


        let bIncludeLowVPoorL = singleParamQualityRange[INCL_LOW_V_POOR_L] != null ? singleParamQualityRange[INCL_LOW_V_POOR_L] : false;
        let bExcludeLowVPoorU = singleParamQualityRange[EXCL_LOW_V_POOR_U] != null  ? singleParamQualityRange[EXCL_LOW_V_POOR_U] : false;
        let bIncludeLowSevereL = singleParamQualityRange[INCL_LOW_SEVERE_L] != null ? singleParamQualityRange[INCL_LOW_SEVERE_L] : false;
        let bExcludeLowSevereU = singleParamQualityRange[EXCL_LOW_SEVERE_U] != null  ? singleParamQualityRange[EXCL_LOW_SEVERE_U] : false;
        let bIncludeGoodL = singleParamQualityRange[INCL_GOOD_L] != null  ? singleParamQualityRange[INCL_GOOD_L] : false;
        let bExcludeGoodU = singleParamQualityRange[EXCL_GOOD_U] != null ? singleParamQualityRange[EXCL_GOOD_U] : false;

        let bIncludeSatisfactoryL = singleParamQualityRange[INCL_SATISFACTORY_L] != null  ? singleParamQualityRange[INCL_SATISFACTORY_L] : false;
        let bExcludeSatisfactoryU = singleParamQualityRange[EXCL_SATISFACTORY_U] != null ? singleParamQualityRange[EXCL_SATISFACTORY_U] : false;

        let bIncludeModerateL = singleParamQualityRange[INCL_MODERATE_L] != null ? singleParamQualityRange[INCL_MODERATE_L] : false;
        let bExcludeModerateU = singleParamQualityRange[EXCL_MODERATE_U] != null ? singleParamQualityRange[EXCL_MODERATE_U] : false;
        let bIncludePoorL = singleParamQualityRange[INCL_POOR_L] != null ? singleParamQualityRange[INCL_POOR_L] : false;
        let bExcludePoorU = singleParamQualityRange[EXCL_POOR_U] != null ? singleParamQualityRange[EXCL_POOR_U] : false;
        let bIncludeVeryPoorL = singleParamQualityRange[INCL_V_POOR_L] != null ? singleParamQualityRange[INCL_V_POOR_L] : false;
        let bExcludeVeryPoorU = singleParamQualityRange[EXCL_V_POOR_U] != null ? singleParamQualityRange[EXCL_V_POOR_U] : false;
        let bIncludeHighSevereL = singleParamQualityRange[INCL_HIGH_SEVERE_L] != null ? singleParamQualityRange[INCL_HIGH_SEVERE_L] : false;
        let bExcludeHighSevereU = singleParamQualityRange[EXCL_HIGH_SEVERE_U] != null ? singleParamQualityRange[EXCL_HIGH_SEVERE_U] : false;

        let valLowVPoorWhen = singleParamQualityRange[LOW_V_POOR_WHEN];
        let valLowSevereWhen = singleParamQualityRange[LOW_SEVERE_WHEN];
        let valGoodWhen = singleParamQualityRange[GOOD_WHEN];
        let valSatisfactoryWhen = singleParamQualityRange[SATISFACTORY_WHEN];
        let valModerateWhen = singleParamQualityRange[MODERATE_WHEN];
        let valPoorWhen = singleParamQualityRange[POOR_WHEN];
        let valVPoorWhen = singleParamQualityRange[V_POOR_WHEN];
        let valHighSevereWhen = singleParamQualityRange[HIGH_SEVERE_WHEN];

        if( inParamValue == null ) {
            strRetRange = PARAM_VALUE_MISSING;
        
        }
        //  else if( bIsParamNonCalibrated == false && inParamValue < paramHighLowValues["RangeMin"] ) {

        //     strRetRange = PARAM_VALUE_LESS_THAN_LOWER_RANGE;

        // } else if( bIsParamNonCalibrated == false && inParamValue > paramHighLowValues["RangeMax"] ) {

        //     strRetRange = PARAM_VALUE_GREATER_THAN_UPPER_RANGE;

        // } 
        else {

            if( isValueWithinSpecifiedRange(
                inParamValue, singleParamQualityRangeLowVPoorL, singleParamQualityRangeLowVPoorU, bIncludeLowVPoorL, bExcludeLowVPoorU, valLowVPoorWhen)
            ) {
                strRetRange = LOW_V_POOR;

            } else if( isValueWithinSpecifiedRange(
                    inParamValue, singleParamQualityRangeLowSevereL, singleParamQualityRangeLowSevereU, bIncludeLowSevereL, bExcludeLowSevereU, valLowSevereWhen)
            ) {
                strRetRange = LOW_SEVERE;

            } else if( isValueWithinSpecifiedRange(
                    inParamValue, singleParamQualityRangeGoodL, singleParamQualityRangeGoodU, bIncludeGoodL, bExcludeGoodU, valGoodWhen)
            ) {
                strRetRange = GOOD;

            } else if( isValueWithinSpecifiedRange(
                    inParamValue, singleParamQualityRangeSatisfactoryL, singleParamQualityRangeSatisfactoryU, bIncludeSatisfactoryL, bExcludeSatisfactoryU, valSatisfactoryWhen)
            ) {
                strRetRange = SATISFACTORY;

            }else if( isValueWithinSpecifiedRange(
                    inParamValue, singleParamQualityRangeModerateL, singleParamQualityRangeModerateU, bIncludeModerateL, bExcludeModerateU, valModerateWhen)
            ) {
                strRetRange = MODERATE;

            } else if( isValueWithinSpecifiedRange(
                    inParamValue, singleParamQualityRangePoorL, singleParamQualityRangePoorU, bIncludePoorL, bExcludePoorU, valPoorWhen)
            ) {
                strRetRange = POOR;

            } else if( isValueWithinSpecifiedRange(
                    inParamValue, singleParamQualityRangeVPoorL, singleParamQualityRangeVPoorU, bIncludeVeryPoorL, bExcludeVeryPoorU, valVPoorWhen)
            ) {
                strRetRange = V_POOR;

            } else if( isValueWithinSpecifiedRange(
                    inParamValue, singleParamQualityRangeHighSevereL, singleParamQualityRangeHighSevereU, bIncludeHighSevereL, bExcludeHighSevereU, valHighSevereWhen)
            ) {
                strRetRange = HIGH_SEVERE;

            } else {
                // Default black/grey
                console.log(`Should not happen. Could not calculate range for ParamType with ParamValue [${inParamValue}].`);

                strRetRange = UNABLE_TO_CALCULATE_RANGE_FOR_PARAMVALUE;
            }
        }

        return strRetRange;
    }
    // =============================================

    // Interval is cleared whenever flag changes
    // If flag is set true then interval is created
    let getAlertLogDataInterval;
    const [getAlertLogDataIntervalFlag, setGetAlertLogDataIntervalFlag] = useState(false)

    useInterval(() => {
        GetAlertLogBasedOnPeriodAndLimit();
    }, getAlertLogDataRefreshTime())


    // The below useInterval() fetches device data after every 5 seconds
    // This is implemented for testing purpose of LPG model or for any model consisting 'LPG' parameter
    useInterval(() => {
        if(state.SeqParam != null && state.SeqParam.length == 1 && state.SeqParam[0] == LPG) {
            GetAlertLogBasedOnPeriodAndLimit();
        } else {
            return; // No further processing required
        }

    }, getLPGSingleDeviceDataRefreshTime())
    
    // useEffect(() => {
    //     if(getAlertLogDataIntervalFlag){
    //         getAlertLogDataInterval = setInterval(
    //             () => { 
    //                 GetAlertLogBasedOnPeriodAndLimit();
    //             },
    //             getAlertLogDataRefreshTime() 
    //         )
    //     }
    //   return () => {
    //     clearInterval(getAlertLogDataInterval)
    //   }
    // }, [getAlertLogDataIntervalFlag, state.selectedTreeNodeID])

    useEffect(() => {
        let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext

        // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
        appRelevantDataContextValue.onChangeProcessingReq(true);
        
        getAlertLogData();
        setGetAlertLogDataIntervalFlag(true);
        return () => {
            setGetAlertLogDataIntervalFlag(false);
        }
    }, []);

    const onRefreshBtnClicked = () => {
        let refreshIconEle = document.getElementById('alertLogRefreshButton');
        if (refreshIconEle) {
                refreshIconEle?.classList.add('spinn')
            setTimeout(() => {
                refreshIconEle?.classList.remove('spinn')
            }, 1000);
        }
        GetAlertLogBasedOnPeriodAndLimit();
    }

    useEffect(() =>{
        let currentState = state;
        let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext
    
        if( appRelevantDataContextValue == null ||
            ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.loggedInUserInfo == null ||
            ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
            appRelevantDataContextValue.loggedInUserInfo.userID == null ||
            appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
            ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.selectedNodeInfo == null ||
            ("nodeID" in appRelevantDataContextValue.selectedNodeInfo) == false ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID == null ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID.length <= 0
        ) {
            console.error("AlertLog:componentDidUpdate - Should not happen. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
            return; // Do not process further
        }
    
        if( currentState.LoggedInUserID != appRelevantDataContextValue.loggedInUserInfo.userID ||
            currentState.selectedTreeNodeID != appRelevantDataContextValue.selectedNodeInfo.nodeID ||
            currentState.languageToViewIn != appRelevantDataContextValue.language.languageToViewIn
        ) {
            // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
            appRelevantDataContextValue.onChangeProcessingReq(true);
    
            // The selected tree node has changed. Update the state based on the new selected node.
            // getAlertLogData(appRelevantDataContextValue);
            getAlertLogData();
        }
    }, [context, state.LoggedInUserID, state.selectedTreeNodeID, state.languageToViewIn]);

    // getAlertLogData = (inAppRelevantDataContextValue) => {
    const getAlertLogData = () => {

        let inAppRelevantDataContextValue = context; // Get all the relevant data from AppContext
        let selectedTreeNodeID = inAppRelevantDataContextValue.selectedNodeInfo.nodeID;
        let selectedTreeNodeTitle = inAppRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        let isRootSelected = inAppRelevantDataContextValue.selectedNodeInfo.isRoot; 
        let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
        let t = inAppRelevantDataContextValue.t;
        let languageToViewIn = inAppRelevantDataContextValue.language.languageToViewIn;
        let IsSelectedNodeDevice = inAppRelevantDataContextValue.selectedNodeInfo.isDevc;

        let modifiedState = state;
        modifiedState.selectedTreeNodeID = selectedTreeNodeID;
        modifiedState.selectedTreeNodeTitle = selectedTreeNodeTitle;
        modifiedState.isRootSelected = isRootSelected;
        modifiedState.LoggedInUserID = loggedInUserID;
        modifiedState.languageToViewIn = languageToViewIn;
        modifiedState.data = [];
        modifiedState.stdAirQualityRanges = [];
        modifiedState.modelInfo = [];
        modifiedState.isDeviceBlocked = false;
        modifiedState.bCheckDeviceBlockedStatus = false;
        modifiedState.havingPvgToViewBlockedDeviceData = false;
        // Get the Browser Timezone Offset
        let localTimeZone = (new Date()).getTimezoneOffset();

        // Incase the LHS Tree does not have any devices OR if it is a General user (a user without Devices) Logged in
        // and the User has clicked the node with ID 'NO_DEVC_FOUND_FOR_LOGGED_IN_USER' or If the selected node itself is not a valid device.
        // In this case no need to bring data from server as DeviceID is not valid and we have to show him a HTML page with message.
        // Also you have to update state with loggedinUser, selectedTreeNodeID as it will avoid triggering componentDidUpdate infinitely.
        if((selectedTreeNodeID.length > 0 && selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER) || !IsSelectedNodeDevice) {
            setState({...modifiedState});
            inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing Loading spinner.
            return;
        }

        let jsonParams = {
            selectedTreeNodeID: selectedTreeNodeID,
            LoggedInUserID: inAppRelevantDataContextValue.loggedInUserInfo.userID,
            localTimeZone: getLocalTimezoneOffset( localTimeZone )
        }

        axios.post(`${getAPIHostURL()}/wclient/getAlertLogData`, jsonParams)
        .then(response => {
        inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing Loading spinner. 
        
        if(response.data.code == 'SUCCESS') {
            let receivedAlertLogs = response.data.retreivedAlertLogs;
            let receivedAirQualityRange = response.data.retrievedAirQualityRange;
            let receivedModelInfo = response.data.retrievedModelInfo;
            modifiedState.SeqParam = [];

            // Show blocked Devices Data to Production/Support Users.
            modifiedState.bCheckDeviceBlockedStatus = response.data.bCheckDeviceBlockedStatus != null && 
                response.data.bCheckDeviceBlockedStatus == 0 ? true : false;
            modifiedState.havingPvgToViewBlockedDeviceData = response.data.havingPvgToViewBlockedDeviceData != null && 
                response.data.havingPvgToViewBlockedDeviceData > 0 ? true : false;

            if(receivedAlertLogs.length <=0 || receivedAirQualityRange <= 0) {
                // No errors  
                // modifiedState.errors.others = 'Selected DeviceID does not have Any AlertLog/Air Quality Range on the Server.';
                modifiedState.errors.others = t(IDS_AlertNoAlertOnSrvr);
            } else {
                modifiedState.errors.others = '';

                // Stored date to state for retrieving alerts which have generated after this date.
                modifiedState.MinimumAlertLogRetrievalUtcDttm = receivedAlertLogs[0].CreatedByDeviceTimeUtcDtTm

                modifiedState.stdAirQualityRanges = receivedAirQualityRange;
                // modifiedState.modelInfo = receivedModelInfo;

                try {
                    // modifiedState.modelInfo = JSON.parse(receivedModelInfo[0]["MeasuredParams"]);
                    modifiedState.modelInfo = receivedModelInfo;
                    let MeasureParamObj = JSON.parse(receivedModelInfo[0].MeasuredParams);
                    modifiedState.SeqParam = MeasureParamObj["Seq"];
                } catch {
                    console.log(`Should not happen. The Model Info obtained from server is in invalid JSON format.`);
                }

                let noOfLogs = receivedAlertLogs.length;
                let arrReceivedAlertLogs = [];
                for(let i = 0; i < noOfLogs; i++) {

                    if(modifiedState.SeqParam.indexOf(receivedAlertLogs[i]["MeasuredParam"]) > -1) {
                    
                    const DeviceAlertLog = receivedAlertLogs[i];

                    // let DateTime = (DeviceAlertLog.CrtTime).split(" ");
                    // let strDate = DateTime[0];
                    // let strTime = DateTime[1];
                    // let concanetedDateTime = strDate + "T" + strTime + "Z";

                    let strDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(DeviceAlertLog.CrtTimeUTC);

                    let singleAlertLog = {
                        ModelID: DeviceAlertLog.ModelID,
                        AlertID: DeviceAlertLog.AlertID,
                        DeviceName: DeviceAlertLog.DeviceName,
                        DeviceID: DeviceAlertLog.DeviceID,
                        MeasuredParam: renderParamNameBasedOnType(DeviceAlertLog.MeasuredParam),
                        Value: DeviceAlertLog.MeasuredParam == NH3OD ? getParamValueBasedOnRangeForNH3OD( getParamValueBasedOnRange(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value) ) : getParamValueBasedOnRange(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value),
                        MinLimitVal: getMinLimitValue(DeviceAlertLog.ModelID, DeviceAlertLog.MinLimitVal, DeviceAlertLog.MeasuredParam),
                        MaxLimitVal: DeviceAlertLog.MeasuredParam == NH3OD ? getParamMaxLevel(DeviceAlertLog.MaxLimitVal) : getMaxLimitValue(DeviceAlertLog.ModelID, DeviceAlertLog.MaxLimitVal, DeviceAlertLog.MeasuredParam),
                        // Message: getAlertMsg(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value, DeviceAlertLog.Message, DeviceAlertLog.MinLimitVal, DeviceAlertLog.MaxLimitVal) + " " + getRemedyMsgBasedOnLevel(DeviceAlertLog.ModelID,DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value),
                        Message: getAlertMsg(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value, DeviceAlertLog.Message, DeviceAlertLog.MinLimitVal, DeviceAlertLog.MaxLimitVal),
                        NotificationSent: DeviceAlertLog.NotificationSent == 1 ? 'Yes' : 'No',
                        // CreatedByDeviceTime: DeviceAlertLog.CrtTime,
                        CreatedByDeviceTime: strDateTimeLocale,
                        // ReceivedFromDeviceTime: DeviceAlertLog.RFDTime, 
                        // NotificationSentTime: DeviceAlertLog.NSTime,
                    };

                        // modifiedState.data.push(singleAlertLog);
                        arrReceivedAlertLogs.push(singleAlertLog);

                    } else {
                        // no need to process
                        continue;
                    }
                }
                modifiedState.data = [...arrReceivedAlertLogs];
                setState({...modifiedState});
            }
        } else {
            if(response.data.code == 'REQ_PARAMS_MISSING') {
                modifiedState.errors.others = t(IDS_AlertSrvcIssue);
            } else if(response.data.code == 'SQL_ERROR') {
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else if(response.data.code == 'DEVICE_IS_BLOCKED') {
                modifiedState.isDeviceBlocked = true;
                // Clear the interval if the Device is blocked so that it will not get the data from the server on every interval for the blocked device.
                // clearInterval(state.interval);
                setGetAlertLogDataIntervalFlag(false);
            } else {
                console.log('Should not reach here');
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } 
        }
        setState({...modifiedState});
    })
        .catch(error => {
        inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing Loading spinner.
        console.log(error);
        console.log("Network error:");
        if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setState({...modifiedState});
        }
        })
        setLoading(false);
    }

    // for retrieving latest alert log data on interval.
    const GetAlertLogBasedOnPeriodAndLimit = () => {

        let inAppRelevantDataContextValue = context; // Get all the relevant data from AppContext
        let selectedTreeNodeID = inAppRelevantDataContextValue.selectedNodeInfo.nodeID;
        let t = inAppRelevantDataContextValue.t;
        let languageToViewIn = inAppRelevantDataContextValue.language.languageToViewIn;
        let IsSelectedNodeDevice = inAppRelevantDataContextValue.selectedNodeInfo.isDevc;

        let modifiedState = state;
        modifiedState.selectedTreeNodeID = selectedTreeNodeID;

        // If the selected node itself is not a valid device.
        // then do not call for the alertlog data.
        if(!IsSelectedNodeDevice) {
            setState({...modifiedState});
            return;
        }


        // Get the Browser Timezone Offset
        let localTimeZone = (new Date()).getTimezoneOffset();

        let jsonParams = {
            selectedTreeNodeID: selectedTreeNodeID,
            MinimumAlertLogRetrievalUtcDttm: modifiedState.MinimumAlertLogRetrievalUtcDttm,
            localTimeZone: getLocalTimezoneOffset( localTimeZone )
        }
    
        axios.post(`${getAPIHostURL()}/wclient/getAlertLogBasedOnPeriodAndLimit`, jsonParams)
        .then(response => {
    
            if(response.data.code == 'SUCCESS') {
                let receivedAlertLogs = [];
                let ForSelectMaxDateOfreceivedAlertLogs = response.data.retreivedAlertLogs;
                let LengthOfRetrivedAlertLogData = response.data.retreivedAlertLogs.length

                // We will start adding to the start of the existing array but starting from
                // the last element of this array. So reverse the order of the array
                // that you received from the api.
                for(let j= LengthOfRetrivedAlertLogData; j > 0; j--) {
                    receivedAlertLogs.push(response.data.retreivedAlertLogs[j-1]);
                }
    
                if(receivedAlertLogs.length <= 0 ) {

                    // modifiedState.MinimumAlertLogRetrievalUtcDttm = state.MinimumAlertLogRetrievalUtcDttm
                    // modifiedState.errors.others = 'Selected DeviceID does not have Any AlertLog on the Server.';
                    modifiedState.errors.others = t(IDS_AlertNoAlertOnSrvr);
                } else {
                    let StateAlertLogsArr = [];

                    // Stored date to state for retrieving alerts which have generated after this date.
                    modifiedState.MinimumAlertLogRetrievalUtcDttm = ForSelectMaxDateOfreceivedAlertLogs[0].CreatedByDeviceTimeUtcDtTm;
                    modifiedState.errors.others = '';
                    
                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing Alert Log data.
                    StateAlertLogsArr = [...modifiedState.data]
    
                    let noOfLogs = receivedAlertLogs.length;

                    for(let i = 0; i < noOfLogs; i++) {

                        if(modifiedState.SeqParam.indexOf(receivedAlertLogs[i]["MeasuredParam"]) > -1) {
                            const DeviceAlertLog = receivedAlertLogs[i];
                            let strDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(DeviceAlertLog.CrtTimeUTC);
        
                            let singleAlertLog = {
                                ModelID: DeviceAlertLog.ModelID,
                                AlertID: DeviceAlertLog.AlertID,
                                DeviceName: DeviceAlertLog.DeviceName,
                                DeviceID: DeviceAlertLog.DeviceID,
                                MeasuredParam: renderParamNameBasedOnType(DeviceAlertLog.MeasuredParam),
                                Value: DeviceAlertLog.MeasuredParam == NH3OD ? getParamValueBasedOnRangeForNH3OD( getParamValueBasedOnRange(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value) ) : getParamValueBasedOnRange(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value),
                                MinLimitVal: getMinLimitValue(DeviceAlertLog.ModelID, DeviceAlertLog.MinLimitVal, DeviceAlertLog.MeasuredParam),
                                MaxLimitVal: DeviceAlertLog.MeasuredParam == NH3OD ? getParamMaxLevel(DeviceAlertLog.MaxLimitVal) : getMaxLimitValue(DeviceAlertLog.ModelID, DeviceAlertLog.MaxLimitVal, DeviceAlertLog.MeasuredParam),
                                // Message: getAlertMsg(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value, DeviceAlertLog.Message, DeviceAlertLog.MinLimitVal, DeviceAlertLog.MaxLimitVal) + " " + getRemedyMsgBasedOnLevel(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value),
                                Message: getAlertMsg(DeviceAlertLog.ModelID, DeviceAlertLog.MeasuredParam, DeviceAlertLog.Value, DeviceAlertLog.Message, DeviceAlertLog.MinLimitVal, DeviceAlertLog.MaxLimitVal),
                                NotificationSent: DeviceAlertLog.NotificationSent == 1 ? 'Yes' : 'No',
                                // CreatedByDeviceTime: DeviceAlertLog.CrtTime,
                                CreatedByDeviceTime: strDateTimeLocale,
                                // ReceivedFromDeviceTime: DeviceAlertLog.RFDTime, 
                                // NotificationSentTime: DeviceAlertLog.NSTime,
                            };
                            StateAlertLogsArr.unshift(singleAlertLog);
                        } else {
                            // no need to process
                            continue;
                        }
                    }

                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing Alert Log data and add at the start of it with only the newly
                    // generated alert logs.
                    modifiedState.data = StateAlertLogsArr;

                    setState({...modifiedState});
                }
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = t(IDS_AlertSrvcIssue);
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } 
            }
            setState({...modifiedState});

        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                setState({...modifiedState});
            }
        })
    }
    
    const getMinLimitValue = (inModelID, inMinLimitVal, inMeasuredParam) => {
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        // In case the device has been replaced, there is a chance that two model IDs will
        // be retreived for the Alert based on which period the alert happened. The below search
        // will bring the detailed information for that particular ModelID. 
        let filteredModelInfo = modifiedState.modelInfo.find((singleModelInfo) => singleModelInfo["ModelID"] == inModelID);

        let objMeasuredParams = {};
        let paramHighLowValues = {};

        try {
            objMeasuredParams = JSON.parse(filteredModelInfo["MeasuredParams"])
            paramHighLowValues = objMeasuredParams[inMeasuredParam];
        } catch {
            console.log(`Should not happen. MeasureParams from the filtered ModelInfo for ModelID [${inModelID}] is in invalid format.`);
            objMeasuredParams = {};
            paramHighLowValues = {};
        }

        let bIsParamAlertBased = paramHighLowValues[ALERT_BASED] != null ? paramHighLowValues[ALERT_BASED] : false;
        let bIsParamNonCalibrated = paramHighLowValues[NO_CALIB] != null ? paramHighLowValues[NO_CALIB] : false;

        // Alert is generated means that the particular value was detected.
        if(bIsParamAlertBased || inMeasuredParam == LPG) {
            return null;
        } else {
            return inMinLimitVal;
        }
    }

    const getMaxLimitValue = (inModelID, inMaxLimitVal, inMeasuredParam) => {
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        // In case the device has been replaced, there is a chance that two model IDs will
        // be retreived for the Alert based on which period the alert happened. The below search
        // will bring the detailed information for that particular ModelID. 
        let filteredModelInfo = modifiedState.modelInfo.find((singleModelInfo) => singleModelInfo["ModelID"] == inModelID);

        let objMeasuredParams = {};
        let paramHighLowValues = {};

        try {
            objMeasuredParams = JSON.parse(filteredModelInfo["MeasuredParams"])
            paramHighLowValues = objMeasuredParams[inMeasuredParam];
        } catch {
            console.log(`Should not happen. MeasureParams from the filtered ModelInfo for ModelID [${inModelID}] is in invalid format.`);
            objMeasuredParams = {};
            paramHighLowValues = {};
        }

        let bIsParamAlertBased = paramHighLowValues[ALERT_BASED] != null ? paramHighLowValues[ALERT_BASED] : false;
        let bIsParamNonCalibrated = paramHighLowValues[NO_CALIB] != null ? paramHighLowValues[NO_CALIB] : false;

        // Alert is generated means that the particular value was detected.
        if(bIsParamAlertBased || inMeasuredParam == LPG) {
            return null;
        } else {
            return inMaxLimitVal;
        }
    }

    const getParamMaxLevel = (inMaxLimitValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = state;

        let singleParamAirQualityInfo = 
                modifiedState.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] == NH3OD && arrElement["RangeValues"] != null );
        
        if(singleParamAirQualityInfo == null) {
            console.log(`Should not happen. Param [NH3OD] was not found in stdAirQualityRange.`);
            return "";
        }
        
        let singleParamAirQualityRange = {};
        try {
            singleParamAirQualityRange = JSON.parse(singleParamAirQualityInfo.RangeValues);
        } catch {
            console.log(`Should not happen. StdAirQualityRange for Param [NH3OD] is in invalid JSON format.`);
            return "";
        }
        
        let singleParamLowRange = singleParamAirQualityRange[LOW];
        let singleParamGoodLRange = singleParamAirQualityRange[GOOD_L];
        let singleParamGoodURange = singleParamAirQualityRange[GOOD_U];
        let singleParamSatisfactoryLRange = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamSatisfactoryURange = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamModerateLRange = singleParamAirQualityRange[MODERATE_L];
        let singleParamModerateURange = singleParamAirQualityRange[MODERATE_U];
        let singleParamPoorLRange = singleParamAirQualityRange[POOR_L];
        let singleParamPoorURange = singleParamAirQualityRange[POOR_U];
        let singleParamVPoorLRange = singleParamAirQualityRange[V_POOR_L];
        let singleParamVPoorURange = singleParamAirQualityRange[V_POOR_U];
        let singleParamHighSevereLRange = singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamHighSevereURange = singleParamAirQualityRange[HIGH_SEVERE_U];
        let singleParamMaxRange = singleParamAirQualityRange[MAX];

        if( (singleParamGoodLRange == null && inMaxLimitValue <= singleParamGoodURange) || 
             (singleParamGoodURange == null && inMaxLimitValue > singleParamGoodLRange) ||
              (inMaxLimitValue > singleParamGoodLRange && inMaxLimitValue <= singleParamGoodURange )
          ) {
            return t(IDS_AlertLevel1);

        } else if( (singleParamSatisfactoryLRange == null && inMaxLimitValue <= singleParamSatisfactoryURange) ||
            (singleParamSatisfactoryURange == null && inMaxLimitValue > singleParamSatisfactoryLRange) ||
            (inMaxLimitValue > singleParamSatisfactoryLRange && inMaxLimitValue <= singleParamSatisfactoryURange )
        ) {

            return t(IDS_AlertLevel2);

        } else if( (singleParamModerateLRange == null && inMaxLimitValue <= singleParamModerateURange) ||
                    (singleParamModerateURange == null && inMaxLimitValue > singleParamModerateLRange) ||
                     (inMaxLimitValue > singleParamModerateLRange && inMaxLimitValue <= singleParamModerateURange )
                 ) {

            return t(IDS_AlertLevel3);

        } else if( (singleParamPoorLRange == null && inMaxLimitValue <= singleParamPoorURange) ||
                    (singleParamPoorURange == null && inMaxLimitValue > singleParamPoorLRange) ||
                    (inMaxLimitValue > singleParamPoorLRange && inMaxLimitValue <= singleParamPoorURange )
                 ) {

            return t(IDS_AlertLevel4);

        } else if( (singleParamVPoorLRange == null && inMaxLimitValue <= singleParamVPoorURange) ||
                     (singleParamVPoorURange == null && inMaxLimitValue > singleParamVPoorLRange) ||
                     (inMaxLimitValue > singleParamVPoorLRange && inMaxLimitValue <= singleParamVPoorURange )
                 ) {

            return t(IDS_AlertLevel5);

        } else if( (singleParamHighSevereLRange == null && inMaxLimitValue <= singleParamHighSevereURange) ||
                     (singleParamHighSevereURange == null && inMaxLimitValue > singleParamHighSevereLRange) ||
                     (inMaxLimitValue > singleParamHighSevereLRange && inMaxLimitValue <= singleParamHighSevereURange )
                 ) {

            return t(IDS_AlertLevel6);
            
        } 
        else {
            return "";
        }
    }

    const getAlertValueTextForAlertBasedParam = (inModelID, inMeasuredParam, inParamValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let RangeBasedOnParamValue = getRangeBasedOnParamValue(inModelID, inParamValue, inMeasuredParam);

        // Alert is generated means that the particular value was detected. Directly show value detected or not detected.
        switch(inMeasuredParam) {
            case LPG:
                return RangeBasedOnParamValue == GOOD ? t(IDS_NoGasLeakage) : t(IDS_SnsrValueLPG);
            case SMOKE:
                return t(IDS_SnsrValueSmoke);
            default:
                console.error(`Unable to get Value text for Alert based Param. Unknown Param Type: ${inMeasuredParam}`);
                return "";
        }

    }

    const getParamValueBasedOnRange = (inModelID, inMeasuredParam, inParamValue) => {
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        // In case the device has been replaced, there is a chance that two model IDs will
        // be retreived for the Alert based on which period the alert happened. The below search
        // will bring the detailed information for that particular ModelID. 
        let filteredModelInfo = modifiedState.modelInfo.find((singleModelInfo) => singleModelInfo["ModelID"] == inModelID);

        let objMeasuredParams = {};
        let paramHighLowValues = {};

        try {
            objMeasuredParams = JSON.parse(filteredModelInfo["MeasuredParams"]);
            paramHighLowValues = objMeasuredParams[inMeasuredParam];
        } catch {
            console.log(`Should not happen. MeasureParams from the filtered ModelInfo for ModelID [${inModelID}] is in invalid format.`);
            console.log(filteredModelInfo);
            objMeasuredParams = {};
            paramHighLowValues = {};
        }

        let bIsParamAlertBased = paramHighLowValues[ALERT_BASED] != null ? paramHighLowValues[ALERT_BASED] : false;
        let bIsParamNonCalibrated = paramHighLowValues[NO_CALIB] != null ? paramHighLowValues[NO_CALIB] : false;

        // Alert is generated means that the particular value was detected.
        if(bIsParamAlertBased || inMeasuredParam == LPG) {
            return getAlertValueTextForAlertBasedParam(inModelID, inMeasuredParam, inParamValue);
        }
        
        if( bIsParamNonCalibrated == false && inParamValue < paramHighLowValues["RangeMin"] ) { 
            // For a calibrated param, If sensor value is less than minimum range then restrict it to Minimum range.
            return paramHighLowValues["RangeMin"];
            
        } else if( bIsParamNonCalibrated == false && inParamValue > paramHighLowValues["RangeMax"] ) { 
            // For a calibrated param, If sensor value is greater than maximum range then restrict it Maximum range.
            return paramHighLowValues["RangeMax"];

        } else {
            return inParamValue;
        }
    }

    const getAlertMsg = (inModelID, inMeasuredParam, inParamValue, inAlertMsg, inAlertMinVal, inAlertMaxVal) => {     
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        // In case the device has been replaced, there is a chance that two model IDs will
        // be retreived for the Alert based on which period the alert happened. The below search
        // will bring the detailed information for that particular ModelID. 
        let filteredModelInfo = modifiedState.modelInfo.find((singleModelInfo) => singleModelInfo["ModelID"] == inModelID);

        let objMeasuredParams = {};
        let paramHighLowValues = {};

        try {
            objMeasuredParams = JSON.parse(filteredModelInfo["MeasuredParams"]);
            paramHighLowValues = objMeasuredParams[inMeasuredParam];
        } catch {
            console.log(`Should not happen. MeasureParams from the filtered ModelInfo for ModelID [${inModelID}] is in invalid format.`);
            objMeasuredParams = {};
            paramHighLowValues = {};
        }

        let bIsParamAlertBased = paramHighLowValues[ALERT_BASED] != null ? paramHighLowValues[ALERT_BASED] : false;
        let bIsParamNonCalibrated = paramHighLowValues[NO_CALIB] != null ? paramHighLowValues[NO_CALIB] : false;

        if(bIsParamAlertBased) {
            // Alert based messages will have highest priority.
            if(inMeasuredParam == LPG && ( inParamValue < paramHighLowValues["RangeMin"] || inParamValue > paramHighLowValues["RangeMax"] ) 
            ) {
                // Only in case of LPG we give a special treatment because even if though it is a "NoCalib = true" parameter 
                // still we indicate that "Sensor is faulty" if it goes beyond min/max range.
                return t(IDS_SnsrFaultyLPG);
            } else {
                let AlertMsgForAlertBasedParam = getAlertMsgForAlertBasedParam(inMeasuredParam, inParamValue, inAlertMinVal, inAlertMaxVal);
                return AlertMsgForAlertBasedParam; 
            }
        }

        // Incase Of param Value is less/greater than RangeMin/RangeMax modify Alert Message 
        // And set it to "Sensor needs Calibration."
        if( bIsParamNonCalibrated == false && 
            ( inParamValue < paramHighLowValues["RangeMin"] || inParamValue > paramHighLowValues["RangeMax"] )
        ) {
            // console.log("Sensor value: " + inParamValue + " range value: " +  paramHighLowValues["RangeMax"] );
            // return `Sensor for ${renderParamNameBasedOnType(inMeasuredParam)} needs Calibration.`
            // return t(IDS_SnsrNeedsCalli, renderParamNameBasedOnType(inMeasuredParam));

            // if bIsParamNonCalibrated == false and inParamValue > RangeMax or inParamValue < RangeMin 
            // then show the message ${strMeasuredParam} value ${inParamValue} has gone ${strAboveOrBelow} ${valueOfLimitCrossed} ${strUnitOfMeasuredParam}
            // where ${inParamValue} in the messsage should be equals to RangeMax or RangeMin
            if(inParamValue < paramHighLowValues["RangeMin"]) {
                inParamValue =  paramHighLowValues["RangeMin"];
            } else if(inParamValue > paramHighLowValues["RangeMax"]) {
               inParamValue = paramHighLowValues["RangeMax"];
            } else {
                inParamValue = inParamValue;
            }

            if (inMeasuredParam == NH3OD){
                let AlertMsgForNH3OD = getAlertMsgForLevelBasedParam(inMeasuredParam, inParamValue, inAlertMsg);
                return AlertMsgForNH3OD; 
            } else {
                let AlertMsgForAllExceptNH3OD = getTranslatedAlertMessage(inMeasuredParam, inParamValue, inAlertMinVal, inAlertMaxVal, inModelID, paramHighLowValues["RangeMin"], paramHighLowValues["RangeMax"]);
                return AlertMsgForAllExceptNH3OD;
            }
            
        } 

        if(inMeasuredParam == NH3OD && (inParamValue >= paramHighLowValues["RangeMin"] && inParamValue <= paramHighLowValues["RangeMax"]) ) {
            let AlertMsgForNH3OD = getAlertMsgForLevelBasedParam(inMeasuredParam, inParamValue, inAlertMsg);
            return AlertMsgForNH3OD; 
        } else {
            let AlertMsgForAllExceptNH3OD = getTranslatedAlertMessage(inMeasuredParam, inParamValue, inAlertMinVal, inAlertMaxVal, inModelID, paramHighLowValues["RangeMin"], paramHighLowValues["RangeMax"]);
             return AlertMsgForAllExceptNH3OD;
        } 
    }

    const getAlertMsgForAlertBasedParam = (inMeasuredParam, inParamValue, inAlertMinVal, inAlertMaxVal) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        // Alert is generated means that the particular value was detected. Directly show message detected or not detected.
        switch(inMeasuredParam) {
            // case LPG:
            //     return t(IDS_AlertMessageLPG);
            case SMOKE:
                return t(IDS_AlertMessageSmoke);
            default:
                console.error(`Unable to get message for Alert based Param. Unknown Param Type: ${inMeasuredParam}`);
                return "";
        }
    }

    const getAlertMsgForLevelBasedParam = (inMeasuredParam, inParamValue, inAlertMsg) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = state;

        let singleParamAirQualityInfo = 
                modifiedState.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] == inMeasuredParam && arrElement["RangeValues"] != null );

        if(singleParamAirQualityInfo == null) {
            console.log(`Should not happen. Param [${inMeasuredParam}] was not found in stdAirQualityRange.`);
            return "";
        }        

        let singleParamAirQualityRange = {};
        try {
            singleParamAirQualityRange = JSON.parse(singleParamAirQualityInfo.RangeValues);
        } catch {
            console.log(`Should not happen. StdAirQualityRange for Param [${inMeasuredParam}] is in invalid JSON format.`);
            return "";
        }

        let singleParamLowRange = singleParamAirQualityRange[LOW];
        let singleParamGoodLRange = singleParamAirQualityRange[GOOD_L];
        let singleParamGoodURange = singleParamAirQualityRange[GOOD_U];
        let singleParamSatisfactoryLRange = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamSatisfactoryURange = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamModerateLRange = singleParamAirQualityRange[MODERATE_L];
        let singleParamModerateURange = singleParamAirQualityRange[MODERATE_U];
        let singleParamPoorLRange = singleParamAirQualityRange[POOR_L];
        let singleParamPoorURange = singleParamAirQualityRange[POOR_U];
        let singleParamVPoorLRange = singleParamAirQualityRange[V_POOR_L];
        let singleParamVPoorURange = singleParamAirQualityRange[V_POOR_U];
        let singleParamHighSevereLRange = singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamHighSevereURange = singleParamAirQualityRange[HIGH_SEVERE_U];
        let singleParamMaxRange = singleParamAirQualityRange[MAX];

        // if( (singleParamGoodLRange == null && inParamValue <= singleParamGoodURange) || 
        //      (singleParamGoodURange == null && inParamValue > singleParamGoodLRange) ||
        //       (inParamValue > singleParamGoodLRange && inParamValue <= singleParamGoodURange )
        //   ) {
        //       console.log(inParamValue, singleParamGoodLRange, singleParamGoodURange);
        //     // return "L1" + " ("+inParamValue + ")";
        //     return "Odour has reached Level 1. Odour is Good."; 
        // } else 

        if( (singleParamSatisfactoryLRange == null && inParamValue <= singleParamSatisfactoryURange) ||
                    (singleParamSatisfactoryURange == null && inParamValue > singleParamSatisfactoryLRange) ||
                     (inParamValue > singleParamSatisfactoryLRange && inParamValue <= singleParamSatisfactoryURange )
            ) {

            return t(IDS_AlertOdourL2);

        } else if( (singleParamModerateLRange == null && inParamValue <= singleParamModerateURange) ||
                (singleParamModerateURange == null && inParamValue > singleParamModerateLRange) ||
            (inParamValue > singleParamModerateLRange && inParamValue <= singleParamModerateURange )
            ) {

            return t(IDS_AlertOdourL3);

        } else if( (singleParamPoorLRange == null && inParamValue <= singleParamPoorURange) ||
                    (singleParamPoorURange == null && inParamValue > singleParamPoorLRange) ||
                    (inParamValue > singleParamPoorLRange && inParamValue <= singleParamPoorURange )
                 ) {

            return t(IDS_AlertOdourL4);

        } else if( (singleParamVPoorLRange == null && inParamValue <= singleParamVPoorURange) ||
                     (singleParamVPoorURange == null && inParamValue > singleParamVPoorLRange) ||
                     (inParamValue > singleParamVPoorLRange && inParamValue <= singleParamVPoorURange )
                 ) {

            return t(IDS_AlertOdourL5);

        } else if( (singleParamHighSevereLRange == null && inParamValue <= singleParamHighSevereURange) ||
                     (singleParamHighSevereURange == null && inParamValue > singleParamHighSevereLRange) ||
                     (inParamValue > singleParamHighSevereLRange && inParamValue <= singleParamHighSevereURange )
                 ) {

            return t(IDS_AlertOdourL6);
        } else {
            return t(IDS_AlertOdourL6);
        }
    }

    const getUnitsBasedOnParamtype = inParamType => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;           

        let retUnit = '';
        switch(inParamType) {
            case NO2:
            case O3:
            case SO2:
            case VOC:
            case H2S:
            case HCHO:
            case CL:
            case CH3SH:
                retUnit = t(IDS_PPB);
                break;
            case CO:
            case NH3:
            case CO2:
            case LPG:
            case NH3OD:
            case NO:
            case NH3:    
                retUnit = t(IDS_PPM);
                break;
            case PM1:
            case PM25:
            case PM10:
                retUnit = '㎍/㎥ ';
                break;
            case TEMP:
                retUnit =  t(IDS_Celsius);
                break;
            case HUM:
            case WLIP:
            case SLIP:
                retUnit = t(IDS_Percent);
                break;
            case VRI:
            case MRI:
            case UV:
                retUnit = t(IDS_Index);
                break;  
            case AQI:
            case CAQI:
                retUnit = "";
                break;
            case O2:
            case CH4:
            case H2:
                retUnit = '%'
                break;
            case NOISE:
                retUnit = 'dB';
                break;
            case LUX:
                retUnit = 'lux';
                break;
            case RADON:
                retUnit = 'Bq/m³';
                break;
            case AP:
                retUnit = 'hPa';
                break;
            case WS:
                retUnit = 'm/s';
                break;
            case WD:
                retUnit = '°';
                break;
            case DLV:
                retUnit = 'km';
                break;
            case RNFL:
                retUnit = 'mm';
                break;
            default:
                console.error(`Unable to get Units. Unknown Param Type: ${inParamType}`);
                retUnit = '';
                break;
            }
        return " " + retUnit;
    }

    const getTranslatedAlertMessage = (inMeasuredParam, inParamValue, inAlertMinVal, inAlertMaxVal, inModelID, rangeMinVal, rangeMaxVal) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let tstrAlertMessage = "";
        const paramParsedValue = parseFloat(inParamValue);
        const alertMaxParsedValue = inAlertMaxVal == null ? parseFloat(rangeMaxVal) : parseFloat(inAlertMaxVal);
        const alertMinParsedValue = inAlertMinVal == null ? parseFloat(rangeMinVal) : parseFloat(inAlertMinVal);

        let strMeasuredParam = renderParamNameBasedOnType(inMeasuredParam);
        let strUnitOfMeasuredParam = getUnitsBasedOnParamtype(inMeasuredParam);
        
        if(paramParsedValue <= alertMaxParsedValue && paramParsedValue >= alertMinParsedValue) {
            tstrAlertMessage = inMeasuredParam == LPG ? t(IDS_RemedyAlertMsgForLPGLevelNormalized) : t(IDS_IsOKAlert, strMeasuredParam, inParamValue, strUnitOfMeasuredParam) + " " + t(IDS_RemedyAlertMsgForHumTempLevelNormalized, strMeasuredParam);
        } else {
            let strAboveOrBelow = (paramParsedValue > alertMaxParsedValue) ? t(IDS_AlertStrAbove) : t(IDS_AlertStrBelow);
            let valueOfLimitCrossed = inAlertMaxVal;
            tstrAlertMessage = (inMeasuredParam == LPG ? t(IDS_AlertMessageLPG) : t(IDS_AlertMessage, strMeasuredParam, inParamValue, strAboveOrBelow, valueOfLimitCrossed, strUnitOfMeasuredParam)) + " " + getRemedyMsgBasedOnLevel(inModelID, inMeasuredParam, inParamValue);
        }

        return tstrAlertMessage;
    }

    const getParamValueBasedOnRangeForNH3OD = (inParamValueForNH3OD) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = state;

        let singleParamAirQualityInfo = 
                modifiedState.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] == NH3OD && arrElement["RangeValues"] != null );
        
        if(singleParamAirQualityInfo == null) {
            console.log(`Should not happen. Param [NH3OD] was not found in stdAirQualityRange.`);
            return "";
        }
        
        let singleParamAirQualityRange = {};
        try {
            singleParamAirQualityRange = JSON.parse(singleParamAirQualityInfo.RangeValues);
        } catch {
            console.log(`Should not happen. StdAirQualityRange for Param [NH3OD] is in invalid JSON format.`);
            return "";
        }

        let singleParamLowRange = singleParamAirQualityRange[LOW];
        let singleParamGoodLRange = singleParamAirQualityRange[GOOD_L];
        let singleParamGoodURange = singleParamAirQualityRange[GOOD_U];
        let singleParamSatisfactoryLRange = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamSatisfactoryURange = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamModerateLRange = singleParamAirQualityRange[MODERATE_L];
        let singleParamModerateURange = singleParamAirQualityRange[MODERATE_U];
        let singleParamPoorLRange = singleParamAirQualityRange[POOR_L];
        let singleParamPoorURange = singleParamAirQualityRange[POOR_U];
        let singleParamVPoorLRange = singleParamAirQualityRange[V_POOR_L];
        let singleParamVPoorURange = singleParamAirQualityRange[V_POOR_U];
        let singleParamHighSevereLRange = singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamHighSevereURange = singleParamAirQualityRange[HIGH_SEVERE_U];
        let singleParamMaxRange = singleParamAirQualityRange[MAX];

        if( (singleParamGoodLRange == null && inParamValueForNH3OD <= singleParamGoodURange) || 
             (singleParamGoodURange == null && inParamValueForNH3OD > singleParamGoodLRange) ||
              (inParamValueForNH3OD > singleParamGoodLRange && inParamValueForNH3OD <= singleParamGoodURange )
          ) {
            
            return t(IDS_AlertLevel1Value);

        } else if( (singleParamSatisfactoryLRange == null && inParamValueForNH3OD <= singleParamSatisfactoryURange) ||
        (singleParamSatisfactoryURange == null && inParamValueForNH3OD > singleParamSatisfactoryLRange) ||
         (inParamValueForNH3OD > singleParamSatisfactoryLRange && inParamValueForNH3OD <= singleParamSatisfactoryURange )
            ) {

        return t(IDS_AlertLevel2Value);

        } else if( (singleParamModerateLRange == null && inParamValueForNH3OD <= singleParamModerateURange) ||
                    (singleParamModerateURange == null && inParamValueForNH3OD > singleParamModerateLRange) ||
                     (inParamValueForNH3OD > singleParamModerateLRange && inParamValueForNH3OD <= singleParamModerateURange )
                 ) {

            return t(IDS_AlertLevel3Value);

        } else if( (singleParamPoorLRange == null && inParamValueForNH3OD <= singleParamPoorURange) ||
                    (singleParamPoorURange == null && inParamValueForNH3OD > singleParamPoorLRange) ||
                    (inParamValueForNH3OD > singleParamPoorLRange && inParamValueForNH3OD <= singleParamPoorURange )
                 ) {

            return t(IDS_AlertLevel4Value);

        } else if( (singleParamVPoorLRange == null && inParamValueForNH3OD <= singleParamVPoorURange) ||
                     (singleParamVPoorURange == null && inParamValueForNH3OD > singleParamVPoorLRange) ||
                     (inParamValueForNH3OD > singleParamVPoorLRange && inParamValueForNH3OD <= singleParamVPoorURange )
                 ) {

            return t(IDS_AlertLevel5Value);

        } else if( (singleParamHighSevereLRange == null && inParamValueForNH3OD <= singleParamHighSevereURange) ||
                     (singleParamHighSevereURange == null && inParamValueForNH3OD > singleParamHighSevereLRange) ||
                     (inParamValueForNH3OD > singleParamHighSevereLRange && inParamValueForNH3OD <= singleParamHighSevereURange )
                 ) {

            return t(IDS_AlertLevel6Value);
            
        } 
        else {
            return "";
        }
    }

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t; 
    let HasSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
    let IsSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.isDevc;  
    let SelectedNodeContainsChildNodes = appRelevantDataContextValue.selectedNodeInfo.containsChildNode;
    let SelectedNodeDeviceType = appRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType;

    return (
        (state.selectedTreeNodeID == null || state.selectedTreeNodeID.length <=0)
        ?
            <div className="sddSingleParam">
                <VcLoader />
            </div>
        :  
        (state.selectedTreeNodeID.length > 0 && state.selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER)
        ?
            <div className="sddSingleParam">
                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 fs-5 d-flex justify-content-center align-items-center">
                        {t(IDS_GeneralUser)}
                    <div style={{margin:"0.5rem"}}>
                        <VcSetupDevice/>
                    </div>               
                </div>
            </div>
        :
        (!IsSelectedNodeDevices && !SelectedNodeContainsChildNodes)
        ?
            <div className="sddSingleParam">
                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8">
                    {
                        HasSelectedNodeDevices 
                        ?
                        t(IDS_InfoMsgForTreeNodeWhileChildDevicesMissingInAlertPg, state.selectedTreeNodeTitle)
                        :
                        t(IDS_InfoMsgForTreeNodeWhileChildNodesMissingInAlertPg, state.selectedTreeNodeTitle)
                    }
                </div>
            </div>
        :
        //  if the selected node itself is not the device
        // then show default msg to the user.
        ((!HasSelectedNodeDevices && !IsSelectedNodeDevices) || HasSelectedNodeDevices)
        ?
            <div className="sddSingleParam">
                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8">
                        {t(IDS_DefInfoMsgForTreeNodeInAlertPg, state.selectedTreeNodeTitle)}
                </div>                  
            </div>
        :
        // if the selected node is PFC Device
        // then show default msg to the user.
        (SelectedNodeDeviceType != null && SelectedNodeDeviceType.length > 0 && SelectedNodeDeviceType == DEVICE_TYPE_PFC)
        ?
            <div className="sddSingleParam">
                    <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8">
                            {t(IDS_InfoMsgForPFCDevicesInAlertPg, state.selectedTreeNodeTitle)}  
                    </div>
            </div>
        :
        // If the dveice has been blocked due to non-payment if some cost.
        (state.isDeviceBlocked == true)
        ?
        <div className="sddSingleParam">
            <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8">
                {t(IDS_DevcBlockedDueToNonPaymentOfSomeCost)}
            </div>
        </div>
        :
        // <div style={{height:'88.5vh', width: "99%"}}>
        <div >
            
            {/* Show blocked Devices Data to Production/Support Users. */}
            { state.bCheckDeviceBlockedStatus == true && state.havingPvgToViewBlockedDeviceData == true ?
                <div style={{color:"green", marginTop:"0.1rem", fontSize:"0.8rem"}}>Note: The Device is blocked or Rental Period is expired. Production/Support Team can view the data.</div>
            : 
                null
            }
            
            {/* <div className="container col-lg-12 pb-2 DeviceNameOfSD mt-3" style={{position: "relative", display: "flex", padding: "0", justifyContent: "center"}}> */}
            <div className='py-2 w-100 flex items-center justify-between ' >
                <span className='text-left flex align-items-center gap-1' style={{minHeight: "30px"}}>
                    <small>{props.fullPath}</small>
                    <small className='selectedNodeTitle' style={{fontWeight:"600"}}>
                        {state.selectedTreeNodeTitle}
                    </small>
                </span>
                <button className='refreshButtonStyle'
                    onClick={onRefreshBtnClicked} 
                    title={t(IDS_RefreshData)}
                    >
                    <i className="fa fa-refresh flex-center" id='alertLogRefreshButton'
                    style={{ paddingLeft: "0", fontSize: "20px"}} 
                    aria-hidden="true"></i>
                </button>
            </div>

            <div className="" style={{borderRadius:"15px", overflow:"hidden"}}>
                <ReactTable columns={state.columns} 
                    data={state.data}
                    // as React Table is outside the main function, state varaibles cant be accessed there 
                    // so passing all the state variables on which react table is dependent through passedStateVariable
                    passedStateVariable = {[state.loading, t(IDS_AlertNoAlertToShow)]}/>
            </div>
        </div>
    );
}

export default VcAlertLog;
