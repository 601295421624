import React, { useContext, useEffect, useState } from 'react'
import { AppRelevantDataContext } from '../../../../AppContext';
import { AQI, TEMP, NH3OD, PFC, WLIP, SLIP, LUX, WD, RNFL, PROD_DEVC_OWNER_USER_ID, LPG, PM1, VRI } from '../../../../VcConstants';
import { IDS_UsedWater, IDS_AUSrvrIssueReqParamsNotSent, IDS_LoginServerIssue, IDS_Last24HourData, IDS_NoDataFoundLast24Hour, IDS_RenewSubsMsg, IDS_No24HourData, IDS_LPG } from '../../../../VcLanguage';
import VcLoader from './VcLoader';
import { convertDateToStrMMMDDHH00, getListOfHoursBetweenRange, getLocalTimezoneOffset } from '../../../../vtUtil';
import { getAPIHostURL } from '../../../../ClientConfig';
import axios from 'axios'
import { Line } from 'react-chartjs-2';
import { Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { setMaxUsage, setMinUsage, setMinUsageHour, setMaxUsageHour, setCurrentHourUsagesWLI, setMaxUsageHourWLI, setMinUsageHourWLI } from '../../Store/Slices/variables';

const VcDailyChart = (props) => {
    const context = useContext(AppRelevantDataContext)
    let t = context.t
    const [state, setState] = useState({
        dataPresent: false,
        receivedChartData: [],
        chartDataObj: null,
        latestLogTime: '',
    });
    
    const dispatch = useDispatch();
    const [loading, setloading] = useState(true);
    const [paramName, setParamName] = useState('');
    let noChartParms = [LUX, WD];

    useEffect(() => {
        if(props.SingleDeviceParamSequence.length == 0 && props.isPFC == null) return;
        // Does not call if SingleDeviceParamSequence is not present
        let paramSelectedInContext = context.quickTrackParamInfo.quickTrackParam;
        if(props.isPFC != null && props.isPFC == true) {
            setParamName(PFC)
        } else if (noChartParms.includes(paramSelectedInContext)) {
            if (props.SingleDeviceParamSequence.includes(AQI)) {
                setParamName(AQI)
            } else {
                setParamName(TEMP)
            }
        } else {
            if(props.SingleDeviceParamSequence.includes(paramSelectedInContext)) {
                setParamName(paramSelectedInContext)
            } else {
                if (props.SingleDeviceParamSequence.includes(NH3OD)) {
                    setParamName(NH3OD)
                } else if (props.SingleDeviceParamSequence.includes(AQI)) {
                    setParamName(AQI)
                } else if (props.SingleDeviceParamSequence.includes(WLIP)) {
                    setParamName(WLIP)
                } else if (props.SingleDeviceParamSequence.includes(SLIP)) {
                    setParamName(SLIP)
                } else if (props.SingleDeviceParamSequence.includes(VRI)) {
                    setParamName(VRI)
                } else if (props.SingleDeviceParamSequence.includes(PM1)) {
                    setParamName(PM1)
                } else if (props.SingleDeviceParamSequence.includes(TEMP)) {
                    setParamName(TEMP)
                } else if (props.SingleDeviceParamSequence.includes(LPG)) {
                    setParamName(LPG)
                }  else {
                    // setParamName(TEMP)
                    setParamName(paramSelectedInContext);
                }
            }
        }
        
    }, [context.quickTrackParamInfo.quickTrackParam, context.selectedNodeInfo.nodeID, props.SingleDeviceParamSequence])

    useEffect(() => {
      if(paramName != '') {
        if(props.isDeviceBlocked) {
            if(props.isUserHavingPvgToViewBlockedData == true && paramName != LPG) {
                fetchLast24HourData();  // API Call to fetch Daily Chart
            } else {
                //When blocked set loading to false and set receivedChartData to [] so that makeChart is triggred again
                setloading(false);
                setState({...state, receivedChartData: []});
            }
        } else {
            setloading(true);

            // To make API call if there is device data in last 24-hour time period
            const chartData = [];
            const current24Hours =  new Date();
            const check24HourDuration = checkTimeWithin24Hours(current24Hours, props.splitedDateTime);
            if(check24HourDuration === "Within24Hour" && paramName != LPG) {
                fetchLast24HourData();  // API Call to fetch Daily Chart
            } else {
                setloading(false);
                makeChart(chartData);
            }
        }
        
      }
    }, [paramName, props.dailyChartFlag, context.selectedNodeInfo.nodeID])

    // useEffect(() => {
    //     // if(paramName != '') {
    //     //     makeChart()
    //     // }

    //     if(paramName == PFC || paramName == WLIP) {
    //         // Setting up pfc details using redux toolkit
    //         let mx = 0;
    //         let mn = 1000;
    //         let mxObj, mnObj;
    //         let idx = state.receivedChartData.length - 1
    //         let lastValue =  '--'
    //         console.log("state.receivedChartData = ",state.receivedChartData);
    //         console.log("state.receivedChartData [idx] = ",state.receivedChartData[idx]);
    //         console.log("state.latestLogTime = ",state.latestLogTime);
    //         console.log("state.receivedChartData [idx].FmtLogTime = ",state.receivedChartData[idx].FmtLogTime);

    //         if (idx >= 0 && state.receivedChartData[idx].Avg != null && state.receivedChartData[idx].Avg != undefined) {
    //             if (state.latestLogTime == state.receivedChartData[idx].FmtLogTime) {
    //                 lastValue =  state.receivedChartData[idx].Avg;
    //             }
    //         }
    //         console.log("lastValue ==",lastValue);
    //         state.receivedChartData.forEach(data => {
    //             let val = data["Avg"]
    //             if (mx < val) {
    //                 mx = val
    //                 mxObj = data;
    //             }
    //             if (mn > val) {
    //                 mn = val
    //                 mnObj = data;
    //             }
    //         })
    //         if (paramName == PFC) {
    //             if(mx != 0) {
    //                 dispatch(setMaxUsage(mx));
    //                 dispatch(setMaxUsageHour(getTime(mxObj["FmtLogTime"])))
    //             } else {
    //                 dispatch(setMaxUsage("--"));
    //                 dispatch(setMaxUsageHour("--"))
    //             }
    //             if(mn != 1000) {
    //                 dispatch(setMinUsageHour(getTime(mnObj["FmtLogTime"])))
    //             } else {
    //                 dispatch(setMinUsageHour("--"))
    //             }
    //         } else {
    //             if(mx != 0) {
    //                 dispatch(setMaxUsageHourWLI(mx + ` L  (${getPreviousHour(mxObj["FmtLogTime"])} ~ ${getTime(mxObj["FmtLogTime"])}) `))
    //             } else {
    //                 dispatch(setMaxUsageHourWLI("--"))
    //             }
    //             if(mn != 1000) {
    //                 dispatch(setMinUsageHourWLI(mn + ` L  (${getPreviousHour(mnObj["FmtLogTime"])} ~ ${getTime(mnObj["FmtLogTime"])}) `))
    //             } else {
    //                 dispatch(setMinUsageHourWLI("--"))
    //             } 

    //             if (lastValue != null) {
    //                 dispatch(setCurrentHourUsagesWLI(lastValue));
    //             } else {
    //                 dispatch(setCurrentHourUsagesWLI("--"));

    //             }

    //         }
            
    //     }

    // }, [state.receivedChartData])

    const getColor = (val) => {
        if(paramName == PFC) {
            return '#50c878';
        } 
        if(paramName == WLIP) {
            return '#2c74b3';
        }
        if(paramName == SLIP) {
            return '#d9a23a'
        }
        if(paramName == RNFL) {
            return '#0958d9';
        }

        try {
            let { styleValueText } = props.getParamValueTextAndBoxStyleBasedOnRange(val, paramName);

            if(styleValueText == 'ParamsValueRangeGood') {
                return '#50c878';
            } else if (styleValueText == 'ParamsValueRangeSatisfactory') {
                return '#9acd32';
            } else if (styleValueText == 'ParamsValueRangeModerate') {
                return '#ffff00';
            } else if (styleValueText == 'ParamsValueRangePoor') {
                return '#ffa500';
            } else if (styleValueText == 'ParamsValueRangeVeryPoor') {
                return '#ff0000';
            } else if (styleValueText == 'ParamsValueRangeSevere') {
                return '#c00000'
            } else if (styleValueText == 'ParamsValueRangeLowVPoor') {
                return 'red';
            } else if (styleValueText == 'ParamsValueMissingOrWithoutRange') {
                // return '#d9a23a';
                return 'gray'
            }
        } catch (err) {
            console.log(err);
            return 'gray';
        }
        return 'gray';
    }
   
    const getNextHour = (dateTime) => {
        let dayTime = new Date(dateTime)
        let hour = dayTime.getHours() + 1;
        if(hour >= 12) {
            if(hour == 12) {
                return 12 + " pm"
            }
            return hour - 12 + " pm"
        }
        return hour + ' am';
    }

    const getTime = (dateTime) => {
        let dayTime = new Date(dateTime)
        let hour = dayTime.getHours()
        if(hour >= 12) {
            if(hour == 12) {
                return 12 + " pm"
            }
            return hour - 12 + " pm"
        }
        return hour + ' am';
    }

    const makeChart = async (receivedChartData) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;    

        let selectedParamType = paramName;
        let localTimeZone = (new Date()).getTimezoneOffset();

        let recChartData = receivedChartData;
        let chartData = [];
        let chartDataFmtLogTime = [];

        let arrChartDatasets = [];
        let objChartOptions = {};

        let chartRangeStartDate = null;
        let chartRangeEndDate = null;

        chartRangeEndDate = new Date();
        chartRangeStartDate = new Date(chartRangeEndDate.valueOf());

        chartRangeStartDate.setHours(chartRangeStartDate.getHours() - 23);

        let listPeriodDtTm = [];
        listPeriodDtTm = getListOfHoursBetweenRange(chartRangeStartDate, chartRangeEndDate);
          
        let noOfReceivedChartDataPoints = recChartData.length;
        let noOfEstimatedXAxisPoints = listPeriodDtTm.length;
        let nextListPeriodDtTm = null;
        let strNextFormatLogTime = null;
        let temp = null;
        let nextUnusedChartDataPosition = 0; 
        let latestTm = ''
        if(props?.SingleDeviceParamSequence != null && props.SingleDeviceParamSequence.length > 0 && props.SingleDeviceParamSequence[0] != LPG) {
            for(let j=0; (  j < noOfEstimatedXAxisPoints ); j++) {
                nextListPeriodDtTm = listPeriodDtTm[j];
                
                strNextFormatLogTime = convertDateToStrMMMDDHH00( nextListPeriodDtTm );
                //Saves last time to compare val for current hour
                latestTm = strNextFormatLogTime
                temp = getTime(nextListPeriodDtTm)
                     
    
                if( nextUnusedChartDataPosition < noOfReceivedChartDataPoints &&
                    recChartData[nextUnusedChartDataPosition].FmtLogTime == strNextFormatLogTime 
                ) {
                    chartData.push(recChartData[nextUnusedChartDataPosition].Avg); // Data found
                    ++nextUnusedChartDataPosition; 
    
                } else {
                    chartData.push(null); 
                }
    
                if(!chartDataFmtLogTime.includes(temp)) {
                    chartDataFmtLogTime.push(temp);
                }
            }
        }
        
        objChartOptions = {
            responsive: true,
            plugins: {
                legend: {
                    display: false 
                },
                tooltip: {
                    callbacks: {
                      label: function(context) {
                        let label = paramName == "WLIP" ? t(IDS_UsedWater) + ": " + context.formattedValue + ' ' + props.getUnits(paramName)
                        : paramName + ": " + context.formattedValue + ' ' + props.getUnits(paramName);
                        return label;
                      },
                    },
                }
            },
            scales: {
                y: {
                    ticks: {
                        display: false  
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: props.SingleDeviceParamSequence[0] == LPG ? "" : props.getUnits(selectedParamType, t),
                    }, 

                    grid: {
                        display: false
                    }
                },
                x: { 
                    grid: {
                        display: false
                    },                       
                }                        
            }
        }

         

        arrChartDatasets.push(
            {
                label: `${props.renderParamNameBasedOnType(paramName)}  ${props.getUnits(paramName)}`,
                data: chartData,
                type: "line", 
                fill: true,
                backgroundColor: '#e7e7e7',
                pointBackgroundColor: chartData.map((data) => {
                    return data != null ? getColor(data) : "black";
                }),
                tension: 0.3,
                borderColor: "#1875f677",
                pointRadius : 6,
                hoverRadius: 8,
            }
        );  

        let latestChartObj = {               
            type: "line",
            options: objChartOptions,
            data: {
                labels: chartDataFmtLogTime,
                datasets: arrChartDatasets,
            },
        }
        

        if(recChartData != null && recChartData.length > 0 && (paramName == PFC || paramName == WLIP)) {
            // Setting up pfc details using redux toolkit
            let mx = 0;
            let mn = 1000;
            let mxObj, mnObj;
            let idx = recChartData.length - 1
            let lastValue =  '--'

            if (idx >= 0 && recChartData[idx].Avg != null && recChartData[idx].Avg != undefined) {
                if (latestTm == recChartData[idx].FmtLogTime) {
                    lastValue =  recChartData[idx].Avg;
                }
            }
            recChartData.forEach(data => {
                let val = data["Avg"]
                if (mx <= val) {
                    mx = val
                    mxObj = data;
                } 
                if (mn >= val) {
                    mn = val
                    mnObj = data;
                }
            })

            if(mx != null && mn != null) {
                if (paramName == PFC) {
                    if(mx != 0) {
                        dispatch(setMaxUsage(mx));
                        dispatch(setMaxUsageHour(getTime(mxObj["FmtLogTime"])))
                    } else {
                        dispatch(setMaxUsage("--"));
                        dispatch(setMaxUsageHour("--"))
                    }
                    if(mn != 1000) {
                        dispatch(setMinUsage(mn));
                        dispatch(setMinUsageHour(getTime(mnObj["FmtLogTime"])))
                    } else {
                        dispatch(setMinUsageHour("--"))
                    }
                } else {
                    if(mx != 0) {
                        dispatch(setMaxUsageHourWLI(mx + ` Ltr  (${getTime(mxObj["FmtLogTime"])} ~ ${getNextHour(mxObj["FmtLogTime"])})`))
                    } else {
                        dispatch(setMaxUsageHourWLI("--"))
                    }
                    if(mn != 1000) {
                        dispatch(setMinUsageHourWLI(mn + ` Ltr  (${getTime(mnObj["FmtLogTime"])} ~ ${getNextHour(mnObj["FmtLogTime"])}) `))
                    } else {
                        dispatch(setMinUsageHourWLI("--"))
                    } 

                    if (lastValue != null) {
                        dispatch(setCurrentHourUsagesWLI(lastValue));
                    } else {
                        dispatch(setCurrentHourUsagesWLI("--"));

                    }
                }
            }
            
        }

        setState({
            ...state, chartDataObj: latestChartObj, latestLogTime : latestTm, receivedChartData : recChartData
        })
        setloading(false)
    
    }

    const checkTimeWithin24Hours = (now, deviceDataTime) => {
        // Extract the hours, minutes, and seconds from the deviceDataTime
        let [hours, minutes, seconds] = deviceDataTime[1].split(":").map(Number);
        let date, month, year;

        if (!deviceDataTime[0].includes("Today")) {
            [date, month, year] = deviceDataTime[0].split("-");
            month = convertMonthToIndex(month); // Convert month name to zero-indexed month
            date = Number(date);
            year = Number(year);
        } else {
            year = now.getFullYear();
            month = now.getMonth();
            date = now.getDate();
        }

        // Create a Date object for the device date time
        let deviceTime = new Date(year, month, date, hours, minutes, seconds);

        // Calculate the difference in milliseconds between device time and current time
        let differenceInMillis = now - deviceTime;

        // Convert the difference to seconds between device time and current time
        let differenceInSeconds = differenceInMillis / 1000;

        // Reutrns 1, 0 or -1 for if differenceInSeconds value is negative, zero or positive 
        let positiveDiffInSecs = Math.sign(differenceInSeconds);

        if(positiveDiffInSecs != -1 && positiveDiffInSecs != 0) {
            // Check if the difference is less than or equal to 86400 seconds (24 hours)
            if (differenceInSeconds <= 86400) {
                return "Within24Hour";
            } else {
                return "NotWithin24Hour"
            }
        }
    }

    // Function to convert month name to zero-indexed month number
    const convertMonthToIndex = (monthName) => {
        const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthArr.indexOf(monthName);
    }

    const fetchLast24HourData = () => {
                
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;    

        let selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        let localTimeZone = (new Date()).getTimezoneOffset();
        
        let jsonParams = {
            selectedTreeNodeID: selectedTreeNodeID,
            selectedParamType: paramName,
            periodToView:  "Daily",
            StartDateTime:  null,
            EndDateTime:  null,
            loggedInUserID: loggedInUserID,
            localTimeZone: getLocalTimezoneOffset( localTimeZone )
        }
       
        axios.post(`${getAPIHostURL()}/wclient/getLastTwentyFourHourData`,jsonParams)
        .then(response => {
        appRelevantDataContextValue.onChangeProcessingReq(false);
        if(response.data.code == "SUCCESS") {
            let receivedChartData = response.data.retrievedChartData;

            makeChart(receivedChartData);

            // setState({
            //     ...state, receivedChartData: receivedChartData,
            //     error: ''
            // })
        } else {
            let errorMsg = ''
            if (response.data.code == 'REQ_PARAMS_MISSING') {
                errorMsg = t(IDS_AUSrvrIssueReqParamsNotSent);
            } else if (response.data.code == 'SQL_ERROR') {
                // Tell the user that Server is experiencing errors
                errorMsg = t(IDS_LoginServerIssue);
            } else if (response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                // Tell the user that Server is experiencing errors
                errorMsg = t(IDS_LoginServerIssue);
            } else if (response.data.code == 'DEVICE_IS_BLOCKED') {
                errorMsg.isDeviceBlocked = true;
            } else {
                console.log('Should not reach here');
                errorMsg = t(IDS_LoginServerIssue);
            }
            setState({...state,
                // receivedChartData : [],  // Can start with null and then change after api call to remake chart
                // Currently DeviceContinuity Id is missing error for devices is there and on error chart is not made
                error:errorMsg
            })
            setloading(false)


        }})
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                console.log(error);
            }
            setloading(false)
        })
    }

    // Add error message text
    const quickTrackParameter = context.quickTrackParamInfo.quickTrackParam;
    const singleDeviceParameterSeq = props?.SingleDeviceParamSequence;

    return (
        <div className='w-100 bg-white rounded flex relative flex-col' style={{minHeight:'200px'}}>
            <div className='flex p-2 justify-between items-center'>
                <span className='text-start'>
                    {t(IDS_Last24HourData)}
                </span>
                {paramName.length > 0 && (
                    <Tag bordered={false} color="blue" style={{fontSize:"0.8rem"}}>
                        {props.renderParamNameBasedOnType(paramName)}
                    </Tag> 
                )}
            </div>

            {loading ? 
            <div className=' bg-white rounded flex flex-center flex-col' style={{height:'250px'}}>
                <VcLoader/> 
            </div>
            : 
            (paramName == LPG) ?
                <>
                    <Line style={{maxHeight:"250px", maxWidth:'100%'}}  options={state.chartDataObj?.options}  data={state.chartDataObj?.data} />
                    <div style={{fontSize:'1.2rem'}} className='absolute-center font-weight-bold text-secondary fw-4'>
                        {t(IDS_No24HourData, t(IDS_LPG))}
                    </div>
                </>
            :
            state.chartDataObj ? 
            <>
                <Line style={{maxHeight:"250px", maxWidth:'100%'}}  options={state.chartDataObj?.options}  data={state.chartDataObj?.data} />
                {/* {props.isDeviceBlocked && !props.isUserHavingPvgToViewBlockedData ? 
                    <div style={{fontSize:'1.2rem'}} className='absolute-center font-weight-bold text-secondary fw-4'>
                        {t(IDS_RenewSubsMsg)}
                    </div>
                : state.receivedChartData.length == 0 && 
                    <div style={{fontSize:'1.2rem'}} className='absolute-center font-weight-bold text-secondary fw-4'>
                        {t(IDS_NoDataFoundLast24Hour)}
                    </div>
                } */}
                {
                    state.receivedChartData != null && state.receivedChartData.length <= 0 && 
                        <div style={{fontSize:'1.2rem'}} className='absolute-center font-weight-bold text-secondary fw-4'>
                            {t(IDS_NoDataFoundLast24Hour)}
                        </div>
                }
            </>
            : 
            <>
                <Line style={{maxHeight:"250px", maxWidth:'100%'}}  options={state.chartDataObj?.options}  data={state.chartDataObj?.data} />
                <div style={{fontSize:'1.2rem'}} className='absolute-center font-weight-bold text-secondary fw-4'>
                    {t(IDS_NoDataFoundLast24Hour)}
                </div>
            </>
            }
        </div>
    )

    
}

export default VcDailyChart;
